import PerfectScrollbar from 'react-perfect-scrollbar';

import { Box, Card, CardHeader, Divider } from '@material-ui/core';
// import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import 'bootstrap/dist/css/bootstrap.min.css';
import RedCapImage from 'src/images/caps/red-cap.png';
import OrangeCapImage from 'src/images/caps/orange-cap.png';
import GreenCapImage from 'src/images/caps/green-cap.png';
import moment from 'moment';
const cap = {
  avatarUrl: OrangeCapImage,
  type: 'Red Score',
  score: '40',
  color: 'Red',
  LastUpdated: '31/03/2021',
  status: 'Completed'
};

const CapDetails = ({ assessmentScore, assessment, capPercent }) => {
  let cap = {
    avatarUrl: RedCapImage,
    type: 'Red Score',
    color: 'Red'
  };

  if (capPercent >= 70) {
    cap = {
      avatarUrl: GreenCapImage,
      type: 'Green Score',
      color: 'Green'
    };
  } else if (capPercent >= 40 && capPercent < 70) {
    cap = {
      avatarUrl: OrangeCapImage,
      type: 'Orange Score',
      color: 'Orange'
    };
  }

  return (
    <Card id="CapDetails">
      <CardHeader title="Assessment Details" />
      <Divider />
      <PerfectScrollbar>
        <Box>
          <div className="container">
            <div className="mt-2 d-flex flex-row justify-content-between align-items-center">
              <div>
                {' '}
                <h5>Assessment: {assessment.assessment.name}</h5>
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div>
                    <span style={{ fontSize: '15px' }}>
                      Company Name: {assessment.contractorName}
                    </span>
                  </div>
                  {/* <div><span style={{fontSize:"15px"}}>Assessment Score: {assessmentScore}</span></div> */}
                </div>
              </div>
              <div>
                <div className="mr-3 my-auto text-center ">
                  <h3>Total Score</h3>
                  <h5
                    style={{ color: '#1D2C56', fontWeight: 'bold' }}
                    className={cap.color}
                  >
                    {+(Math.round(capPercent + 'e+2') + 'e-2')}
                  </h5>
                </div>
              </div>
            </div>

            <div className=" row">
              <div className="col-md-6">
                <div className="my-auto text-center score">
                  <h3>Assessment Score</h3>
                  <h1 className={cap.color}>{assessmentScore}</h1>
                </div>
              </div>
              <div className="col-md-6  text-center score">
                <img src={cap.avatarUrl} alt="cap" id="cap" />
                <h5 className={cap.color}>{cap.type}</h5>
              </div>

              {/* <div className="col-md-6">
                <div className="my-auto text-center score">
                  <h3>Total Score</h3>
                  <h1 className={cap.color}>
                    {+(Math.round(capPercent + 'e+2') + 'e-2')}
                  </h1>
                </div>
              </div> */}

              <div className=" col-md-6 pull-left">
                <p className="status ">
                  Status:
                  <k className=" text-success">
                    {' '}
                    {assessment.status.onlyFirstCapital()}
                  </k>
                </p>
              </div>
              <div className=" col-md-6 pull-right">
                <p className="LastUpdated">
                  Last Updated:&nbsp;
                  {moment(assessment.lastAccessTime).format('DD/MM/YYYY')}
                </p>
              </div>
            </div>
          </div>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export default CapDetails;

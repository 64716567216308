import {
  Box,
  CardContent,
  Checkbox,
  TextareaAutosize,
  Chip,
  Tooltip
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useFormik } from 'formik';
import { MDBDataTableV5, MDBTableHead } from 'mdbreact';
import { MDBTable, MDBTableBody } from 'mdbreact';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Form, ListGroup, Tab, Tabs } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal'; // for Modal
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import OverlaySpinner from 'src/components/common/OverlaySpinner';
import {
  assessmentAdded,
  getAllQuestionsAssessment,
  getAssessmentCategories
} from 'src/store/assessment/thunk';
import { getAllContractorsList } from 'src/store/auditors/thunk';
import { setLoading } from 'src/store/auth/actions';
import swal from 'sweetalert';
import * as Yup from 'yup';
import API from '../../../Api';
import './add_assessment.css';
import InputLabel from '@material-ui/core/InputLabel';
import QuestionsImport from './QuestionsImport';
import ShortTextIcon from '@material-ui/icons/ShortText';
import SegmentIcon from '@material-ui/icons/Segment';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import _ from 'lodash';

export const menuItems = [
  {
    value: 'numeric',
    lable: 'Numeric answer',
    icon: (
      <ConfirmationNumberIcon
        sx={{
          mr: 1
        }}
      />
    ),
    divider: false,
    answerType: <TextField />
  },
  {
    value: 'shortAnswer',
    lable: 'Short answer',
    icon: (
      <ShortTextIcon
        sx={{
          mr: 1
        }}
      />
    ),
    divider: false,
    answerType: <TextField />
  },
  {
    value: 'longAnswer',
    lable: 'Long answer',
    icon: (
      <SegmentIcon
        sx={{
          mr: 1
        }}
      />
    ),
    divider: false,
    answerType: <TextareaAutosize />
  },
  {
    value: 'multiChoice',
    lable: 'Multiple choice',
    icon: (
      <LibraryAddCheckIcon
        sx={{
          mr: 1
        }}
      />
    ),
    divider: false,
    answerType: <></>
  },
  {
    value: 'checkbox',
    lable: 'Checkbox',
    icon: (
      <CheckBoxIcon
        sx={{
          mr: 1
        }}
      />
    ),
    divider: false,
    answerType: <Checkbox />
  }
];

const Add_assess = (props) => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const { categories, questions } = useSelector((state) => state.assessment);
  const { userData } = useSelector((state) => state.auth);
  const { allContrators } = useSelector((state) => state.auditors);

  useEffect(() => {
    if (userData && userData?.companyId) {
      dispatch(getAllContractorsList(userData.companyId));
      dispatch(getAssessmentCategories(userData.companyId));
      dispatch(getAllQuestionsAssessment(userData.companyId));
    }
  }, []);

  const useMenuItemStyles = makeStyles((theme) => ({
    root: {
      whiteSpace: 'unset !important',
      wordBreak: 'break-word'
    },
    social: {
      // color: '#F5CD6A !important',
      '&$socialChecked': {
        color: '#F5CD6A !important',
        '& + $socialBar': {
          backgroundColor: '#F5CD6A !important'
        }
      }
    },
    socialTrack: {},
    socialBar: {},
    socialChecked: {
      '& + $socialTrack': {
        backgroundColor: '#F5CD6A !important'
      }
    },

    governance: {
      // color: '#F5CD6A !important',
      '&$governanceChecked': {
        color: '#EC9466 !important',
        '& + $governanceBar': {
          backgroundColor: '#EC9466 !important'
        }
      }
    },
    governanceTrack: {},
    governanceBar: {},
    governanceChecked: {
      '& + $governanceTrack': {
        backgroundColor: '#EC9466 !important'
      }
    }
  }));

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25%'
      }
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 300
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 500
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  }));
  const classes = useStyles();
  const listClasses = useMenuItemStyles();
  const [category, setCategory] = React.useState('');
  const handleChange = (event) => {
    console.log(event.target.value);
    setCategory(event.target.value);
  };

  const [allCompaniesSelected, setAllCompaniesSelected] = useState(false);

  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => {
    sectionQueFormik.resetForm();
    setShow2(false);
  };
  const handleShow2 = () => setShow2(true);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [state, setState] = React.useState({
    environment: false,
    social: false,
    governance: false,
    others: false
  });

  const [mainIndex, setMainIndex] = useState(0);
  const [changedCategory, setChangedCategory] = useState('');
  const [editCategoryModal, setEditCategoryModal] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [editQuestionModal, setEditQuestionModal] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [filter, setFilter] = useState('ALL');
  const [confirmQuestions, setConfirmQuestions] = useState([]);

  const [questionTabClose, setQuestionTabClose] = useState(false);
  const [defaultActiveKey, setDefaultActiveKey] = useState(
    'Select Exisiting Qustion'
  );
  const [validateStatus, setValidateStatus] = useState(false);

  const handleChange2 = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    formik.setFieldValue('envir', state);
  };

  const searchQuesByID = (id) => {
    return questions.find((que) => {
      return que._id == id;
    })?.questText;
  };

  const onSubmitForm = async (values, helpers) => {
    // ESGO checking
    // let esgoValidation = values.sections.every(sect =>{
    //   if(sect.factors.environment || sect.factors.social || sect.factors.governance || sect.factors.others){
    //     return true;
    //   }
    //   return false;
    // })

    // if(!esgoValidation){
    //   return swal('Please select ESGO', '', 'error');
    // }

    setLoading(true);
    console.log('useFormik onSubmit', { values, helpers });
    let val = {
      ...values
    };
    val.status = 'NEW';
    val.creator = {
      id: userData.companyId,
      userType: userData.type,
      name: userData.name
    };
    val.companyId = userData.companyId;
    val.companyName = userData.name;
    //val.contractors= []

    console.log(
      '🚀 ~ file: add_assessment.js ~ line 78 ~ onSubmitForm ~ val',
      val
    );

    val.contractors = val.contractors.filter((cont) => {
      return !!cont;
    });

    val.sections = val.sections.map((sect) => {
      sect.questions = sect.questions.filter((que) => {
        return !!que?.question;
      });
      return sect;
    });

    await dispatch(assessmentAdded(val));
    handleClose3();
    // helpers.resetForm();
    setLoading(false);
    Navigate('/sapphire/Assessment');
  };

  const onSubmitQuestionForm = async (val, helpers) => {
    console.log(
      '🚀 ~ file: add_assessment.js ~ line 78 ~ onSubmitQuestionForm ~ val',
      val
    );

    try {
      setLoading(true);
      await API.createQuestion({
        companyId: userData.companyId,
        questText: val.question
      });

      swal({
        title: 'Question Added Successfully',
        icon: 'success',
        button: 'Ok'
      });
      helpers.resetForm();

      await dispatch(getAllQuestionsAssessment(userData.companyId));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const onSubmitCategoryForm = async (val, helpers) => {
    // console.log("onSubmitCategoryForm", val, categories.find(el => el.name === val.category));
    try {
      setLoading(true);
      setChangedCategory(val.category);
      if (!categories.find((el) => el.name === val.category)) {
        let categoryRes = await API.createAssessCategory({
          companyId: userData.companyId,
          name: val.category
        });
        //swal
        formik.setFieldValue(
          `sections.${currentSectionIndex}.category`,
          categoryRes.data.data._id
        );
        swal({
          title: 'Category Added Successfully',
          icon: 'success',
          button: 'Ok'
        });
      } else {
        swal({
          title: 'Category already in available categories!',
          // text: 'Error',
          icon: 'error',
          button: 'Ok'
        });
      }
      helpers.resetForm();
      dispatch(getAssessmentCategories(userData.companyId));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const onSubmitSelectedQuestions = (val) => {
    setLoading(true);
    console.log('VALUES>>>', val.selectedQues);
    let temp = [];
    temp = val.selectedQues.filter((q) => {
      return q?.question;
    });
    setConfirmQuestions(temp);
    handleClose2();
    console.log('TEMP>>>', temp);
    setLoading(false);
  };

  const onSubmitEditCategoryForm = async (val) => {
    try {
      setLoading(true);
      let res = await API.editAssessCategory({
        categoryId: selectedCategory._id,
        updateData: {
          name: val.category
        }
      });
      setEditCategoryModal(false);
      setSelectedCategory(null);
      await dispatch(getAssessmentCategories(userData.companyId));
      swal('Category Edited successfully', '', 'success');

      setLoading(false);
    } catch (err) {
      setLoading(false);
      swal({
        title: 'Error',
        text: err.message,
        icon: 'error',
        button: 'Ok'
      });
      dispatch(setLoading(false));
    }
  };

  const onSubmitEditQuestionForm = async (val) => {
    // console.log(val)
    // setEditQuestionModal(false)
    try {
      setLoading(true);
      let res = await API.editQuestion({
        questionId: selectedQuestion._id,
        updateData: {
          questText: val.question
        }
      });
      setEditQuestionModal(false);

      setSelectedQuestion(null);
      await dispatch(getAllQuestionsAssessment(userData.companyId));
      setLoading(false);
      swal('Edited successfully', {
        icon: 'success'
      });
    } catch (err) {
      setLoading(false);
      swal({
        title: 'Error',
        text: err.message,
        icon: 'error',
        button: 'Ok'
      });
    }
  };

  // formik

  const formik = useFormik({
    initialValues: {
      name: '',
      dueDate: '',
      contractors: [],
      sections: [
        {
          category: '',
          questions: [],
          totalMarks: 0,
          factors: {
            environment: false,
            social: false,
            governance: false,
            others: false
          }
        }
      ]
      // catge: '',
      // factors: null,
      // marks: ''
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3)
        .matches(/^[a-zA-Z0-9 ,.-?"':;]*$/, 'Special characters not allowed!')
        .required('Name is required'),
      dueDate: Yup.string().required('Date is required'),
      contractors: Yup.array()
        .test({
          message: 'Please select minimum 1 company',
          test: (arr) => arr.filter((ar) => !!ar).length > 0
        })
        .required('Contractor is required'),
      sections: Yup.array().of(
        Yup.object()
          .shape({
            category: Yup.string().required('Category is required'),
            questions: Yup.array()
              .compact(function (v) {
                return v == null;
              })
              .required('Minimum one question to be selected'),
            totalMarks: Yup.number('Marks must be number')
              .required('Marks is required')
              .min(1, 'Marks is required')
          })
          .required()
      )
    }),

    validateOnChange: true,

    onSubmit: onSubmitForm
  });

  const questionFormik = useFormik({
    initialValues: {
      question: ''
    },
    onSubmit: onSubmitQuestionForm,
    validationSchema: Yup.object({
      question: Yup.string()
        .min(2, 'Question must be at least 2 characters')
        .trim('Question is required')
        .test(
          'exists',
          'Question already exists',
          (val) => !!!questions.find((que) => que.questText === val)
        )
        // .matches(/^\\s+$/, "Category is required")
        .required('Question is required')
    })
  });

  const sectionQueFormik = useFormik({
    initialValues: {
      sectionQues: (formik.values.sections ?? [{ questions: [] }])[
        currentSectionIndex
      ]?.questions
    },
    enableReinitialize: true,
    onSubmit: (values, helpers) => {
      console.log('VALUES QUE', values);
      formik.setFieldValue(
        `sections.${currentSectionIndex}.questions`,
        values.sectionQues
      );
      helpers.resetForm();
      handleClose2();
    }
  });

  const categoryFormik = useFormik({
    initialValues: {
      category: ''
    },
    onSubmit: onSubmitCategoryForm,
    validationSchema: Yup.object({
      category: Yup.string()
        .matches(/^[a-zA-Z0-9 ]*$/, 'Special characters not allowed !')
        .min(3, 'Category must be at least 3 characters')
        .trim('Category is required')
        // .matches(/^\\s+$/, "Category is required")
        .required('Category is required')
    })
  });

  const editCategoryFormik = useFormik({
    initialValues: {
      category: selectedCategory?.name
    },
    enableReinitialize: true,
    onSubmit: onSubmitEditCategoryForm,
    validationSchema: Yup.object({
      category: Yup.string()
        .min(3)
        .matches(/^[a-zA-Z0-9 ]*$/, 'Special characters not allowed!')
        .required('Category is required')
    })
  });

  const editQuestionFormik = useFormik({
    initialValues: {
      question: selectedQuestion?.questText
    },
    enableReinitialize: true,
    onSubmit: onSubmitEditQuestionForm,
    validationSchema: Yup.object({
      question: Yup.string()
        .min(3, 'Question must be at least 3 characters')
        .required('Question is required')
    })
  });

  const selectedQuestions = useFormik({
    initialValues: {
      selectedQues: [
        {
          question: '',
          questText: '',
          questType: {
            yesNo: true,
            text: false,
            attachment: true,
            InputType: '',
            options: []
          }
        }
      ]
    },
    onSubmit: onSubmitSelectedQuestions
  });

  const validationOfMainFields = (touched, errors, sectionIndex, fieldName) => {
    if (
      touched.hasOwnProperty('sections') &&
      errors.hasOwnProperty('sections')
    ) {
      if (typeof touched?.sections[sectionIndex] != 'undefined') {
        if (typeof errors?.sections[sectionIndex] != 'undefined') {
          return errors?.sections[sectionIndex][fieldName];
        }
      }
    } else {
      return '';
    }
  };

  const AnswerTypes = ({ id, type, index, values }) => {
    switch (type) {
      case 'shortAnswer':
        return (
          <TextField
            type="text"
            maxRows={1}
            disabled
            style={{
              height: '20px',
              width: '100px'
            }}
          />
        );
      case 'numeric':
        return (
          <TextField
            type="number"
            maxRows={1}
            disabled
            style={{
              height: '20px',
              width: '100px'
            }}
          />
        );
      case 'longAnswer':
        return <TextareaAutosize maxRows={1} disabled />;
      case 'checkbox':
        return <Checkbox disabled />;
      case 'multiChoice':
        return (
          <div>
            <Tooltip title="Type Enter to add multiple values">
              <Autocomplete
                multiple
                id="tags-filled"
                options={[]}
                freeSolo
                value={values}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label="Choices"
                    placeholder="Add new choices"
                  />
                )}
                onChange={(event, value) => {
                  sectionQueFormik.setFieldValue(`sectionQues.${index}`, {
                    question: id,
                    questType: {
                      yesNo: false,
                      text: false,
                      attachment: true,
                      answerType: type,
                      options: value
                    }
                  });
                }}
              />
            </Tooltip>

            {(!values || values?.length === 0) && (
              <span
                style={{
                  color: '#f96e6e '
                }}
              >
                Please add choices
              </span>
            )}
          </div>
        );
      default:
        return <> Choose Type </>;
    }
  };

  useEffect(() => {
    if (!_.isEmpty(sectionQueFormik?.values)) {
      let status = false;
      sectionQueFormik?.values?.sectionQues?.map((que, index) => {
        if (que?.answerType === 'multiChoice' && que?.options?.length === 0) {
          status = true;
        }
      });
      setValidateStatus(status);
    }
  }, [sectionQueFormik?.values]);

  return (
    <Box {...props}>
      <Box sx={{ mt: 3 }}>
        {loading && <OverlaySpinner />}
        <CardContent>
          <form onSubmit={formik.handleSubmit}>
            <div>
              <h2>Add Assessment</h2>
            </div>
            <div className="row">
              <div className="col-md-4 my-2">
                <TextField
                  id="standard-basic"
                  label="Name of Assessment"
                  variant="standard"
                  className={classes.textField}
                  onChange={formik.handleChange('name')}
                  value={formik.values.name}
                  onFocus={() => formik.setFieldTouched('name')}
                  InputLabelProps={{
                    shrink: true
                  }}
                />

                <div>
                  <small className="text-danger form-text">
                    {formik.touched.name && formik.errors.name}
                  </small>
                </div>
              </div>
              <div className="col-md-4 my-2">
                <TextField
                  id="standard-basic"
                  label="Due Date"
                  variant="standard"
                  type="date"
                  inputProps={{
                    min: moment().format('YYYY-MM-DD').toString()
                  }}
                  className={classes.textField}
                  onChange={formik.handleChange('dueDate')}
                  value={formik.values.dueDate}
                  onFocus={() => formik.setFieldTouched('dueDate')}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <div>
                  <small className="text-danger form-text">
                    {formik.touched.dueDate && formik.errors.dueDate}
                  </small>
                </div>
              </div>
              {/* <div className="col-md-2 my-2">
                <FormControlLabel
                  control={
                    <Switch
                      // checked={state.checkedA}
                      // onChange={formik.handleChange(
                      //   `sections.${index}.factors.environment`
                      // )}
                      // value={
                      //   formik.values.sections[`${index}.factors.environment`]
                      // }
                      name="checkedA"
                      color="success"
                    />
                  }
                  label="Option"
                  labelPlacement="start"
                />
              </div> */}
            </div>
            <hr />
            {formik.values.sections.map((sect, index) => {
              return (
                <>
                  <div>
                    <div className="my-4">
                      <div className=" row">
                        <div className="col-md-3 my-3">
                          <FormControl
                            variant="outlined"
                            className={classes.formControl + 'col-md-3'}
                            className="select"
                          >
                            <Select
                              value={formik.values.sections[index]?.category}
                              onChange={formik.handleChange(
                                `sections.${index}.category`
                              )}
                              displayEmpty
                              className={
                                classes.selectEmpty + classes.formControl
                              }
                              inputProps={{ 'aria-label': 'Without label' }}

                              ///  className="select"
                            >
                              <MenuItem value="">Select Category</MenuItem>
                              {categories.length != 0
                                ? categories.map((cat, index) => {
                                    return (
                                      <MenuItem
                                        style={{
                                          maxWidth: '400px'
                                        }}
                                        classes={{ root: listClasses.root }}
                                        key={index}
                                        value={cat._id}
                                      >
                                        {cat.name}
                                      </MenuItem>
                                    );
                                  })
                                : null}
                            </Select>
                            {/* <FormHelperText>Without label</FormHelperText> */}
                          </FormControl>
                        </div>

                        <div className="col-md-2">
                          <Button
                            variant=""
                            className="view-btn add-category-button"
                            onClick={() => {
                              //set index
                              setCurrentSectionIndex(index);
                              handleShow();
                            }}
                          >
                            Add Category
                          </Button>
                        </div>
                        <div className="col-md-7">
                          <div className="d-flex w-100 justify-content-end">
                            {index !== 0 ? (
                              <Button
                                variant="danger"
                                // className="view-btn add-category-button"
                                onClick={() =>
                                  formik.setFieldValue(
                                    'sections',
                                    formik.values.sections.filter(
                                      (_, ind) => ind !== index
                                    )
                                  )
                                }
                              >
                                Remove Section
                              </Button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      {/* <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        className="select-assesment"
                      >
                        <InputLabel id="demo-simple-select-filled-label">
                          Select Assessment
                        </InputLabel>
                        <Select
                          value={formik.values.sections[index]?.category}
                          onChange={formik.handleChange(
                            `sections.${index}.category`
                          )}
                          classes={classes.selectEmpty + classes.formControl}
                          inputProps={{ 'aria-label': 'Without label' }}
                          style={{ marginTop: 0, height: 40, width: '20rem' }}
                        >
                          {categories.length != 0
                            ? categories.map((cat, index) => {
                                return (
                                  <MenuItem key={index} value={cat._id}>
                                    {cat.name}
                                  </MenuItem>
                                );
                              })
                            : null}
                        </Select>
                        <div>
                          <small className="text-danger form-text">
                            {validationOfMainFields(
                              formik.touched,
                              formik.errors,
                              index,
                              'category'
                            )}
                          </small>
                        </div>
                      </FormControl> */}
                      <div className="assessment-add-categroy">
                        {/* <Button
                          variant=""
                          className="chat-btn"
                          onClick={handleShow}
                        >
                          Add Category
                        </Button> */}
                        <Modal
                          size="lg"
                          show={show}
                          onHide={handleClose}
                          backdrop="static"
                          keyboard={false}
                          centered
                        >
                          <Modal.Header>
                            <Modal.Title>Add Category</Modal.Title>
                            <CloseIcon
                              fontSize="medium"
                              onClick={handleClose}
                              style={{ color: 'red', cursor: 'pointer' }}
                            />
                          </Modal.Header>
                          <Modal.Body className="add-category">
                            <div className="row">
                              <div className="col-md-5">
                                <h6>Add Category</h6>
                                <Form onSubmit={categoryFormik.handleSubmit}>
                                  <Form.Group
                                    className="sm-3"
                                    controlId="text"
                                    style={{ marginTop: '18px' }}
                                  >
                                    <Form.Control
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter New Category Name"
                                      onChange={categoryFormik.handleChange(
                                        'category'
                                      )}
                                      value={categoryFormik.values.category}
                                      style={{
                                        paddingTop: '10px',
                                        paddingBottom: '10px'
                                      }}
                                    />
                                    <div>
                                      <small className="text-danger form-text">
                                        {categoryFormik.touched.category &&
                                          categoryFormik.errors.category}
                                      </small>
                                      <br />
                                    </div>
                                  </Form.Group>
                                </Form>
                                <button
                                  onClick={categoryFormik.handleSubmit}
                                  type="button"
                                  disabled={categoryFormik.isSubmitting}
                                  className="btn view-btn add-category-btn pull-left"
                                >
                                  Add
                                </button>
                              </div>
                              <div className="col-md-7">
                                <h6>Available Categories</h6>
                                <div
                                  className="add-category-card"
                                  style={{
                                    textAlign: 'initial',
                                    overflowY: 'scroll',
                                    height: '12rem'
                                  }}
                                >
                                  <ListGroup variant="flush">
                                    {categories.length != 0
                                      ? categories.map((cat, index) => {
                                          return (
                                            <ListGroup.Item key={index}>
                                              <div className="row">
                                                <div className="col col-lg-8">
                                                  {cat.name}
                                                </div>
                                                <div className="col col-lg-4">
                                                  <button
                                                    onClick={() => {
                                                      setSelectedCategory(cat);
                                                      setEditCategoryModal(
                                                        true
                                                      );
                                                    }}
                                                    type="button"
                                                    className="btn btn-delete pull-right"
                                                  >
                                                    Edit
                                                  </button>
                                                </div>
                                              </div>
                                            </ListGroup.Item>
                                          );
                                        })
                                      : null}
                                  </ListGroup>
                                </div>
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>
                    </div>
                    <div className="my-3">
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={state.checkedA}
                              onChange={formik.handleChange(
                                `sections.${index}.factors.environment`
                              )}
                              value={
                                formik.values.sections[
                                  `${index}.factors.environment`
                                ]
                              }
                              name="checkedA"
                              color="success"
                            />
                          }
                          label="Environment"
                          labelPlacement="start"
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={state.checkedB}
                              onChange={formik.handleChange(
                                `sections.${index}.factors.social`
                              )}
                              value={
                                formik.values.sections[
                                  `${index}.factors.social`
                                ]
                              }
                              name="checkedB"
                              classes={{
                                switchBase: listClasses.social,
                                checked: listClasses.socialChecked,
                                bar: listClasses.socialBar,
                                track: listClasses.socialTrack
                              }}
                            />
                          }
                          label="Social"
                          labelPlacement="start"
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={state.checkedC}
                              onChange={formik.handleChange(
                                `sections.${index}.factors.governance`
                              )}
                              value={
                                formik.values.sections[
                                  `${index}.factors.governance`
                                ]
                              }
                              name="checkedC"
                              className="governance-switch"
                              classes={{
                                switchBase: listClasses.governance,
                                checked: listClasses.governanceChecked,
                                bar: listClasses.governanceBar,
                                track: listClasses.governanceTrack
                              }}
                            />
                          }
                          label="Governance"
                          labelPlacement="start"
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={state.checkedD}
                              onChange={formik.handleChange(
                                `sections.${index}.factors.others`
                              )}
                              value={
                                formik.values.sections[
                                  `${index}.factors.others`
                                ]
                              }
                              name="checkedD"
                              className="switch-other"
                            />
                          }
                          label="Others"
                          labelPlacement="start"
                        />
                      </FormGroup>
                    </div>
                    <div className="row marks-row my-3">
                      <div className="col-md-1">
                        <h3>Enter Total Points</h3>
                      </div>

                      <div className="col-md-1">
                        <Form>
                          <Form.Group className="sm-3" controlId="text">
                            <FormControl>
                              <Input
                                required
                                type="number"
                                className="form-control my-1"
                                onChange={(e) => {
                                  console.log(e.target.value);
                                  formik.setFieldValue(
                                    `sections.${index}.totalMarks`,
                                    e.target.value
                                  );
                                }}
                                value={
                                  formik.values.sections[`${index}.totalMarks`]
                                }
                                onFocus={() =>
                                  formik.setFieldTouched(
                                    `sections.${index}.totalMarks`
                                  )
                                }
                                placeholder="Enter Total Points"
                                style={{ width: '18rem', height: 30 }}
                              />
                              <div>
                                <small className="text-danger form-text">
                                  {validationOfMainFields(
                                    formik.touched,
                                    formik.errors,
                                    index,
                                    'totalMarks'
                                  )}
                                </small>
                              </div>
                            </FormControl>
                          </Form.Group>
                        </Form>
                      </div>
                    </div>

                    <div className="row question-row my-3">
                      <div className="col-md-1">
                        <h3>Question</h3>
                      </div>
                      <div className="col-md-2 mx-2">
                        <Button
                          variant=""
                          className="chat-btn"
                          onClick={() => {
                            setMainIndex(index);
                            setCurrentSectionIndex(index);
                            handleShow2();
                          }}
                        >
                          Add Question
                        </Button>
                        <Modal
                          show={show2}
                          onHide={handleClose2}
                          size="xl"
                          backdrop="static"
                          keyboard="false"
                          centered
                        >
                          <Modal.Header>
                            <Modal.Title>Add Question</Modal.Title>
                            <CloseIcon
                              fontSize="medium"
                              onClick={() => {
                                if (
                                  defaultActiveKey == 'Select Exisiting Qustion'
                                ) {
                                  handleClose2();
                                } else {
                                  setDefaultActiveKey(
                                    'Select Exisiting Qustion'
                                  );
                                }
                              }}
                              style={{ color: 'red', cursor: 'pointer' }}
                            />
                          </Modal.Header>
                          <Modal.Body>
                            <Tabs
                              defaultActiveKey="Select Exisiting Qustion"
                              activeKey={defaultActiveKey}
                              id="uncontrolled-tab-example"
                              className="addbutton mb-3"
                              variant="pills"
                              centered
                              onSelect={(value) => setDefaultActiveKey(value)}
                            >
                              <Tab
                                eventKey="Select Exisiting Qustion"
                                title="Select Existing Question"
                              >
                                <div
                                  className="add-category-card"
                                  style={{
                                    width: '70rem',
                                    textAlign: 'initial',
                                    overflowY: 'scroll',
                                    overflowX: 'hidden',
                                    height: '65vh'
                                  }}
                                >
                                  <MDBDataTableV5
                                    fixed
                                    searchTop
                                    disableRetreatAfterSorting
                                    searchBottom={false}
                                    className="text-center"
                                    scrollX={false}
                                    scrollY={false}
                                    sortRows={[]}
                                    hover
                                    sortable
                                    entriesOptions={[5, 10, 20]}
                                    entries={5}
                                    pagesAmount={6}
                                    data={{
                                      columns: [
                                        {
                                          label: 'Sr.No',
                                          field: 'ref',
                                          width: 100,
                                          attributes: {
                                            'aria-controls': 'DataTable',
                                            'aria-label': 'id'
                                          }
                                        },

                                        {
                                          label: 'Questions',
                                          field: 'Questions',
                                          width: 60
                                        },
                                        {
                                          label: 'Input type',
                                          field: 'InputType',
                                          width: 120
                                        },
                                        {
                                          label: 'Actions',
                                          field: 'Actions',
                                          sort: 'disabled',
                                          width: 30
                                        }
                                      ],
                                      rows:
                                        questions.length != 0
                                          ? questions
                                              .map((question) => {
                                                let searchArray =
                                                  formik.values.sections.filter(
                                                    (section, index) =>
                                                      index !== mainIndex
                                                  );
                                                let isUsed = searchArray.some(
                                                  (section) =>
                                                    section.questions.some(
                                                      (que) =>
                                                        que?.question ===
                                                        question._id
                                                    )
                                                );
                                                return { ...question, isUsed };
                                              })
                                              .map((ques, queIndex) => {
                                                let queArray =
                                                  (
                                                    sectionQueFormik.values
                                                      .sectionQues ?? []
                                                  ).length > 0
                                                    ? sectionQueFormik.values
                                                        .sectionQues[queIndex]
                                                    : {};

                                                return {
                                                  ref: (
                                                    <div
                                                      className="d-flex w-100 h-100 justify-content-center align-self-center"
                                                      style={{
                                                        marginTop: '50%',
                                                        transform:
                                                          'translateY(-50%)'
                                                      }}
                                                    >
                                                      {queIndex + 1}
                                                    </div>
                                                  ),
                                                  InputType: (
                                                    <AnswerTypes
                                                      type={
                                                        queArray?.questType
                                                          ?.answerType
                                                      }
                                                      id={ques._id}
                                                      index={queIndex}
                                                      values={
                                                        queArray?.questType
                                                          ?.options
                                                      }
                                                    />
                                                  ),
                                                  Questions: (
                                                    <span
                                                      searchvalue={
                                                        ques.questText
                                                      }
                                                      style={{
                                                        marginTop: '2.5%',
                                                        minWidth: '250px',
                                                        maxWidth: '400px',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'start',
                                                        alignItems: 'center'
                                                      }}
                                                    >
                                                      {ques.questText}
                                                    </span>
                                                  ),
                                                  Actions: (
                                                    <>
                                                      <FormGroup
                                                        style={{
                                                          minWidth: '500.32px',
                                                          maxWidth: '600.32px',
                                                          paddingLeft: '15%'
                                                        }}
                                                        row
                                                      >
                                                        <div
                                                          className="d-flex flex-row justify-content-around"
                                                          style={{
                                                            width: '85%'
                                                          }}
                                                        >
                                                          <div>
                                                            {/* menu items for answers */}

                                                            <Select
                                                              id="menu-items"
                                                              labelId="demo-simple-select-helper-label"
                                                              value={
                                                                queArray
                                                                  ?.questType
                                                                  ?.answerType
                                                                  ? queArray
                                                                      ?.questType
                                                                      ?.answerType
                                                                  : ''
                                                              }
                                                              label="Select Answer Type"
                                                              sx={{
                                                                width: '160px'
                                                              }}
                                                              onChange={(e) => {
                                                                sectionQueFormik.setFieldValue(
                                                                  `sectionQues.${queIndex}`,
                                                                  {
                                                                    question:
                                                                      ques._id,
                                                                    questType: {
                                                                      yesNo: false,
                                                                      text: false,
                                                                      attachment: true,
                                                                      answerType:
                                                                        e.target
                                                                          .value
                                                                    }
                                                                  }
                                                                );
                                                              }}
                                                            >
                                                              <MenuItem
                                                                selected
                                                                disabled
                                                              >
                                                                {' '}
                                                                Select type{' '}
                                                              </MenuItem>
                                                              {menuItems.map(
                                                                (menuItem) => {
                                                                  return (
                                                                    <MenuItem
                                                                      key={
                                                                        menuItem.value
                                                                      }
                                                                      value={
                                                                        menuItem.value
                                                                      }
                                                                      divider={
                                                                        menuItem.divider
                                                                      }
                                                                    >
                                                                      {' '}
                                                                      {
                                                                        menuItem.icon
                                                                      }{' '}
                                                                      {
                                                                        menuItem.lable
                                                                      }{' '}
                                                                    </MenuItem>
                                                                  );
                                                                }
                                                              )}
                                                            </Select>
                                                          </div>
                                                          {/* <div>
                                                            <FormControlLabel
                                                              control={
                                                                <Switch
                                                                  disabled={
                                                                    ques.isUsed
                                                                  }
                                                                  checked={
                                                                    !!queArray?.question
                                                                  }
                                                                  onChange={(
                                                                    data
                                                                  ) => {
                                                                    console.log(
                                                                      'INDEX>>',
                                                                      index
                                                                    );

                                                                    if (
                                                                      data
                                                                        .target
                                                                        .checked &&
                                                                      !!ques._id
                                                                    ) {
                                                                      sectionQueFormik.setFieldValue(
                                                                        `sectionQues.${queIndex}`,
                                                                        {
                                                                          question:
                                                                            ques._id,
                                                                          questType:
                                                                            {
                                                                              yesNo: true,
                                                                              text: false,
                                                                              attachment: true
                                                                            }
                                                                        }
                                                                      );
                                                                    } else if (
                                                                      !!ques._id
                                                                    ) {
                                                                      sectionQueFormik.setFieldValue(
                                                                        `sectionQues.${queIndex}`,
                                                                        null
                                                                      );
                                                                    }
                                                                  }}
                                                                  //name="checkedA"
                                                                  color="success"
                                                                />
                                                              }
                                                              label="Select"
                                                              labelPlacement="start"
                                                            />
                                                          </div>
                                                          <div>
                                                            <FormControlLabel
                                                              control={
                                                                <Switch
                                                                  checked={
                                                                    !!queArray
                                                                      ?.questType
                                                                      ?.text
                                                                  }
                                                                  disabled={
                                                                    ques.isUsed
                                                                  }
                                                                  onChange={(
                                                                    data
                                                                  ) => {
                                                                    if (
                                                                      !!!queArray
                                                                    ) {
                                                                      return;
                                                                    }
                                                                    if (
                                                                      data
                                                                        .target
                                                                        .checked &&
                                                                      !!ques._id
                                                                    ) {
                                                                      sectionQueFormik.setFieldValue(
                                                                        `sectionQues.${queIndex}`,
                                                                        {
                                                                          question:
                                                                            ques._id,
                                                                          questType:
                                                                            {
                                                                              ...queArray?.questType,
                                                                              text: true
                                                                            }
                                                                        }
                                                                      );
                                                                    } else if (
                                                                      !!ques._id
                                                                    ) {
                                                                      sectionQueFormik.setFieldValue(
                                                                        `sectionQues.${queIndex}`,
                                                                        {
                                                                          question:
                                                                            ques._id,
                                                                          questType:
                                                                            {
                                                                              ...queArray?.questType,
                                                                              text: false
                                                                            }
                                                                        }
                                                                      );
                                                                    }
                                                                  }}
                                                                  className="governance-switch"
                                                                />
                                                              }
                                                              label="Text"
                                                              labelPlacement="start"
                                                            />
                                                          </div> */}
                                                          <div>
                                                            <button
                                                              onClick={() => {
                                                                setSelectedQuestion(
                                                                  ques
                                                                );
                                                                setEditQuestionModal(
                                                                  true
                                                                );
                                                              }}
                                                              type="button"
                                                              className="btn btn-primary pull-right"
                                                            >
                                                              Edit
                                                            </button>
                                                          </div>
                                                          <div>
                                                            <button
                                                              onClick={() => {
                                                                // remove this question data
                                                                sectionQueFormik.setFieldValue(
                                                                  `sectionQues.${queIndex}`,
                                                                  undefined
                                                                );
                                                              }}
                                                              type="button"
                                                              className="btn btn-outline-danger pull-right"
                                                            >
                                                              Unselect
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </FormGroup>
                                                    </>
                                                  )
                                                };
                                              })
                                          : []
                                    }}
                                  />

                                  <div className="addbutton my-2">
                                    <button
                                      onClick={() => {
                                        sectionQueFormik.handleSubmit();
                                      }}
                                      type="button"
                                      className="btn view-btn"
                                      disabled={validateStatus}
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </Tab>
                              <Tab
                                eventKey="Create New Question"
                                title="Create New Question"
                              >
                                <Form onSubmit={questionFormik.handleSubmit}>
                                  {/* create question : use formik */}
                                  <Form.Group className="sm-3" controlId="text">
                                    <FormControl
                                      className="add-category-card"
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '50px'
                                      }}
                                    >
                                      <Input
                                        className="form-control my-1"
                                        type="text"
                                        placeholder="Enter Text"
                                        onChange={questionFormik.handleChange(
                                          'question'
                                        )}
                                        onFocus={questionFormik.handleBlur(
                                          'question'
                                        )}
                                        value={questionFormik.values?.question}
                                        style={{
                                          width: '47rem',
                                          marginRight: '20px'
                                        }}
                                        endAdornment={
                                          <InputAdornment position="end">
                                            {/*----------  Delete button  ----------*/}
                                            {/* <button
                                            onClick={() => {
                                              deleteQuestion('hii');
                                            }}
                                            type="button"
                                            className="btn btn-delete"
                                          >
                                            <DeleteIcon />
                                          </button> */}
                                          </InputAdornment>
                                        }
                                      />
                                      {/* <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'start',
                                          alignItems: 'center',
                                          height: '50px'
                                        }}
                                      >
                                        <small className="text-danger form-text">
                                          {questionFormik.touched.question &&
                                            questionFormik.errors.question}
                                        </small>
                                        <br />
                                      </div> */}
                                    </FormControl>
                                  </Form.Group>
                                  <div
                                    style={{
                                      marginLeft: '15%'
                                    }}
                                  >
                                    <small className="text-danger form-text">
                                      {questionFormik.touched.question &&
                                        questionFormik.errors.question}
                                    </small>
                                    <br />
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      height: '50px'
                                    }}
                                    className="addbutton my-2"
                                  >
                                    <button
                                      onClick={questionFormik.handleSubmit}
                                      disabled={questionFormik.isSubmitting}
                                      type="button"
                                      disabled={
                                        questionFormik.values.question != ''
                                          ? false
                                          : true
                                      }
                                      className="btn view-btn"
                                    >
                                      Add
                                    </button>
                                  </div>
                                </Form>
                              </Tab>
                              <Tab
                                eventKey="Import Questions"
                                title="Import Questions"
                              >
                                <QuestionsImport
                                  onImportComplete={async () => {
                                    await dispatch(
                                      getAllQuestionsAssessment(
                                        userData.companyId
                                      )
                                    );
                                  }}
                                />
                              </Tab>
                            </Tabs>
                          </Modal.Body>
                        </Modal>
                      </div>
                    </div>
                    <div className="my-4 add-category-card">
                      <MDBTable className="table-striped view-questions">
                        {formik.values.sections[index]?.questions.filter(
                          (ques) => !!ques
                        ).length != 0 ? (
                          formik.values.sections[index]?.questions
                            .filter((ques) => !!ques)
                            .map((que, index) => {
                              return (
                                <tr
                                  style={{
                                    backgroundColor: 'white',
                                    marginBottom: '5%'
                                  }}
                                  key={index}
                                >
                                  <td style={{ float: 'left' }}>
                                    {`${index + 1}) `}
                                    {searchQuesByID(que.question)}
                                  </td>
                                </tr>
                              );
                            })
                        ) : (
                          <MDBTableBody>
                            <tr>
                              <td>No Questions</td>
                            </tr>
                          </MDBTableBody>
                        )}
                      </MDBTable>
                      <div>
                        <small className="text-danger form-text">
                          {validationOfMainFields(
                            formik.touched,
                            formik.errors,
                            index,
                            'questions'
                          )}
                        </small>
                      </div>
                    </div>
                    <Divider
                      style={{ marginBottom: '15px' }}
                      variant="middle"
                    />
                  </div>
                </>
              );
            })}

            <div>
              <Button
                onClick={() =>
                  formik.setFieldValue(
                    `sections.${formik.values.sections.length}`,
                    {
                      category: '',
                      questions: [],
                      totalMarks: 0,
                      factors: {
                        environment: false,
                        social: false,
                        governance: false,
                        others: false
                      }
                    }
                  )
                }
                type="button"
                className="btn chat-btn"
              >
                Add Another Category
              </Button>
            </div>
            <div className="addbutton">
              <Button
                variant=""
                className="chat-btn"
                onClick={() => {
                  console.log('Formik errors', Object.values(formik.errors));
                  handleShow3();
                }}
                disabled={
                  // no field is touched
                  (Object.values(formik.touched) ?? []).length === 0 ||
                  // or some errors are present but error is not related to contractors
                  ((Object.values(formik.errors) ?? []).length > 0 &&
                    !(
                      (Object.keys(formik.errors) ?? []).length === 1 &&
                      Object.keys(formik.errors)[0] === 'contractors'
                    )) ||
                  // ESGO validation
                  !formik.values.sections.every((sect) => {
                    if (
                      sect.factors.environment ||
                      sect.factors.social ||
                      sect.factors.governance ||
                      sect.factors.others
                    ) {
                      return true;
                    }
                    return false;
                  }) ||
                  formik.isSubmitting
                }
              >
                Share
              </Button>
              <Modal
                size="lg"
                show={show3}
                onHide={handleClose3}
                backdrop="static"
                keyboard={false}
                centered
              >
                <Modal.Header>
                  <Modal.Title>Select Companies</Modal.Title>
                  <CloseIcon
                    fontSize="medium"
                    onClick={handleClose3}
                    style={{ color: 'red', cursor: 'pointer' }}
                  />
                </Modal.Header>
                <Modal.Body>
                  <div className="d-flex flex-row justify-content-end align-item-center">
                    <button
                      onClick={() => {
                        if (!allCompaniesSelected) {
                          setAllCompaniesSelected(!allCompaniesSelected);
                          if (allContrators.length != 0) {
                            allContrators.forEach((contra, index) => {
                              formik.setFieldValue(
                                `contractors.${index}`,
                                contra.user
                              );
                            });
                          }
                        } else {
                          setAllCompaniesSelected(!allCompaniesSelected);
                          if (allContrators.length != 0) {
                            allContrators.forEach((contra, index) => {
                              formik.setFieldValue(`contractors.${index}`, '');
                            });
                          }
                        }
                      }}
                      type="button"
                      className="btn view-btn"
                    >
                      {allCompaniesSelected ? 'Deselect All' : 'Select All'}
                    </button>
                  </div>
                  <div className="mt-4">
                    {allContrators.length != 0
                      ? allContrators.map((contra, index) => {
                          return (
                            <div className="row">
                              <div className="col-sm-4">
                                <h5 className="company-title">{contra.name}</h5>
                              </div>
                              <div className="col-sm-4">
                                <h5 className="company-title">
                                  {contra.cmp_name}
                                </h5>
                              </div>
                              <div className="col-sm-4">
                                <Form.Group
                                  className=" pull-right"
                                  controlId="formBasicCheckbox"
                                >
                                  <Form.Check
                                    checked={!!formik.values.contractors[index]}
                                    onChange={(data) => {
                                      if (data.target.checked) {
                                        formik.setFieldValue(
                                          `contractors.${index}`,
                                          contra.user
                                        );
                                      } else {
                                        formik.setFieldValue(
                                          `contractors.${index}`,
                                          ''
                                        );
                                      }
                                    }}
                                    type="checkbox"
                                  />
                                </Form.Group>
                              </div>
                              <hr />
                            </div>
                          );
                        })
                      : null}
                  </div>
                  <div>
                    <small className="text-danger form-text">
                      {formik.touched.contractors && formik.errors.contractors}
                    </small>
                    <br />
                  </div>
                  <div className="addbutton">
                    <button
                      onClick={formik.handleSubmit}
                      disabled={formik.isSubmitting}
                      type="button"
                      className="btn view-btn"
                    >
                      Share
                    </button>
                  </div>
                </Modal.Body>
              </Modal>
              <Modal
                size="lg"
                show={editCategoryModal}
                onHide={() => {
                  setEditCategoryModal(false);
                }}
                backdrop="static"
                keyboard={false}
                centered
              >
                <Modal.Header>
                  <Modal.Title>Edit Category</Modal.Title>
                  <CloseIcon
                    fontSize="medium"
                    onClick={() => {
                      setEditCategoryModal(false);
                    }}
                    style={{ color: 'red', cursor: 'pointer' }}
                  />
                </Modal.Header>
                <Modal.Body className="add-category">
                  <div className="row">
                    <div className="col-md-5">
                      <h6>Edit Category</h6>
                      <Form onSubmit={editCategoryFormik.handleSubmit}>
                        <Form.Group
                          className="sm-3"
                          controlId="text"
                          style={{ marginTop: '18px' }}
                        >
                          <Form.Control
                            className="form-control"
                            type="text"
                            placeholder="Enter New Category Name"
                            onChange={editCategoryFormik.handleChange(
                              'category'
                            )}
                            value={editCategoryFormik.values.category}
                            style={{
                              paddingTop: '10px',
                              paddingBottom: '10px'
                            }}
                          />
                          <div>
                            <small className="text-danger form-text">
                              {editCategoryFormik.touched.category &&
                                editCategoryFormik.errors.category}
                            </small>
                            <br />
                          </div>
                        </Form.Group>
                      </Form>
                      <button
                        onClick={editCategoryFormik.handleSubmit}
                        disabled={editCategoryFormik.isSubmitting}
                        type="button"
                        className="btn view-btn add-category-btn pull-left"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              <Modal
                size="lg"
                show={editQuestionModal}
                onHide={() => {
                  setEditQuestionModal(false);
                }}
                backdrop="static"
                keyboard={false}
                centered
              >
                <Modal.Header>
                  <Modal.Title>Edit Question</Modal.Title>
                  <CloseIcon
                    fontSize="medium"
                    onClick={() => {
                      setEditQuestionModal(false);
                    }}
                    style={{ color: 'red', cursor: 'pointer' }}
                  />
                </Modal.Header>
                <Modal.Body className="add-category">
                  <div className="row">
                    <div className="col-md-5">
                      <h6>Edit Question</h6>
                      <Form onSubmit={editQuestionFormik.handleSubmit}>
                        <Form.Group
                          className="sm-3"
                          controlId="text"
                          style={{ marginTop: '18px' }}
                        >
                          <Form.Control
                            className="form-control"
                            type="text"
                            placeholder="Enter Question"
                            onChange={editQuestionFormik.handleChange(
                              'question'
                            )}
                            value={editQuestionFormik.values.question}
                            style={{
                              paddingTop: '10px',
                              paddingBottom: '10px'
                            }}
                          />
                          <div>
                            <small className="text-danger form-text">
                              {editQuestionFormik.touched.question &&
                                editQuestionFormik.errors.question}
                            </small>
                            <br />
                          </div>
                        </Form.Group>
                      </Form>
                      <button
                        onClick={editQuestionFormik.handleSubmit}
                        disabled={editQuestionFormik.isSubmitting}
                        type="button"
                        className="btn view-btn add-category-btn pull-left"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </form>
        </CardContent>
      </Box>
    </Box>
  );
};

export default Add_assess;

import React from 'react';
import { MDBDataTableV5 } from 'mdbreact';


export default function Basic() {
  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: 'Topic',
        field: 'Topic',
        sort: 'disabled',
        width: 250,
      },
      {
        label: 'Score',
        field: 'Score',
        width: 50,
      },
      {
        label: 'Response',
        field: 'Response',
        width: 50,
      },
      {
        label: 'Remarks',
        field: 'Remarks',
        width: 50,
      },
      
  ],
    rows: [
      {
        Topic: '1. Do you have defined HSE policy?',
        Score: '5',
        Response: 'Yes',
        Remarks: 'Lorem ipsum',
      },
      {
        Topic: '2. Does management set corporate goals?',
        Score: '5',
        Response: 'No',
        Remarks: 'Lorem ipsum',
      },
      {
        Topic: '3. Are you ISO 9000 or ISO 14001 or ISO 45001 certifed?',
        Score: '5',
        Response: 'Yes',
        Remarks: 'Lorem ipsum',
      },
     
      
    ],
  });

  return <MDBDataTableV5 id="atgl_assessement_table" searchBottom={false} hover entriesOptions={[10, 20, 30]} entries={10} pagesAmount={4} data={datatable} />;
}

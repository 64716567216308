
import swal from "sweetalert"
import API from '../../Api'
import { GenRandomString } from '../../Constants'
import { storage } from "../../firebase"
import { setAllFeedbacks, setLoading } from "../feedback/slice"

const uploadFilePromise = async (file, id, dir) => {
  let trimmedFileName = file.name.trim().replace(/\s+/g, "_")
  let filePath = `feedback/${id}/${dir}/${GenRandomString(
    5
  )}-${trimmedFileName}`
  let fileRef = storage.ref(filePath)
  await fileRef.put(file)
  let fileUrl = await fileRef.getDownloadURL()
  return {
    fileName: trimmedFileName,
    filePath,
    fileLink: fileUrl,
    fileSize: file.size,
    fileType: file.type,
  }
}

export const feedbackAdded = (feedback,filesData) => async dispatch => {
    try {
      dispatch(setLoading(true))
      //store into firestore 
      let feedbackDocs = await Promise.allSettled(
        filesData.map(file =>
          uploadFilePromise(file, feedback.userUid, "feedbacks")
        )
      )

      feedback.attachments=feedbackDocs
      console.log("Feedback",feedback)
      
      let res = await API.createFeedback(feedback)

      swal({
        title: "Feedback Added Successfully",
        
        icon: "success",
        button: "Ok",
      })
      dispatch(setLoading(false))
      console.log(res)
    } catch (err) {
      dispatch(setLoading(true))
      swal({
        title: "Error",
        text: err.message,
        icon: "error",
        button: "Ok",
      })
      console.log(err)
    }
}

export const deleteFeedback = (feedback) => async dispatch => {
  try {
    dispatch(setLoading(true))
    await API.deleteFeedback({feedbackId:feedback.feedbackId})
    await dispatch(getFeedback())
    swal({
      title: "Auditor Deleted Successfully",
      
      icon: "success",
      button: "Ok",
    })
  } catch (err) {
    dispatch(setLoading(false))
    swal({
      title: "Error",
      text: err.message,
      icon: "error",
      button: "Ok",
    })
    console.log(err)
  }
}

export const getFeedback = (companyId) => async dispatch => {
  try {
    dispatch(setLoading(true))
    let allFeedback = []

    allFeedback = await API.getFeedbacksByCompanyId({
      companyId
    })
    // let sortData = allFeedback.data.data.sort((a, b) =>
    //   b.createdAt._seconds-a.createdAt._seconds
    // )
    //console.log("SORTDATA>>>", sortData)
    dispatch(setAllFeedbacks(allFeedback.data.data))

    dispatch(setLoading(false))
  } catch (err) {
    dispatch(setLoading(false))
    console.log(err)
  }
}

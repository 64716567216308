import {
  Box,
  CardContent,
  FormControl,
  MenuItem,
  Select
} from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import { Button, Form, Image, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import Api from '../Api';
import MainSpinner from '../components/common/OverlaySpinner';
import { storage } from '../firebase';

const ViewObservation = (props) => {
  const navigate = useNavigate();
  const { observation } = useSelector((state) => state.observation);
  const { userData } = useSelector((state) => state.auth);
  const rcaFormData = observation;
  const [loading, setLoading] = useState(false);
  const [managerName, setManagerName] = useState(null);
  const [actionTaken, setActionTaken] = useState(0);
  const [reportCategory, setReportCategory] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [rootCauseAnalysis, setRootCauseAnalysis] = useState();
  const [actionTakenForAccept, setActionTakenForAccept] = useState();
  const [hideAftrFinzl, setHideAftrFinzl] = useState(true);
  const [hideForReject, setHideForreject] = useState(false);
  const [rcaImage, setRcaImage] = useState(null);
  const [image, setImage] = useState(null);
  const [loadpdf, setloadpdf] = useState(null);
  const [imageStatus, setImageStatus] = useState(null);
  const [fileType, setfileType] = useState(null);
  const [spinner, setspinner] = useState(false);
  const [disabledReport, setdisabledReport] = useState(false);

  React.useEffect(() => {
    // getManagerData()
    //   .then((result) => {
    //     console.log('Manager Result>>>>>>>>>', result);
    //     setManagerName(result.name.stringValue);
    //   })
    //   .catch(() => {});

    if (!rcaFormData?.length == 0) {
      navigate(
        `/${userData?.type == 2 ? 'sapphire' : 'atgl/Contractor'}/Observation`
      );
    } else {
      console.log('RCA', rcaFormData);
      if (rcaFormData.form_data) {
        console.log('success');
        rcaFormData.rootCauseAnalysis &&
          setRootCauseAnalysis(rcaFormData.rootCauseAnalysis);
      }
      if (rcaFormData.action && typeof rcaFormData.action == 'number') {
        setDisabled(true);
        setActionTaken(rcaFormData.action);
        setReportCategory(rcaFormData.report_category);
        setActionTakenForAccept(rcaFormData.status - 1);
        setRootCauseAnalysis(rcaFormData.rootCauseAnalysis);

        if (rcaFormData.status >= 3) {
          setHideAftrFinzl(false);
          setdisabledReport(true);
        } else {
          setHideAftrFinzl(true);
          setdisabledReport(false);
        }

        if (rcaFormData.action !== 1) {
          setHideForreject(true);
        }
      }

      if (rcaFormData.reportImage) {
        console.log('reportImage', rcaFormData.reportImage);
        getFireImageRca(rcaFormData.reportimagepath, rcaFormData.fileType)
          .then((res) => {
            // setRcaImage(res);
            setImage(res);
          })
          .catch((e) => {
            // setImage('Failed to load image');
            console.log('error report img:', e);
          });
      } else {
        setImage('No Image');
      }

      if (rcaFormData.rcaStatusImage) {
        console.log(
          'rcaFormData',
          rcaFormData.rcaStatusImage,
          'rcaFormData',
          rcaFormData.fileType
        );
        getFireImageRca(rcaFormData.rcaStatusImage, rcaFormData.fileType)
          .then((res) => {
            setImageStatus(res);
          })
          .catch((e) => {
            console.log('error status img:', e);
          });
      }
    }
  }, []);

  const getActionTaken = (e) => {
    setActionTaken(parseInt(e.target.value));
  };

  const getReportCategory = (e) => {
    setReportCategory(e.target.value);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      let postData = { ...rcaFormData };
      postData.rootCauseAnalysis = rootCauseAnalysis;

      let res = await Api.getViewObservationRCAStatus(postData);
      console.log('RCAStatus', res);
      if (res.status !== 200) {
        throw 'Error in fetching RCA Status!';
      } else {
        navigate(
          `/${userData?.type == 2 ? 'sapphire' : 'atgl/Contractor'}/Observation`
        );
        swal({
          icon: 'success',
          button: 'Ok',
          title: 'Success',
          text: 'Comment has been added.'
        });
      }
    } catch (err) {
      swal({
        icon: 'error',
        button: 'Ok',
        title: 'Error',
        text: typeof err != 'object' ? err : 'Unknown Error'
      });
      console.log('ERROR Submit :: ', err);
    } finally {
      setLoading(false);
    }
  }

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   try {
  //     let postData = { ...rcaFormData };
  //     if (disabled) {
  //       if (![4, 5, 6].includes(actionTakenForAccept)) {
  //         throw 'Please select report action.';
  //       }
  //       if (!rootCauseAnalysis && !imageStatus) {
  //         throw 'Please fill Remarks or Upload Attachment.';
  //       }
  //       if (reportCategory == '') {
  //         throw 'Please fill Report Category.';
  //       }
  //     } else {
  //       if (actionTaken == '') {
  //         throw 'Please select Action Taken.';
  //       }
  //     }

  //     postData.action = actionTaken;
  //     if (actionTaken) {
  //       // debugger;
  //       postData.status = actionTaken + 1;
  //       postData.capture_accesstime =
  //         !rcaFormData.report_action_time && !rcaFormData.accept_time;
  //     }
  //     if (actionTakenForAccept) {
  //       postData.status = actionTakenForAccept + 1;
  //     }
  //     postData.accessed_time = Math.floor(new Date().getTime() / 1000);
  //     postData.managerid = observation.managerid;
  //     postData.report_category = reportCategory;
  //     postData.userId = rcaFormData.user;
  //     if (rootCauseAnalysis) {
  //       postData.rootCauseAnalysis = rootCauseAnalysis;
  //     }
  //     if (image) {
  //       postData.rcaStatusImage = image;
  //       postData.fileType = fileType; //imageStatus;
  //     }

  //     let res = await Api.getViewObservationRCAStatus(postData);
  //     console.log('RCAStatus', res);
  //     if (res.status !== 200) {
  //       throw 'Error in fetching RCA Status!';
  //     } else {
  //       navigate(
  //         `/${userData?.type == 2 ? 'sapphire' : 'atgl/Contractor'}/Observation`
  //       );
  //       swal({
  //         icon: 'success',
  //         button: 'Ok',
  //         title: 'Success',
  //         text: 'Status has been updated.'
  //       });
  //     }
  //   } catch (err) {
  //     swal({
  //       icon: 'error',
  //       button: 'Ok',
  //       title: 'Error',
  //       text: typeof err != 'object' ? err : 'Unknown Error'
  //     });
  //     console.log('ERROR Submit :: ', err);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const onChangeSwitch = (e) => {
  //     console.log(e);
  //     if ('textarea' === e.target.value) {
  //         setSwitchValue('attachment');
  //     } else {
  //         setSwitchValue('textarea');
  //     }
  // }

  const onChangeSetRootCauseAnalysis = (e) => {
    setRootCauseAnalysis(e.target.value);
  };

  const getReportActionTakenForAccept = (e) => {
    setActionTakenForAccept(parseInt(e.target.value));
  };
  const [rcaAttachment, setRcaAttachment] = React.useState(null);
  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      setRcaAttachment(image.name);
      if (
        image.type.indexOf('jpeg') > -1 ||
        image.type.indexOf('jpg') > -1 ||
        image.type.indexOf('png') > -1
      ) {
        const url =
          'report/' +
          localStorage.getItem('userId') +
          '/rca/' +
          rcaFormData.srno;
        setImage(url);
        // setImageStatus('progress');

        const uploadTask = uploadFireImageRca(url + '.jpg', image, (url) => {
          setImageStatus(url);
        });
        setfileType(1);
      } else if (image.type.indexOf('pdf') > -1) {
        const url =
          'report/' +
          localStorage.getItem('userId') +
          '/rca/' +
          rcaFormData.srno;
        setImage(url);
        setloadpdf(true);
        // setImageStatus('progress');

        const uploadTask = uploadFireImageRca(url, image, (result) => {
          // console.log("Abhishek", result)
          setImageStatus('pdf');
          setloadpdf(false);
        });
        setfileType(2);
      } else {
        swal({
          icon: 'error',
          button: 'Ok',
          title: 'Error',
          text: 'File type not supported.'
        });
        setImageStatus(null);
        setfileType(null);
      }
    }
  };

  const getFireImageRca = (path, fileType) => {
    let pathName = '';
    if (fileType === 2) {
      pathName = path;
    } else {
      pathName = path + '.jpg';
    }
    const ref = storage.ref(pathName);
    return ref.getDownloadURL();
  };

  const uploadFireImageRca = (path, image, cb) => {
    // const ref = fire.storage().ref(path + '.jpg');
    const uploadTask = storage.ref(path).put(image);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Progresss
        // console.log("snapshot>>>>>>>>>", snapshot);
      },
      (error) => {
        // console.log(error);
      },
      (result) => {
        // console.log(result);
        const ref = storage.ref(path);
        ref.getDownloadURL().then((url) => {
          // console.log("url>>>>>>>>>>>>", url);
          cb(url);
        });
      }
    );
  };

  const displayTime = (time) => {
    if (time && time._seconds) {
      return moment(time._seconds * 1000).format('MMMM Do YYYY, h:mm:ss a');
    }
    return null;
  };

  return (
    <React.Fragment>
      {loading && <MainSpinner />}

      <Box sx={{ mt: 3 }}>
        <CardContent>
          <h3 className="m-28-42"> Activity No. {rcaFormData?.srno} </h3>
          <hr></hr>
          <Form className="rca-form" onSubmit={handleSubmit}>
            {rcaFormData.form_data ? (
              <div className="row">
                {Object.values(rcaFormData.form_data).map(
                  (obj) =>
                    obj.label != 'Upload Photo' && (
                      <div className="col-md-3 my-2">
                        <h2>
                          <strong>
                            {' '}
                            {obj.label == 'FullName' ? 'Full Name' : obj.label}
                          </strong>
                        </h2>
                        <div>{obj.value}</div>
                      </div>
                    )
                )}
              </div>
            ) : null}
            <hr></hr>
            <br />
            <div className="row">
              <div className="col-md-4 my-2">
                <h2>
                  <strong>Action Taken</strong>
                </h2>

                <FormControl style={{ width: '50%' }}>
                  <Select
                    onChange={getActionTaken}
                    value={actionTaken}
                    disabled={disabled}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value="0" disabled>
                      Please Select
                    </MenuItem>
                    <MenuItem value="1">Accept</MenuItem>
                    <MenuItem value="2">Reject</MenuItem>
                    <MenuItem value="3">Mark as Duplicate</MenuItem>
                  </Select>
                </FormControl>
                <br />
                {rcaFormData.accept_time ? (
                  <div>
                    <br />
                    At:{' '}
                    <strong>
                      {rcaFormData.accept_time &&
                        displayTime(rcaFormData.accept_time)}
                    </strong>
                    {/* <br />
                    By: <strong> {managerName} </strong> */}
                  </div>
                ) : null}
              </div>

              <div className="col-md-4 my-2">
                {/* <Form.Label>
              <strong>Remarks</strong>
            </Form.Label> */}
                <Form.Control
                  as="textarea"
                  placeholder="Write your comment"
                  rows="5"
                  onChange={onChangeSetRootCauseAnalysis}
                  value={rootCauseAnalysis}
                  style={{ width: '85%' }}
                  // disabled={disabledReport}
                />
              </div>

              {image ? (
                typeof image != 'No Image' ? (
                  <div className="col-md-4 my-2">
                    <Image
                      style={{ maxHeight: 200, maxWidth: 200 }}
                      src={image}
                      rounded
                    />
                  </div>
                ) : (
                  <div className="col-md-4 my-2">
                    <strong>{image}</strong>
                  </div>
                )
              ) : (
                <div className="col-md-4 my-2">
                  <Spinner
                    size="md"
                    className="bigger-spinner"
                    animation="border"
                    variant="primary"
                  />
                </div>
              )}
            </div>
            <br></br>

            {/* {hideAftrFinzl ? ( */}
            <div className="d-flex justify-content-center align-items-center">
              <Button
                variant="dark"
                className="submit-btn"
                type="submit"
                // disabled={hideForReject}
              >
                Submit
              </Button>
            </div>
            {/* ) : null} */}
          </Form>
        </CardContent>
      </Box>
    </React.Fragment>
  );
};

export default ViewObservation;

import { Avatar, Divider, IconButton } from '@material-ui/core';
import { InsertDriveFileRounded, UploadFile } from '@material-ui/icons';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CloseIcon from '@material-ui/icons/Close';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'; // for Modal
import Modal from 'react-bootstrap/Modal'; // for Modal
import Spinner from 'react-bootstrap/Spinner';
import { useSelector } from 'react-redux';
import FileDisplay, { fileSize } from 'src/components/common/FileDisplay';
import { USER_TYPES } from 'src/Constants';
import { auth, firestore, storage } from 'src/firebase';
import './checklist.css';

const AssessmentChat = (props) => {
  const inputFile = useRef(null);
  const chatMessageRef = useRef(null);
  const dummy = useRef();
  const [selectedFile, setSelectedFile] = useState();
  const [messages, setMessages] = useState([]);
  const [messageSendLoading, setMessageSendLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [init, setInit] = useState(false);
  const [profilePics, setProfilePics] = useState({});
  const {
    userData: { type: userType, profileImage }
  } = useSelector((state) => state.auth);

  const fetchProfilePic = async (uid, type) => {
    if (profilePics?.[uid]) return { uid: uid, link: profilePics[uid] };
    if (uid === auth.currentUser.uid)
      return { uid: uid, link: profileImage?.fileLink };
    switch (type) {
      case USER_TYPES.COMPANY:
        let compData = await firestore.collection('company').doc(uid).get();
        // console.log('company', compData);
        return { uid: uid, link: compData.data()?.profileImage?.fileLink };
      case USER_TYPES.CONTRACTOR:
        let contData = await firestore.collection('managers').doc(uid).get();
        // console.log(
        //   '🚀 ~ file: AssessmentChat.js ~ line 41 ~ fetchProfilePic ~ contData',
        //   contData
        // );
        return { uid: uid, link: contData.data()?.profileImage?.fileLink };
      case USER_TYPES.TPI:
        let tpiData = await firestore.collection('tpis').doc(uid).get();
        // console.log(
        //   '🚀 ~ file: AssessmentChat.js ~ line 45 ~ fetchProfilePic ~ tpiData',
        //   tpiData
        // );
        return { uid: uid, link: tpiData.data()?.profileImage?.fileLink };
      default:
        return { uid: uid, link: '' };
        break;
    }
  };

  const handleClose = () => {
    props.handleClose();
    props.setSendFile(false);
    setSelectedFile();
    setMessage('');
    setInit(false);
  };
  useEffect(() => {
    // console.log("AssessmentChat", {props});
    (async () => {
      try {
        if (props.question && props.response) {
          let chatRoomId = `${props.response.contractorId}-${props.question._id}`;
          let chatRoomRef = firestore.collection('chat_rooms').doc(chatRoomId);

          if (!(await chatRoomRef.get().exists)) {
            await chatRoomRef.set({
              id: chatRoomId,
              assessment_id: props.response.assessment,
              section_id: props.response.sections.find((section) =>
                section.questions.find(
                  (question) => question._id === props.question._id
                )
              )._id,
              question_id: props.question._id,
              companyId: props.response.companyId,
              contractor_id: props.response.contractorId,
              created_at: Date.parse(new Date())
            });
          }

          chatRoomRef
            .collection('messages')
            .orderBy('created_at')
            .onSnapshot((snapshot) => {
              let comments = [];

              snapshot.docs.forEach((doc) => {
                const data = doc.data();

                comments.push(data);
              });
              setMessages(comments);

              console.log('internal chat ', { comments });
            });
        }
      } catch (error) {
        console.log("Chat snapshot",error);
      }
    })();

    if (props.sendFile && !init) {
      setInit(true);
      inputFile.current.click();
    }
  }, [props, init]);

  useEffect(() => {
    let imagePromises = [];
    let currentState = { ...profilePics };
    // console.log(
    //   '🚀 ~ file: AssessmentChat.js ~ line 96 ~ useEffect ~ currentState',
    //   currentState
    // );

    messages.forEach((data) => {
      if (!Object.keys(currentState).includes(data.from_id)) {
        // console.log('keys', Object.keys(currentState));
        currentState[data.from_id] = '';
        imagePromises.push(fetchProfilePic(data.from_id, data.from_type));
      }
    });
    if (imagePromises.length > 0) {
      Promise.all(imagePromises).then((pics) => {
        let picsObj = {};
        pics.forEach((pic) => (picsObj[pic.uid] = pic.link));
        setProfilePics((old) =>
          Object.values(old).length !== 0 ? { ...old, ...picsObj } : picsObj
        );
      });
    }
    return () => {};
  }, [messages]);

  const handleMessage = async (e) => {
    try {
      e.preventDefault();
      setMessageSendLoading(true);
      let messageId = firestore
        .collection('chat_rooms')
        .doc(`${props.response.contractorId}-${props.question._id}`)
        .collection('messages')
        .doc().id;

      console.log('message id', messageId);

      let obj = {
        message,
        from_id: props.uid,
        from_type: props.type,
        from_name: props.name,
        created_at: Date.parse(new Date())
      };
      // if (selectedFile) {
      //   obj.document = {};
      // }
      if (selectedFile) {
        const storageRef = storage.ref();
        const metadata = {
          contentType: selectedFile.type
        };
        const path = `chat_rooms/${props.response.contractorId}-${props.question._id}/messages/${messageId}/attachments/${selectedFile.name}`;
        let uploadData = {
          document: {
            filePath: path,
            metadata: {
              contentType: selectedFile.type
            },
            fileName: selectedFile.name,
            fileSize: selectedFile.size
          }
        };
        await storageRef.child(path).put(selectedFile, metadata);
        obj.document = uploadData.document;
      }
      //   console.log('message', { obj });

      let sendedMessage = await firestore
        .collection('chat_rooms')
        .doc(`${props.response.contractorId}-${props.question._id}`)
        .collection('messages')
        .doc(messageId)
        .set(obj);

      setSelectedFile(null);
      setMessage('');
      setMessageSendLoading(false);
      chatMessageRef.current.focus();
      dummy.current.scrollIntoView({ behavior: 'smooth' });
    } catch (err) {
      console.log('handleMessage err', { err });
      setMessageSendLoading(false);
    }
  };

  useEffect(() => {
    if (dummy.current) {
      dummy.current.scrollIntoView({ behavior: 'smooth' });
    }
    // console.log({selectedFile});
  }, [messages, selectedFile]);

  const getTitle = (type) => {
    switch (type) {
      case USER_TYPES.CONTRACTOR:
        return 'COMPANY';
      case USER_TYPES.TPI:
        return 'AUDITOR';
      case USER_TYPES.COMPANY:
        return 'ORGANIZATION';

      default:
        return '';
    }
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={handleClose}
        size="lg"
        backdrop="static"
        keyboard="false"
        centered
      >
        <Modal.Header>
          <Modal.Title>Response</Modal.Title>
          <CloseIcon
            fontSize="medium"
            onClick={handleClose}
            style={{ color: 'red', cursor: 'pointer' }}
          />
        </Modal.Header>
        <Modal.Body
          style={{
            padding: 0
          }}
        >
          {selectedFile && (
            <div
              className="d-flex justify-content-between flex-column w-100 h-100 position-absolute"
              style={{
                zIndex: 1000,
                background: 'white',
                height: '69vh'
              }}
            >
              {/* header with file name  and cross*/}
              <div className="d-flex py-1">
                <div
                  className=""
                  style={{
                    paddingLeft: '1rem'
                  }}
                >
                  <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                    <IconButton onClick={() => setSelectedFile(null)}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
                <div
                  className=""
                  style={{
                    flexGrow: 1
                  }}
                >
                  <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                    {selectedFile?.name}
                  </div>
                </div>
              </div>
              <Divider />
              {/* file preview */}
              <div
                className="d-flex w-100 justify-content-center align-items-center"
                style={{
                  height: '100%'
                }}
              >
                {selectedFile?.type.includes('image/') ? (
                  <img
                    style={{
                      maxHeight: '60vh'
                      // height : "60vh"
                    }}
                    className="img-thumbnail"
                    src={URL.createObjectURL(selectedFile)}
                  />
                ) : (
                  <object
                    data={URL.createObjectURL(selectedFile)}
                    type={selectedFile.type}
                    // className="img-thumbnail"
                    width="90%"
                    height="100%"
                  >
                    <div className="d-flex flex-column h-100 justify-content-center align-items-center img-thumbnail">
                      <InsertDriveFileRounded
                        style={{
                          width: '10rem',
                          height: '10rem',
                          color: 'grey'
                        }}
                      />
                      {fileSize(selectedFile.size)} - {selectedFile?.name}
                    </div>
                  </object>
                )}
                {/* )} */}
              </div>

              {/* send button */}
            </div>
          )}

          {/* <h6 className="msg-date">26 June 2021</h6> */}
          <div class="card-body msg_card_body" style={{ height: '69vh' }}>
            {messages.map((doc) =>
              doc.from_id === props.uid ? (
                <div class="d-flex flex-column justify-content-end my-2">
                  <div class="d-flex justify-content-end">
                    <div>
                      <div class="msg_cotainer_send">
                        <div
                          className="d-flex w-100 justify-content-end align-items-center"
                          style={{
                            fontSize: '.7rem',
                            fontWeight: 'bold'
                          }}
                        >
                          {doc.from_name}
                          <div
                            className="px-1"
                            style={{
                              background: 'grey',
                              letterSpacing: '1px',
                              fontSize: '.5rem',
                              color: 'white',
                              height: 'auto',
                              display: 'inline',
                              marginLeft: '.4rem'
                            }}
                          >
                            {getTitle(doc.from_type)}
                          </div>
                        </div>
                        {doc.document ? (
                          <FileDisplay
                            key={doc.created_at}
                            mode="view"
                            file={{ value: doc.document }}
                          />
                        ) : null}
                        {doc.message}
                      </div>
                      <span
                        className="d-flex w-100 justify-content-end"
                        style={{
                          color: ' #32434c !important',
                          fontSize: '10px'
                        }}
                      >
                        {moment(doc.created_at).format('hh:mm A')},{' '}
                        {moment(doc.created_at).fromNow()}
                      </span>
                    </div>
                    <div class="img_cont_msg">
                      <Avatar
                        alt={doc.from_name}
                        src={profilePics?.[doc.from_id]}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div class="d-flex justify-content-start my-2">
                  <div class="img_cont_msg">
                    <Avatar
                      alt={doc.from_name}
                      src={profilePics?.[doc.from_id]}
                    />
                  </div>
                  <div>
                    <div class="msg_cotainer">
                      <div
                        className="d-flex w-100 justify-content-start align-items-center"
                        style={{
                          fontSize: '.7rem',
                          fontWeight: 'bold'
                        }}
                      >
                        {doc.from_name}
                        <div
                          className="px-1"
                          style={{
                            background: 'grey',
                            letterSpacing: '1px',
                            fontSize: '.5rem',
                            color: 'white',
                            height: 'auto',
                            display: 'inline',
                            marginLeft: '.4rem'
                          }}
                        >
                          {getTitle(doc.from_type)}
                        </div>
                      </div>
                      {doc.document ? (
                        <FileDisplay
                          key={doc.created_at}
                          mode="view"
                          file={{ value: doc.document }}
                        />
                      ) : null}
                      {doc.message}
                    </div>
                    <span
                      style={{
                        color: ' #32434c !important',
                        fontSize: '10px'
                      }}
                    >
                      {moment(doc.created_at).format('hh:mm A')},{' '}
                      {moment(doc.created_at).fromNow()}
                    </span>
                  </div>
                </div>
              )
            )}

            <span ref={dummy}></span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Form className="w-100 d-flex align-items-center">
            <Form.Control
              // as="textarea"
              onSubmit={handleMessage}
              placeholder="Type a message....."
              style={{ height: '50px', flexGrow: 1 }}
              value={message}
              disabled={messageSendLoading}
              onChange={(e) => setMessage(e.target.value)}
              ref={chatMessageRef}
            />
            {/* <CalendarTodayIcon fontSize="large" /> */}
            <label
              style={{ marginLeft: '20px' }}
              for="file-input"
              className="response-file"
              // onClick={handleClose}
            >
              <AttachFileIcon fontSize="large" />
            </label>
            <input
              id="file-input"
              type="file"
              ref={inputFile}
              // multiple
              // required
              onChange={(e) => setSelectedFile(e.target.files[0])}
              accept="image/*, .doc, .docx, application/pdf, application/msword, application/vnd.ms-powerpoint,.ppt, .pptx, .csv, .xlsv"
            />
            <Button
              // variant="primary"
              style={{ marginLeft: '20px' }}
              type="submit"
              className="modal-submit-btn"
              onClick={handleMessage}
              disabled={messageSendLoading || (!message && !selectedFile)}
            >
              {messageSendLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                'Send'
              )}
            </Button>
          </Form>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AssessmentChat;

import {
    LOGIN_USER,
    LOGOUT_USER,
    SET_LOADING
} from './types'


export const loginUser = user => {
    return {
      type: LOGIN_USER,
      payload: user,
    }
}

export const logoutUser = () => {
    return {
      type: LOGOUT_USER,
    }
}

export const setLoading = loading => {
    return {
      type: SET_LOADING,
      payload: loading,
    }
}
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  colors,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme
} from '@material-ui/core';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
// Stack Bar Graph
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { MDBDataTableV5 } from 'mdbreact';
// Table
import moment from 'moment';
import { useEffect, useState } from 'react';
// Pie Chart
import { Doughnut } from 'react-chartjs-2';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Api from 'src/Api';
import swal from 'sweetalert';
import { drillDownGraphData1 } from '../components/ATGL/observation/data';
import CustomSpinner from '../components/common/CustomSpinner';
import Spinner from '../components/common/OverlaySpinner';
import { setObservation } from '../store/observation/slice';

const CustomColors = [
  '#ACDEEF',
  '#D59AF7',
  '#F96E6E',
  '#9fd29a',
  '#f5cd6a',
  '#f79666'
];

const obsColors = {
  Accident: '#ACDEEF',
  Incident: '#D59AF7',
  'Positive Observation': '#F96E6E',
  'Unsafe Act': '#9fd29a',
  'Unsafe Condition': '#f5cd6a',
  Nearmiss: '#f79666'
};

const CustomBGColors = [
  '#E9F6FB',
  '#F4E5FD',
  '#FDD9D9',
  '#9fd29a42',
  '#f5cd6a42',
  '#f7966642'
];

const Observation = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.auth);
  const { startDate, endDate } = useSelector((state) => state.header);
  const [loading, setLoading] = useState(false);
  const [loadingReportTable, setLoadingReportTable] = useState(false);
  const [loadingBarGraph, setLoadingBarGraph] = useState(false);
  const [stackBarGraph, setStackBarGraph] = useState(null);
  const [viewAll, setViewAll] = useState(false);
  const [labels, setLabels] = useState([]);
  const [arcChart, setArcChart] = useState([]);
  const [arcChartData, setArcChartData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [tableDataOld, setTableDataOld] = useState([]);
  const [managers, setManagers] = useState([]);
  const [managerID, setManagerID] = useState([]);

  const [obsTypes, setObsTypes] = useState([]);

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    colors: CustomColors,
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  useEffect(() => {
    if (userData) {
      console.log({ userData });
      dispatch(setObservation(userData));
      getObservationReportGraph();
      getObservationReportTable();
    }
  }, [startDate, endDate]);

  const getObservationReportGraph = async (Mid) => {
    try {
      setLoadingBarGraph(true);
      let currentYear = moment().format('YYYY');
      let query = {
        year: currentYear
      };
      let res;
      if (userData?.type == '2' && (Mid == 'all' || !Mid)) {
        query.company_id = userData.cmp_id ?? userData.companyId;
        res = await Api.getObservationReportGraphCompany(query);
        console.log('ReportGraph company', res);
      } else if (userData?.type == '3' || Mid) {
        query.managerid = Mid ?? userData?.user;
        res = await Api.getObservationReportGraphContractor(query);
        console.log('ReportGraph contractor', res);
      }
      if (res.status !== 200)
        throw new Error('Error in fetching Report Graph!');

      let data = res.data[currentYear];
      createStackedBarGraph(data);
    } catch (error) {
      swal({
        title: 'Error',
        text: error.message,
        icon: 'error',
        button: 'OK'
      });
      console.log('ReportGraph ERROR :: ', error.message);
    } finally {
      setLoadingBarGraph(false);
    }
  };

  async function createStackedBarGraph(data) {
    console.log('StackedBarGraph', moment().month());
    let key = [];
    for (let k in data) {
      let month = data[k];
      if (Object.keys(month).length != 0) {
        for (let k2 in month) {
          if (!key.includes(k2)) {
            key.push(k2);
          }
        }
      }
    }
    key = await key.sort((a, b) => {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });
    console.log('key param', key);
    key = await key.map((obj) => {
      return { name: obj, data: [] };
    });
    console.log('key param', key);

    let count = 0;
    for (let k in data) {
      console.log('tesss', data[k], count, moment().month());

      // create series for Stacked Bar graph
      let month = data[k];
      let newObj = [];
      for (let k in key) {
        let tempObj = { ...key[k] };
        let tempArr = [...tempObj.data];
        tempArr.push(month?.[key[k].name] ?? 0);
        tempObj.data = tempArr;
        newObj.push(tempObj);
      }
      key = [...newObj];

      // Break if loop reaches current month
      if (count == moment().month()) {
        break;
      }
      count++;
    }
    console.log('stacked bar graph', key);
    let newResult = drillDownGraphData1(key, 'Safety Reporting');
    newResult.colors = newResult.series.map((serie) => obsColors?.[serie.name]);
    newResult.series = newResult.series.map((series) => ({
      ...series,
      events: {
        legendItemClick: (e) => {
          e.target.visible
            ? setObsTypes((old) => [...old, series.name])
            : setObsTypes((old) => old.filter((obs) => obs !== series.name));
        }
      }
    }));
    setStackBarGraph({ ...newResult });
  }

  const getObservationReportTable = async () => {
    try {
      setLoadingReportTable(true);
      let query = {
        start_date: startDate,
        end_date: endDate,
        company_id: userData.cmp_id ?? userData.companyId
        // 'csGUYxQSvD7y6dm8ypOe'
      };
      let res = await Api.getObservationReportTable(query);
      console.log('ReportTable', res);
      if (res.status !== 200)
        throw new Error('Error in fetching Report Graph!');

      let data = res.data.items;
      if (userData?.type == '3') {
        data = data.filter((el) => el.managerid == userData.user);
      }
      let managersData = data.map((el) => {
        return { label: el.managername, value: el.managerid };
      });
      managersData = uniqueObj(managersData, 'value');
      console.log({ managersData });
      setManagers(managersData);
      setTableData([...data].sort((a, b) => b.srno - a.srno));
      setTableDataOld([...data]);
      createPieChart(data);
    } catch (error) {
      swal({
        title: 'Error',
        text: error.message,
        icon: 'error',
        button: 'OK'
      });
    } finally {
      setLoadingReportTable(false);
    }
  };

  function uniqueObj(array, propertyName) {
    return array.filter(
      (e, i) =>
        array.findIndex((a) => a[propertyName] === e[propertyName]) === i
    );
  }

  function createPieChart(data) {
    let uniq = [];
    for (let obj of data) {
      const found = uniq.some((el) => el.name == obj.category);
      if (found) {
        let idx = uniq.findIndex((ele) => ele.name == obj.category);
        let temp = [...uniq];
        temp[idx].y = temp[idx].y + 1;
        uniq = [...temp];
      } else {
        uniq.push({ name: obj.category, y: 1 });
      }
    }
    console.log('uniq', uniq);
    if (uniq && uniq.length) {
      uniq = uniq.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
    }
    let SortedLabel = uniq.map((el) => el.name);
    setLabels(SortedLabel);
    let PieData = {
      datasets: [
        {
          data: uniq.map((el) => el.y),
          backgroundColor: uniq.map((el) => obsColors?.[el.name]),
          borderWidth: 8,
          borderColor: colors.common.white,
          hoverBorderColor: colors.common.white
        }
      ],
      labels: SortedLabel
    };
    setArcChart(uniq);
    setArcChartData(PieData);
  }

  const status_arr = [
    'Submitted',
    'Accepted',
    'Rejected',
    'Marked as Duplicate'
  ];

  // function ManagerHandle(e) {
  //   let data = e.target.value;
  //   setSelectedManager(data.value);
  // }

  function ManagerHandle(e) {
    let Mid = e.target.value;
    setManagerID(Mid);
    if (Mid != 'all') {
      let newTable = [
        ...tableDataOld.filter((el) => {
          if (el.managerid == Mid) return el;
        })
      ];
      console.log({ newTable });
      setTableData(newTable);
      createPieChart(newTable);
    } else {
      setTableData(tableDataOld);
      createPieChart(tableDataOld);
    }
    getObservationReportGraph(Mid);
  }

  async function submitAction(item, type) {
    setLoading(true);
    try {
      let postData = { ...item };
      postData.action = type;
      if (type) {
        // debugger;
        postData.status = type + 1;
        postData.capture_accesstime =
          !item.report_action_time && !item.accept_time;
      }
      postData.accessed_time = Math.floor(new Date().getTime() / 1000);
      postData.userId = item.user;

      let res = await Api.getViewObservationRCAStatus(postData);
      console.log('RCAStatus', res);
      if (res.status !== 200) {
        throw 'Error in fetching RCA Status!';
      } else {
        getObservationReportTable();
        swal({
          icon: 'success',
          button: 'Ok',
          title: 'Success',
          text: 'Status has been updated.'
        });
      }
    } catch (err) {
      swal({
        icon: 'error',
        button: 'Ok',
        title: 'Error',
        text: typeof err != 'object' ? err : 'Unknown Error'
      });
      console.log('ERROR Submit :: ', err);
    } finally {
      setLoading(false);
    }
  }

  const ActionHandler = (item, view = '') => {
    console.log('obser', item);
    dispatch(setObservation(item));

    {
      view == 'view'
        ? navigate(
            `/${
              userData?.type == 2 ? 'sapphire' : 'atgl/Contractor'
            }/OnlyViewObservation`,
            {
              state: item
            }
          )
        : navigate(
            `/${
              userData?.type == 2 ? 'sapphire' : 'atgl/Contractor'
            }/ViewObservation`,
            {
              state: item
            }
          );
    }

    // window.location.href = 'ViewObservation';
  };

  return (
    <>
      {loading && <Spinner />}
      <Helmet>
        <title>Observation | Oren</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <div className="row">
            <div className="col-md-10">
              <h2>
                <ul class="breadcrumb">
                  <li>
                    <a href="Observation">Observation</a>
                  </li>
                </ul>
              </h2>
            </div>
            <div className="col-md-2">
              {userData?.type == 2 && (
                <FormControl style={{ width: '100%' }}>
                  <InputLabel id="demo-simple-select-label">
                    Companies
                  </InputLabel>
                  <Select value={managerID} onChange={ManagerHandle}>
                    <MenuItem value="all">All</MenuItem>
                    {managers.map((val) => (
                      <MenuItem value={val.value}>{val.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </div>
          </div>
          <Grid container spacing={3}>
            <Grid item lg={8} md={12} xl={9} xs={12}>
              <Card>
                <CardHeader title="Monthly Reporting" />
                <Divider />
                {/********* Stack Bar Graph *********/}
                {loadingBarGraph ? (
                  <div className="bar-graph-spinner">
                    <CustomSpinner />
                  </div>
                ) : stackBarGraph ? (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={stackBarGraph}
                  />
                ) : (
                  <h2>No Data Available</h2>
                )}
              </Card>
            </Grid>
            <Grid item lg={4} md={6} xl={3} xs={12}>
              {/********* Pie chart *********/}
              <Card sx={{ height: '100%' }}>
                <CardHeader title="Types" />
                <Divider />
                {loadingReportTable ? (
                  <div className="bar-graph-spinner">
                    <CustomSpinner />
                  </div>
                ) : (
                  <CardContent>
                    <Box
                      sx={{
                        height: 300,
                        position: 'relative'
                      }}
                    >
                      <Doughnut data={arcChartData} options={options} />
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        pt: 2
                      }}
                    >
                      {arcChart.map(({ name, y }, i) => (
                        <Box
                          key={i}
                          sx={{
                            p: 1,
                            textAlign: 'center'
                          }}
                        >
                          <div className="d-flex h-100 flex-column justify-content-between">
                            <div>
                              <Typography
                                color="textPrimary"
                                variant="body2"
                                className="my-1"
                              >
                                {name}
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                color={obsColors?.[name]}
                                variant="h2"
                              >
                                {y}
                              </Typography>
                            </div>
                          </div>
                        </Box>
                      ))}
                    </Box>
                  </CardContent>
                )}
              </Card>
            </Grid>

            <Grid item lg={12} md={12} xl={12} xs={12}>
              {/********** Table **********/}
              <Card>
                <CardHeader title="Observation Details" />
                <Divider />
                {loadingReportTable ? (
                  <div className="bar-graph-spinner">
                    <CustomSpinner />
                  </div>
                ) : (
                  <MDBDataTableV5
                    searchTop
                    disableRetreatAfterSorting
                    searchBottom={false}
                    className="text-center"
                    scrollX={false}
                    scrollY={false}
                    hover
                    sortable
                    entriesOptions={[5, 10, 20]}
                    entries={5}
                    pagesAmount={6}
                    data={{
                      columns: [
                        {
                          label: 'Sr.No',
                          field: 'srNo',
                          width: 10,
                          attributes: {
                            'aria-controls': 'DataTable',
                            'aria-label': 'id'
                          }
                        },

                        {
                          label: 'Activity',
                          field: 'activity',
                          width: 100
                        },

                        {
                          label: 'Date',
                          field: 'date',
                          sort: 'reportedBy',
                          width: 100
                        },
                        {
                          label: 'Time',
                          field: 'time',
                          sort: 'reportedBy',
                          width: 100
                        },
                        {
                          label: 'Reported By',
                          field: 'reportedBy',
                          sort: 'reportedBy',
                          width: 100
                        },
                        {
                          label: 'Description',
                          field: 'description',
                          sort: 'reportedBy',
                          width: 100
                        },
                        {
                          label: 'Status',
                          field: 'status',
                          width: 100
                        },
                        {
                          label: 'Action',
                          field: 'action',
                          sort: 'disabled',
                          width: 100
                        }
                      ],
                      rows: tableData
                        ? tableData
                            .filter((item, index) => {
                              let inde = obsTypes.indexOf(item.category) === -1;

                              return inde;
                            })
                            .map((item, index) => {
                              return {
                                srNo: index + 1,
                                activity: (
                                  <div
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => ActionHandler(item, 'view')}
                                  >
                                    {item.category}
                                  </div>
                                ),
                                date: moment(
                                  item.reportedAt._seconds * 1000
                                ).format('DD-MM-YYYY'),
                                time: moment(
                                  item.reportedAt._seconds * 1000
                                ).format('h:mm a'),
                                reportedBy: item.reported_by,
                                description:
                                  item?.form_data?.description?.value ??
                                  'No Description',
                                status: status_arr[item.status - 1],
                                action: (
                                  <>
                                    {item?.status == 1 && (
                                      <>
                                        <Button
                                          onClick={() => submitAction(item, 1)}
                                        >
                                          <CheckBoxOutlinedIcon className="green" />
                                        </Button>
                                        <Button
                                          onClick={() => submitAction(item, 2)}
                                        >
                                          <CloseIcon className="red" />
                                        </Button>
                                        <Button
                                          onClick={() => submitAction(item, 3)}
                                        >
                                          <FilterNoneIcon className="dark-blue" />
                                        </Button>
                                      </>
                                    )}

                                    {item.status == 2 && (
                                      <Button>
                                        <DoneIcon className="green-fill" />
                                      </Button>
                                    )}

                                    {item.status == 3 && (
                                      <Button>
                                        <CloseIcon className="red-fill" />
                                      </Button>
                                    )}

                                    {item.status == 4 && (
                                      <Button>
                                        <FilterNoneIcon className="dark-blue" />
                                      </Button>
                                    )}

                                    {item.status != 1 && (
                                      <Button
                                        variant="success"
                                        className="rca-success"
                                        onClick={() => ActionHandler(item)}
                                      >
                                        <div className="editBtn">
                                          <EditIcon
                                            style={{
                                              fontSize: 17,
                                              marginRight: 1
                                            }}
                                          />{' '}
                                          Edit
                                        </div>
                                      </Button>
                                    )}
                                  </>
                                )
                              };
                            })
                        : null
                    }}
                  />
                  // <PerfectScrollbar>
                  //   <Box
                  //     sx={{
                  //       minWidth: 800,
                  //       maxHeight: viewAll ? 1000 : 500,
                  //       overflowY: viewAll ? 'scroll' : 'hidden'
                  //     }}
                  //   >
                  //     <Table>
                  //       <TableHead>
                  //         <TableRow>
                  //           <TableCell style={{ minWidth: 7 }}></TableCell>
                  //           <TableCell>Sr. No</TableCell>
                  //           <TableCell>Activity</TableCell>
                  //           <TableCell sortDirection="desc">
                  //             <Tooltip enterDelay={300} title="Sort">
                  //               <TableSortLabel>Date</TableSortLabel>
                  //             </Tooltip>
                  //           </TableCell>
                  //           <TableCell sortDirection="desc">
                  //             <Tooltip enterDelay={300} title="Sort">
                  //               <TableSortLabel>Time</TableSortLabel>
                  //             </Tooltip>
                  //           </TableCell>
                  //           <TableCell>Reported By</TableCell>
                  //           <TableCell>Description</TableCell>
                  //           <TableCell>Status</TableCell>
                  //           <TableCell>Action</TableCell>
                  //         </TableRow>
                  //       </TableHead>
                  //       <TableBody>
                  //         {tableData.map((item, i) => (
                  //           <>
                  //             <TableRow
                  //               style={{
                  //                 backgroundColor:
                  //                   CustomBGColors[
                  //                     labels.indexOf(item.category)
                  //                   ],
                  //                 border: '0px'
                  //               }}
                  //               hover
                  //               key={i}
                  //             >
                  //               <TableCell
                  //                 style={{
                  //                   backgroundColor:
                  //                     CustomColors[
                  //                       labels.indexOf(item.category)
                  //                     ],
                  //                   width: '10px'
                  //                 }}
                  //               ></TableCell>
                  //               <TableCell>{item.srno}</TableCell>
                  //               <TableCell>{item.category}</TableCell>
                  //               <TableCell>
                  //                 {moment(
                  //                   item.reportedAt._seconds * 1000
                  //                 ).format('DD-MM-YYYY')}
                  //               </TableCell>
                  //               <TableCell>
                  //                 {moment(
                  //                   item.reportedAt._seconds * 1000
                  //                 ).format('h:mm a')}
                  //               </TableCell>
                  //               <TableCell>{item.reported_by}</TableCell>
                  //               <TableCell>
                  //                 {item?.form_data?.description?.value ??
                  //                   'No Description'}
                  //               </TableCell>
                  //               <TableCell>
                  //                 {status_arr[item.status - 1]}
                  //               </TableCell>
                  //               <TableCell>
                  //                 {item?.status == 1 && (
                  //                   <>
                  //                     <Button
                  //                       onClick={() => submitAction(item, 1)}
                  //                     >
                  //                       <CheckBoxOutlinedIcon className="green" />
                  //                     </Button>
                  //                     <Button
                  //                       onClick={() => submitAction(item, 2)}
                  //                     >
                  //                       <CloseIcon className="red" />
                  //                     </Button>
                  //                     <Button
                  //                       onClick={() => submitAction(item, 3)}
                  //                     >
                  //                       <FilterNoneIcon className="dark-blue" />
                  //                     </Button>
                  //                   </>
                  //                 )}
                  //                 {item.status == 2 && (
                  //                   <Button>
                  //                     <DoneIcon className="green-fill" />
                  //                   </Button>
                  //                 )}
                  //                 {item.status == 3 && (
                  //                   <Button>
                  //                     <CloseIcon className="red-fill" />
                  //                   </Button>
                  //                 )}
                  //                 {item.status == 4 && (
                  //                   <Button>
                  //                     <FilterNoneIcon className="dark-blue" />
                  //                   </Button>
                  //                 )}
                  //                 {item.status != 1 && (
                  //                   <Button
                  //                     variant="success"
                  //                     className="rca-success"
                  //                     onClick={() => ActionHandler(item)}
                  //                   >
                  //                     <div className="editBtn">
                  //                       <EditIcon
                  //                         style={{
                  //                           fontSize: 17,
                  //                           marginRight: 1
                  //                         }}
                  //                       />{' '}
                  //                       Edit
                  //                     </div>
                  //                   </Button>
                  //                 )}
                  //               </TableCell>
                  //             </TableRow>
                  //             <div style={{ height: 7 }} />
                  //           </>
                  //         ))}
                  //       </TableBody>
                  //     </Table>
                  //   </Box>
                  // </PerfectScrollbar>
                )}
                {!loadingReportTable && !viewAll && tableData.length > 5 && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      p: 2
                    }}
                  >
                    {/* <Button
                      className="view-btn"
                      color="primary"
                      endIcon={<ArrowRightIcon />}
                      size="small"
                      variant="text"
                      onClick={() => setViewAll(true)}
                    >
                      View all
                    </Button> */}
                  </Box>
                )}
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Observation;

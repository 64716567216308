import {
  Box,
  Card,
  CardContent,
  LinearProgress,
  MenuItem,
  Select
} from '@material-ui/core';
import { History } from '@material-ui/icons';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { roundTwoDecimals } from 'src/Constants';
import 'src/css/TPI/assessments.css';
import { setFilterValue } from 'src/store/assessment/slice';

const AssessmentCard = ({ assessment, status }) => {
  let progress = assessment.sections.reduce(
    (acc, section) => {
      if (
        section.questions.every((quest) => quest.answerStatus === 'ANSWERED')
      ) {
        acc.completed++;
      }
      return acc;
    },
    { completed: 0, total: assessment.sections.length }
  );
  let progPerc = !!progress.total
    ? roundTwoDecimals((progress.completed / progress.total) * 100)
    : 0;
  return (
    <div class="col-lg-3 col-12 text-center">
      <div class="box-column">
        <div class={'box-top ' + assessment.status.toLowerCase()}>
          <h5 className="assess-title">{assessment.assessment.name}</h5>
          <br />
          <h5 className="assess-title">
            Score:{' '}
            {assessment.sections.reduce(
              (acc, curr) => acc + curr.scoredMarks,
              0
            )}{' '}
            /{' '}
            {assessment.sections.reduce(
              (acc, curr) => acc + curr.totalMarks,
              0
            )}
          </h5>
        </div>
        <div class="box-bottom">
          <div class="box-title facebook-title">
            {assessment.contractorName}
          </div>
          <div class="box-text">
            Last Updated:{' '}
            {moment(assessment.lastAccessTime).format('DD/MM/YYYY')}
            <div className="row">
              <div className="col-md-10">
                <LinearProgress
                  className="category-progress-bar catbar "
                  value={progPerc}
                  variant="determinate"
                />
              </div>
              <div className="col-md-2">
                <p className=" catper ">{progPerc}%</p>
              </div>
            </div>
            <Link to={`/auditor/assessment/${assessment._id}/categories`}>
              <Button
                className="view-btn"
                color="primary"
                endIcon={<ArrowRightIcon />}
                size="small"
                variant="text"
              >
                View
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const timeFilters = {
  NTO: 'NTO',
  OTN: 'OTN'
};

const renderCardsWithFilter = (responses, filter, contractor, timeFilter) => {
  let filtered = responses;

  if (contractor !== 'ALL') {
    filtered = filtered.filter((resp) => resp.contractorId === contractor);
  }

  if (filter !== 'ALL') {
    filtered = filtered.filter((response) => response.status === filter);
  }

  if (timeFilter === timeFilters.NTO) {
    let toFilter = [...filtered];
    filtered = toFilter.sort((a, b) =>
      moment(b.lastAccessTime).diff(moment(a.lastAccessTime))
    );
  }

  if (timeFilter === timeFilters.OTN) {
    let toFilter = [...filtered];
    filtered = toFilter.sort((b, a) =>
      moment(b.lastAccessTime).diff(moment(a.lastAccessTime))
    );
  }

  return filtered.map((response) => (
    <AssessmentCard status={response.status} assessment={response} />
  ));
};

const AssessmentGrid = ({ assessments, contractors = [] }) => {
  const { filter } = useSelector((state) => state.assessment);
  // const [filter, setFilter] = useState('ALL');
  const dispatch = useDispatch();
  const [contractor, setContractor] = useState('ALL');
  const [timeFilter, setTimeFilter] = useState(timeFilters.NTO);

  const setFilter = (data) => {
    dispatch(setFilterValue(data));
  };
  return (
    <Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <div className="d-flex w-100 justify-content-between align-items-center">
              <div className="Button">
                {[
                  'All',
                  // 'New',
                  'Pending',
                  'Submitted',
                  'Reassessment',
                  'Completed'
                ].map((status) => (
                  <button
                    type="button"
                    className={'btn btn-' + status.toLowerCase()}
                    style={
                      filter === status.toUpperCase()
                        ? {
                            boxShadow: '0 0 0 0.25rem rgb(13 110 253 / 25%)'
                          }
                        : {}
                    }
                    onClick={() => setFilter(status.toUpperCase())}
                  >
                    {status}
                  </button>
                ))}
              </div>
              <div style={{ marginBottom: '10px' }}>
                <Select
                  startAdornment={<History />}
                  value={timeFilter}
                  onChange={(e) => setTimeFilter(e.target.value)}
                  defaultValue={''}
                  variant="outlined"
                  style={{
                    marginRight: '1rem',
                    transform: 'translateY(.6rem)'
                  }}
                >
                  <MenuItem value={timeFilters.NTO}>Newest To Oldest</MenuItem>
                  <MenuItem value={timeFilters.OTN}>Oldest To Newest</MenuItem>
                </Select>
                <Select
                  value={contractor}
                  onChange={(e) => setContractor(e.target.value)}
                  defaultValue={'ALL'}
                  variant="outlined"
                  style={{
                    marginRight: '1rem'
                  }}
                >
                  <MenuItem value="ALL">All Companies</MenuItem>
                  {contractors.length !== 0
                    ? contractors.map((contra, index) => {
                        return (
                          <MenuItem key={index} value={contra.user}>
                            {contra.name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Select>
              </div>
            </div>
            {/* <br /> */}
            <div className="category-container">
              <div class="box">
                <div class="pl-24 pr-24">
                  <div class="row">
                    {renderCardsWithFilter(
                      assessments,
                      filter,
                      contractor,
                      timeFilter
                    )}
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default AssessmentGrid;

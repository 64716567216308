import {
    FormControl,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    TextField
  } from '@material-ui/core';
  import AttachFileIcon from '@material-ui/icons/AttachFile';
  import { useFormik } from 'formik';
  import React, { useState } from 'react';
  import Modal from 'react-bootstrap/Modal';
  import { useSelector } from 'react-redux';
  import { Button } from 'reactstrap';
  import { validateFileSize } from 'src/Constants';
  import swal from 'sweetalert';
  import * as Yup from 'yup';
  import Close from '@material-ui/icons/Close';
  import OverlaySpinner from 'src/components/common/OverlaySpinner';
  import Api from 'src/Api';
  import { storage } from 'src/firebase';
import FileDisplay from 'src/components/common/FileDisplay';
  
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      width: '100%'
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
      // marginLeft:theme.spacing(2)
    }
  }));
  
  const validateFileType = (file, docType) => {
    let pattern = /(jpg|jpeg|png|pdf|mp4|wmv|avi|mkv|flv|webm|gif|m4p|svi)$/;
    if (docType) {
      switch (docType) {
        case '1':
          pattern = /(jpg|jpeg|png)$/;
          break;
  
        case '2':
          pattern = /(mp4|wmv|avi|mkv|flv|webm|gif|m4p|svi)$/;
          break;
  
        case '3':
          pattern = /(pdf)$/;
          break;
        default:
          break;
      }
      if (!file.type.toLowerCase().match(pattern)) {
        swal({
          title: file.name,
          text: 'Please select Image, PDF and Excel files only!',
          icon: 'error',
          button: 'Ok'
        });
        return false;
      } else {
        return true;
      }
    } else {
      swal({
        title: 'Error',
        text: 'Please select document type!',
        icon: 'error',
        button: 'Ok'
      });
      return false;
    }
  };
  
  const AddNotice = ({ handleClose, show, onAddNotice = async () => {} }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
  
    const {
      userData
    } = useSelector((state) => state.auth);
  
    const submitHandler = async (val, helpers) => {
      let data = {};
      data.cmp_id = userData.assoc_companies[0];
      data.topic = 'Notices';
      data.document = {
        desc: val.description,
        rewards: Number(val.rewards),
        title: val.title,
        totaltime: Number(val.totalTime),
        type: Number(val.docType) === 4 ? 1 : Number(val.docType),
        subcat: 'Notice',
        filename:
          Number(val.docType) === 4 ? val.embedLink : val.selectedFile?.name,
        item_url: Number(val.docType) === 4 ? val.embedLink : ''
      };
      console.log('submitHandler', { data });
      setLoading(true);
      try {
        let response = await Api.uploadLearning(data);
        console.log('uploadLearning', { data, response });
        if (val.docType !== 4) {
          let file = val.selectedFile;
          let filePath = response.data.data;
          let fileRef = storage.ref(filePath);
          await fileRef.put(file);
        }
        helpers.resetForm();
        handleClose();
        await onAddNotice();
        swal({
          title: 'Success',
          text: 'Notice created successfully!',
          icon: 'success',
          button: 'Ok'
        });
      } catch (err) {
        console.log('createNotice err', { err });
        swal({
          title: 'Error',
          text: err.message,
          icon: 'error',
          button: 'Ok'
        });
      } finally {
        setLoading(false);
      }
    };
  
    const formik = useFormik({
      initialValues: {
        title: '',
        description: '',
        totalTime: '',
        rewards: '',
        docType: '',
        selectedFile: null,
        embedLink: ''
      },
      validationSchema: Yup.object().shape({
        title: Yup.string().required().label('Title'),
        description: Yup.string().required().label('Description'),
        docType: Yup.number().required(),
        totalTime: Yup.number()
          .typeError('Total time must be a number')
          .test(
            'isGT0',
            'Time should be greater than 0',
            (val) => !!val && val > 0
          )
          .required()
          .label('Total time'),
        rewards: Yup.number()
          .typeError('Rewards must be a number')
          .test(
            'isGrT0',
            'Rewards should be greater than 0',
            (val) => !!val && val > 0
          )
          .required()
          .label('Total Rewards'),
        embedLink: Yup.string()
          .when('docType', {
            is: (val) => Number(val) === 4,
            then: Yup.string()
              .required()
              .matches(/^https:\/\/www.youtube.com\/embed\/.*$/, 'Invalid Url'),
            otherwise: Yup.string()
          })
          .label('Embed Link'),
        selectedFile: Yup.mixed()
          .when('docType', {
            is: (val) => Number(val) !== 4,
            then: Yup.mixed().required('File is required'),
            otherwise: Yup.mixed().nullable()
          })
          .label('File')
      }),
      onSubmit: submitHandler
    });
    return (
      <>
        {loading && <OverlaySpinner />}
        <Modal
          show={show}
          onHide={handleClose}
          size="xl"
          backdrop="static"
          keyboard="false"
          centered
        >
          <Modal.Header>
            <Modal.Title>Upload Notice</Modal.Title>
            <h5
              className="add-document-close"
              onClick={() => {
                handleClose();
                formik.resetForm();
              }}
            >
              <Close />
            </h5>
          </Modal.Header>
          <Modal.Body>
            <div className="col-12 my-3">
              <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                  value={formik.values.title}
                  onChange={formik.handleChange('title')}
                  onBlur={formik.handleBlur('title')}
                  id="title"
                  label="Title"
                  className="form-control title-learning"
                  variant="outlined"
                  error={formik.touched?.title && formik.errors?.title}
                  helperText={formik.touched?.title ? formik.errors?.title : ''}
                />
              </FormControl>
            </div>
            <div className="col-md-12 my-4">
              <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                  value={formik.values.description}
                  onChange={formik.handleChange('description')}
                  onBlur={formik.handleBlur('description')}
                  id="Description"
                  label="Description"
                  multiline
                  rows={3}
                  className={classes.selectEmpty + classes.formControl}
                  variant="outlined"
                  error={
                    formik.touched?.description && formik.errors?.description
                  }
                  helperText={
                    formik.touched?.description ? formik.errors?.description : ''
                  }
                />
              </FormControl>
            </div>
            <div className="row">
              <div className="col-3 my-3">
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    value={formik.values.totalTime}
                    onChange={formik.handleChange('totalTime')}
                    onBlur={formik.handleBlur('totalTime')}
                    type="number"
                    id="totaltime"
                    label="Time in seconds"
                    multiline
                    className={classes.selectEmpty + classes.formControl}
                    variant="outlined"
                    error={formik.touched?.totalTime && formik.errors?.totalTime}
                    helperText={
                      formik.touched?.totalTime ? formik.errors?.totalTime : ''
                    }
                  />
                </FormControl>
              </div>
              <div className="col-3 my-3">
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    value={formik.values.rewards}
                    onChange={formik.handleChange('rewards')}
                    onBlur={formik.handleBlur('rewards')}
                    type="number"
                    id="rewards"
                    label="Rewards"
                    multiline
                    className={classes.selectEmpty}
                    variant="outlined"
                    error={formik.touched?.rewards && formik.errors?.rewards}
                    helperText={
                      formik.touched?.rewards ? formik.errors?.rewards : ''
                    }
                  />
                </FormControl>
              </div>
              <div className="col-2 my-3">
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel variant="outlined">Document Type</InputLabel>
                  <Select
                    value={formik.values.docType}
                    onChange={(e) => {
                      formik.setFieldValue('docType', Number(e.target.value));
                      formik.setFieldValue('selectedFile', null);
                    }}
                    onBlur={formik.handleBlur('docType')}
                    displayEmpty
                    className={classes.selectEmpty + classes.formControl}
                    autoWidth
                    id="document-type"
                    error={formik.touched?.docType && formik.errors?.docType}
                    helperText={
                      formik.touched?.docType ? formik.errors?.docType : ''
                    }
                  >
                    <MenuItem value={1}>Video</MenuItem>
                    <MenuItem value={2}>Image</MenuItem>
                    <MenuItem value={3}>PDF</MenuItem>
                    <MenuItem value={4}>Youtube Link</MenuItem>
                  </Select>
                  {/* <FormHelperText>Without label</FormHelperText> */}
                </FormControl>
              </div>
              {Number(formik.values.docType) !== 4 && (
               <>
               {!formik.values.selectedFile && (
                 <div className="col-1 my-3">
                   <Button variant="" className=" learning-buttons">
                     <input
                       id="file-input"
                       type="file"
                       color="primary"
                       className="file-input-icon "
                       required
                       onBlur={formik.handleBlur('selectedFile')}
                       onChange={(e) => {
                         console.log('fileHandler', e.target.files[0]);
                         let file = e.target.files[0];
                         if (file) {
                           if (
                             !validateFileSize(file, 10) ||
                             !validateFileType(file, formik.values.docType)
                           ) {
                             return formik.setFieldValue('selectedFile', null);
                           }
                           formik.setFieldValue('selectedFile', file);
                         }
                       }}
                       accept={
                         !formik.values.docType
                           ? 'image/*, video/*, application/pdf'
                           : Number(formik.values.docType) === 2
                           ? 'image/*'
                           : Number(formik.values.docType) === 1
                           ? 'video/*'
                           : 'application/pdf'
                       }
                     />
                     <label for="file-input" className="file response-file">
                       <AttachFileIcon />
                     </label>
                   </Button>
                 </div>
               )}
             </>
           )}
           <div className="col-2 my-3">
             {formik.values.selectedFile ? (
               <FileDisplay
                 file={formik.values.selectedFile}
                 handleDelete={() => {
                   formik.setFieldValue('selectedFile', null);
                 }}
                 style={{
                  margin: '-.25rem .5rem 0rem 0',
                  padding : ".4rem .7rem"
                }}
               />
             ) : null}
                <p className="text-danger">
                  {formik.touched?.selectedFile && formik.errors?.selectedFile}
                </p>
              </div>
              <div className="col-6 my-3">
                {Number(formik.values.docType) === 4 && (
                  <FormControl variant="outlined" className={classes.formControl}>
                    <TextField
                      value={formik.values.embedLink}
                      onChange={formik.handleChange('embedLink')}
                      onBlur={formik.handleBlur('embedLink')}
                      type="number"
                      id="embedLink"
                      label="Embed Link"
                      multiline
                      className={classes.selectEmpty}
                      variant="outlined"
                      error={
                        formik.touched?.embedLink && formik.errors?.embedLink
                      }
                      helperText={
                        formik.touched?.embedLink ? formik.errors?.embedLink : ''
                      }
                    />
                  </FormControl>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <Button
                // variant="primary"
                type="button"
                className="modal-submit-btn"
                onClick={formik.handleSubmit}
                color="primary"
                // disabled={!formValid}
              >
                Submit
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  };
  
  export default AddNotice;
  
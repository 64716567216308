import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  observation: {}
};
console.log('observation', initialState);
export const observationSlice = createSlice({
  name: 'observation',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setObservation(state, action) {
      state.observation = action.payload;
    },
    resetObservation(state, action) {
      state = initialState
      return state
    }
  }
});

// Action creators are generated for each case reducer function
export const { setLoading, setObservation, resetObservation } = observationSlice.actions;

export default observationSlice.reducer;

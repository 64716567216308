import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography
} from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import InsertChartIcon from '@material-ui/icons/InsertChartOutlined';

const TasksProgress = (props) => (
  
    <CardContent>
     
        
         
        
        
        
     
      <Box sx={{ pt: 3 }}>
        <div className="row">
        <div className="col-md-2">  <Typography
            color="textPrimary"
            variant="h3"
          >
          {props.count}
          </Typography></div>
        <div class="col-md-10">
        <LinearProgress
          value={props?.total ? ((props?.count * 100) / props?.total) > 100 ? 100 : (props?.count * 100) / props?.total : 0}
          variant="determinate"
        />
        </div>
        </div>
        
      </Box>
    </CardContent>
  
);

export default TasksProgress;

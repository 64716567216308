import auth from './auth/reducers';
import header from './header/slice';
import auditors from './auditors/reducers';
import feedback from './feedback/slice';
import assessment from './assessment/slice';
import observation from './observation/slice';

const rootReducer = {
  auth,
  header,
  auditors,
  feedback,
  assessment,
  observation
};

export default rootReducer;

import PerfectScrollbar from 'react-perfect-scrollbar';
import '../../css/dashboard/assessments.css';
import { Box, Card, CardHeader, Divider } from '@material-ui/core';
// import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import 'bootstrap/dist/css/bootstrap.min.css';
import CapImage from 'src/images/caps/red-cap.png';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Api from 'src/Api';
import swal from 'sweetalert';
import { getCapData } from 'src/Constants';
import SpinnerContainer from '../common/SpinnerContainer';
const cap = {
  avatarUrl: CapImage,
  type: 'Red Score',
  score: '40',
  color: 'Red',
  LastUpdated: '31/03/2021'
};

const CapDetails = (props) => {
  const [loading, setLoading] = useState(true);
  const [capDetails, setCapDetails] = useState({});

  const { userData } = useSelector((state) => state.auth);

  const fetchCapDetails = async () => {
    try {
      setLoading(true);
      let res = (
        await Api.getTotalScoreForContractor({
          contractorId: userData.user
        })
      ).data;

      if (res.code >= 400) {
        throw new Error(res.message);
      }

      let totalScores = res.data;

      if (totalScores.length > 0) {
        let score = totalScores.reduce(
          (acc, score) => {
            acc.scored = acc.scored + score.scored;
            acc.total = acc.total + score.total;
            return acc;
          },
          { scored: 0, total: 0 }
        );

        let percent = (score.scored / score.total) * 100;

        setCapDetails(getCapData(percent));
      }
    } catch (error) {
      swal('Error', error.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    userData?.user && fetchCapDetails();
    return () => {};
  }, [userData]);

  return (
    <Card {...props} id="CapDetails">
      <CardHeader title={<h2>Score Details</h2>} />
      <Divider />
      {loading ? (
        <div
          className="h-100"
          style={{
            marginTop: '-2em'
          }}
        >
          <SpinnerContainer />
        </div>
      ) : capDetails?.score ? (
        <PerfectScrollbar>
          <Box>
            <div className="container">
              <div className=" row">
                <div className="col-md-6 score text-center">
                  <img src={capDetails?.avatarUrl} alt="cap" id="cap" />
                  <h5 className={capDetails?.color}>{capDetails?.type}</h5>
                </div>
                <div className="col-md-6">
                  <div className="my-auto text-center score">
                    <h3>Your Score</h3>
                    <h1 className={capDetails?.color} id="score-size">
                      {capDetails?.score}
                    </h1>
                  </div>
                  {/* <div className="pull-right">
                    <p className="LastUpdated LastUpdated2">
                      Last Updated:&nbsp; */}
                  {/* //TODO: get last updated from assessments */}
                  {/* {cap.LastUpdated}
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </Box>
        </PerfectScrollbar>
      ) : (
        <div
          className="h-100 w-100 d-flex justify-content-center align-items-center"
          style={{
            marginTop: '-2em'
          }}
        >
          No Data Available
        </div>
      )}
    </Card>
  );
};

export default CapDetails;

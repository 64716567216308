import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import Checklistcomponent from 'src/components/ATGL/assessment/Checklistcomponent';
import { Link } from 'react-router-dom';

const Checklist = () => (
  <>
    <Helmet>
      <title>Checklist | Oren</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <h2>
          <ul class="breadcrumb">
            <li>
              <Link to="/sapphire/Assessment">Assessment</Link>
            </li>
            <li>
              <Link to="#">Assessment Detail</Link>
            </li>
            <li>
              <Link to="#">Checklist</Link>
            </li>
          </ul>
        </h2>
        <Checklistcomponent />
        <Box sx={{ pt: 3 }}></Box>
      </Container>
    </Box>
  </>
);

export default Checklist;

import moment from 'moment';
import Api from 'src/Api';
import swal from 'sweetalert';
import {
  setAllCategories,
  setAssessments,
  setAllQuestions,
  setLoading,
  setResponses,
  setContractors
} from './slice';

export const assessmentAdded = (assessment) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    let res = await Api.createAssessment(assessment);
    swal({
      title: 'Assessment Added Successfully',

      icon: 'success',
      button: 'Ok'
    });
    //Navigate("/sapphire/users")
    dispatch(setLoading(false));
    //dispatch(getAllUAuditors())
    console.log(res);
  } catch (err) {
    dispatch(setLoading(false));
    swal({
      title: 'Error',
      text: err.message,
      icon: 'error',
      button: 'Ok'
    });
    console.log(err);
  }
};

export const getAssessmentResponses = (companyId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    let query = {
      companyIds: companyId,
      include: {
        assessment: true,
        sections_category: false,
        sections_questions_question: false
      }
    };
    let res = await Api.getResponsesByCompanyIds(query);

    if (res.status !== 200) throw new Error('Error in fetching assessments!');

    let responses = res.data.data.sort((a, b) =>
      moment(b.lastAccessTime).diff(moment(a.lastAccessTime))
    );

    dispatch(setResponses(responses));
  } catch (error) {
    swal({
      title: 'Error',
      text: error.message,
      icon: 'error',
      button: 'OK'
    });
  } finally {
    dispatch(setLoading(false));
  }
};

export const getAssessmentCategories = (companyId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    let allCategories = [];

    allCategories = await Api.getAssessmentCategories({ companyId: companyId });
    // let sortData = allFeedback.data.data.sort((a, b) =>
    //   b.createdAt._seconds-a.createdAt._seconds
    // )
    //console.log("SORTDATA>>>", sortData)
    dispatch(setAllCategories(allCategories.data.data));

    dispatch(setLoading(false));
  } catch (err) {
    console.log('errr');
    console.error(err);
    dispatch(setLoading(false));
    console.log(err);
  }
};

export const getAllQuestionsAssessment = (companyId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    let questions = [];

    questions = await Api.getQuestionByCmpId({ companyId: companyId });
    // let sortData = allFeedback.data.data.sort((a, b) =>
    //   b.createdAt._seconds-a.createdAt._seconds
    // )
    //console.log("SORTDATA>>>", sortData)
    dispatch(setAllQuestions(questions.data.data));

    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
    console.log(err);
  }
};

export const setupAssessmentsForContractor =
  (contractorId) => async (dispatch) => {
    try {
      dispatch(setLoading(true));

      let assessmentQuery = {
        contractorId: contractorId,
        include: {
          sections_category: false,
          sections_questions_question: false
        }
      };

      let responseQuery = {
        contractorId: contractorId,
        include: {
          assessment: true,
          sections_category: false,
          sections_questions_question: false
        }
      };

      let res = await Promise.all([
        Api.getAssessmentsByContractorId(assessmentQuery),
        Api.getResponsesByContractorId(responseQuery)
      ]);

      if (res[0].status !== 200 || res[1].status !== 200)
        throw new Error('Error in fetching assessments!');

      let assessments = res[0].data.data.sort((a, b) =>
        moment(b.createdAt).diff(moment(a.createdAt))
      );
      let responses = res[1].data.data.sort((a, b) =>
        moment(b.lastAccessTime).diff(moment(a.lastAccessTime))
      );

      assessments = assessments.filter((assessment) => {
        let dec =
          typeof responses.find(
            (res) => res.assessment._id === assessment._id
          ) === 'undefined';

        console.log(assessment._id, dec);

        return dec;
      });

      dispatch(setAssessments(assessments));
      dispatch(setResponses(responses));
    } catch (error) {
      swal({
        title: 'Error',
        text: error.message,
        icon: 'error',
        button: 'OK'
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

export const setupAssessmentsForCompany = (companyId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    let assessmentQuery = {
      companyIds: [companyId],
      include: {
        sections_category: false,
        sections_questions_question: false
      }
    };

    let responseQuery = {
      companyIds: [companyId],
      include: {
        assessment: true,
        sections_category: false,
        sections_questions_question: false
      }
    };

    let res = await Promise.all([
      Api.getAssessmentsByCompanyIds(assessmentQuery),
      Api.getResponsesByCompanyIds(responseQuery),
      Api.getAllContractorsByID({ companyId })
    ]);

    if (res[0].status !== 200 || res[1].status !== 200)
      throw new Error('Error in fetching assessments!');

    let assessments = res[0].data.data.sort((a, b) =>
      moment(b.createdAt).diff(moment(a.createdAt))
    );
    let responses = res[1].data.data.sort((a, b) =>
      moment(b.lastAccessTime).diff(moment(a.lastAccessTime))
    );
    let contractors = res[2].data.data;

    assessments = assessments.filter((assessment) => {
      let dec =
        typeof responses.find(
          (res) => res.assessment._id === assessment._id
        ) === 'undefined';

      console.log(assessment._id, dec);

      return dec;
    });

    dispatch(setAssessments(assessments));
    dispatch(setResponses(responses));
    dispatch(setContractors(contractors));
  } catch (error) {
    swal({
      title: 'Error',
      text: error.message,
      icon: 'error',
      button: 'OK'
    });
  } finally {
    dispatch(setLoading(false));
  }
};

export const setupAssessmentsForTpi = (companyIds) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    let responseQuery = {
      companyIds: companyIds,
      include: {
        assessment: true,
        sections_category: false,
        sections_questions_question: false
      }
    };

    let res = await Promise.all([
      Api.getResponsesByCompanyIds(responseQuery),
      Api.getAllContractorsByID({ companyId: companyIds })
    ]);

    if (res[0].status !== 200 || res[1].status !== 200)
      throw new Error('Error in fetching assessments!');

    let responses = res[0].data.data.sort((a, b) =>
      moment(b.lastAccessTime).diff(moment(a.lastAccessTime))
    );
    let contractors = res[1].data.data;

    dispatch(setResponses(responses));
    dispatch(setContractors(contractors));
  } catch (error) {
    swal({
      title: 'Error',
      text: error.message,
      icon: 'error',
      button: 'OK'
    });
  } finally {
    dispatch(setLoading(false));
  }
};

export const createAssessmentResponse =
  (contractor, assessmentId, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));

      let assessmentQuery = {
        assessmentId: assessmentId,
        contractor
      };

      let res = await Api.createResponseForContractor(assessmentQuery);

      if (res.status >= 400) throw new Error('Error in creating response!');

      let response = res.data.data;

      navigate(`/atgl/Contractor/assessment/${response._id}/categories`, {
        replace: true
      });
    } catch (error) {
      swal({
        title: 'Error',
        text: error.message,
        icon: 'error',
        button: 'OK'
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

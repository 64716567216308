import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
// import CustomerListResults from 'src/components/customer/CustomerListResults';
// import CustomerListToolbar from 'src/components/customer/CustomerListToolbar';
// import customers from 'src/__mocks__/customers';
import Checklistcomponent from 'src/components/customer/Checklistcomponent';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import swal from 'sweetalert';
import Api from 'src/Api';
import OverlaySpinner from 'src/components/common/OverlaySpinner';
import { Link } from 'react-router-dom';

const Checklist = () => {
  const { responseId, categoryId } = useParams();
  const [response, setResponse] = useState(null);
  const [section, setSection] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchAssessmentResponseById = async () => {
    try {
      setLoading(true);
      let query = {
        responseId: responseId,
        include: {
          assessment: false,
          sections_category: true,
          sections_questions_question: true
        }
      };
      let request = await Api.getResponseById(query);
      let response = request.data.data;
      console.log("getResponseById", {response, query})

      setResponse(response);
      setSection(
        response.sections.find((section) => section._id === categoryId)
      );
    } catch (error) {
      console.log(error);
      return swal({
        title: 'Error',
        text: 'Error in fetching assessment',
        icon: 'error',
        button: 'OK'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (responseId && categoryId) fetchAssessmentResponseById();
    return () => {};
  }, [responseId, categoryId]);

  return (
    <>
      {loading && <OverlaySpinner />}
      <Helmet>
        <title>Checklist | Oren</title>
      </Helmet>
      {section && (
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          <Container maxWidth={false}>
            <h2>
              <ul class="breadcrumb">
                {/* <li><a href="dashboard">Home</a></li> */}
                <li>
                  <Link to="/atgl/Contractor/Assessment">Assessment</Link>
                </li>
                <li>
                  <Link
                    to={`/atgl/Contractor/assessment/${responseId}/categories`}
                  >
                    Categories
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/atgl/Contractor/assessment/${responseId}/categories/${categoryId}`}
                  >
                    Checklist
                  </Link>
                </li>
              </ul>
            </h2>
            {section && (
              <Checklistcomponent
                section={section}
                setLoading={setLoading}
                responseId={responseId}
                response={response}
              />
            )}
            <Box sx={{ pt: 3 }}>
              {/* <CustomerListResults customers={customers} /> */}
            </Box>
          </Container>
        </Box>
      )}
    </>
  );
};

export default Checklist;

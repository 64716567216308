import {
  Box,
  Button,
  Card,
  CardHeader,
  // Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
  // TableSortLabel,
  // Tooltip
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import Api from 'src/Api';
import { roundTwoDecimals } from 'src/Constants';
import 'src/css/ATGL/dashboard.css';
import swal from 'sweetalert';
// import score from 'src/components/Learning/Tasksscore';

const Documents = (props) => {
  const { userData } = useSelector((state) => state.auth);
  const [scores, setScores] = useState([]);
  const [isAll, setIsAll] = useState(false);

  const fetchContractorScores = async () => {
    try {
      // setLoading(true);

      let scoreQuery = {
        companyId: userData?.companyId
      };

      let res = await Api.getTotalScoresOfContrsInCompany(scoreQuery);

      if (res.data.data.code >= 400)
        throw new Error('Error in fetching scores!');

      setScores(
        res.data.data.map((score) => ({
          ...score._id,
          scored: score.scored,
          total: score.total,
          perc: (score.scored / score.total) * 100
        }))
      );
    } catch (error) {
      swal({
        title: 'Error',
        text: error.message,
        icon: 'error',
        button: 'OK'
      });
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    !!userData?.companyId && fetchContractorScores();
    return () => {};
  }, [userData]);

  return (
    <Card {...props}>
      <CardHeader title={<h2>Leaderboard</h2>} />
      <Divider />
      <PerfectScrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="text-center">Companies</TableCell>
                <TableCell className="text-center">Score</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {scores
                .slice(0, isAll ? scores.length : 5)
                .map((score, index) => {
                  if (!isAll && index > 4) return null;
                  let percentage = roundTwoDecimals(score.perc);
                  let color =
                    percentage >= 70
                      ? 'green'
                      : percentage >= 40
                      ? 'orange'
                      : 'red';
                  console.log(percentage, color);
                  return (
                    <TableRow hover key={score.contractorId}>
                      <TableCell className={'light-' + color + ' text-center'}>
                        <u>{score.contractorName}</u>
                      </TableCell>
                      <TableCell className={'light-' + color + ' text-center'}>
                        {percentage}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        {scores.length > 5 && (
          <Button
            className="view-btn"
            color="primary"
            endIcon={<ArrowRightIcon />}
            size="small"
            variant="text"
            onClick={() => setIsAll((old) => !old)}
          >
            {isAll ? 'View Less' : 'View all'}
          </Button>
        )}
      </Box>
    </Card>
  );
};

export default Documents;

import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import Api from 'src/Api';
import SpinnerContainer from 'src/components/common/SpinnerContainer';
import 'src/css/dashboard/tpi_dashboard.css';
import swal from 'sweetalert';
import { ASSESS_STATUS } from 'src/Constants';
import { useNavigate } from 'react-router-dom';

const orders = [
  {
    ref: '1',
    Company: 'XYZ Company',

    Updated: '20/04/2021',
    assessment: 'ESG Assessment',
    status: 'Pending',
    color: 'text-green'
  },
  {
    ref: '2',
    Company: 'PQR Company',
    Updated: '20/04/2021',
    assessment: 'ESG Assessment',
    status: 'Submitted',
    color: 'text-blue'
  },
  {
    ref: '3',
    Company: 'ABC Company',
    Updated: '20/04/2021',
    assessment: 'ESG Assessment',
    status: 'Reassessment',
    color: 'text-orange'
  }
];

const Documents = (props) => {
  const [loading, setLoading] = useState(true);
  const [assessments, setAssessments] = useState([]);
  const [isAll, setIsAll] = useState(false);
  const navigate = useNavigate();

  const { userData } = useSelector((state) => state.auth);

  const fetchAssessments = async () => {
    try {
      setLoading(true);
      let query = {
        companyIds: userData?.assoc_companies,
        include: {
          assessment: true,
          sections_category: false,
          sections_questions_question: false
        }
      };
      let res = (await Api.getResponsesByCompanyIds(query)).data;

      if (res.code >= 400) {
        throw new Error(res.message);
      }

      setAssessments(
        res.data
          .filter((resp) => resp?.status === ASSESS_STATUS.SUBMITTED)
          .sort((a, b) =>
            moment(b.lastAccessTime).diff(moment(a.lastAccessTime))
          )
          .map((assess, index) => ({
            ref: index.toString(),
            Company: assess?.contractorName,
            Updated: moment(assess?.lastAccessTime).format('DD/MM/YYYY'),
            assessment: assess?.assessment?.name,
            id: assess._id,
            status: (assess?.status ?? '-').onlyFirstCapital(),
            color: 'text-' + (assess?.status ?? '').toLowerCase()
          }))
      );
    } catch (error) {
      swal('Error', error.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    userData?.assoc_companies && fetchAssessments();
    return () => {};
  }, [userData]);

  if (loading) {
    return (
      <div
        className="w-100 h-100 p-5"
        style={{
          marginTop: '-2rem'
        }}
      >
        <SpinnerContainer />
      </div>
    );
  }

  return (
    <>
      <Card>
        <div className="row">
          {/* <div className="col-md-6"><CardHeader title="Pending" /></div> */}
          {/* <div className="col-md-6" id="notice-add"><a href=""><h5>+Add new notice</h5></a></div> */}
        </div>

        {/* <Divider /> */}
        <PerfectScrollbar>
          <Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="text-center">Company</TableCell>
                  <TableCell className="text-center">Updated On</TableCell>
                  <TableCell className="text-center">Assessment</TableCell>
                  <TableCell className="text-center">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {assessments
                  .slice(0, isAll ? assessments.length : 5)
                  .map((assessment) => (
                    <TableRow>
                      <TableCell className="text-center">
                        {assessment.Company}
                      </TableCell>
                      <TableCell className="text-center">
                        {assessment.Updated}
                        {/* {moment(order.date).format('DD/MM/YYYY')} */}
                      </TableCell>
                      <TableCell className="text-center">
                        <div
                          style={{
                            cursor: 'pointer'
                          }}
                          onClick={() =>
                            navigate(
                              `/auditor/assessment/${assessment.id}/categories`
                            )
                          }
                        >
                          {assessment.assessment}
                        </div>
                      </TableCell>
                      <TableCell className={assessment.color}>
                        {assessment.status}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2
            }}
          >
            {assessments.length > 5 && (
              <Button
                className="view-btn view-btn2"
                color="primary"
                endIcon={<ArrowRightIcon />}
                size="small"
                variant=""
                onClick={() => setIsAll((old) => !old)}
              >
                {isAll ? 'View Less' : 'View all'}
              </Button>
            )}
          </Box>
        </PerfectScrollbar>
      </Card>
    </>
  );
};

export default Documents;

import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import DashboardLayout2 from 'src/components/DashboardLayout2';
import DashboardLayout3 from 'src/components/DashboardLayout3';
import MainLayout from 'src/components/MainLayout';
import Account from 'src/pages/Account';
import CustomerList from 'src/pages/CustomerList';
import Dashboard from 'src/pages/Dashboard';
import Login from 'src/pages/Login';
import NotFound from 'src/pages/NotFound';
import ProductList from 'src/pages/ProductList';
import Register from 'src/pages/Register';
import Settings from 'src/pages/Settings';
import Assessment from 'src/pages/Assessment';
import Observation from 'src/pages/Observation';
import Learning from 'src/pages/Learning';
import Categories from 'src/pages/Categories';
import Engagement from 'src/pages/Engagement';
import Checklist from 'src/pages/Checklist';
import Forgot_Password from 'src/pages/Forgot_Password';

/*****ATGL PORTAL****/
import Home from 'src/pages_2/Home';
import Assessment_ATGL from 'src/pages_2/Assessment';
import Users from 'src/pages_2/Users';
import Learning_atgl from 'src/pages_2/Learning';
import Observation_atgl from 'src/pages_2/Observation';
import ViewObservation_atgl from 'src/pages_2/ViewObservation';
import Add_Auditor from 'src/pages_2/Add_Auditor';
import Add_Assessment from 'src/pages_2/Add_Assessment';
import Checklist_ATGL from 'src/pages_2/Checklist';
import Cap_AssesmentView from 'src/pages_2/Cap_view_assessment';
import Feedback from 'src/pages_2/Feedback';
import View_assessment from 'src/pages_2/View_assessment';

/*****TPI PORTAL****/
import Home_tpi from 'src/pages_3/Home';
import Assessment_TPI from 'src/pages_3/Assessment';
import Categories_TPI from 'src/pages_3/Categories';
import Checklist_TPI from 'src/pages_3/Checklist';
import AddCompany from './components/users/add_company';
import Companies from './pages_2/Companies';
import ViewAuditor from './components/users/ViewAuditor';
import EditAuditor from './components/users/EditAuditor';
import ViewCompany from './components/users/ViewCompany';
import EditCompany from './components/users/EditCompany';
import ResetPassword from './pages/Reset_Password';
import AccountProfile from './components/account/AccountProfile';
import Edit_assess from './components/ATGL/assessment/edit_assessment';
import Edit_Assessment from './pages_2/Edit_Assessment';
import OnlyViewObservation from './pages_2/OnlyViewObservation';

const routes = [
  {
    //contractor
    path: 'atgl/',
    element: <DashboardLayout />,
    children: [
      { path: 'Contractor/account', element: <Account /> },
      { path: 'Contractor/assessment', element: <Assessment /> },
      { path: 'Contractor/observation', element: <Observation_atgl /> },
      { path: 'Contractor/ViewObservation', element: <ViewObservation_atgl /> },
      {
        path: 'Contractor/OnlyViewObservation',
        element: <OnlyViewObservation />
      },
      { path: 'Contractor/learning', element: <Learning /> },
      { path: 'Contractor/engagement', element: <Engagement /> },
      {
        path: 'Contractor/assessment/:responseId/categories',
        element: <Categories />
      },
      {
        path: 'Contractor/assessment/:responseId/categories/:categoryId',
        element: <Checklist />
      },
      { path: 'Contractor/customers', element: <CustomerList /> },
      { path: 'Contractor/dashboard', element: <Dashboard /> },
      { path: 'products', element: <ProductList /> },
      { path: 'settings', element: <Settings /> },
      { path: '/', element: <Navigate to="Contractor/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    //compamny
    path: 'sapphire/',
    element: <DashboardLayout2 />,
    children: [
      { path: 'Dashboard', element: <Home /> },
      { path: 'account', element: <Account /> },
      { path: 'observation', element: <Observation_atgl /> },
      { path: 'ViewObservation', element: <ViewObservation_atgl /> },
      { path: 'OnlyViewObservation', element: <OnlyViewObservation /> },
      { path: 'learning', element: <Learning_atgl /> },
      { path: 'assessment', element: <Assessment_ATGL /> },
      { path: 'add_assessment', element: <Add_Assessment /> },
      { path: 'editAssessment/:assessmentId', element: <Edit_Assessment /> },
      {
        path: 'cap_assesmentview/:assessmentId',
        element: <Cap_AssesmentView />
      },
      {
        path: 'cap_assesmentview/:responseId/Categories/:categoryId',
        element: <Checklist_TPI />
      },
      { path: 'view_assessment', element: <View_assessment /> },
      { path: 'engagement', element: <Engagement /> },
      { path: 'checklist', element: <Checklist_ATGL /> },
      { path: 'users', element: <Users /> },
      { path: 'feedback', element: <Feedback /> },
      { path: 'companies', element: <Companies /> },
      { path: 'add_auditor', element: <Add_Auditor /> },
      { path: 'ViewAuditor', element: <ViewAuditor /> },
      { path: 'ViewCompany', element: <ViewCompany /> },
      { path: 'EditAuditor', element: <EditAuditor /> },
      { path: 'EditCompany', element: <EditCompany /> },
      { path: 'add_company', element: <AddCompany /> },
      { path: 'products', element: <ProductList /> },
      { path: 'settings', element: <Settings /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'auditor/',
    element: <DashboardLayout3 />,
    children: [
      { path: 'dashboard', element: <Home_tpi /> },
      { path: 'assessment', element: <Assessment_TPI /> },
      { path: 'engagement', element: <Engagement /> },
      {
        path: 'assessment/:responseId/Categories/:categoryId',
        element: <Checklist_TPI />
      },
      {
        path: 'assessment/:responseId/Categories/',
        element: <Categories_TPI />
      },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: '',
    children: [
      { path: '/', element: <Login /> },
      { path: 'Forgot_Password', element: <Forgot_Password /> },
      { path: 'reset_password', element: <ResetPassword /> },
      { path: 'register', element: <Register /> },
      { path: '/profile', element: <AccountProfile /> },
      { path: '404', element: <NotFound /> },
      // { path: '/', element: <Navigate to="/Contractor/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;

import { useState, useEffect } from 'react';
import { MDBDataTableV5 } from 'mdbreact';
import swal from 'sweetalert';
// import '../../css/l/assessments.css';
import Progress from 'src/components/Learning/TasksProgress';
import API from '../../../Api';
import { useSelector } from 'react-redux';
import OverlaySpinner from 'src/components/common/OverlaySpinner';

const columns = [
  {
    label: 'Sr.No.',
    field: 'no',
    width: 10
  },
  {
    label: 'Employee',
    field: 'Employee',
    width: 50
  },
  {
    label: 'Learning Units',
    field: 'units',
    width: 50
  },
  {
    label: 'Completion',
    field: 'Completion',
    width: 370
  }
];

export default function Basic() {
  const [datatable, setDatatable] = useState({ columns });
  const [loading, setLoading] = useState(false);
  const { userData } = useSelector((state) => state.auth);

  useEffect(() => {
    if (userData) {
      fetchLearningTable();
    }
  }, [userData]);

  const fetchLearningTable = async () => {
    setLoading(true);
    try {
      let response = await API.fetchLearningTable(
        userData.companyId,
        'company'
      );
      console.log("fetchLearningTable", {response})
      let rows = response.data.data.map((el, i) => {
        return {
          no: i + 1,
          Employee: el.name,
          units: `${(el.completed_hrs / 3600).toFixed(2)}hrs`,
          Completion: (
            <Progress
              total={response.data.total_learning_module}
              count={el.completed_modules}
            />
          )
        };
      });
      setDatatable({ columns, rows });
      console.log('fetchLearningTable', { response });
    } catch (err) {
      console.log('createNotice err', { err });
      swal({
        title: 'Error',
        text: err.message,
        icon: 'error',
        button: 'Ok'
      });
    } finally {
      setLoading(false);
    }
  };
  return loading ? (
    <OverlaySpinner />
  ) : (
    <MDBDataTableV5
      hover
      entriesOptions={[10, 20, 30]}
      entries={10}
      pagesAmount={4}
      data={datatable}
      searchTop 
      searchBottom={false}
    />
  );
}

import { Box, Card, Container, Divider, Grid, Menu } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import ChatIcon from '@material-ui/icons/Chat';
import { Link, useParams } from 'react-router-dom';
import Api from 'src/Api';
import Assessments from 'src/components/ATGL/assessment/assessments';
import CapDetails from 'src/components/ATGL/assessment/CapDetails';
import Documents from 'src/components/ATGL/assessment/Documents';
import OverlaySpinner from 'src/components/common/OverlaySpinner';
import swal from 'sweetalert';
import '../css/dashboard/assessments.css';
import '../css/dashboard/dashboard.css';
import AssessmentChat from 'src/components/customer/AssessmentChat';
import { useSelector } from 'react-redux';
import ListIcon from '@material-ui/icons/List';
import { writeDataToExcel } from 'src/utils/excel';
import { AnswerTypesValues } from 'src/components/common/AnwerTypes';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const Cap_assesmentview = () => {
  const classes = useStyles();
  const { userData } = useSelector((state) => state.auth);
  const [type, setType] = React.useState('All');
  const [selectedCategory, setSelectedCategory] = React.useState();

  const { assessmentId } = useParams();

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [assessment, setAssessment] = useState(null);
  const [capPercent, setCapPercent] = useState(0);
  const [assessmentScore, setAssessmentScore] = useState(0);
  const [question, setQuestion] = useState('');
  const [sendFile, setSendFile] = useState(null);
  const [files, setFiles] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [section, setSection] = useState(null);

  const handleShow = (questionId) => () => {
    setQuestion(questionId);
    setShow(true);
  };
  const handleClose1 = () => setShow(false);

  const fetchAssessmentResponseById = async () => {
    try {
      setLoading(true);
      let query = {
        responseId: assessmentId,
        include: {
          assessment: true,
          sections_category: true,
          sections_questions_question: true
        }
      };
      let request = await Api.getResponseById(query);
      let response = request.data.data;
      //assessment score calculation
      let sect = [];
      let sum = 0;
      sect = response.sections;
      for (var i = 0; i < sect.length; i++) {
        sum = sum + sect[i].scoredMarks;
      }
      console.log('SUM::::', sum);

      setAssessmentScore(sum);
      setSection(response.sections);
      let totalScores = (
        await Api.getTotalScoreForContractor({
          contractorId: response.contractorId
        })
      ).data.data;

      console.log(totalScores);

      if (totalScores.length > 0) {
        let scored, total;

        let score = totalScores.reduce(
          (acc, score) => {
            acc.scored = acc.scored + score.scored;
            acc.total = acc.total + score.total;
            return acc;
          },
          { scored: 0, total: 0 }
        );

        let percent = (score.scored / score.total) * 100;

        setCapPercent(percent);
      }

      setAssessment(response);

      console.log(
        'RESPONSE',
        response.sections.map((section) => {
          let sheet = {
            sheetName: section.category.name,
            data: []
          };

          sheet.data = section.questions.map((quest) => {
            let cell = {};
            cell.topic = quest.question.questText;

            if (quest.questType.yesNo) {
              cell['Yes/No Response'] = quest.answer.yesNo ? 'YES' : 'NO';
            }
            if (quest.questType.text) {
              cell['Text Response'] = quest.answer.text;
            }
            if (quest.questType.attachment) {
              quest.answer.attachment.forEach((atch, index) => {
                cell['Attachment-' + index] = {
                  t: 's',
                  v: atch.fileName,
                  l: { Target: atch.fileLink, Tooltip: 'Click to view file' }
                };
              });
            }

            return cell;
          });
          return sheet;
        })
      );
    } catch (error) {
      console.log('EXCEL error', error);
      return swal({
        title: 'Error',
        text: 'Error in fetching assessment',
        icon: 'error',
        button: 'OK'
      });
    } finally {
      setLoading(false);
    }
  };

  const renderTableRows = (assessment, category) => {
    if (category === 'All') {
      return assessment.sections
        .map((section) => section.questions)
        .reduce((acc, curr) => {
          acc = [...acc, ...curr];
          return acc;
        }, [])
        .map((question, index) => {
          let row = {
            Topic: `${index + 1}. ${question?.question?.questText}`,
            Score: '-',
            Response:
              question?.answerStatus === 'ANSWERED' ? (
                <>
                  {/* {question.answer.yesNo ? (
                    <div className="sub-title company-res mt-3 text-center">
                      <h5 className="text-success">Yes</h5>
                    </div>
                  ) : (
                    <div className="sub-title mt-3 company-res text-center">
                      <h5 className="text-danger">No</h5>
                    </div>
                  )} */}
                  {/* {question.answer.text && (
                    <div className="border mt-3 text-center">
                      <span style={{ lineHeight: '1.5' }} className="">
                        {question.answer.text}
                      </span>
                    </div>
                  )} */}
                  {question?.questType?.yesNo && (
                    <div className="border mt-3 text-center">
                      <span style={{ lineHeight: '1.5' }} className="">
                        {typeof question.answer?.value === 'boolean'
                          ? question.answer?.value === true
                            ? 'Yes'
                            : 'No'
                          : question.answer?.value}
                      </span>
                    </div>
                  )}
                  {question?.questType.answerType && (
                    <div className="border mt-3 text-center">
                      <span style={{ lineHeight: '1.5' }} className="">
                        {
                          <AnswerTypesValues
                            answer={question.answer.value}
                            type={question?.questType?.answerType}
                          />
                        }
                      </span>
                    </div>
                  )}

                  {question.answer.text && (
                    <div className="border mt-3 text-center">
                      <span style={{ lineHeight: '1.5' }} className="">
                        {question.answer.text}
                      </span>
                    </div>
                  )}
                </>
              ) : (
                '-'
              ),
            Remarks: (
              <div
                style={{
                  display: 'flex'
                }}
              >
                <Button
                  variant=""
                  className="chat-btn"
                  onClick={handleShow(question)}
                >
                  <ChatIcon />
                </Button>
                {Array.isArray(question?.answer?.attachment) &&
                question?.answer?.attachment.length > 0 ? (
                  <>
                    <Button
                      style={{ marginLeft: '5px' }}
                      variant=""
                      className="attach-btn"
                      onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setFiles(question?.answer?.attachment);
                      }}
                    >
                      <label className="file">
                        <ListIcon />
                      </label>
                    </Button>

                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      {files.length == 0 && (
                        <MenuItem>
                          <p>No files available</p>
                        </MenuItem>
                      )}
                      {files.map((item, index) => {
                        return (
                          <MenuItem key={index} onClick={handleClose}>
                            <p className="file-name mx-2 my-2">
                              <a href={item?.fileLink} target="blank">
                                {item?.fileName}
                              </a>
                            </p>
                          </MenuItem>
                        );
                      })}
                    </Menu>
                  </>
                ) : null}
              </div>
            )
          };

          return row;
        });
    }
    return assessment.sections
      .filter(
        (section) => section?.category && section.category._id === category
      )
      .map((section) => section.questions)
      .reduce((acc, curr) => {
        acc = [...acc, ...curr];
        return acc;
      }, [])
      .map((question, index) => {
        let row = {
          Topic: `${index + 1}. ${question.question.questText}`,
          Score: '-',
          Response:
            question?.answerStatus === 'ANSWERED' ? (
              <>
                {question.questType.yesNo &&
                  (question.answer.yesNo ? (
                    <div className="sub-title company-res mt-3 text-center">
                      <h5 className="text-success">Yes</h5>
                    </div>
                  ) : (
                    <div className="sub-title mt-3 company-res text-center">
                      <h5 className="text-danger">No</h5>
                    </div>
                  ))}
                {question?.questType.answerType && (
                  <div className="border mt-3 text-center">
                    <span style={{ lineHeight: '1.5' }} className="">
                      {
                        <AnswerTypesValues
                          answer={question.answer.value}
                          type={question?.questType?.answerType}
                        />
                      }
                    </span>
                  </div>
                )}
                {question.answer.text && (
                  <div className="border mt-3 text-center">
                    <span style={{ lineHeight: '1.5' }} className="">
                      {question.answer.text}
                    </span>
                  </div>
                )}
              </>
            ) : (
              '-'
            ),
          Remarks: (
            <div
              style={{
                display: 'flex'
              }}
            >
              <Button
                variant=""
                className="chat-btn"
                onClick={handleShow(question)}
              >
                <ChatIcon />
              </Button>
              {Array.isArray(question?.answer?.attachment) &&
              question?.answer?.attachment.length > 0 ? (
                <>
                  <Button
                    style={{ marginLeft: '5px' }}
                    variant=""
                    className="attach-btn"
                    onClick={(event) => {
                      setAnchorEl(event.currentTarget);
                      setFiles(question?.answer?.attachment);
                    }}
                  >
                    <label className="file">
                      <ListIcon />
                    </label>
                  </Button>

                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {files.length == 0 && (
                      <MenuItem>
                        <p>No files available</p>
                      </MenuItem>
                    )}
                    {files.map((item, index) => {
                      return (
                        <MenuItem key={index} onClick={handleClose}>
                          <p className="file-name mx-2 my-2">
                            <a href={item?.fileLink} target="blank">
                              {item?.fileName}
                            </a>
                          </p>
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </>
              ) : null}
            </div>
          )
        };

        return row;
      });
  };

  const renderFactors = (assessment, type) => {
    let section = assessment.sections.find(
      (section) => section.category._id === type
    );

    let string = [];

    if (section.factors.environment) {
      string.push('Environment');
    }

    if (section.factors.governance) {
      string.push('Governance');
    }

    if (section.factors.social) {
      string.push('Social');
    }

    if (section.factors.environment) {
      string.push('Others');
    }

    return string.join(' ,');
  };

  useEffect(() => {
    console.log('assessmentId', assessmentId);
    fetchAssessmentResponseById();
    return () => {};
  }, [assessmentId]);

  const handleChange = (event) => {
    console.log(event.target.value);
    setType(event.target.value);

    section.map((sec) => {
      if (sec.category._id == event.target.value) {
        setSelectedCategory(sec._id);
      }
    });
  };
  return (
    <>
      <Helmet>
        <title>Assessment | Orennow</title>
      </Helmet>
      {loading && <OverlaySpinner />}
      {assessment && (
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          <Container
            maxWidth={false}
            style={{ lineHeight: 'normal !important' }}
          >
            <div className="row">
              <div className="col-md-6">
                <h2>
                  <ul class="breadcrumb">
                    <li>
                      <Link to="/sapphire/Assessment">Assessment</Link>
                    </li>
                    <li>
                      <Link to="/sapphire/Cap_AssesmentView">
                        Assessment Detail
                      </Link>
                    </li>
                  </ul>
                </h2>
              </div>
              <div className="col-md-6">
                {type != 'All' ? (
                  <Link to={`Categories/${selectedCategory}`}>
                    {assessment.status == 'PENDING' ? (
                      <Button
                        style={{ float: 'right' }}
                        variant=""
                        className="modal-submit-btn"
                        disabled
                      >
                        Edit
                      </Button>
                    ) : (
                      <Button
                        style={{ float: 'right' }}
                        variant=""
                        className="modal-submit-btn"
                      >
                        Edit
                      </Button>
                    )}
                  </Link>
                ) : null}
              </div>
            </div>
            <div className="row" container spacing={2}>
              <div className="col-md-5">
                <Grid item lg={12} md={12} xl={12} xs={12}>
                  <CapDetails
                    assessmentScore={assessmentScore}
                    assessment={assessment}
                    capPercent={capPercent}
                  />
                </Grid>
                <Grid className="grid2" item lg={12} md={12} xl={12} xs={12}>
                  <Documents timeline={assessment.timeline ?? []} />
                </Grid>
              </div>
              <div className="col-md-7">
                <Card sx={{ height: '100%' }}>
                  <br />
                  <div className="row">
                    <div className="col-md-3 ">
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        className="select"
                      >
                        <Select
                          value={type}
                          onChange={handleChange}
                          displayEmpty
                          className={classes.selectEmpty}
                          inputProps={{ 'aria-label': 'Without label' }}
                          style={{ marginTop: 5, marginLeft: 10 }}
                        >
                          <MenuItem value="All">All Categories</MenuItem>
                          {assessment.sections.map((section) =>
                            section.category ? (
                              <MenuItem value={section.category._id}>
                                {section.category.name}
                              </MenuItem>
                            ) : null
                          )}
                          {/* <MenuItem value={'Corona'}>Corona</MenuItem>
                          <MenuItem value={'Delta'}>Delta</MenuItem> */}
                        </Select>
                        {/* <FormHelperText>Without label</FormHelperText> */}
                      </FormControl>
                    </div>
                    <div className="col-md-7 ">
                      <h5>
                        {type !== 'All'
                          ? renderFactors(assessment, type)
                          : null}
                      </h5>
                    </div>
                    {userData?.canExportAssessmentData && (
                      <div className="col-md-2 pb-3 mr-3">
                        <Button
                          style={{
                            backgroundColor: '#223059'
                          }}
                          onClick={() => {
                            writeDataToExcel(
                              assessment?.assessment?.name,
                              assessment.sections.map((section) => {
                                let sheet = {
                                  sheetName: section.category.name.slice(0, 31),
                                  data: []
                                };

                                sheet.data = section.questions.map((quest) => {
                                  let cell = {};
                                  cell.Topic = quest.question.questText;

                                  if (quest.questType.yesNo) {
                                    cell['Yes/No Response'] = quest.answer.yesNo
                                      ? 'YES'
                                      : 'NO';
                                  }
                                  if (quest.questType.text) {
                                    cell['Text Response'] = quest.answer.text;
                                  }
                                  if (quest.questType.attachment) {
                                    quest.answer.attachment.forEach(
                                      (atch, index) => {
                                        cell['Attachment-' + (index + 1)] = {
                                          t: 's',
                                          v: atch.fileName,
                                          l: {
                                            Target: atch.fileLink,
                                            Tooltip: 'Click to view file'
                                          }
                                        };
                                      }
                                    );
                                  }

                                  return cell;
                                });
                                return sheet;
                              })
                            );
                          }}
                        >
                          Export To Excel
                        </Button>
                      </div>
                    )}
                  </div>

                  <Divider />

                  <Grid
                    item
                    lg={12}
                    md={12}
                    xl={12}
                    xs={12}
                    className="text-center"
                  >
                    <Assessments rows={renderTableRows(assessment, type)} />
                  </Grid>
                </Card>
              </div>
              <AssessmentChat
                sendFile={sendFile}
                setSendFile={setSendFile}
                show={show}
                handleClose={handleClose1}
                question={question}
                response={assessment}
                uid={userData.companyId}
                type={userData.type}
                name={userData.name}
              />
            </div>
          </Container>
        </Box>
      )}
    </>
  );
};

export default Cap_assesmentview;

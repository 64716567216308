import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import CustomerListToolbar from 'src/components/customer/searchbox';
// import Logo from 'src/images/oren-logo.png';
import '../style.css';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import 'bootstrap/dist/css/bootstrap.min.css';
import { logOut } from 'src/store/auth/thunk';
import { useDispatch, useSelector } from 'react-redux';
import * as Constants from '../Constants';
import { setStartDate, setEndDate } from '../store/header/slice';
import {
  createTheme,
  withStyles,
  ThemeProvider
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
// import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import { useMatch } from 'react-router-dom';

const logo = {
  avatarUrl: '/static/images/oren-logo.png'
};
const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  jobTitle: '',
  name: 'Katarina Smith'
};
const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""'
    }
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0
    }
  }
}))(Badge);

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: '1.5rem'
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    width: 200
  }
}));
// const theme = createTheme({
//   palette: {
//     primary: '#F96E6E',
//   },
// });

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const matchsapphire = useMatch('sapphire/observation');
  const matchContractor = useMatch('atgl/Contractor/Observation');
  const dateSelector = matchsapphire || matchContractor;

  const { userData } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [notifications] = useState([]);
  const dispatch = useDispatch();
  const { startDate, endDate } = useSelector((state) => state.header);

  const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText('#F96E6E'),
      backgroundColor: '#F96E6E',
      '&:hover': {
        backgroundColor: '#F96E6E'
      }
    }
  }))(Button);

  return (
    <AppBar elevation={0} {...rest}>
      <Toolbar className={'d-flex w-100'}>
        <div className="d-flex">
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
          <RouterLink to={Constants.getUrl(userData?.type)}>
            {/* <Logo />OREN LOGO */}
            <img alt="" src={logo.avatarUrl} id="logo" />
          </RouterLink>
        </div>

        {/* <Box sx={{ flexGrow: 1 }} /> */}
        <div className="d-flex w-100 justify-content-end align-items-center">
          {dateSelector && (
            <>
              <form className={classes.container} noValidate>
                <label className="date-label">Start Date &nbsp;</label>
                <TextField
                  id="date"
                  type="date"
                  defaultValue={startDate}
                  //className={classes.textField}
                  onChange={(e) => dispatch(setStartDate(e.target.value))}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </form>
              <form className={classes.container} noValidate>
                <label className="date-label">End Date &nbsp;</label>
                <TextField
                  id="date"
                  type="date"
                  defaultValue={endDate}
                  onChange={(e) => dispatch(setEndDate(e.target.value))}
                  style={{ padding: ' 5.5px 0px !important' }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </form>
            </>
          )}
          {/* <CustomerListToolbar /> */}
          <div className={'d-flex align-items-center'}>
            {/* <IconButton color="inherit">
              <Badge
                badgeContent={notifications.length}
                color="primary"
                variant="dot"
              >
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            <div className={classes.root} style={{ marginRight: '2rem' }}>
              <StyledBadge
                onClick={() => navigate('/profile')}
                overlap="circular"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                variant="dot"
              >
                <Avatar
                  alt="Remy Sharp"
                  src={userData?.profileImage?.fileLink ?? null}
                  imgProps={{
                    style: {
                      objectFit: 'contain'
                    }
                  }}
                />
              </StyledBadge>
            </div>
            <ColorButton
              onClick={() =>
                dispatch(logOut(() => navigate('/', { replace: true })))
              }
              variant="contained"
              id="logout-btn"
              color="primary"
              className={classes.margin}
            >
              Log out
            </ColorButton>
            {/* <IconButton onClick={() => dispatch(logOut(() => navigate("/",{replace : true})))} color="inherit">
            <InputIcon />
          </IconButton> */}
          </div>
        </div>
        {/* <Hidden lgUp> */}

        {/* </Hidden> */}
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;

import React, { useState } from 'react';
import { MDBDataTableV5 } from 'mdbreact';
import '../../css/dashboard/assessments.css';
import { useSelector } from 'react-redux';
import Api from 'src/Api';
import swal from 'sweetalert';
import { useEffect } from 'react';
import moment from 'moment';
import SpinnerContainer from '../common/SpinnerContainer';

export default function Basic() {
  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: 'Due Date',
        field: 'date',
        sort: 'disabled',
        width: 150
      },
      {
        label: 'Task',
        field: 'Task',
        width: 270
      }
    ],
    rows: []
  });

  const [loading, setLoading] = useState(true);

  const { userData } = useSelector((state) => state.auth);

  const fetchCapDetails = async () => {
    try {
      setLoading(true);
      let res = await Promise.all([
        Api.getAssessmentsByContractorId({
          contractorId: userData?.user,
          include: {
            sections_category: false,
            sections_questions_question: false
          }
        }),
        Api.getResponsesByContractorId({
          contractorId: userData?.user,
          include: {
            assessment: false,
            sections_category: false,
            sections_questions_question: false
          }
        })
      ]);

      if (res[0].data.code >= 400) {
        throw new Error(res[0].data.message);
      }

      if (res[1].data.code >= 400) {
        throw new Error(res[1].data.message);
      }

      setDatatable({
        ...datatable,
        rows: res[0].data.data
          .map((assess) => {
            let dueDate = assess.dueDate;
            let response = res[1].data.data.find(
              (resp) => resp.assessment === assess._id
            );

            if (response) {
              if (
                response.isDueDateExtended &&
                moment(response.newDueDate).isSameOrAfter(dueDate)
              )
                dueDate = response.newDueDate;
            }

            return {
              Task: assess.name,
              date: moment(dueDate).format('DD/MM/YYYY')
            };
          })
          .sort((a, b) =>
            moment(a.date, 'DD/MM/YYYY').diff(moment(b.date, 'DD/MM/YYYY'))
          )
      });
    } catch (error) {
      swal('Error', error.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    userData?.user && fetchCapDetails();
    return () => {};
  }, [userData]);

  if (loading) {
    return (
      <div className="w-100 h-100 p-5">
        <SpinnerContainer />
      </div>
    );
  }

  return (
    <MDBDataTableV5
      hover
      entries={10}
      pagesAmount={4}
      data={datatable}
      pagingBottom
      searchTop
      searchBottom={false}
    />
  );
}

import { MDBDataTableV5 } from 'mdbreact';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FileDisplay from 'src/components/common/FileDisplay';
import { getFeedback } from 'src/store/feedback/thunk';
import moment from 'moment';
import OverlaySpinner from 'src/components/common/OverlaySpinner';

export default function Basic() {
  const dispatch = useDispatch();
  const { allFeedbacks, loading } = useSelector((state) => state.feedback);
  const {userData} = useSelector(state => state.auth)
  
  useEffect(() => {
    userData?.companyId && dispatch(getFeedback(userData?.companyId));
  }, [userData?.companyId]);
  return (
    <>
      {loading ? (
        <OverlaySpinner />
      ) : (
        <MDBDataTableV5
          searchTop={false}
          searchBottom={false}
          className="text-center"
          hover
          entriesOptions={[5, 10, 20]}
          entries={5}
          pagesAmount={6}
          data={{
            columns: [
              {
                label: 'Sr.No',
                field: 'ref',
                width: 10,
                attributes: {
                  'aria-controls': 'DataTable',
                  'aria-label': 'id'
                }
              },
              {
                label: 'Date',
                field: 'Date',
                width: 12
              },
              {
                label: 'Company',
                field: 'Company',
                width: 100
              },
              {
                label: 'Description',
                field: 'Description',
                sort: 'asc',
                width: 10
              },
              {
                label: 'Attachments',
                field: 'Attachments',
                sort: 'disabled',
                width: 10
              }
            ],
            rows: allFeedbacks
              ? allFeedbacks.map((feedback, index) => {
                  return {
                    ref: index + 1,
                    Date: moment(feedback.created_at).format('DD/MM/YYYY'),
                    Company: feedback.cmp_name,
                    Description: feedback.feedbackText,
                    Attachments: (
                      <>
                        {/* <button
                          type="button"
                          className="btn btn-edit"
                          style={{ float: 'center' }}
                        >
                          Download Attachment
                        </button> */}
                        {feedback.attachments.length >= 1 ? feedback.attachments.map((doc) => {
                          console.log("doc" , doc)
                          return (
                            <>
                              <div className="d-flex flex-row justify-content-center align-items-center">
                                <a href={doc.value.fileLink} target="_blank">{doc.value.fileName}</a>
                                {/* <FileDisplay
                                  key={index.toString()}
                                  mode="view"
                                  file={doc}
                                /> */}
                              </div>
                            </>
                          );
                        }) : "No Attachment Added"}
                      </>
                    )

                    // <React.Fragment>
                    //   <button type="button" className="btn btn-edit" style={{float: "center"}}>
                    //     Download Attachment
                    //   </button>
                    //   <p style={{float: "center"}}>(New Covid policy.pdf)</p>
                    // </React.Fragment>
                  };
                })
              : null
          }}
        />
      )}
    </>
  );
}

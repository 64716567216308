import {
  Box,
  Card,
  CardContent,
  colors,
  Divider,
  // Select,
  useTheme
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import Api from 'src/Api';
import CustomSpinner from 'src/components/common/CustomSpinner';
import 'src/css/ATGL/dashboard.css';
import swal from 'sweetalert';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const TrafficByDevice = (props) => {
  const theme = useTheme();

  const [allContractors, setAllContractors] = useState([]);
  const { userData } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const [graphData, setGraphData] = useState({});
  const [filter, setFilter] = useState('ALL');
  const [loading, setLoading] = useState(false);

  const fetchGraphData = async (id) => {
    try {
      let res;
      if (!!graphData?.[id]) return;
      setLoading(true);
      if (id === 'ALL') {
        res = (
          await Api.getESGOForAllContractors({
            companyId: userData.companyId
          })
        )?.data;
      } else {
        res = (
          await Api.getESGOForContractor({
            contractorId: id
          })
        )?.data;
      }
      setGraphData({
        ...graphData,
        [id]: [
          res.data.environment.scored,
          res.data.social.scored,
          res.data.governance.scored,
          res.data.others.scored
        ]
      });
    } catch (error) {
      swal('Error', 'Failed to fetch ESGO data', 'error');
    } finally {
      setLoading(false);
    }
  };

  const init = async () => {
    let contractors = await Api.getAllContractorsByID({
      companyId: userData?.companyId
    });
    setAllContractors(contractors.data.data);
  };

  useEffect(() => {
    userData?.companyId && init();
    return () => {};
  }, [userData]);

  useEffect(() => {
    userData?.companyId && fetchGraphData(filter);
    return () => {};
  }, [filter]);

  const options = {
    animation: false,
    cutoutPercentage: 70,
    layout: {
      padding: 0
    },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: true,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const classes = useStyles();
  const [Contractor, setContractor] = React.useState('');

  const handleChange = (event) => {
    setFilter(event.target.value);
  };
  return (
    <Card {...props}>
      <CardContent>
        <div className="row">
          <div className="col-md-9">
            <h2>E-S-G-O Performance</h2>
          </div>
          <div className="col-md-3">
            <div className="">
              <FormControl
                variant="outlined"
                className={classes.formControl}
                className="select"
              >
                <Select
                  value={filter}
                  onChange={handleChange}
                  displayEmpty
                  className={classes.selectEmpty}
                  inputProps={{ 'aria-label': 'Without label' }}
                  autoWidth
                >
                  <MenuItem value="ALL">
                    <span>All Companies</span>
                  </MenuItem>
                  {allContractors.map((contra) => (
                    <MenuItem value={contra.user}>
                      {contra.name}
                    </MenuItem>
                  ))}
                </Select>
                {/* <FormHelperText>Without label</FormHelperText> */}
              </FormControl>
            </div>
          </div>
        </div>
        <Divider />

        <Box
          sx={{
            height: 200,
            position: 'relative',
            top: 20
          }}
        >
          {loading && (
            <div className="d-flex w-100 h-100 justify-content-center align-items-center">
              <CustomSpinner />
            </div>
          )}

          {graphData[filter] && graphData[filter].every((val) => val === 0) && (
            <div className="d-flex w-100 h-100 justify-content-center align-items-center">
              No data available
            </div>
          )}

          {graphData[filter] && graphData[filter].length > 0 && (
            <Doughnut
              redraw={!!filter}
              data={{
                datasets: [
                  {
                    data: graphData[filter] ?? [],
                    backgroundColor: [
                      '#9BDD9B',
                      '#F4CC69',
                      '#F99564',
                      '#5D5D5D'
                    ],
                    borderWidth: 5,
                    borderColor: colors.common.white,
                    hoverBorderColor: colors.common.white
                  }
                ],
                labels: ['Environment', 'Social', 'Governance', 'Others']
              }}
              options={options}
            />
          )}
        </Box>
        <Box
          sx={{
            justifyContent: 'center',
            pt: 5,
            pl: 20
          }}
        >
          <div className="row ">
            <div className="col-md-6 dots">
              <li className="dot-green">Environment</li>
            </div>
            <div className="col-md-6 dots">
              <li className="dot-orange">Social</li>
            </div>
            <div className="col-md-6 dots">
              <li className="dot-red">Governance</li>
            </div>
            <div className="col-md-6 dots">
              <li className="dot-grey">Others</li>
            </div>
          </div>
        </Box>
      </CardContent>
    </Card>
  );
};

export default TrafficByDevice;

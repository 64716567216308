import axios from 'axios';

export default class Api {
  // ======================= COMPANY =================================

  static createCompany(data) {
    return axios.post('company/createCompany', data);
  }

  static fetchCompanyById(data) {
    return axios.post('company/getCompanyById', data);
  }

  static getAllCompanies(data) {
    return axios.post('company/getAllCompanies', data);
  }

  static editCompany(data) {
    return axios.post('company/editCompany', data);
  }

  static deleteCompany(data) {
    return axios.post('company/deleteCompany', data);
  }

  static companyCheckCodeExists(data) {
    return axios.post('company/checkCodeExists', data);
  }

  static companyCheckEmailExists(data) {
    return axios.post('company/checkEmailExists', data);
  }

  static companyCheckNameExists(data) {
    return axios.post('company/checkNameExists', data);
  }

  static companyCheckPhoneNumberExists(data) {
    return axios.post('company/checkPhoneNumberExists', data);
  }

  // ======================= CONTRACTOR =================================

  static createContractor(data) {
    return axios.post('contractor/createContractor', data);
  }
  static createBulkContractors(data) {
    return axios.post('contractor/createBulkContractors', data);
  }

  static deleteContractor(data) {
    return axios.post('contractor/deleteContractor', data);
  }

  static statusContractori(data) {
    return axios.post('contractor/changeContractorAccStatus', data);
  }

  static fetchContractorById(data) {
    return axios.post('contractor/getContractorById', data);
  }

  static changeContractorAccStatus(data) {
    return axios.post('contractor/changeContractorAccStatus', data);
  }

  static getAllContractors() {
    return axios.get('contractor/getAllContractors');
  }

  static getAllContractorsByID(id) {
    return axios.post('contractor/getContractorsByCompanyId', id);
  }

  //   static getAllContractorsByCmpID() {
  //     return axios.get("contractor/getAllContractors")
  //   }

  static contractorCheckNameExists(data) {
    return axios.post('contractor/checkNameExists', data);
  }

  static editContractor(data) {
    return axios.post('contractor/editContractor', data);
  }

  static contractorCheckEmailExists(data) {
    return axios.post('contractor/checkEmailExists', data);
  }

  static contractorCheckCodeExists(data) {
    return axios.post('contractor/checkCodeExists', data);
  }

  static contractorCheckPhoneNumberExists(data) {
    return axios.post('contractor/checkPhoneNumberExists', data);
  }

  // ======================= TPI =================================

  static createTpi(data) {
    return axios.post('tpi/createTpi', data);
  }

  static editTpi(data) {
    return axios.post('tpi/editTpi', data);
  }

  static statusTpi(data) {
    return axios.post('tpi/changeTpiAccStatus', data);
  }

  static fetchTpiById(data) {
    return axios.post('tpi/getTpiById', data);
  }

  static deleteTpi(data) {
    return axios.post('tpi/deleteTpi', data);
  }

  static getAllTpis() {
    return axios.get('tpi/getAllTpis');
  }

  //checking
  static tpiCheckPhoneNumberExists(data) {
    return axios.post('/tpi/checkPhoneNumberExists', data);
  }

  static getTpisByCmpID(id) {
    return axios.post('tpi/getTpisByCompanyId', id);
  }

  static tpiCheckCodeExists(data) {
    return axios.post('/tpi/checkCodeExists', data);
  }

  static tpiCheckEmailExists(data) {
    return axios.post('/tpi/checkEmailExists', data);
  }

  static tpiCheckNameExists(data) {
    return axios.post('/tpi/checkNameExists', data);
  }

  // ======================= FEEDBACK =================================

  static deleteFeedback(data) {
    return axios.post('/feedback/deleteFeedback', data);
  }

  static getAllFeedback() {
    return axios.get('/feedback/getAllFeedbacks');
  }

  static getFeedbacksByCompanyId(data) {
    return axios.post('/feedback/getFeedbacksByCompanyId', data);
  }

  /*----------  Assessment Responses  ----------*/

  static getAssessmentsByCompanyIds(data) {
    return axios.post('/assessment/getAssessmentsByCompanyIds', data);
  }

  static getAssessmentById(body) {
    return axios.post('/assessment/getAssessmentById', body);
  }

  static getResponsesByCompanyIds(data) {
    return axios.post('assessment/responses/getResponsesByCompanyIds', data);
  }

  static getResponseById(data) {
    return axios.post('assessment/responses/getResponseById', data);
  }

  static getResponsesByAssessmentId(data) {
    return axios.post('assessment/responses/getResponsesByAssessmentId', data);
  }

  static editResponse(data) {
    return axios.post('assessment/responses/editResponse', data);
  }

  static changeResponseStatus(data) {
    return axios.post('assessment/responses/changeResponseStatus', data);
  }

  static getResponsesByContractorId(data) {
    return axios.post('assessment/responses/getResponsesByContractorId', data);
  }

  static createResponseForContractor(data) {
    return axios.post('assessment/responses/createResponseForContractor', data);
  }

  static updateSectionInResponse(data) {
    return axios.post('assessment/responses/updateSectionInResponse', data);
  }

  static getTotalScoreForContractor(data) {
    return axios.post('assessment/responses/getTotalScoreForContractor', data);
  }

  static getTotalScoresOfContrsInCompany(data) {
    return axios.post(
      'assessment/responses/getTotalScoresOfContrsInCompany',
      data
    );
  }

  // ======================= ASSESSMENT =================================

  static createAssessment(data) {
    return axios.post('/assessment/createAssessment', data);
  }

  static editAssessment(data) {
    return axios.post('/assessment/editAssessment', data);
  }

  static deleteAssessment(data) {
    return axios.post('/assessment/deleteAssessment', data);
  }

  static getAllAssessment(data) {
    return axios.post('/assessment/getAllAssessments', data);
  }

  static getAllAssessmentByCmpID(data) {
    return axios.post('/assessment/getAssessmentsByCompanyIds', data);
  }

  static getAssessmentsByContractorId(data) {
    return axios.post('/assessment/getAssessmentsByContractorId', data);
  }

  // ================== QUESTIONS ============================

  static createQuestion(data) {
    return axios.post('/assessment/questions/createQuestion', data);
  }

  static editQuestion(data) {
    return axios.post('/assessment/questions/editQuestion', data);
  }

  static createBulkQuestions(data) {
    return axios.post('/assessment/questions/createBulkQuestions', data);
  }

  static getQuestionByCmpId(data) {
    return axios.post('/assessment/questions/getQuestionsByCompanyId', data);
  }

  // ================== CATEGORIES ============================

  static createAssessCategory(data) {
    return axios.post('/assessment/categories/createAssessCategory', data);
  }

  static editAssessCategory(data) {
    return axios.post('/assessment/categories/editAssessCategoryById', data);
  }

  static getAssessmentCategories(data) {
    return axios.post(
      '/assessment/categories/getAssessCategoriesByCompanyId',
      data
    );
  }

  // ================== NOTICCE ============================

  static getAllNotices(viewers) {
    return axios.get(`/notice/getAllNotices?viewers=${viewers}`);
  }

  static getAllNoticesByCompanyId(data, viewers) {
    return axios.post(`/notice/getNoticesByCompanyId?viewers=${viewers}`, data);
  }

  static createNotice(data) {
    return axios.post('/notice/createNotice', data);
  }

  static recordNoticeView(data) {
    return axios.post('/notice/recordNoticeView', data);
  }
  static createFeedback(data) {
    return axios.post('/feedback/createFeedback', data);
  }

  // ================== QUESTIONS ============================

  // ======================= OBSERVATION =================================

  static getObservationReportTable(data) {
    return axios.post('/company/reporttable', data);
  }

  static getObservationReportGraphCompany(data) {
    return axios.post('/company/reportgraph', data);
  }

  static getObservationReportGraphContractor(data) {
    return axios.post('/contractor/reportgraph', data);
  }

  static getViewObservationRCAStatus(data) {
    return axios.post('/admin/rcastatus', data);
  }

  // ================== LEARNING ============================

  static uploadLearning(data) {
    return axios.post('/company/uploadLearning', data);
  }

  static fetchLearningTable(cmp_id, type, contractor_id) {
    return axios.get(
      `/${type}/fetchLearningTable?cmp_id=${cmp_id}&contractor_id=${contractor_id}`
    );
  }

  static fetchLearningGraph(cmp_id, year) {
    return axios.get(
      `/company/fetchLearningGraph?cmp_id=${cmp_id}&year=${year}`
    );
  }

  static getLearningTopicDocs(cmp_id, topic) {
    return axios.post('/company/getLearningTopicDocs', {
      companyId: cmp_id,
      topic
    });
  }
  // ================== ENGAGEMENT ============================

  static fetchLeaderBoard(cmp_id) {
    return axios.get(`/admin/leaderboard?cmp_id=${cmp_id}`);
  }

  static fetchCapDetails(data) {
    return axios.post(
      '/assessment/responses/getTotalScoresOfContrsInCompany',
      data
    );
  }

  // ======================= DOCUMENTS =================================

  static createDocuments(data) {
    return axios.post('/document/createDocument', data);
  }

  static editDocuments(data) {
    return axios.post('/document/editDocument', data);
  }

  static deleteDocuments(data) {
    return axios.post('/document/deleteDocument', data);
  }

  static getAllDocuments(data) {
    return axios.post('/document/getAllDocuments', data);
  }

  static getAllDocumentsByCmpID(data, config = {}) {
    if (Object.values(config).length > 0)
      return axios.post('/document/getDocumentsByCompanyId', data, config);
    return axios.post('/document/getDocumentsByCompanyId', data);
  }

  static recordDocView(data) {
    return axios.post('/document/recordDocView', data);
  }
  /*----------  Company dashboard Graphs  ----------*/

  static getESGOForAllContractors(data) {
    return axios.post('/assessment/responses/getESGOForAllContractors', data);
  }

  static getESGOForContractor(data) {
    return axios.post('/assessment/responses/getESGOForContractor', data);
  }

  /*----------  Auth management apis  ----------*/

  static forgotPassword(data) {
    return axios.post('/auth/forgotPassword', data);
  }

  static changePasswordWithEmail(data) {
    return axios.post('/auth/changePasswordWithEmail', data);
  }

  /*----------  sapphire graphs  ----------*/
  static getCapPerformanceData(data) {
    return axios.post('/assessment/responses/getCapPerformanceData', data);
  }

  static getEmployeeByIds(employees) {
    return axios.post('/app/getUsersByIds', {
      userIds: employees
    });
  }
}

import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'reactstrap';
import swal from 'sweetalert';
import FileDisplay from './FileDisplay';
import PublishIcon from '@material-ui/icons/Publish';

const ProfileUpload = ({
  handleFileChanges,
  acceptExt = '*',
  defaultFile,
  handleUploadedFileDelete,
  disable,
  disableIcon
}) => {
  const fileInputRef = useRef();
  const [file, setFile] = useState(null);

  useEffect(() => {
    handleFileChanges(file);
    fileInputRef.current.value = '';
    return () => {};
  }, [file]);

  const handleFileSelectionChanges = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (acceptExt !== '*') {
        let extensions = acceptExt
          .split(',')
          .map((ext) => ext.trim().substring(1));
        let fileNameSplit = e.target.files[0].name.split('.');

        if (!extensions.includes(fileNameSplit[fileNameSplit.length - 1].toLowerCase())) {
          fileInputRef.current.value = '';

          return swal({
            title: 'Error',
            text: 'File type not supported',
            icon: 'error',
            button: 'OK'
          });
        }
      }

      setFile(e.target.files[0]);
    }
    // fileInputRef.current.value = ""
  };

  const handleFileDelete = () => {
    setFile(null);
  };

  return (
    <div>
      {!!!file && !!!defaultFile && (
        <Button
        className="view-btn"
        disabled={disable}
        onClick={() => fileInputRef.current.click()}
      >
        <div className="d-flex flex-row justify-content-center align-item-center">
          <div><PublishIcon/></div>
          <div>Upload Image</div>
        </div>
        
        
      </Button>
      )}
      <input
        disabled={disable}
        type="file"
        multiple
        style={{ display: 'none', height: 0 }}
        ref={fileInputRef}
        onChange={handleFileSelectionChanges}
        accept={acceptExt}
      />
      <div
        style={{
          margin: '.1rem 0 0 0',
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap'
        }}
      >
        {defaultFile ? (
          <FileDisplay
            file={defaultFile}
            mode="edit-profile"
            handleDelete={handleUploadedFileDelete}
            disableIcon={disableIcon}
          />
        ) : (
          file && <FileDisplay mode="edit-profile" file={file} handleDelete={handleFileDelete} />
        )}
      </div>
    </div>
  );
};

export default ProfileUpload;
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  allFeedbacks:[],
  loading: false
}

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    setAllFeedbacks: (state,action) => {
      state.allFeedbacks = [...action.payload]
    },
    setLoading(state,action){
      state.loading=action.payload
    },
    setAllDataEmpty(state,action){
      state = initialState
      return state
    }
  },
})

// Action creators are generated for each case reducer function
export const { setAllFeedbacks,setLoading,setAllDataEmpty } = feedbackSlice.actions

export default feedbackSlice.reducer
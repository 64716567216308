import { Helmet } from 'react-helmet';
import { Box, Card, Container, Button } from '@material-ui/core';
import List from 'src/components/ATGL/feedback/List';

const Users = () => {
  return (
    <>
      <Helmet>
        <title>Feedback | Oren</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <div className="row">
            <div className="col-md-6">
              <h2>
                <ul class="breadcrumb">
                  <li>
                    Feedback
                  </li>
                </ul>
              </h2>
            </div>
            
          </div>

          <Card className="col-sm-12">
            <List />
          </Card>

          <Box sx={{ pt: 3 }}></Box>
        </Container>
      </Box>
    </>
  );
};

export default Users;

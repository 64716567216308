import {
  Box,
  Button,
  Card,
  CardHeader,
  // Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
  // TableSortLabel,
  // Tooltip
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Close from '@material-ui/icons/Close';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal'; // for Modal
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import Api from 'src/Api';
import { downloadFile } from 'src/Constants';
import swal from 'sweetalert';
// import Button from 'react-bootstrap/Button';//for modal
import '../../css/dashboard/dashboard.css';
import '../../css/observations/observation.css';
import SpinnerContainer from '../common/SpinnerContainer';

const Documents = (props) => {
  const [show2, setShow] = useState(false);

  const [documents, setDocuments] = useState([]);
  const [currDoc, setCurrDoc] = useState({});
  const [views, setViews] = useState({});
  const [isAll, setIsAll] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (doc) => async () => {
    let hasViewed = false;
    // check if viewed in local state obj
    if (views?.[doc._id]) hasViewed = true;

    console.log(doc.viewers);

    // check if viewed in docs viewers obj
    if (!hasViewed) {
      hasViewed = doc.viewers.some(
        (user) => user.viewer.uid === userData?.user
      );
    }

    setCurrDoc(doc);
    setShow(true);

    // if not viewed call api and set local view obj true
    if (!hasViewed) {
      try {
        await Api.recordDocView({
          documentId: doc._id,
          viewer: {
            type: userData?.type,
            name: userData?.name,
            uid: userData?.user
          }
        });
        setViews(
          Object.values(views) !== null
            ? { ...views, [doc._id]: true }
            : { [doc._id]: true }
        );
      } catch (error) {
        swal('Error', error.message, 'error');
        setViews(
          Object.values(views) !== null
            ? { ...views, [doc._id]: false }
            : { [doc._id]: false }
        );
      }
    }
  };
  // <>

  const [loading, setLoading] = useState(true);

  const { userData } = useSelector((state) => state.auth);

  const fetchDocuments = async () => {
    try {
      setLoading(true);
      let res = (
        await Api.getAllDocumentsByCmpID(
          {
            companyId: userData?.cmp_id ?? userData.assoc_companies[0]
          },
          {
            params: { viewers: true }
          }
        )
      ).data;

      if (res.code >= 400) {
        throw new Error(res.message);
      }

      setDocuments(
        res.data.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
      );
    } catch (error) {
      swal('Error', error.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (userData?.user && fetchDocuments()) || (userData.assoc_companies[0] && fetchDocuments())
    return () => {};
  }, [userData]);

  return (
    <>
      <Modal
        size="lg"
        show={show2}
        onHide={handleClose}
        backdrop="static"
        keyboard="false"
        centered
      >
        <Modal.Header>
          <Modal.Title>Document</Modal.Title>
          <label onClick={handleClose} id="feedback-btn">
            <Close />
          </label>
          {/* <Button variant="" style={{ color: 'red'  }}  onClick={handleClose}>X</Button> */}
        </Modal.Header>
        <Modal.Body>
          <div className="text-center ">
            <h5>{currDoc?.title}</h5>
          </div>

          <div className="row">
            <div className="col-md-6">
              {' '}
              <p className="text-left">
                Date: {moment(currDoc?.createdAt).format('DD/MM/YYYY')}
              </p>
            </div>
            <div className="col-md-6">
              <p style={{ float: 'right' }}>
                Time: {moment(currDoc?.createdAt).format('hh:mm A')}
              </p>
            </div>
          </div>

          <p>{currDoc?.description}</p>
          <p>
            {/* Secretary */}
            <br />
            {currDoc?.creatorName}
          </p>
          <Divider />
          <br />
          {(currDoc?.attachments ?? []).map((file) => (
            <div className="mb-2 d-flex align-items-center">
                <Button
                  className="view-btn"
                  color="primary"
                  size="small"
                  variant="text"
                  onClick={()=>{
                    downloadFile(file.fileLink,file.fileName)
                  }}
                >
                  Download
                </Button>
              <p>&nbsp;&nbsp;({file.fileName})</p>
            </div>
          ))}
        </Modal.Body>
      </Modal>
      <Card {...props}>
        <CardHeader title={<h2>Recent Policy Updates</h2>} />
        <Divider />
        <PerfectScrollbar>
          <Box>
            {loading ? (
              <div className="w-100 h-100 p-5">
                <SpinnerContainer />
              </div>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="text-center">Date</TableCell>
                    <TableCell className="text-center">Title</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {documents
                    .slice(0, isAll ? documents.length : 5)
                    .map((doc) => (
                      <TableRow hover key={doc._id}>
                        <TableCell>
                          {moment(doc.createdAt).format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell onClick={handleShow(doc)} className="">
                          <u>{doc.title}</u>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            )}
          </Box>
        </PerfectScrollbar>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          {(documents ?? []).length > 5 && (
            <Button
              className="view-btn"
              color="primary"
              endIcon={<ArrowRightIcon />}
              size="small"
              variant="text"
              onClick={() => setIsAll((old) => !old)}
            >
              {isAll ? 'View Less' : 'View all'}
            </Button>
          )}
        </Box>
      </Card>
    </>
  );
};

export default Documents;

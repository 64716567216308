import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
// import CustomerListResults from 'src/components/customer/CustomerListResults';
// import CustomerListToolbar from 'src/components/customer/CustomerListToolbar';
// import customers from 'src/__mocks__/customers';
import Category from 'src/components/customer/assessment';

const Categories = () => (
  <>
  <Helmet>
    <title>Assessment | Oren</title>
  </Helmet>
  <Box
    sx={{
      backgroundColor: 'background.default',
      minHeight: '100%',
      py: 3
    }}
  >
    <Container maxWidth={false}>

      <h2>


      <ul class="breadcrumb">
        {/* <li><a href="dashboard">Home</a></li> */}
        <li><a href="Assessment">Assessment</a></li>
       
        {/* <li><a href="dashboard">Dashboard</a></li> */}
      </ul>
      </h2>   

      <Category />
      <Box sx={{ pt: 3 }}>
        {/* <CustomerListResults customers={customers} /> */}
      </Box>
    </Container>
  </Box>
  </>
);

export default Categories;

import swal from 'sweetalert';
import * as actions from './actions';
import * as auditorAction from '../auditors/actions';
import { setAllDataEmpty } from '../feedback/slice';
import { resetObservation } from '../observation/slice';
import { resetAllData } from '../assessment/slice';
import { auth } from '../../firebase';

export const loginUser = (email, password) => async (dispatch) => {
  try {
    dispatch(actions.setLoading(true));
    await auth.signInWithEmailAndPassword(email, password);
    // dispatch(actions.setLoading(false))
  } catch (err) {
    console.log(err.code);
    let errorMsg = '';

    switch (err.code) {
      case 'auth/wrong-password':
        errorMsg =
          "Wrong password. Please try again or click 'Forgot password' to reset it.";
        break;
      case 'auth/user-not-found':
        errorMsg = 'No account found with this email address';
        break;
      default:
        errorMsg = err.message;
        break;
    }
    console.log('ERROR>>>', err);
    swal({
      title: 'Error',
      text: errorMsg,
      icon: 'error',
      button: 'Ok'
    });
    dispatch(actions.setLoading(false));
  }
};

export const logOut = (cb) => async (dispatch) => {
  await auth.signOut();
  //console.log("HII LOGOUT")
  dispatch(actions.logoutUser());
  dispatch(auditorAction.setAllDataEmpty());
  dispatch(setAllDataEmpty());
  dispatch(resetObservation());
  dispatch(resetAllData());
  swal('Logged Out Successfully', '', 'success');
  cb();
};

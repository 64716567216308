import { Helmet } from 'react-helmet';
import { Box, Card, Container, Button } from '@material-ui/core';
import Auditor from 'src/components/users/add_auditor';

const Add_Auditor = () => {
  return (
    <>
      <Helmet>
        <title>Users | Oren</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Card className="col-sm-12">
            <Auditor />
          </Card>

          <Box sx={{ pt: 3 }}></Box>
        </Container>
      </Box>
    </>
  );
};

export default Add_Auditor;

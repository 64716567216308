import { Helmet } from 'react-helmet';
import { Box, Container, Button } from '@material-ui/core';
import Assessment from 'src/components/ATGL/assessment/assessment';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const Categories = () => {
  return (
    <>
      <Helmet>
        <title>Assessment | Oren</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <div className="row">
            <div className="col-md-6">
              <h2>
                <ul class="breadcrumb">
                  <li>
                    <Link to="/sapphire/Assessment">Assessment</Link>
                  </li>
                </ul>
              </h2>
            </div>
            <div className="col-md-6">
              <Link to="/sapphire/Add_Assessment">
                <button
                  type="button"
                  className="btn btn-submitted view-btn pull-right"
                  color="primary"
                  size="small"
                  variant="text"
                  ml={5}
                >
                  Add New Assessment
                </button>
              </Link>
            </div>
          </div>
          <Assessment />
          <Box sx={{ pt: 3 }}></Box>
        </Container>
      </Box>
    </>
  );
};

export default Categories;

import { customAlphabet as mCustomAlphabet } from 'nanoid';
import RedCapImage from 'src/images/caps/red-cap.png';
import OrangeCapImage from 'src/images/caps/orange-cap.png';
import GreenCapImage from 'src/images/caps/green-cap.png';
import { storage } from './firebase';
import swal from 'sweetalert';
import axios from 'axios';

export const USER_TYPES = {
  SUPER_ADMIN: '1',
  COMPANY: '2',
  CONTRACTOR: '3',
  TPI: '4',
  EMPLOYEE: '5'
};

export const uploadFilePromise = async (file, id) => {
  let trimmedFileName = file.name.trim().replace(/\s+/g, '_');
  let filePath = `profileImage/${id}/${GenRandomString(5)}-${trimmedFileName}`;
  let fileRef = storage.ref(filePath);
  await fileRef.put(file);
  let fileUrl = await fileRef.getDownloadURL();
  return {
    fileName: trimmedFileName,
    filePath,
    fileLink: fileUrl,
    fileSize: file.size,
    fileType: file.type
  };
};

export const GenRandomString = (len) => {
  const customAlphabet = mCustomAlphabet;
  const alphabet =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const onlyAlphaNumId = customAlphabet(alphabet, len);
  return onlyAlphaNumId();
};

export const roundTwoDecimals = (num) => +(Math.round(num + 'e+2') + 'e-2');

export const getCapData = (percent) => {
  let perc = roundTwoDecimals(percent);

  if (perc >= 70) {
    return {
      avatarUrl: GreenCapImage,
      type: 'Green Score',
      color: 'Green',
      score: perc
    };
  } else if (perc >= 40) {
    return {
      avatarUrl: OrangeCapImage,
      type: 'Orange Score',
      color: 'Orange',
      score: perc
    };
  }
  return {
    avatarUrl: RedCapImage,
    type: 'Red Score',
    color: 'Red',
    score: perc
  };
};

export const getUrl = (type) => {
  switch (type) {
    case USER_TYPES.SUPER_ADMIN:
      break;
    case USER_TYPES.COMPANY:
      return '/sapphire/Dashboard';

    case USER_TYPES.CONTRACTOR:
      return '/atgl/Contractor/dashboard';

    case USER_TYPES.TPI:
      return '/auditor/dashboard';

    case USER_TYPES.EMPLOYEE:
      break;
    default:
      return '/';
  }
};

export const validateFileSize = (file, limit) => {
  let fileSize = file.size / 1024 / 1024;
  if (fileSize > limit) {
    swal({
      title: file.name,
      text: 'Sorry, Max file size allowed is 10MB',
      icon: 'error',
      button: 'Ok'
    });
    return false;
  } else {
    return true;
  }
};

export const ASSESS_STATUS = {
  PENDING: 'PENDING',
  SUBMITTED: 'SUBMITTED',
  COMPLETED: 'COMPLETED',
  REASSESSMENT: 'REASSESSMENT'
};

export const PERMISSIONS = {
  TPI: {
    NOASSESS_STATES: [ASSESS_STATUS.PENDING]
  }
};

export const downloadFile = (filePath, fileName) => {
  axios({
    url: filePath,
    method: 'GET',
    responseType: 'blob' // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  });
};

import PerfectScrollbar from 'react-perfect-scrollbar';

import { Box, Card, CardHeader, Divider } from '@material-ui/core';
// import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import 'bootstrap/dist/css/bootstrap.min.css';
import CapImage from 'src/images/caps/red-cap.png';
const cap = {
  avatarUrl: CapImage,
  type: 'Red Score',
  score: '40',
  color: 'Blue',
  LastUpdated: '31/03/2021',
  status: 'Assessed'
};

const CapDetails = (props) => (
  <Card {...props} id="CapDetails">
    <CardHeader title="Assessment Details" />
    <Divider />
    <PerfectScrollbar>
      <Box>
        <div className="container">
          <br />
          <h5>Assessment: Privacy Policy</h5>
          <h5>Company Name: Josh Co.</h5>
          <div className=" row">
            {/* <div className="col-md-6 score text-center">
              <img src={cap.avatarUrl} alt="cap" id="cap" />
              <h5 className={cap.color}>{cap.type}</h5>
            </div> */}
            <div className="col-md-12">
              <div className="my-auto text-center score">
                <h3 className="total-score">Total Score</h3>
                <h1 className={cap.color}>{cap.score}</h1>
              </div>
            </div>
            <div className=" col-md-6 pull-left">
              <p className="status ">
                Status:
                <k className=" text-success"> {cap.status}</k>
              </p>
            </div>
            <div className=" col-md-6 pull-right">
              <p className="LastUpdated">
                Last Updated:
                {cap.LastUpdated}
              </p>
            </div>
          </div>
        </div>
      </Box>
    </PerfectScrollbar>
  </Card>
);

export default CapDetails;

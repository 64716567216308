import Highcharts from 'highcharts';
export const drillDownGraphData1 = (result, nameOfGraph) => {
  console.log('test result', result);
  // const series = processSeries(result, nameOfGraph);
  // const drillDown = processDrillDown(result, nameOfGraph);
  const series = [
    {
      name: nameOfGraph,
      colorByPoint: true,
      data: result.proficiencySeries
    }
  ];
  // const drillDown = result.drilldown.series;

  return {
    colors: ['#ACDEEF', '#D59AF7', '#F96E6E', '#9fd29a', '#f5cd6a', '#f79666'],
    chart: {
      type: 'column'
    },
    title: {
      text: ''
      // text: 'Employee ' + nameOfGraph
    },
    // subtitle: {
    //     text: 'Click the columns to view details.'
    // },
    accessibility: {
      announceNewData: {
        enabled: true
      }
    },
    // xAxis: {
    //   type: "category",
    // },
    // yAxis: {
    //   title: {
    //     text: "Team completion in %", //'Total ' + nameOfGraph
    //   },
    // },
    lang: {
      drillUpText: 'Back'
    },
    credits: {
      enabled: false
    },
    legend: {
      align: 'right',
      x: 0,
      verticalAlign: 'top',
      y: 0,
      floating: true,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || 'white',
      borderColor: '#CCC',
      borderWidth: 1,
      shadow: false
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true
        }
      }
      // series: {
      //   borderWidth: 0,
      //   dataLabels: {
      //     enabled: true,
      //     format: "{point.y}",
      //   },
      // },
    },

    tooltip: {
      // headerFormat: '<span style="font-size:10px">{series.name}</span><br>',
      //   pointFormat:
      //     '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> of total<br/>',
      pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
    },
    xAxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
    },
    yAxis: {
      min: 0,
      title: {
        text: ''
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color:
            // theme
            (Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color) ||
            'gray'
        }
      }
    },
    // series: series,
    series: result
    // [
    //   {
    //     data: [10, 23, 12, 30, 45, 32, 29, 50, 21, 38, 60, 42],
    //   },
    // ],
    // drilldown: {
    //     drillUpButton: {
    //         position: {
    //             verticalAlign: 'top',
    //             align: 'right',
    //             y: -50,
    //             x: -20
    //         }
    //     },
    //     series: drillDown
    // }
  };
};

import moment from 'moment';
import { useState, useEffect } from 'react';
import swal from 'sweetalert';
import { v4 as uuid } from 'uuid';
import Close from '@material-ui/icons/Close';
import PerfectScrollbar from 'react-perfect-scrollbar';
// import '../../css/observations/observation.css';
import Modal from 'react-bootstrap/Modal'; // for Modal
import Form from 'react-bootstrap/Form'; // for Modal
import AttachFileIcon from '@material-ui/icons/AttachFile'; //for modal
import { makeStyles } from '@material-ui/core/styles';
import FileDisplay from 'src/components/common/FileDisplay';
import { useSelector } from 'react-redux';
import { storage } from 'src/firebase';
import {
  Box,
  Button,
  Card,
  CardHeader,
  // Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField

  // TableSortLabel,
  // Tooltip
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import 'bootstrap/dist/css/bootstrap.min.css';
import Progress from 'src/components/ATGL/dashboard/TasksProgress';
import API from '../../../Api';
import 'src/css/ATGL/dashboard.css';
import OverlaySpinner from 'src/components/common/OverlaySpinner';
import CloseIcon from '@material-ui/icons/Close';
import { Api } from '@material-ui/icons';

const orders = [
  {
    id: uuid(),
    ref: '1',
    date: 1554670800000,
    Title: 'New Covid policy for May',
    progress: <Progress />
  },
  {
    id: uuid(),
    ref: '2',
    date: 1554670800000,
    Title: 'Carbon footprint capturing to be conducted',
    progress: <Progress />
  },
  {
    id: uuid(),
    ref: '3',
    date: 1554670800000,
    Title: 'Carbon footprint capturing to be conducted',
    progress: <Progress />
  }
];
const views = [
  {
    id: uuid(),
    ref: '1',
    date: 1554670800000,
    Name: 'ABC Company, John(TPI)',
    Time: '12:30 PM'
  }
];
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
}));

const Documents = (props) => {
  const { userData } = useSelector((state) => state.auth);

  // View Document Modal
  const [show2, setShow] = useState(false);
  const [show, setShow2] = useState(false);
  const [allNotices, setAllNotices] = useState([]);
  const [notices, setNotices] = useState([]);
  const [selectedNotice, setSelectedNotice] = useState();
  const [employeeCount, setEmployeeCount] = useState(0);
  const [noticeTitle, setNoticeTitle] = useState();
  const [noticeDescription, setNoticeDescription] = useState();
  const [selectedFiles, setSelectedFiles] = useState({});
  const [formValid, setFormValid] = useState(false);
  const [viewMore, setViewMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [isAll, setIsAll] = useState(false);

  //Add Document Modal
  const handleClose = () => {
    setShow(false);
    setSelectedNotice();
  };
  const handleShow = (notice) => {
    setShow(true);
    console.log('DOCUMENT>>>', notice);
    setSelectedNotice(notice);
  };

  const handleClose2 = () => {
    resetData();
    setShow2(false);
  };
  const handleShow2 = () => setShow2(true);

  useEffect(() => {
    if (userData) {
      console.log({ userData });
      fetchAllNotices(userData.companyId);
    }
  }, [userData]);

  const fetchAllNotices = (companyId) => {
    setLoading(true);

    API.getAllDocumentsByCmpID({ companyId }, { params: { viewers: true } })
      .then((res) => {
        console.log('fetchAllDocuments res', res.data.data);
        let sortedData = [];
        sortedData = res.data.data.sort((a, b) =>
          moment(b.createdAt).diff(moment(a.createdAt))
        );
        setNotices(sortedData);
        setDocuments(sortedData);
        setEmployeeCount(res.data.employee_count);
        setLoading(false);
      })
      .catch((err) => {
        console.log('fetchAllDocuments err', { err });
        swal({
          title: 'Error',
          text: err.message,
          icon: 'error',
          button: 'Ok'
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    if (Object.keys(selectedFiles).length) {
      let keys = Object.keys(selectedFiles);
      for (let file of keys) {
        if (
          !validateFileSize(selectedFiles[file]) ||
          !validateFileType(selectedFiles[file])
        ) {
          setSelectedFiles({});
        }
      }
    }
  }, [selectedFiles]);

  useEffect(() => {
    let valid = true;
    valid =
      noticeTitle &&
      noticeDescription &&
      Object.keys(selectedFiles).length &&
      valid;
    setFormValid(valid);
  }, [noticeTitle, noticeDescription, selectedFiles]);

  const submitHandler = (e) => {
    e.preventDefault();
    let data = {};
    data.creatorId = userData.companyId;
    data.creatorType = userData.type;
    data.creatorName = userData.name;
    data.companyId = userData.companyId;
    if (!formValid) {
      console.log('INSIDE IF');
      swal('All fields are mandatory', {
        icon: 'error'
      });
      return;
    }
    data.title = noticeTitle;
    data.description = noticeDescription;
    console.log('submitHandler', { data });
    uploadNotice(data);
  };

  const uploadFilePromise = async (file) => {
    let trimmedFileName = file.name.trim().replace(/\s+/g, '_');
    let filePath = `attachments/documents/${uuid().replaceAll(
      '-',
      ''
    )}/${trimmedFileName}`;
    let fileRef = storage.ref(filePath);
    await fileRef.put(file);
    let fileUrl = await fileRef.getDownloadURL();
    return {
      fileName: trimmedFileName,
      filePath,
      fileLink: fileUrl,
      fileSize: file.size,
      fileType: file.type
    };
  };

  const uploadNotice = async (data) => {
    setLoading(true);
    try {
      data.attachments = [];
      let filePromises = Object.keys(selectedFiles).map((key) => {
        let file = selectedFiles[key];
        return uploadFilePromise(file);
      });
      data.attachments = await Promise.all(filePromises);
      await API.createDocuments(data);
      fetchAllNotices(userData.companyId);
      handleClose2();
      swal({
        title: 'Success',
        text: 'Document created successfully!',
        icon: 'success',
        button: 'Ok'
      });
    } catch (err) {
      console.log('createNotice err', { err });
      swal({
        title: 'Error',
        text: err.message,
        icon: 'error',
        button: 'Ok'
      });
    } finally {
      setLoading(false);
    }
  };

  const resetData = () => {
    setNoticeTitle();
    setNoticeDescription();
    setSelectedFiles({});
    setFormValid(false);
  };

  const validateFileSize = (file) => {
    let fileSize = file.size / 1024 / 1024;
    if (fileSize > 10) {
      swal({
        title: file.name,
        text: 'Sorry, Max file size allowed is 10MB',
        icon: 'error',
        button: 'Ok'
      });
      return false;
    } else {
      return true;
    }
  };

  const validateFileType = (file) => {
    if (
      !file.type
        .toLowerCase()
        .match(
          /(jpg|jpeg|png|pdf|xlsx|xlsm|xlsb|xltx|xltm|xls|xlt|xls|xml|xlam|xla|xlw|xlr|csv)$/
        )
    ) {
      swal({
        title: file.name,
        text: 'Please select Image, PDF and Excel files only!',
        icon: 'error',
        button: 'Ok'
      });
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (!viewMore) {
      let data = notices.slice(0, 3);
      setAllNotices(data);
    } else {
      setAllNotices(notices);
    }
  }, [viewMore]);

  return (
    <>
      {loading ? <OverlaySpinner /> : null}
      <Modal
        size="lg"
        show={show2}
        onHide={handleClose}
        backdrop="static"
        keyboard="false"
        centered
      >
        <Modal.Header>
          <Modal.Title>Document</Modal.Title>
          <label onClick={handleClose} id="feedback-btn">
            <CloseIcon />
          </label>
          {/* <Button variant="" style={{ color: 'red'  }}  onClick={handleClose}>X</Button> */}
        </Modal.Header>
        <Modal.Body>
          {selectedNotice ? (
            <>
              <div className="text-center">
                <h5>{selectedNotice?.title}</h5>
              </div>

              <div className="row">
                <div className="col-md-6">
                  {' '}
                  <p className="text-left">
                    Date:{' '}
                    {moment(selectedNotice?.createdAt).format('DD/MM/YYYY')}
                  </p>
                </div>
                <div className="col-md-6">
                  <p style={{ float: 'right' }}>
                    Time: {moment(selectedNotice?.createdAt).format('hh:mm A')}
                  </p>
                </div>
              </div>
              <p>{selectedNotice?.description}</p>
              <Divider />
              <br />
              <h5>Attachments:</h5>
              <div className="d-flex flex-wrap my-1">
                {selectedNotice?.attachments?.map((doc) => (
                  <FileDisplay
                    key={doc._id}
                    mode="view"
                    file={{ value: doc }}
                  />
                ))}
              </div>
              {/* <a href={''} download="New COVID policy for May.pdf"><Button
            className="view-btn"
            color="primary"
            style={{ float: "left" }}
            size="small"
            variant="text"
          >Download</Button></a> */}
              {/* <p>(New COVID policy for May.pdf)</p> */}
              <br />
              <h5> Completed By: {selectedNotice?.viewers?.length}</h5>
              <Divider />
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedNotice?.viewers?.map((views) => (
                    <TableRow hover key={views._id}>
                      <TableCell>
                        {moment(views.viewedAt).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>
                        {moment(views.viewedAt).format('hh:mm A')}
                      </TableCell>
                      <TableCell>{views.viewer.name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          ) : null}
        </Modal.Body>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard="false"
        centered
      >
        <Modal.Header>
          <Modal.Title>Add Document</Modal.Title>
          <Button
            variant="primary"
            className="add-document-close"
            onClick={handleClose2}
          ></Button>
          <h5 className="add-document-close" onClick={handleClose2}>
            <CloseIcon />
          </h5>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="row">
          <div className="col-md-6">
            <label htmlFor="date">Date</label>
            <input type="date" placeholder="Enter Title" className="form-control" />
          </div>
          <div className="col-md-6">
            <label htmlFor="date">Time</label>
            <input type="time" className="form-control" />
          </div>
        </div>
        <br/> */}
          <input
            required
            type="text"
            value={noticeTitle}
            onChange={(e) => setNoticeTitle(e.target.value)}
            placeholder="Enter Title"
            className="form-control"
          />
          <div className="my-2">
            <Form.Control
              required
              value={noticeDescription}
              onChange={(e) => setNoticeDescription(e.target.value)}
              as="textarea"
              placeholder="Enter Text"
              style={{ height: '100px' }}
            />
          </div>
          <ol>
            {Object.keys(selectedFiles).map((key, index) => (
              <>
                <li key={index}>
                  {selectedFiles[key].name}
                  <span
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => {
                      let files = { ...selectedFiles };
                      delete files[key];
                      setSelectedFiles(files);
                    }}
                  >
                    <Close />
                  </span>
                </li>
              </>
            ))}
          </ol>
          <Button variant="" className="modal-submit-btn">
            <input
              id="file-input"
              type="file"
              color="primary"
              className="file-input-icon"
              multiple
              required
              onChange={(e) => {
                console.log('fileHandler', e.target.files);
                setSelectedFiles(e.target.files);
              }}
              // onChange={(e) => fileUploadHandler(e, 'file-input')}
              accept="image/*, .doc, .docx, application/pdf, application/msword, application/vnd.ms-powerpoint,.ppt, .pptx"
            />
            <label for="file-input" className="file response-file">
              <AttachFileIcon />
            </label>
          </Button>

          <Button
            // variant="primary"
            type="button"
            className="modal-submit-btn"
            onClick={submitHandler}
            style={{ float: 'right' }}
            color="primary"
            disabled={!formValid}
          >
            Submit
          </Button>
        </Modal.Body>
      </Modal>

      <Card {...props}>
        <div className="row">
          <div className="col-md-8">
            <CardHeader title={<h2>Recent Policy Updates</h2>} />
          </div>
          <div className="col-md-4 ">
            <CardHeader
              onClick={handleShow2}
              className="hover pull-right"
              title=" + Add Recent Policy Updates"
            />
          </div>
        </div>

        <Divider />
        <PerfectScrollbar>
          <Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="text-center">Uploaded On</TableCell>
                  <TableCell className="text-center">Title</TableCell>
                  <TableCell className="text-center">Views</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notices
                  .slice(0, isAll ? notices.length : 5)
                  .map((doc, index) => {
                    return (
                      <TableRow hover key={doc._id}>
                        <TableCell className="text-center">
                          {moment(doc.createdAt).format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell onClick={() => handleShow(doc)}>
                          <u> {doc.title}</u>
                        </TableCell>
                        <TableCell className="text-center">
                          <Progress
                            total={employeeCount}
                            count={doc.viewers.length}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {/* {notices.map(notice => (
                <TableRow
                  hover
                  key={notice._id}
                >
                  <TableCell className="text-center">
                    {moment(notice.createdAt).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell onClick={() => handleShow(notice)}>
                    {notice.title}
                  </TableCell>
                  <TableCell className="text-center">
                    <Progress total={employeeCount} count={notice.viewers.length}/>
                  </TableCell>
                </TableRow>
              ))} */}
              </TableBody>
            </Table>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
              }}
            >
              {notices.length > 5 && (
                <Button
                  className="view-btn"
                  color="primary"
                  endIcon={<ArrowRightIcon />}
                  size="small"
                  variant="text"
                  onClick={() => setIsAll((old) => !old)}
                >
                  {isAll ? 'View Less' : 'View all'}
                </Button>
              )}
            </Box>
          </Box>
        </PerfectScrollbar>
      </Card>
    </>
  );
};
export default Documents;

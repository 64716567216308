import { Box, Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { uploadFilePromise } from 'src/Constants';
import { setLoading } from 'src/store/auth/actions';
import * as Yup from 'yup';
import { contractorEdit } from '../../store/auditors/thunk';
import OverlaySpinner from '../common/OverlaySpinner';
import ProfileUpload from '../common/ProfileUpload';
import SingleFileUpload from '../common/SingleFileUpload';
import './add_auditor.css';

const EditCompany = (props) => {
  const Navigate = useNavigate();

  const [image, setImage] = useState([]);
  const { userData } = useSelector((state) => state.auth);
  const { currentCompany } = useSelector((state) => state.auditors);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [selectedImage, setSelectedImage] = useState({
    isDeleted: false,
    file: null
  });
  const [imageChange, setImageChange] = useState(false);

  useEffect(() => {
    setSelectedImage({ isDeleted: false, file: currentCompany.profileImage });
  }, []);

  const onSubmit = async (val) => {
    //console.log('Values>>', val);
    //dispatch(setLoading(true));
    setLoading(true);
    if (image) {
      let profileData = await uploadFilePromise(image, userData.companyId);
      val.profileImage = {
        fileName: profileData.fileName,
        fileLink: profileData.fileLink,
        filePath: profileData.filePath,
        fileSize: profileData.fileSize
      };
    }

    if (selectedImage.isDeleted) {
      val.profileImage = {
        fileName: '',
        fileLink: '',
        filePath: '',
        fileSize: ''
      };
    }

    // val.cmp_id = userData.companyId;
    // val.cmp_name = userData.name;
    val.user = currentCompany.user;
    //val.mobile="+91"+val.mobile
    await dispatch(contractorEdit(val, Navigate));
    //dispatch(setLoading(false));
    setLoading(false);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '75%'
      }
    }
  }));

  const classes = useStyles();
  return (
    <>
      {loading && <OverlaySpinner />}
      <div style={{ marginTop: '2%', marginLeft: '1%' }} className="row">
        <div className="col-md-6">
          <h2>
            <ul class="breadcrumb">
              <li>
                <Link to="/sapphire/companies">Users</Link>
              </li>
              <li>
                <Link to="">Edit Company</Link>
              </li>
            </ul>
          </h2>
        </div>
      </div>
      <Box
        {...props}
        style={{
          backgroundColor: 'white',
          marginLeft: '2%',
          marginRight: '2%'
        }}
      >
        <Box>
          <Card>
            <CardContent>
              <div className="row">
                <Formik
                  initialValues={
                    currentCompany
                      ? {
                          code: currentCompany.code,
                          name: currentCompany.name,
                          mobile: currentCompany.mobile,
                          emailid:
                            currentCompany.emailid || currentCompany.emailId,
                          location: currentCompany.location,
                          contactPersonName: currentCompany.contactPersonName
                        }
                      : null
                  }
                  onSubmit={(val) => onSubmit(val)}
                  validationSchema={Yup.object({
                    code: Yup.string()
                      .required('Code is required')
                      .matches(/^[A-Za-z0-9_-]*$/, 'Code is invalid'),
                    // name: Yup.string()
                    //   .required('Name is required')
                    //   .matches(/^[A-Za-z/' ]+$/, 'Name is invalid'),
                    location: Yup.string()
                      .required('Location is required')
                      .matches(/^\w{2,}$/, 'Location is invalid'),
                    // emailid: Yup.string()
                    //   .required('Email is required')
                    //   .email('Please enter valid email'),
                    // mobile: Yup.string()
                    //   .required('Phone no is required')
                    //   .min(10, 'Phone no is invalid')
                    //   .max(10, 'Phone no is invalid'),
                    contactPersonName: Yup.string()
                      .required('Name is required')
                      .matches(/^[A-Za-z/' ]+$/, 'Name is invalid')
                  })}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    errors,
                    touched,
                    setFieldTouched,
                    setFieldValue,
                    setFieldError,
                    dirty
                  }) => (
                    <>
                      <form className={classes.root} onSubmit={handleSubmit}>
                        <div className="col-md-12">
                          <h2>Edit Company</h2>
                          <div className="image-btn my-4">
                            <ProfileUpload
                              handleFileChanges={(file) => {
                                setImage(file);
                              }}
                              defaultFile={
                                selectedImage.file?.fileName == ''
                                  ? null
                                  : selectedImage.file
                              }
                              handleUploadedFileDelete={() => {
                                setSelectedImage({
                                  isDeleted: true,
                                  file: null
                                });
                                setImageChange(true);
                              }}
                              acceptExt={'.jpg, .png'}
                            />
                            {/* <SingleFileUpload
                            handleFileChanges={(file) =>{
                              setImage(file)
                            }}
                            defaultFile={selectedImage.file?.fileName=="" ? null : selectedImage.file}
                            handleUploadedFileDelete={() => {
                              setSelectedImage({
                                isDeleted: true,
                                file: null
                              });
                              setImageChange(true);
                            }}
                          /> */}
                          </div>
                          <div className="row">
                            <div className="col-md-5">
                              {/* <div>
                              <TextField
                              disabled
                                id="standard-basic"
                                label="Company Code"
                                variant="standard"
                                className="my-2 form-control"
                                onChange={handleChange('code')}
                                value={values.code}
                                onFocus={() => setFieldTouched('code')}
                              />
                              <small className="text-danger form-text">
                                {touched.code && errors.code}
                              </small>
                              <br />
                            </div> */}
                              <div>
                                <div>
                                  <TextField
                                    id="standard-basic"
                                    label="Location"
                                    variant="standard"
                                    className="my-2 form-control"
                                    onChange={handleChange('location')}
                                    value={values.location}
                                    onFocus={() => setFieldTouched('location')}
                                  />
                                </div>
                                <div>
                                  <small className="text-danger form-text">
                                    {touched.location && errors.location}
                                  </small>

                                  <br />
                                </div>
                              </div>
                              <div>
                                <div>
                                  <TextField
                                    disabled
                                    id="standard-basic"
                                    label="Email"
                                    variant="standard"
                                    className="my-2 form-control"
                                    onChange={handleChange('emailid')}
                                    value={values.emailid}
                                    onFocus={() => setFieldTouched('emailid')}
                                  />
                                </div>
                                <div>
                                  <small className="text-danger form-text">
                                    {touched.emailid && errors.emailid}
                                  </small>
                                  <br />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-1"></div>
                            <div className="col-md-5">
                              <div>
                                <TextField
                                  disabled
                                  id="standard-basic"
                                  label="Company Name"
                                  variant="standard"
                                  className="my-2 form-control"
                                  onChange={handleChange('name')}
                                  value={values.name}
                                  onFocus={() => setFieldTouched('name')}
                                />
                              </div>
                              <div>
                                <small className="text-danger form-text">
                                  {touched.name && errors.name}
                                </small>
                                <br />
                              </div>

                              <div>
                                <TextField
                                  disabled
                                  id="standard-basic"
                                  label="Phone Number"
                                  variant="standard"
                                  className="my-2 form-control"
                                  onChange={handleChange('mobile')}
                                  value={values.mobile}
                                  onFocus={() => setFieldTouched('mobile')}
                                />
                              </div>
                              <div>
                                <small className="text-danger form-text">
                                  {touched.mobile && errors.mobile}
                                </small>
                                <br />
                              </div>

                              <div>
                                <TextField
                                  id="standard-basic"
                                  label="Contact Person Name"
                                  variant="standard"
                                  className="my-2 form-control"
                                  onChange={handleChange('contactPersonName')}
                                  value={values.contactPersonName}
                                  onFocus={() =>
                                    setFieldTouched('contactPersonName')
                                  }
                                />
                              </div>
                              <div>
                                <small className="text-danger form-text">
                                  {touched.contactPersonName &&
                                    errors.contactPersonName}
                                </small>
                                <br />
                              </div>
                            </div>
                          </div>
                          <div className="addbutton my-2">
                            <Button
                              className="modal-submit-btn"
                              className="view-btn"
                              color="primary"
                              size="small"
                              disabled={
                                dirty || image || imageChange ? false : true
                              }
                              onClick={handleSubmit}
                            >
                              Save
                            </Button>
                          </div>
                        </div>
                      </form>
                    </>
                  )}
                </Formik>
              </div>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default EditCompany;

import React from 'react';
import API from '../../Api';

//TPI
export const tpiPhoneCheck = async (phoneNo) => {
  let result = await API.tpiCheckPhoneNumberExists({ phoneNo: phoneNo });
  if (result.data.data.exists) {
    return true;
  }
  return false;
};

export const tpiEmailCheck = async (email) => {
  let result = await API.tpiCheckEmailExists({ email: email });
  if (result.data.data.exists) {
    return true;
  }
  return false;
};

export const tpiNameCheck = async (name) => {
  let result = await API.tpiCheckNameExists({ name: name });
  if (result.data.data.exists) {
    return true;
  }
  return false;
};

export const tpiCodeCheck = async (code) => {
  let result = await API.tpiCheckCodeExists({ code: code });
  if (result.data.data.exists) {
    return true;
  }
  return false;
};

//CONTRACTOR
export const contractPhoneCheck = async (phoneNo) => {
  let result = await API.contractorCheckPhoneNumberExists({ phoneNo: phoneNo });
  if (result.data.data.exists) {
    return true;
  }
  return false;
};

export const contractEmailCheck = async (email) => {
  let result = await API.contractorCheckEmailExists({ email: email });
  if (result.data.data.exists) {
    return true;
  }
  return false;
};

export const contractNameCheck = async (name) => {
  let result = await API.contractorCheckNameExists({ name: name });
  if (result.data.data.exists) {
    return true;
  }
  return false;
};

export const contractCodeCheck = async (code) => {
  let result = await API.contractorCheckCodeExists({ code: code });
  if (result.data.data.exists) {
    return true;
  }
  return false;
};

// COMPANY

export const companyPhoneCheck = async (phoneNo) => {
  let result = await API.companyCheckPhoneNumberExists({ phoneNo: phoneNo });
  if (result.data.data.exists) {
    return true;
  }
  return false;
};

export const companyEmailCheck = async (email) => {
  let result = await API.companyCheckEmailExists({ email: email });
  if (result.data.data.exists) {
    return true;
  }
  return false;
};

export const companyNameCheck = async (name) => {
  let result = await API.companyCheckNameExists({ name: name });
  if (result.data.data.exists) {
    return true;
  }
  return false;
};

export const companyCodeCheck = async (code) => {
  let result = await API.companyCheckCodeExists({ code: code });
  if (result.data.data.exists) {
    return true;
  }
  return false;
};

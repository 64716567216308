import swal from 'sweetalert';
import * as actions from './actions';
import { auth } from '../../firebase';
import API from '../../Api';

export const auditorAdded = (auditors, Navigate) => async (dispatch) => {
  try {
    dispatch(actions.setLoading(true));
    let res = await API.createTpi(auditors);
    swal({
      title: 'Auditor Added Successfully',

      icon: 'success',
      button: 'Ok'
    });
    Navigate('/sapphire/users');
    dispatch(actions.setLoading(false));
    //dispatch(getAllUAuditors())
    console.log(res);
  } catch (err) {
    dispatch(actions.setLoading(false));
    swal({
      title: 'Error',
      text: err.message,
      icon: 'error',
      button: 'Ok'
    });
    console.log(err);
  }
};

export const auditorEdit = (auditors, Navigate) => async (dispatch) => {
  try {
    dispatch(actions.setLoading(true));
    let res = await API.editTpi(auditors);
    swal({
      title: 'Auditor Edited Successfully',

      icon: 'success',
      button: 'Ok'
    });
    Navigate('/sapphire/users');
    dispatch(actions.setLoading(false));
    //dispatch(getAllUAuditors())
    console.log(res);
  } catch (err) {
    dispatch(actions.setLoading(false));
    swal({
      title: 'Error',
      text: err.message,
      icon: 'error',
      button: 'Ok'
    });
    console.log(err);
  }
};

export const contractorrAdded = (contractor, Navigate) => async (dispatch) => {
  try {
    dispatch(actions.setLoading(true));
    let res = await API.createContractor(contractor);
    swal({
      title: 'Company Added Successfully',

      icon: 'success',
      button: 'Ok'
    });
    setTimeout(() => {
      Navigate('/sapphire/companies');
      dispatch(actions.setLoading(false));
    }, 2000);

    // dispatch(getAllContractorsList())
    console.log(res);
  } catch (err) {
    dispatch(actions.setLoading(false));
    swal({
      title: 'Error',
      text: err.message,
      icon: 'error',
      button: 'Ok'
    });
    console.log(err);
  }
};
export const bulkContractorsAdded = (data) => async (dispatch) => {
  try {
    dispatch(actions.setLoading(true));
    let res = await API.createBulkContractors(data);

    console.log(res);
    swal({
      title: 'Companys Added Successfully',
      icon: 'success',
      button: 'Ok'
    });
    // setTimeout(() => {
    //   Navigate('/sapphire/companies');
    //   dispatch(actions.setLoading(false));
    // }, 2000);

    // dispatch(getAllContractorsList())
    console.log(res);
  } catch (err) {
    dispatch(actions.setLoading(false));
    swal({
      title: 'Error',
      text: err.message,
      icon: 'error',
      button: 'Ok'
    });
    console.log(err);
  } finally {
    dispatch(actions.setLoading(false));
  }
};

export const contractorEdit = (contractor, Navigate) => async (dispatch) => {
  try {
    dispatch(actions.setLoading(true));
    let res = await API.editContractor(contractor);
    swal({
      title: 'Company Edited Successfully',

      icon: 'success',
      button: 'Ok'
    });
    Navigate('/sapphire/companies');
    dispatch(actions.setLoading(false));
    //dispatch(getAllUAuditors())
    console.log(res);
  } catch (err) {
    dispatch(actions.setLoading(false));
    swal({
      title: 'Error',
      text: err.message,
      icon: 'error',
      button: 'Ok'
    });
    console.log(err);
  }
};

export const getAllUAuditors = (id) => async (dispatch) => {
  try {
    dispatch(actions.setLoading(true));
    let allUsers = [];

    allUsers = await API.getTpisByCmpID({ companyId: id });
    // let sortData = allUsers.data.data.sort((a, b) =>
    //   b.createdAt._seconds-a.createdAt._seconds
    // )
    // console.log("SORTDATA>>>", sortData)
    dispatch(actions.setAllAuditors(allUsers.data.data));

    dispatch(actions.setLoading(false));
    //console.log(sortData)
  } catch (err) {
    dispatch(actions.setLoading(false));
    console.log(err);
  }
};

export const getAllContractorsList = (id) => async (dispatch) => {
  try {
    dispatch(actions.setLoading(true));
    let allCompanies = [];

    allCompanies = await API.getAllContractorsByID({ companyId: id });
    console.log('ALLCONTRACTORS>>', allCompanies.data.data);

    // let sortData = allCompanies.data.data.sort((a, b) =>
    //   b.createdAt._seconds-a.createdAt._seconds
    // )
    //console.log("COMPANYSORTDATA>>>", allCompanies)
    dispatch(actions.setAllContractors(allCompanies.data.data));

    dispatch(actions.setLoading(false));
  } catch (err) {
    dispatch(actions.setLoading(false));
    console.log(err);
  }
};

export const deleteAuditor = (auditor) => async (dispatch) => {
  try {
    dispatch(actions.setLoading(true));
    await API.deleteTpi({ tpiId: auditor.tpiId });
    swal({
      title: 'Auditor Deleted Successfully',

      icon: 'success',
      button: 'Ok'
    });
  } catch (err) {
    dispatch(actions.setLoading(false));
    swal({
      title: 'Error',
      text: err.message,
      icon: 'error',
      button: 'Ok'
    });
    console.log(err);
  }
};

export const deleteContractor = (contractor) => async (dispatch) => {
  try {
    console.log('CONTRACTOR ID:::', contractor);
    dispatch(actions.setLoading(true));
    await API.deleteContractor({ contractorId: contractor.user });

    swal({
      title: 'Company Deleted Successfully',

      icon: 'success',
      button: 'Ok'
    });
  } catch (err) {
    dispatch(actions.setLoading(false));
    swal({
      title: 'Error',
      text: err.message,
      icon: 'error',
      button: 'Ok'
    });
    console.log(err);
  }
};

export const disableAuditor = (data) => async (dispatch) => {
  try {
    dispatch(actions.setLoading(true));

    console.log('Data', data);
    let res = await API.statusTpi(data);
    if (res.data.code >= 400)
      throw new Error('Failed to change account status');
    swal({
      title: `Auditor ${
        data.disable == true ? 'Disabled' : 'Enabled'
      } Succesfully`,

      icon: 'success',
      button: 'Ok'
    });
    dispatch(actions.setLoading(false));
  } catch (err) {
    dispatch(actions.setLoading(false));
    swal({
      title: 'Error',
      text: err.message,
      icon: 'error',
      button: 'Ok'
    });

    console.log(err);
  }
};

export const disableContractor = (data) => async (dispatch) => {
  try {
    dispatch(actions.setLoading(true));

    console.log('Data', data);
    let res = await API.statusContractori(data);
    if (res.data.code >= 400)
      throw new Error('Failed to change account status');
    swal({
      title: `Company ${
        data.disable == true ? 'Disabled' : 'Enabled'
      } Successfully`,

      icon: 'success',
      button: 'Ok'
    });
    dispatch(actions.setLoading(false));
  } catch (err) {
    dispatch(actions.setLoading(false));
    swal({
      title: 'Error',
      text: err.message,
      icon: 'error',
      button: 'Ok'
    });

    console.log(err);
  }
};

import { Helmet } from 'react-helmet';
import { Box, Card, Container, Button } from '@material-ui/core';
import Add_assess from 'src/components/ATGL/assessment/add_assessment';
import { Link } from 'react-router-dom';

const Add_Assessment = () => {
  return (
    <>
      <Helmet>
        <title>Assessment | Oren</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <h2>
            <ul class="breadcrumb">
              <li>
                <Link to="/sapphire/Assessment">Assessment</Link>
              </li>
              <li>
                <Link to="/sapphire/Add_Assessment">Add Assessment</Link>
              </li>
            </ul>
          </h2>
          <Card className="col-sm-12">
            <Add_assess />
          </Card>

          <Box sx={{ pt: 3 }}></Box>
        </Container>
      </Box>
    </>
  );
};

export default Add_Assessment;

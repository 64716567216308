import moment from 'moment';
import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Modal from 'react-bootstrap/Modal'; // for Modal
import Form from 'react-bootstrap/Form'; // for Modal
import AttachFileIcon from '@material-ui/icons/AttachFile'; //for modal
// import Button from 'react-bootstrap/Button';//for modal

import {
  Box,
  Button,
  Card,
  CardHeader,
  // Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
  // TableSortLabel,
  // Tooltip
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import 'bootstrap/dist/css/bootstrap.min.css';

const orders = [
  {
    id: uuid(),
    ref: '1',
    date: 1554670800000,

    customer: {
      name: 'Adam Denisov'
    },
    createdAt: 1554670800000,
    status: 'Completed'
  },
  {
    id: uuid(),
    ref: '2',
    date: 1554670800000,

    customer: {
      name: 'John'
    },
    createdAt: 1554670800000,
    status: 'Completed'
  },
  {
    id: uuid(),
    ref: '3',
    date: 1554670800000,

    customer: {
      name: 'Johnson'
    },
    createdAt: 1554670800000,
    status: 'Completed'
  }
];

const Documents = ({timeline,...props}) => {
  const [show2, setShow] = useState(false);
  const [isAll, setIsAll] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // <>

  const downloadFile = () => {
    window.location.href = '/static/wiremap.pdf';
  };
  return (
    <>
      <Card {...props}>
        <CardHeader title="Timeline" />
        <Divider />
        <PerfectScrollbar>
          <Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="text-center">Date</TableCell>
                  <TableCell className="text-center">Updated By</TableCell>
                  <TableCell className="text-center">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {timeline.sort((a,b) => moment(b.updatedAt).diff(moment(a.updatedAt))).slice(0, isAll ? timeline.length : 5).map((event) => (
                  <TableRow hover>
                    <TableCell className="text-center">
                      {moment(event.updatedAt).format('DD/MM/YYYY')}
                    </TableCell >
                    <TableCell className="text-center">{event.updatedBy.name}</TableCell>
                    <TableCell className="text-success text-center">
                      {event.status.onlyFirstCapital()}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
        {timeline.length > 5 && (
          <Button
            className="view-btn"
            color="primary"
            endIcon={<ArrowRightIcon />}
            size="small"
            variant="text"
            onClick={() => setIsAll((old) => !old)}
          >
            {isAll ? 'View Less' : 'View all'}
          </Button>
        )}
        </Box>
      </Card>
    </>
  );
};

export default Documents;

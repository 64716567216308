import BlockIcon from '@material-ui/icons/Block';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { MDBDataTableV5 } from 'mdbreact';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { setCurrentCompany } from 'src/store/auditors/actions';
import swal from 'sweetalert';
import {
  deleteContractor,
  disableContractor,
  getAllContractorsList
} from '../../store/auditors/thunk';
import './auditors.css';

const tableCol = [
  {
    label: 'S.no',
    field: 'sno',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Company Name',
    field: 'companyName',
    width: 10
  },
  // {
  //   label: 'Company Code',
  //   field: 'companycode',
  //   width: 10,
  //   attributes: {
  //     'aria-controls': 'DataTable',
  //     'aria-label': 'Company Code'
  //   }
  // },
  // {
  //   label: 'Organisation',
  //   field: 'organisation',
  //   width: 10
  // },
  {
    label: 'Location',
    field: 'location',
    width: 10
  },
  // {
  //   label: 'Mobile',
  //   field: 'mobile',
  //   width: 10
  // },
  {
    label: 'E-mail',
    field: 'email',
    // sort: 'asc',
    width: 10
  },
  {
    label: 'Contact Person',
    field: 'contactperson',
    // sort: 'asc',
    width: 10
  },
  {
    label: 'Actions',
    field: 'action',
    sort: 'disabled',
    width: 10
  }
];

export default function Basic() {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.auth);
  const { allContrators } = useSelector((state) => state.auditors);

  useEffect(() => {
    if (userData && userData?.companyId) {
      dispatch(getAllContractorsList(userData.companyId));
      console.log('CONTRACTOR', allContrators);
    }
  }, []);

  const deleteContractorTable = (contractor) => {
    swal({
      title: `Delete`,
      text: `Are you sure,You want to delete ${contractor.name}?`,
      icon: 'warning',
      buttons: true,
      dangerMode: true
    }).then(async (willDelete) => {
      if (willDelete) {
        await dispatch(deleteContractor(contractor));
        await dispatch(getAllContractorsList(userData.companyId));
        //  setTimeout(async()=>{

        //  },1000)
      }
    });
  };

  const onDisable = async (company, index) => {
    try {
      swal({
        title: 'Are you sure?',
        text: `You want to ${
          company.isActive == true ? 'disable' : 'enable'
        } this user`,
        icon: 'warning',
        buttons: true,
        dangerMode: true
      }).then(async (willDelete) => {
        if (willDelete) {
          let data = {
            contractorId: company.user,
            disable: company.isActive == true ? true : false
          };
          await dispatch(disableContractor(data));

          await dispatch(getAllContractorsList(userData.companyId));
        } else {
          dispatch(getAllContractorsList(userData.companyId));
        }
      });
    } catch (err) {
      swal({
        title: 'Error',
        text: err.message,
        icon: 'error',
        button: 'Ok'
      });
    }
  };

  return (
    <MDBDataTableV5
      className="my-3"
      disableRetreatAfterSorting
      searchTop
      sortRows={[]}
      searchBottom={false}
      entriesOptions={[5, 10, 20]}
      entries={5}
      pagesAmount={6}
      data={{
        columns: tableCol,
        rows: allContrators
          ? allContrators.map((company, index) => {
              return {
                sno: company.isActive ? (
                  <div>{index + 1}</div>
                ) : (
                  <div style={{ color: '#BDBDBD' }}>{index + 1}</div>
                ),
                //companycode: company.code,
                companyName: company.isActive ? (
                  <span searchvalue={company.name}>{company.name}</span>
                ) : (
                  <span searchvalue={company.name} style={{ color: '#BDBDBD' }}>
                    {company.name}
                  </span>
                ),
                location: company.isActive ? (
                  <span searchvalue={company.location}>{company.location}</span>
                ) : (
                  <span
                    searchvalue={company.locationt}
                    iv
                    style={{ color: '#BDBDBD' }}
                  >
                    {company.location}
                  </span>
                ),
                // mobile: company.isActive ? (
                //   <span searchvalue={company.mobile}>{company.mobile}</span>
                // ) : (
                //   <span
                //     searchvalue={company.mobile}
                //     style={{ color: '#BDBDBD' }}
                //   >
                //     {company.mobile}
                //   </span>
                // ),
                email: company.isActive ? (
                  <span searchvalue={company.emailid || company.emailId}>
                    {company.emailid || company.emailId}
                  </span>
                ) : (
                  <span
                    searchvalue={company.emailid || company.emailId}
                    style={{ color: '#BDBDBD' }}
                  >
                    {company.emailid || company.emailId}
                  </span>
                ),
                contactperson: company.isActive ? (
                  <span searchvalue={company.contactPersonName}>
                    {company.contactPersonName}
                  </span>
                ) : (
                  <span
                    searchvalue={company.contactPersonName}
                    style={{ color: '#BDBDBD' }}
                  >
                    {company.contactPersonName}
                  </span>
                ),
                action: (
                  <React.Fragment>
                    <button
                      disabled={!company.isActive}
                      onClick={async () => {
                        await dispatch(setCurrentCompany(company));
                        Navigate('/sapphire/EditCompany');
                      }}
                      type="button"
                      className="btn btns-users btn-edit"
                    >
                      <CreateIcon className="icons-users" />
                    </button>
                    <button
                      disabled={!company.isActive}
                      onClick={() => {
                        deleteContractorTable(company);
                      }}
                      type="button"
                      className="btn btns-users  btn-delete"
                    >
                      <DeleteIcon className="icons-users" />
                    </button>
                    <button
                      disabled={!company.isActive}
                      onClick={async () => {
                        await dispatch(setCurrentCompany(company));
                        Navigate('/sapphire/ViewCompany');
                      }}
                      type="button"
                      className="btn btns-users btn-visibility"
                    >
                      <VisibilityIcon className="icons-users" />
                    </button>
                    <button
                      onClick={() => {
                        onDisable(company, index);
                      }}
                      type="button"
                      className="btn btns-users btn-block"
                    >
                      {company.isActive ? (
                        <BlockIcon className="icons-users" />
                      ) : (
                        <CheckCircleIcon className="icons-users" />
                      )}
                    </button>
                  </React.Fragment>
                )
              };
            })
          : null
      }}
    />
  );
}

import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import swal from 'sweetalert';
import { storage } from 'src/firebase';
import './FileDIsplay.css';
import { Avatar } from '@material-ui/core';
import axios from 'axios';

export const fileSize = (size) => {
  if (isNaN(size)) return '';
  let KB = (size / 1024).toFixed(2);
  let MB = (KB / 1024).toFixed(2);

  if (KB > 1000) {
    return MB + ' MB';
  }

  return KB + ' KB';
};

const FileDisplay = ({
  file,
  handleDelete,
  mode,
  disableIcon = false,
  style = {}
}) => {
  const [filePath, setFilePath] = useState();

  const downloadFile = () => {
    axios({
      url: filePath,
      method: 'GET',
      responseType: 'blob' // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.value.fileName);
      document.body.appendChild(link);
      link.click();
    });
  };

  useEffect(() => {
    console.log({ file });
    if (file?.value) {
      const ref = storage.ref(file?.value?.filePath);
      ref
        .getDownloadURL({ action: 'read', promptSaveAs: file.value.fileName })
        .then((url) => {
          console.log('getFileUrl', url);
          setFilePath(url);
        })
        .catch((err) => {
          console.log('getFileUrl err', err, file?.value?.filePath);
          setFilePath();
        });
    }
  }, []);

  if (mode === 'view') {
    return (
      <div
        style={{
          margin: '.5rem .5rem .5rem 0',
          padding: '.5rem .7rem',
          border: '1px solid #808080ab',
          borderRadius: '5px',
          display: 'flex',
          justifyContent: 'space-between',
          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
          width: '300px'
        }}
      >
        <div style={{ width: '100%' }}>
          <div
            onClick={() => {
              downloadFile();
            }}
            style={{ cursor: 'pointer' }}
            className="d-flex align-items-center"
          >
            <i
              className={`fas fa-file-download`}
              style={{
                marginRight: '.5rem',
                fontSize: '1.5rem',
                color: '#8d8d8d'
              }}
            />
            <div>
              <p
                style={{
                  margin: 0,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '200px'
                }}
                data-tip={file.value.fileName}
              >
                {file.value.fileName}
              </p>
              <ReactTooltip
                className="file-tooltip"
                effect="solid"
                place="bottom"
                backgroundColor="rgba(105, 105, 105, 0.781)"
              />

              <small className="text-muted">
                {fileSize(file.value.fileSize)}
              </small>
            </div>
          </div>
        </div>
        <div
          onClick={() => {
            downloadFile();
          }}
          className="d-flex"
        >
          <i
            className="fas fa-external-link-alt"
            style={{
              cursor: 'pointer',
              fontSize: '1.1rem'
              // color: "#8d8d8d",
            }}
          ></i>
        </div>
      </div>
    );
  }

  if (mode === 'youtube-embed') {
    return (
      <div
        style={{
          padding: '.5rem',
          border: '1px solid #808080ab',
          borderRadius: '5px',
          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
        }}
      >
        <object width="300px" data={file}>
          {file}
        </object>
      </div>
    );
  }

  if (mode === 'edit') {
    return (
      <div
        style={{
          margin: '.5rem .5rem .5rem 0',
          padding: '.5rem .7rem',
          border: '1px solid #808080ab',
          borderRadius: '5px',
          display: 'flex',
          justifyContent: 'space-between',
          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
          width: '300px'
        }}
      >
        <div style={{ width: '100%' }}>
          <div className="d-flex align-items-center">
            <i
              className={`fas fa-file-download`}
              style={{
                marginRight: '.5rem',
                fontSize: '1.5rem',
                color: '#8d8d8d'
              }}
            />
            <div>
              <p
                style={{
                  margin: 0,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '200px'
                }}
                data-tip={file.fileName}
              >
                {file.fileName}
              </p>
              <ReactTooltip
                className="file-tooltip"
                effect="solid"
                place="bottom"
                backgroundColor="rgba(105, 105, 105, 0.781)"
              />

              <small className="text-muted">{fileSize(file.fileSize)}</small>
            </div>
          </div>
        </div>
        <div className="d-flex">
          <a href={file.fileLink} target="blank">
            <i
              className="fas fa-external-link-alt"
              style={{
                cursor: 'pointer',
                fontSize: '1.1rem'
              }}
            ></i>
          </a>
          <i
            className="fas fa-trash-alt"
            style={{
              cursor: 'pointer',
              paddingLeft: '1rem',
              fontSize: '1.1rem',
              color: '#8d8d8d'
            }}
            onClick={() => {
              swal({
                title: 'Warning',
                text: `Are you sure you want to delete the file ${file.fileName}?`,
                icon: 'warning',
                buttons: true,
                dangerMode: true
              }).then((willDelete) => {
                if (willDelete) {
                  handleDelete();
                } else {
                  return;
                }
              });
            }}
          ></i>
        </div>
      </div>
    );
  }

  if (mode === 'edit-profile') {
    console.log('FILE NAME>>', file.name);
    return (
      <>
        <div className="d-flex flex-row justify-content-center">
          <div>
            <Avatar
              src={file.fileLink ? file.fileLink : URL.createObjectURL(file)}
              sx={{
                height: 100,
                width: 100
              }}
            />
            <span
              style={{
                fontSize: '12px'
              }}
            >
              {file.name}
            </span>
          </div>
          <div></div>
          <div>
            {disableIcon ? null : (
              <i
                className="fa fa-times"
                style={{
                  cursor: 'pointer',
                  paddingLeft: '1rem',
                  fontSize: '1.1rem',
                  color: '#8d8d8d'
                }}
                onClick={() => {
                  swal({
                    title: 'Warning',
                    text: `Are you sure you want to delete the file ${file.name}?`,
                    icon: 'warning',
                    buttons: true,
                    dangerMode: true
                  }).then((willDelete) => {
                    if (willDelete) {
                      handleDelete();
                    } else {
                      return;
                    }
                  });
                }}
              ></i>
            )}
          </div>
        </div>
      </>
    );
  }

  return (
    <div
      style={{
        margin: '.5rem .5rem .5rem 0',
        padding: '.5rem .7rem',
        border: '1px solid #808080ab',
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
        width: '300px',
        ...style
      }}
    >
      <div style={{ width: '100%' }}>
        <div className="d-flex align-items-center">
          <i
            className={`fas fa-file-upload`}
            style={{
              marginRight: '.5rem',
              fontSize: '1.5rem',
              color: '#8d8d8d'
            }}
          />
          <div>
            <p
              style={{
                margin: 0,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '220px'
              }}
              data-tip={file.name}
            >
              {file.name}
            </p>
            <ReactTooltip
              className="file-tooltip"
              effect="solid"
              place="bottom"
              backgroundColor="rgba(105, 105, 105, 0.781)"
            />

            <small className="text-muted">{fileSize(file.size)}</small>
          </div>
        </div>
      </div>
      <div>
        <i
          className="fas fa-times"
          style={{
            cursor: 'pointer',
            paddingLeft: '1rem',
            fontSize: '1.1rem',
            color: 'Red'
          }}
          onClick={handleDelete}
        ></i>
      </div>
    </div>
  );
};

export default FileDisplay;

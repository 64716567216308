import { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import swal from 'sweetalert';
import { v4 as uuid } from 'uuid';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useSelector } from 'react-redux';
import { storage } from 'src/firebase';
import Modal from 'react-bootstrap/Modal';
import AttachFileIcon from '@material-ui/icons/AttachFile'; //for modal
import Form from 'react-bootstrap/Form';
import API from '../Api';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import OverlaySpinner from 'src/components/common/OverlaySpinner';
import Close from '@material-ui/icons/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FileDisplay from 'src/components/common/FileDisplay';

const useStyles = makeStyles((theme) => ({
  formControl: {
    display: 'flex',
    flexDirection: 'row !important',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '100%'
  },
  selectEmpty: {
    width: '100%',
    height: '100% !important',
    padding: '11px 5px !important'
  },
  selectWrap: {
    marginRight: '1rem !important',
    width: '100%',
    height: '100% !important'
  }
}));

const useMenuItemStyles = makeStyles((theme) => ({
  root: {
    whiteSpace: 'unset !important',
    wordBreak: 'break-word'
  }
}));

const AddLearning = (props) => {
  const { userData } = useSelector((state) => state.auth);
  const classes = useStyles();
  const listClasses = useMenuItemStyles();
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedSubCategory, setSelectedSubCategory] = useState();
  const [categories, setCategories] = useState([
    'Corona Virus',
    'Safety',
    'Environment'
  ]);
  const [subCategories, setSubCategories] = useState([
    'Tablet',
    'Testing',
    'Pollution'
  ]);
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [embedLink, setEmbedLink] = useState('');
  const [totalTime, setTotalTime] = useState();
  const [rewards, setRewards] = useState();
  const [docType, setDocType] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [formValid, setFormValid] = useState(false);
  const [show, setShow] = useState(false);
  const [addCat, setAddCat] = useState();
  const [mode, setMode] = useState('cat');
  const [loading, setLoading] = useState(false);
  const [embedLinkError, setEmbedLinkError] = useState('');

  const [isVideo, setIsVideo] = useState(false);

  useEffect(() => {
    if (selectedFile) {
      if (!validateFileSize(selectedFile) || !validateFileType(selectedFile)) {
        setSelectedFile();
      }
    }
    if (embedLink) {
    }
  }, [selectedFile]);

  useEffect(() => {
    let valid = true;
    valid =
      selectedCategory &&
      selectedSubCategory &&
      (selectedFile || embedLink) &&
      // selectedFile &&
      // embedLink &&
      valid;
    setFormValid(valid);
  }, [selectedFile, selectedCategory, selectedSubCategory, embedLink, docType]);

  const submitHandler = async (val, helpers) => {
    //e.preventDefault();
    let data = {};
    data.cmp_id = props.company_id;
    data.topic = selectedCategory;
    data.document = {
      desc: val.description,
      rewards: Number(val.rewards),
      title: val.title,
      totaltime: Number(val.time),
      type: Number(docType),
      subcat: selectedSubCategory,
      filename:
        Number(val.docType) === 4 ? embedLink : val.selectedFile?.name,
      item_url: Number(val.docType) === 4 ? embedLink : ''
    };
    console.log('submitHandler', { data });
    await uploadLearning(data);
    helpers.resetForm();
  };

  const uploadLearning = async (data) => {
    setLoading(true);
    try {
      let response = await API.uploadLearning(data);
      console.log('uploadLearning', { data, response });
      let file = selectedFile;
      let filePath = response.data.data;
      let fileRef = storage.ref(filePath);
      await fileRef.put(file);
      resetData();
      props.handleClose();
      swal({
        title: 'Success',
        text: ' Learning uploaded Successfully ',
        icon: 'success',
        button: 'Ok'
      });
    } catch (err) {
      console.log('createNotice err', { err });
      swal({
        title: 'Error',
        text: err.message,
        icon: 'error',
        button: 'Ok'
      });
    } finally {
      setLoading(false);
    }
  };

  const resetData = () => {
    setSelectedCategory();
    setSelectedSubCategory();
    setCategories(['Corona Virus', 'Safety', 'Environment']);
    setSubCategories(['Tablet', 'Testing', 'Pollution']);
    // setTitle();
    // setDescription();
    // setTotalTime();
    // setRewards();
    setDocType();
    setSelectedFile();
    setFormValid(false);
    setShow(false);
    setEmbedLinkError('');
    setEmbedLink();
    //setAddCat();
    setMode('cat');
  };

  const validateFileSize = (file) => {
    let fileSize = file.size / 1024 / 1024;
    if (fileSize > 10) {
      swal({
        title: file.name,
        text: 'Sorry, Max file size allowed is 10MB',
        icon: 'error',
        button: 'Ok'
      });
      return false;
    } else {
      return true;
    }
  };

  const handleModal = (mode) => {
    setShow(!show);
    if (mode) {
      setMode(mode);
    }
    catFormik.resetForm();
  };

  const validateFileType = (file) => {
    let pattern = /(jpg|jpeg|png|pdf|mp4|wmv|avi|mkv|flv|webm|gif|m4p|svi)$/;
    if (docType) {
      switch (docType) {
        case '1':
          pattern = /(jpg|jpeg|png)$/;
          break;

        case '2':
          pattern = /(mp4|wmv|avi|mkv|flv|webm|gif|m4p|svi)$/;
          break;

        case '3':
          pattern = /(pdf)$/;
          break;
        default:
          break;
      }
      if (!file.type.toLowerCase().match(pattern)) {
        swal({
          title: file.name,
          text: 'Please select Image, PDF and Excel files only!',
          icon: 'error',
          button: 'Ok'
        });
        return false;
      } else {
        return true;
      }
    } else {
      swal({
        title: 'Error',
        text: 'Please select document type!',
        icon: 'error',
        button: 'Ok'
      });
      return false;
    }
  };

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      time: '',
      rewards: '',
      docType: '',
    },
    onSubmit: submitHandler,
    validationSchema: Yup.object({
      title: Yup.string()
        .min(2, 'Title must be at least 2 characters')
        .trim('Title is required')
        .required('Title is required'),
      description: Yup.string()
        .min(2, 'Description must be at least 2 characters')
        .trim('Description is required')
        .required('Description is required'),
      time: Yup.number()
        .typeError('Time must be a number')
        .test(
          'isGT0',
          'Time should be greater than 0',
          (val) => !!val && val > 0
        )
        .required()
        .label('Time'),
      rewards: Yup.number()
        .typeError('Rewards must be a number')
        .test(
          'isGrT0',
          'Rewards should be greater than 0',
          (val) => !!val && val > 0
        )
        .required()
        .label('Rewards')
    })
  });

  const catFormik = useFormik({
    initialValues: {
      text: ''
    },
    onSubmit: () => {
      if (mode === 'cat') {
        setCategories([...categories, catFormik.values.text]);
        //setAddCat('');
      } else {
        setSubCategories([...subCategories, catFormik.values.text]);
        //setAddCat('');
      }
      catFormik.resetForm();
      //setAddCat('');
    },
    validationSchema: Yup.object({
      text: Yup.string()
        .matches(/^[a-zA-Z0-9\s]+$/, 'Special characters are not allowed')
        .test(
          'isAlreadyUsed',
          `${mode === 'cat' ? 'Category' : 'Sub Category'} already exists`,
          (val) =>
            !!val
              ? mode === 'cat'
                ? !categories.some((cat) => val === cat)
                : !subCategories.some((cat) => val === cat)
              : true
        )
        .min(3, 'Category must be at least 3 characters')
        .trim('Category is required')
        .required('Category is required')
    })
  });

  return (
    <>
      {loading ? <OverlaySpinner /> : null}
      <Modal
        show={props.show}
        onHide={() => {
          props.handleClose();
          resetData();
          catFormik.resetForm();
        }}
        backdrop="static"
        keyboard="false"
        size="xl"
        centered
      >
        <Modal.Header>
          <Modal.Title>Upload Learning </Modal.Title>
          <Button
            variant="primary"
            className="add-document-close"
            onClick={() => {
              props.handleClose();
              formik.resetForm();
              resetData();
            }}
          ></Button>
          <h5
            className="add-document-close"
            onClick={() => {
              props.handleClose();
              formik.resetForm();
              resetData();
            }}
          >
            <Close />
          </h5>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-4 my-3">
              <div className={classes.formControl}>
                <FormControl variant="outlined" className={classes.selectWrap}>
                  <Select
                    style={{ width: '100%' }}
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    displayEmpty
                    fullWidth
                    defaultValue=""
                    className={classes.selectEmpty}
                    id="Category"
                  >
                    <MenuItem
                      style={{
                        maxWidth: '400px'
                      }}
                      classes={{ root: listClasses.root }}
                      value=""
                    >
                      Select Category
                    </MenuItem>
                    {categories.map((cat) => (
                      <MenuItem
                        style={{
                          maxWidth: '400px'
                        }}
                        classes={{ root: listClasses.root }}
                        value={cat}
                      >
                        {cat}
                      </MenuItem>
                    ))}
                  </Select>

                  {/* <FormHelperText>Without label</FormHelperText> */}
                </FormControl>
                <Button
                  type="button"
                  className=" modal-submit-btn"
                  onClick={() => handleModal('cat')}
                  style={{ textTransform: 'none' }}
                  color="primary"
                  // disabled={!formValid}
                >
                  Add
                </Button>
              </div>
            </div>
            <div className="col-4 my-3">
              <div className={classes.formControl}>
                <FormControl variant="outlined" className={classes.selectWrap}>
                  <Select
                    // style={{ width: '100%' }}
                    style={{ width: '100%' }}
                    value={selectedSubCategory}
                    onChange={(e) => setSelectedSubCategory(e.target.value)}
                    displayEmpty
                    fullWidth
                    defaultValue=""
                    className={classes.selectEmpty}
                    // autoWidth
                    id="Sub-Category"
                  >
                    <MenuItem
                      style={{
                        maxWidth: '400px'
                      }}
                      classes={{ root: listClasses.root }}
                      value=""
                    >
                      Select Sub Category
                    </MenuItem>
                    {subCategories.map((cat) => (
                      <MenuItem
                        style={{
                          maxWidth: '400px'
                        }}
                        classes={{ root: listClasses.root }}
                        value={cat}
                      >
                        {cat}
                      </MenuItem>
                    ))}
                  </Select>

                  {/* <FormHelperText>Without label</FormHelperText> */}
                </FormControl>
                <Button
                  // variant="primary"
                  type="button"
                  className="modal-submit-btn"
                  onClick={() => handleModal('sub-cat')}
                  style={{ textTransform: 'none' }}
                  color="primary"
                  // disabled={!formValid}
                >
                  Add
                </Button>
              </div>
            </div>
            <div className="col-4 my-3">
              <FormControl variant="outlined" className="w-100">
                <TextField
                  // value={title}
                  // onChange={(e) => setTitle(e.target.value)}
                  value={formik.values.title}
                  fullWidth
                  onChange={formik.handleChange('title')}
                  onBlur={formik.handleBlur('title')}
                  id="title"
                  label="Title"
                  // className="form-control title-learning"
                  variant="outlined"
                />
              </FormControl>
              <div>
                <small className="text-danger form-text">
                  {formik.touched.title && formik.errors.title}
                </small>
                <br />
              </div>
            </div>
          </div>

          <div className="col-md-12 my-4">
            <FormControl variant="outlined" className={'w-100'}>
              <TextField
                // value={description}
                // onChange={(e) => setDescription(e.target.value)}
                value={formik.values.description}
                onChange={formik.handleChange('description')}
                onBlur={formik.handleBlur('description')}
                id="Description"
                label="Description"
                multiline
                rows={3}
                className={classes.selectEmpty + classes.formControl}
                variant="outlined"
              />
              <div>
                <small className="text-danger form-text">
                  {formik.touched.description && formik.errors.description}
                </small>
                <br />
              </div>
            </FormControl>
          </div>
          <div className="row">
            <div className="col-3 my-3">
              <FormControl variant="outlined" className={'w-100'}>
                <TextField
                  // value={totalTime}
                  // onChange={(e) => setTotalTime(e.target.value)}
                  value={formik.values.time}
                  onChange={formik.handleChange('time')}
                  onBlur={formik.handleBlur('time')}
                  type="number"
                  id="totaltime"
                  label="Time in seconds"
                  multiline
                  className={classes.selectEmpty + classes.formControl}
                  variant="outlined"
                />
                <div>
                  <small className="text-danger form-text">
                    {formik.touched.time && formik.errors.time}
                  </small>
                  <br />
                </div>
              </FormControl>
            </div>
            <div className="col-3 my-3">
              <FormControl variant="outlined" className={'w-100'}>
                <TextField
                  // value={rewards}
                  // onChange={(e) => setRewards(e.target.value)}
                  value={formik.values.rewards}
                  onChange={formik.handleChange('rewards')}
                  onBlur={formik.handleBlur('rewards')}
                  type="number"
                  id="rewards"
                  label="Rewards"
                  multiline
                  className={classes.selectEmpty + classes.formControl}
                  variant="outlined"
                />
                <div>
                  <small className="text-danger form-text">
                    {formik.touched.rewards && formik.errors.rewards}
                  </small>
                  <br />
                </div>
              </FormControl>
            </div>
            <div className="col-2 my-3">
              <FormControl variant="outlined" className={'w-100'}>
                <InputLabel variant="outlined">Document Type</InputLabel>
                <Select
                  value={docType}
                  onChange={(e) => {
                    setSelectedFile();
                    setDocType(e.target.value);
                  }}
                  displayEmpty
                  className={classes.selectEmpty + classes.formControl}
                  fullWidth
                  id="document-type"
                >
                  <MenuItem
                    style={{
                      maxWidth: '400px'
                    }}
                    classes={{ root: listClasses.root }}
                    value={1}
                  >
                    Image
                  </MenuItem>
                  <MenuItem
                    style={{
                      maxWidth: '400px'
                    }}
                    classes={{ root: listClasses.root }}
                    value={2}
                  >
                    Video
                  </MenuItem>
                  <MenuItem
                    style={{
                      maxWidth: '400px'
                    }}
                    classes={{ root: listClasses.root }}
                    value={3}
                  >
                    PDF
                  </MenuItem>
                  <MenuItem
                    style={{
                      maxWidth: '400px'
                    }}
                    classes={{ root: listClasses.root }}
                    value={4}
                  >
                    Youtube link
                  </MenuItem>
                </Select>
                {/* <FormHelperText>Without label</FormHelperText> */}
              </FormControl>
            </div>

            {docType != '4' ? (
              <div className="col-4 my-3">
                <div className="d-flex justify-content-start align-items-start">
                  {!selectedFile && (
                    <Button variant="" className="modal-submit-btn">
                      <input
                        id="file-input"
                        type="file"
                        color="primary"
                        className="file-input-icon"
                        multiple
                        required
                        onChange={(e) => {
                          console.log('fileHandler', e.target.files[0]);
                          setSelectedFile(e.target.files[0]);
                        }}
                        accept={
                          !docType
                            ? 'image/*, video/*, application/pdf'
                            : Number(docType) === 1
                            ? 'image/*'
                            : Number(docType) === 2
                            ? 'video/*'
                            : 'application/pdf'
                        }
                      />
                      <label for="file-input" className="file response-file">
                        <AttachFileIcon />
                      </label>
                    </Button>
                  )}
                  {selectedFile && (
                    <FileDisplay
                      file={selectedFile}
                      handleDelete={() => {
                        setSelectedFile();
                      }}
                      style={{
                        margin: '0rem .5rem 0rem 0'
                      }}
                    />
                  )}
                  {/* <div className="col-2 my-3">
                    {selectedFile ? (
                      <>
                        <div className="d-flex flex-row justify-content-center align-items-center">
                          <div>
                            <span>File : {selectedFile.name}</span>
                          </div>
                          <div>
                            <span
                              style={{ color: 'red', cursor: 'pointer' }}
                              onClick={() => {
                                setSelectedFile();
                              }}
                            >
                              <Close />
                            </span>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div> */}
                </div>
              </div>
            ) : null}
          </div>
          {docType == '4' ? (
            <div className="col-5 my-3">
              <FormControl variant="outlined" className={'w-100'}>
                <TextField
                  value={embedLink}
                  onChange={(e) => {
                    if (
                      /^https:\/\/www.youtube.com\/embed\/.*$/.test(
                        e.target.value
                      )
                    ) {
                      setEmbedLink(e.target.value);
                      setEmbedLinkError('');
                    } else {
                      setEmbedLinkError('Link is not valid');
                    }
                  }}
                  type="text"
                  id="embedLink"
                  error={embedLinkError}
                  helperText={embedLinkError}
                  label="Youtube Embed link"
                  className={classes.selectEmpty + classes.formControl}
                  variant="outlined"
                />
              </FormControl>
            </div>
          ) : null}

          <div className="d-flex justify-content-center">
            <Button
              // variant="primary"
              type="button"
              className="modal-submit-btn"
              onClick={formik.handleSubmit}
              color="primary"
              disabled={!formValid || Object.values(formik.errors).length > 0}
            >
              Submit
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show}
        onHide={handleModal}
        backdrop="static"
        keyboard="false"
        size="xl"
        centered
      >
        <Modal.Header>
          <Modal.Title>
            Add {mode === 'cat' ? 'Category' : 'Sub-Category'}
          </Modal.Title>
          <Button
            variant="primary"
            className="add-document-close"
            onClick={() => {
              handleModal();
            }}
          ></Button>
          <h6
            className="add-document-close"
            onClick={() => {
              handleModal();
            }}
          >
            <Close />
          </h6>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-6 d-flex justify-content-center align-items-center flex-column">
              <FormControl variant="outlined" className={'w-100'}>
                <TextField
                  // value={addCat}
                  // onChange={(e) => setAddCat(e.target.value)}
                  value={catFormik.values.text}
                  onChange={catFormik.handleChange('text')}
                  onBlur={catFormik.handleBlur('text')}
                  id="Category"
                  label="Category"
                  className={classes.selectEmpty + classes.formControl}
                  variant="outlined"
                />

                <small className="text-danger form-text">
                  {catFormik.touched.text && catFormik.errors.text}
                </small>
              </FormControl>

              <Button
                // variant="primary"
                type="button"
                className="modal-submit-btn mt-3 mx-auto"
                style={{ width: '10%' }}
                value={catFormik.values.text}
                onClick={catFormik.handleSubmit}
                color="primary"
                disabled={!catFormik.values.text}
              >
                Add
              </Button>
            </div>
            <div className="col-6">
              <span style={{ fontSize: '18px', fontWeight: 'bolder' }}>
                All {mode === 'cat' ? 'Categories' : 'Sub-Categories'}
              </span>
              <ul style={{ listStyle: 'none', padding: 0 }}>
                {mode === 'cat'
                  ? categories.map((value) => (
                      <li className="d-flex justify-content-between align-items-center my-1">
                        {value}
                        <Button
                          variant=""
                          style={{ color: 'red', padding: '2px' }}
                          className="modal-submit-btn"
                          onClick={() => {
                            let cats = [...categories];
                            var carIndex = cats.indexOf(value);
                            cats.splice(carIndex, 1);
                            setCategories(cats);
                          }}
                        >
                          <Close style={{ fontSize: '15px' }} />
                        </Button>
                      </li>
                    ))
                  : subCategories.map((value) => (
                      <li className="d-flex justify-content-between align-items-center my-1">
                        {value}
                        <Button
                          variant=""
                          style={{ color: 'red', padding: '2px' }}
                          className="modal-submit-btn"
                          onClick={() => {
                            let cats = [...subCategories];
                            var carIndex = cats.indexOf(value);
                            cats.splice(carIndex, 1);
                            setSubCategories(cats);
                          }}
                        >
                          <Close style={{ fontSize: '15px' }} />
                        </Button>
                      </li>
                    ))}
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddLearning;

import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Card,
  Divider,
  CardHeader,
  Grid
} from '@material-ui/core';
import Caplist from 'src/components/engagement/Caplist';
import customers from 'src/__mocks__/customers';
import Leaderboard from 'src/components/engagement/Leaderboard';
import CapDetails from 'src/components/engagement//CapDetails';
import CapDetails2 from 'src/components/engagement//CapDetails2';
import CapDetails3 from 'src/components/engagement//CapDetails3';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Engagement/engagement.css';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import API from '../Api';
import OverlaySpinner from 'src/components/common/OverlaySpinner';
import { roundTwoDecimals } from 'src/Constants';
const Engagement = () => {
  const { userData } = useSelector((state) => state.auth);
  const [capDetails, setCapDetails] = useState([]);
  const [RedCapDetails, setRedCapDetails] = useState([]);
  const [GreenCapDetails, setGreenCapDetails] = useState([]);
  const [OrangeCapDetails, setOrangeCapDetails] = useState([]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (userData) {
      fetchCapDetails();
    }
  }, [userData]);

  const fetchCapDetails = async () => {
    setLoading(true);
    const companyId = userData.cmp_id ?? userData.companyId ?? userData.assoc_companies[0];
    const data = {};
    data.companyId = companyId;
    let response = await API.fetchCapDetails(data);
    console.log('fetchCapDetails', response.data.data);
    

    setCapDetails(response.data.data);
    const RedCap = [];
    const OrangeCap = [];
    const GreenCap = [];

    response.data.data.map((item) => {
      if (roundTwoDecimals((item.scored / item.total) * 100) >= 70) {
        GreenCap.push(item);
      } else if (
        roundTwoDecimals((item.scored / item.total) * 100) >= 40 &&
        roundTwoDecimals((item.scored / item.total) * 100) < 70
      ) {
        OrangeCap.push(item);
      } else {
        RedCap.push(item);
      }
    });
    setRedCapDetails(RedCap);
    setOrangeCapDetails(OrangeCap);
    setGreenCapDetails(GreenCap);

    setLoading(false);
  };
  return loading ? (
    <OverlaySpinner />
  ) : (
    <>
      <Helmet>
        <title>Engagement | Oren</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <h2>
            <ul class="breadcrumb">
              <li>
                <a href="Engagement">Engagement</a>
              </li>
            </ul>
          </h2>
          {/* <h2>Tabs</h2>
      <p>Click on the buttons inside the tabbed menu:</p>
      <div className="tab">
        <button type="button" className="tablinks" onClick={(ev) => shoot(ev, 'London')}>London</button>
        <button type="button" className="tablinks" onClick={(ev) => shoot(ev, 'Paris')}>Paris</button>
        <button type="button" className="tablinks" onClick={(ev) => shoot(ev, 'Tokyo')}>Tokyo</button>
      </div> */}
          {/* <div id="London" className="tabcontent">
        <h3>London</h3>
        <p>London is the capital city of England.</p>
      </div>
      <div id="Paris" className="tabcontent">
        <h3>Paris</h3>
        <p>Paris is the capital of France.</p>
      </div>
      <div id="Tokyo" className="tabcontent">
        <h3>Tokyo</h3>
        <p>Tokyo is the capital of Japan.</p>
      </div> */}
          <div className="row" container spacing={3}>
            <div className="col-md-7">
              <div className="row">
                <div className="col-md-4">
                  <Grid className="text-center">
                    <CapDetails
                      RedCap={RedCapDetails}
                      viewDetails={() => setCapDetails(RedCapDetails)}
                    />
                  </Grid>
                </div>
                <div className="col-md-4">
                  <Grid className="text-center">
                    <CapDetails2
                      OrangeCap={OrangeCapDetails}
                      viewDetails={() => setCapDetails(OrangeCapDetails)}
                    />
                  </Grid>
                </div>
                <div className="col-md-4 text-center">
                  <Grid className="text-center">
                    <CapDetails3
                      GreenCap={GreenCapDetails}
                      viewDetails={() => setCapDetails(GreenCapDetails)}
                    />
                  </Grid>
                </div>
                <Grid>
                  <Caplist CapList={capDetails} />
                </Grid>
              </div>
            </div>
            <div className="col-md-5 " id="leader-board">
              <CardHeader title="Leaderboard" />
              <Divider />

              <Grid className="text-center">
                <Leaderboard customers={customers} />
              </Grid>
            </div>
          </div>
        </Container>
      </Box>
    </>
  );
};

export default Engagement;

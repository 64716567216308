import { Box, Card, CardContent } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import PublishIcon from '@material-ui/icons/Publish';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Formik } from 'formik';
import * as Yup from 'yup';
import './add_auditor.css';
import SingleFileUpload from '../common/SingleFileUpload';
import { contractorrAdded } from '../../store/auditors/thunk';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  contractCodeCheck,
  contractEmailCheck,
  contractNameCheck,
  contractPhoneCheck
} from '../common/DuplicationCheck';
import swal from 'sweetalert';
import { setLoading } from 'src/store/auth/actions';
import OverlaySpinner from '../common/OverlaySpinner';
import ProfileUpload from '../common/ProfileUpload';

const ViewCompany = (props) => {
  const Navigate = useNavigate();

  const [image, setImage] = useState([]);
  const { userData, loading } = useSelector((state) => state.auth);
  const { currentCompany } = useSelector((state) => state.auditors);
  const [selectedImage, setSelectedImage] = useState({
    isDeleted: false,
    file: null
  });
  const [imageChange, setImageChange] = useState(false);
  useEffect(() => {
    setSelectedImage({ isDeleted: false, file: currentCompany.profileImage });
  }, []);
  const dispatch = useDispatch();

  const onSubmit = async (val) => {
    //console.log('Values>>', val);
    dispatch(setLoading(true));

    val.cmp_id = userData.companyId;
    val.cmp_name = userData.name;
    val.mobile = '+91' + val.mobile;
    dispatch(contractorrAdded(val, Navigate));
    dispatch(setLoading(false));
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '75%'
      }
    }
  }));
  const classes = useStyles();
  return (
    <>
      {loading && <OverlaySpinner />}
      <div style={{ marginTop: '2%', marginLeft: '1%' }} className="row">
        <div className="col-md-6">
          <h2>
            <ul class="breadcrumb">
              <li>
                <Link to="/sapphire/companies">Users</Link>
              </li>
              <li>
                <Link to="">View Company</Link>
              </li>
            </ul>
          </h2>
        </div>
      </div>
      <Box
        {...props}
        style={{
          backgroundColor: 'white',
          marginLeft: '2%',
          marginRight: '2%'
        }}
      >
        <Box>
          <Card>
            <CardContent>
              <div className="row">
                <Formik
                  initialValues={
                    currentCompany
                      ? {
                          code: currentCompany.code,
                          name: currentCompany.name,
                          mobile: currentCompany.mobile.substring(3),
                          emailid:
                            currentCompany.emailid || currentCompany.emailId,
                          location: currentCompany.location,
                          contactPersonName: currentCompany.contactPersonName
                        }
                      : null
                  }
                  onSubmit={(val) => onSubmit(val)}
                  validationSchema={Yup.object({
                    code: Yup.string()
                      .required('Code is required')
                      .matches(/^[A-Za-z0-9_-]*$/, 'Code is invalid'),
                    name: Yup.string()
                      .required('Name is required')
                      .matches(/^[A-Za-z/' ]+$/, 'Name is invalid'),
                    location: Yup.string()
                      .required('Location is required')
                      .matches(/^\w{5,}$/, 'Location is invalid'),
                    emailid: Yup.string()
                      .required('Email is required')
                      .email('Please enter valid email'),
                    mobile: Yup.string()
                      .required('Phone no is required')
                      .min(10, 'Phone no is invalid')
                      .max(10, 'Phone no is invalid'),
                    contactPersonName: Yup.string()
                      .required('Name is required')
                      .matches(/^[A-Za-z/' ]+$/, 'Name is invalid')
                  })}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    errors,
                    touched,
                    setFieldTouched,
                    setFieldValue,
                    setFieldError
                  }) => (
                    <>
                      <form className={classes.root} onSubmit={handleSubmit}>
                        <div className="col-md-12">
                          <h2>View Company</h2>
                          <div className="image-btn my-4">
                            <ProfileUpload
                              handleFileChanges={(file) => {
                                setImage(file);
                              }}
                              defaultFile={
                                selectedImage.file?.fileName == ''
                                  ? null
                                  : selectedImage.file
                              }
                              handleUploadedFileDelete={() => {
                                setSelectedImage({
                                  isDeleted: true,
                                  file: null
                                });
                                setImageChange(true);
                              }}
                              disableIcon={true}
                              acceptExt={'.jpg, .png'}
                            />
                          </div>
                          <div className="row">
                            <div className="col-md-5">
                              {/* <div>
                              <TextField
                                disabled
                                id="standard-basic"
                                label="Company Code"
                                variant="standard"
                                className="my-2 form-control"
                                onChange={handleChange('code')}
                                value={values.code}
                                onFocus={() => setFieldTouched('code')}
                              />
                              <small className="text-danger form-text">
                                {touched.code && errors.code}
                              </small>
                              <br />
                            </div> */}
                              <div>
                                <div>
                                  <TextField
                                    disabled
                                    id="standard-basic"
                                    label="Location"
                                    variant="standard"
                                    className="my-2 form-control"
                                    onChange={handleChange('location')}
                                    value={values.location}
                                    onFocus={() => setFieldTouched('location')}
                                  />
                                </div>
                                <div>
                                  <small className="text-danger form-text">
                                    {touched.location && errors.location}
                                  </small>

                                  <br />
                                </div>
                              </div>
                              <div>
                                <div>
                                  <TextField
                                    disabled
                                    id="standard-basic"
                                    label="Email"
                                    variant="standard"
                                    className="my-2 form-control"
                                    onChange={handleChange('emailid')}
                                    value={values.emailid}
                                    onFocus={() => setFieldTouched('emailid')}
                                  />
                                </div>
                                <div>
                                  <small className="text-danger form-text">
                                    {touched.emailid && errors.emailid}
                                  </small>
                                  <br />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-1"></div>
                            <div className="col-md-5">
                              <div>
                                <TextField
                                  disabled
                                  id="standard-basic"
                                  label="Company Name"
                                  variant="standard"
                                  className="my-2 form-control"
                                  onChange={handleChange('name')}
                                  value={values.name}
                                  onFocus={() => setFieldTouched('name')}
                                />
                              </div>
                              <div>
                                <small className="text-danger form-text">
                                  {touched.name && errors.name}
                                </small>
                                <br />
                              </div>

                              <div>
                                <TextField
                                  disabled
                                  id="standard-basic"
                                  label="Phone Number"
                                  variant="standard"
                                  className="my-2 form-control"
                                  onChange={handleChange('mobile')}
                                  value={values.mobile}
                                  onFocus={() => setFieldTouched('mobile')}
                                />
                              </div>
                              <div>
                                <small className="text-danger form-text">
                                  {touched.mobile && errors.mobile}
                                </small>
                                <br />
                              </div>

                              <div>
                                <TextField
                                  disabled
                                  id="standard-basic"
                                  label="Contact Person Name"
                                  variant="standard"
                                  className="my-2 form-control"
                                  onChange={handleChange('contactPersonName')}
                                  value={values.contactPersonName}
                                  onFocus={() =>
                                    setFieldTouched('contactPersonName')
                                  }
                                />
                              </div>
                              <div>
                                <small className="text-danger form-text">
                                  {touched.contactPersonName &&
                                    errors.contactPersonName}
                                </small>
                                <br />
                              </div>
                            </div>
                          </div>
                          {/* <div className="addbutton my-2">
                          <Button
                            disabled
                            className="modal-submit-btn"
                            className="view-btn"
                            color="primary"
                            size="small"
                            onClick={handleSubmit}
                          >
                            Add
                          </Button>
                        </div> */}
                        </div>
                      </form>
                    </>
                  )}
                </Formik>
              </div>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default ViewCompany;

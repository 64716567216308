import {
    SET_LOADING,
    SET_ALL_AUDITORS,
    SET_ALL_CONTRACTORS,
    SET_CURRENT_AUDITOR,
    SET_CURRENT_COMPANY,
    SET_ALLDATA
} from './types'


export const setAllAuditors = (auditors) => {
  return {
    type: SET_ALL_AUDITORS,
    payload: auditors,
  }
}

export const setAllContractors = (companies) => {
  return {
    type: SET_ALL_CONTRACTORS,
    payload: companies,
  }
}

export const setLoading = loading => {
    return {
      type: SET_LOADING,
      payload: loading,
    }
}

export const setCurrentAuditor = data => {
  return {
    type: SET_CURRENT_AUDITOR,
    payload: data,
  }
}

export const setCurrentCompany = data => {
  return {
    type: SET_CURRENT_COMPANY,
    payload: data,
  }
}

export const setAllDataEmpty = data =>{
  return {
    type: SET_ALLDATA,
    payload: data,
  }
}
import { ThemeProvider } from '@material-ui/core';
import axios from 'axios';
import { useEffect } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useDispatch } from 'react-redux';
import { useNavigate, useRoutes } from 'react-router-dom';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import routes from 'src/routes';
import theme from 'src/theme';
import swal from 'sweetalert';
import * as actions from '../src/store/auth/actions';
import API from './Api';
import { USER_TYPES } from './Constants';
import { auth } from './firebase';

swal.setDefaults({
  closeOnClickOutside: false
})

String.prototype.onlyFirstCapital = function () {
  return this.charAt(0).toUpperCase() + this.substring(1).toLowerCase();
};

// let baseUrl = "http://localhost:5001/oleo-6b2af/us-central1/";
let baseUrl = process.env.REACT_APP_BACKEND_BASE;
// process.env.REACT_APP_BACKEND_BASE
axios.defaults.baseURL = baseUrl;

const AutoUserLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      try {
        if (user) {
          dispatch(actions.setLoading(true));

          console.log('USER>>>', user);
          //dispatch(actions.loginUser(user))

          switch (user.displayName) {
            case USER_TYPES.SUPER_ADMIN:
              break;
            case USER_TYPES.COMPANY:
              await API.fetchCompanyById({ companyId: user.uid }).then(
                (res) => {
                  console.log('RES DATA', res.data);
                  //save data into redux
                  dispatch(actions.loginUser(res.data.data));
                }
              );
              break;
            case USER_TYPES.CONTRACTOR:
              await API.fetchContractorById({ contractorId: user.uid }).then(
                (res) => {
                  console.log('RES DATA', res.data);
                  //save data into redux
                  dispatch(actions.loginUser(res.data.data));
                }
              );
              break;
            case USER_TYPES.TPI:
              await API.fetchTpiById({ tpiId: user.uid }).then((res) => {
                console.log('RES DATA', res.data);
                //save data into redux
                dispatch(actions.loginUser(res.data.data));
              });
              break;
            case USER_TYPES.EMPLOYEE:
              break;
            default:
              await auth.signOut();
              dispatch(actions.setLoading(false));
              navigate('/', { replace: true });
              break;
          }
          localStorage.setItem('isSignedIn', true);
        } else {
          console.log('Logging out');
          dispatch(actions.setLoading(false));
          localStorage.setItem('isSignedIn', false);
          // dispatch(logOut());
          // navigate('/', { replace: true });
        }
      } catch (err) {
        console.log('Error', err.message);
        localStorage.setItem('isSignedIn', false);
      } finally {
        dispatch(actions.setLoading(false));
      }
    });
  }, []);
  return <></>;
};

const App = () => {
  const routing = useRoutes(routes);

  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV=="production") {
      console.log = function () {};
    }

    return () => {};
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <AutoUserLogin />
        <GlobalStyles />
        {routing}
      </ThemeProvider>
    </>
  );
};

export default App;

import moment from 'moment';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography
} from '@material-ui/core';
import { useSelector } from 'react-redux';

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith',
  timezone: 'GTM-7'
};

const AccountProfile = (props) => {
  const { userData } = useSelector((state) => state.auth);

  return (
    <Card {...props}>
      <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Avatar
            src={
              userData?.profileImage?.fileLink
                ? userData?.profileImage?.fileLink
                : null
            }
            sx={{
              height: 100,
              width: 100
            }}
            imgProps={{
              style: {
                objectFit: 'contain'
              }
            }}
          />
          <Typography color="textPrimary" gutterBottom variant="h3">
            {userData.name}
          </Typography>
          <Typography color="textPrimary" gutterBottom variant="h3">
            {userData.email}
          </Typography>
          <Typography color="textPrimary" gutterBottom variant="h3">
            {userData.phoneNo || userData.mobile}
          </Typography>
          <Typography color="textPrimary" gutterBottom variant="h3">
            {userData.location}
          </Typography>
          {/* <Typography
          color="textSecondary"
          variant="body1"
        >
          {`${user.city} ${user.country}`}
        </Typography> */}
          {/* <Typography
          color="textSecondary"
          variant="body1"
        >
          {`${moment().format('hh:mm A')} ${user.timezone}`}
        </Typography> */}
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Button color="primary" fullWidth variant="text">
          Upload picture
        </Button>
      </CardActions>
    </Card>
  );
};
export default AccountProfile;

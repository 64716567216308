import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

let firebaseConfig

const firebaseConfigTest = {
    apiKey: "AIzaSyCnawoFCPQ9_dXhAN2F3JNKytZmALEUq4Q",
    authDomain: "oleo-test.firebaseapp.com",
    databaseURL: "https://oleo-test.firebaseio.com",
    projectId: "oleo-test",
    storageBucket: "oleo-test.appspot.com",
    messagingSenderId: "943565173119",
    appId: "1:943565173119:web:c1d41760205146a4b9aa4a",
    measurementId: "G-01QKF6EK1L"
};

const firebaseConfigProd= {
    apiKey: "AIzaSyCM3I__TjrrVLIIJGT1jV8Z7rLgPCZ41BI",
  authDomain: "oleo-6b2af.firebaseapp.com",
  databaseURL: "https://oleo-6b2af.firebaseio.com",
  projectId: "oleo-6b2af",
  storageBucket: "oleo-6b2af.appspot.com",
  messagingSenderId: "145456652364",
  appId: "1:145456652364:web:46faffefcaa9a17a5b14e9",
  measurementId: "G-L2QDQ49J2E"
};

if(process.env.REACT_APP_NODE_ENV=="production"){
    firebaseConfig = firebaseConfigProd
}else{
    firebaseConfig = firebaseConfigTest
}

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const storage = firebase.storage();
export const firestore = firebase.firestore();
export default firebase;
import { Helmet } from 'react-helmet';
import {
  Box,
  Card,
  Container,
  Button,
  Tabs,
  Tab,
  createStyles,
  makeStyles
} from '@material-ui/core';
import Auditors from 'src/components/users/auditors';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import OverlaySpinner from 'src/components/common/OverlaySpinner';
import { CSVLink } from 'react-csv';
import moment from 'moment';

const headers = [
  { label: 'Organization', key: 'name' },
  { label: 'Mobile', key: 'phoneNo' },
  { label: 'E-mail', key: 'email' },
  { label: 'Contact Person', key: 'contactPersonName' }
];

const useStyles = makeStyles((theme) =>
  createStyles({
    scroller: {
      '& > .MuiTabs-scroller  > .MuiTabs-flexContainer': {
        // border: '1px solid black'
      }
    }
  })
);

const Users = () => {
  const [type, setType] = useState('auditors');
  const { loading } = useSelector((state) => state.auditors);
  const Navigate = useNavigate();
  const classes = useStyles();
  const { allAuditors } = useSelector((state) => state.auditors);
  return (
    <>
      {loading && <OverlaySpinner />}
      <>
        <Helmet>
          <title>Users | Oren</title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          <Container maxWidth={false}>
            <div className="row">
              <div className="d-flex flex-row justify-content-center align-items-center">
                <Tabs
                  // value={value}
                  // indicatorColor="primary"
                  // textColor="primary"
                  // onChange={handleChange}
                  // aria-label="disabled tabs example"
                  // className="users-tab"
                  className={classes.scroller}
                >
                  <Tab
                    className="view-btn"
                    style={{
                      minWidth: 0,
                      minHeight: 0,
                      padding: '.9rem 2.2rem',
                      boxSizing: 'border-box',
                      borderTop: '1px solid #000000a6',
                      borderLeft: '1px solid #000000a6',
                      borderBottom: '1px solid #000000a6'
                    }}
                    onClick={() => Navigate('/sapphire/users')}
                    label="Auditors"
                  />
                  <Tab
                    style={{
                      minWidth: 0,
                      minHeight: 0,
                      boxSizing: 'border-box',
                      padding: '.9rem 2rem',
                      borderTop: '1px solid #000000a6',
                      borderRight: '1px solid #000000a6',
                      borderBottom: '1px solid #000000a6'
                    }}
                    className="tab-title"
                    onClick={() => Navigate('/sapphire/companies')}
                    label="Company"
                  />
                </Tabs>
              </div>
              <div className="col-md-6">
                <h2>
                  <ul class="breadcrumb">
                    <li>
                      <Link to="/sapphire/users">Auditors</Link>
                    </li>
                  </ul>
                </h2>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-row justify-content-end align-items-center">
                  <div style={{ marginRight: '2%' }}>
                    <CSVLink
                      filename={`Auditors_${moment().format('DD-MM-YYYY')}.csv`}
                      data={allAuditors}
                      headers={headers}
                    >
                      <Button
                        style={{ float: 'right' }}
                        className="modal-submit-btn view-btn"
                        color="primary"
                        // startIcon={<PublishIcon />}
                        size="small"
                        variant="text"
                      >
                        Export
                      </Button>
                    </CSVLink>
                  </div>
                  <div>
                    <Link to="/sapphire/Add_Auditor">
                      <Button
                        style={{ float: 'right' }}
                        className="modal-submit-btn view-btn"
                        color="primary"
                        size="small"
                        variant="text"
                      >
                        Add Auditor
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <Card className="col-sm-12">
              <Auditors type={type} />
            </Card>

            <Box sx={{ pt: 3 }}></Box>
          </Container>
        </Box>
      </>
    </>
  );
};

export default Users;

import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid,
  CardHeader,
  Divider,
  Card
} from '@material-ui/core';
import React, { useState } from 'react';

import Assessment from 'src/components/TPI/Home/assessment';

import Cap_performance from 'src/components/TPI/Home/cap_performance';

import Noticeboard from 'src/components/TPI/Home/Noticeboard';
import Pending from 'src/components/TPI/Home/Pending';
import Modal from 'react-bootstrap/Modal'; // for Modal
import Form from 'react-bootstrap/Form'; // for Modal
import AttachFileIcon from '@material-ui/icons/AttachFile'; //for modal
import Button from 'react-bootstrap/Button'; //for modal
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { feedbackAdded } from 'src/store/feedback/thunk';
import MultipleFileUploads from 'src/components/common/MultipleFileUploads';
import CloseIcon from '@material-ui/icons/Close';
import '../css/Auditor/auditor_style.css';
import Documents from '../components/dashboard/Documents';
const Dashboard = () => {
  const [show2, setShow] = useState(false);
  const { userData } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [feedbackDocs, setFeedbackDocs] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onSubmit = (val) => {
    console.log('Val>>', val);
    let feedback = {
      userUid: userData.user,
      userType: userData.type,
      userInfo: {
        name: userData.name,
        email: userData.email
      },
      cmp_id: userData.cmp_id,
      cmp_name: userData.name,
      feedbackText: val.feedbackText,
      attachments: []
    };
    handleClose();
    dispatch(feedbackAdded(feedback, feedbackDocs));
  };

  return (
    <>
      <Helmet>
        <title>Home | Oren</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <div className="row">
            <div className="row">
              <div className="col-md-6">
                <h2>
                  <ul class="breadcrumb">
                    <li>
                      <a href="dashboard">Dashboard</a>
                    </li>
                    {/* <li><a href="Observation">Observation</a></li> */}
                  </ul>
                </h2>
              </div>
              <div className="col-md-6">
                {/* <FeedBack /> */}
                {/* Try and Error for modal */}
                <Button
                  style={{ float: 'right' }}
                  variant=""
                  className="modal-submit-btn"
                  onClick={handleShow}
                >
                  Give Feedback
                </Button>
                <Modal
                  show={show2}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard="false"
                  centered
                >
                  <Modal.Header>
                    <Modal.Title  className="feedback-header">Feedback</Modal.Title>
                    <Button
                      variant="secondary"
                      className="feedback-btn"
                      onClick={handleClose}
                    >
                      <CloseIcon />
                    </Button>
                  </Modal.Header>
                  <Modal.Body>
                    <Formik
                      initialValues={{
                        feedbackText: ''
                      }}
                      onSubmit={(val) => onSubmit(val)}
                      validationSchema={Yup.object({
                        // feedbackText: Yup.string().required(
                        //   'FeedbackText is required'
                        // )
                      })}
                    >
                      {({
                        handleSubmit,
                        handleChange,
                        values,
                        errors,
                        touched,
                        setFieldTouched,
                        setFieldValue,
                        setFieldError
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <p className="feedback-title">
                            {' '}
                            Share your suggestions or feedback below : {' '}
                          </p>
                          <div className="my-2">
                            <Form.Control
                              as="textarea"
                              placeholder="Leave a comment here"
                              style={{ height: '100px' }}
                              onChange={handleChange('feedbackText')}
                              value={values.feedbackText}
                              onFocus={() => setFieldTouched('feedbackText')}
                            />
                            <small className="text-danger form-text">
                              {touched.feedbackText && errors.feedbackText}
                            </small>
                            <br />
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <MultipleFileUploads
                                handleFileChanges={(files) =>
                                  setFeedbackDocs(files)
                                }
                                acceptExt={'.jpeg, .jpg, .png'}
                              />
                            </div>
                            <div>
                              <Button
                                // variant="primary"
                                disabled={values.feedbackText != "" ? false : true}
                                className="modal-submit-btn"
                                style={{ float: 'right' }}
                                onClick={() => {
                                  handleSubmit();
                                  
                                }}
                              >
                                Submit
                              </Button>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
          <Grid container spacing={3}>
            <Grid item lg={6} md={12} xl={9} xs={12}>
              <Card>
                <Assessment />
              </Card>
            </Grid>
            <Grid item lg={6} md={6} xl={3} xs={12}>
              <Cap_performance sx={{ height: '100%' }} />
            </Grid>
            <Grid item lg={6} md={6} xl={3} xs={12}>
              {/* <Noticeboard sx={{ height: '100%' }} /> */}
              <Documents sx={{ height: '100%' }} />
            </Grid>
            <Grid item lg={6} md={12} xl={12} xs={12}>
              <Card sx={{ height: '100%' }}>
                <CardHeader title={<h2>Pending Tasks</h2>} />

                <Divider />

                <Pending />
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;

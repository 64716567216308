import { Box, Card, CardContent, LinearProgress } from '@material-ui/core';
import { WarningAmberRounded } from '@material-ui/icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Api from 'src/Api';
import { roundTwoDecimals } from 'src/Constants';
import { auth } from 'src/firebase';
import swal from 'sweetalert';
import './category.css';
import { useNavigate } from 'react-router-dom';

const CategoryCard = ({ category, responseId }) => {
  return (
    <>
      {' '}
      <h4>
        <Link
          to={`/atgl/Contractor/assessment/${responseId}/categories/${category._id}`}
          className="link-title d-flex align-items-center"
        >
          {category?.category?.name}
          {category.questions.some(
            (ques) => ques?.assessStatus === 'REJECTED'
          ) && (
            <WarningAmberRounded
              style={{
                marginLeft: '.5rem'
              }}
              color="error"
            />
          )}
        </Link>
      </h4>
      <LinearProgress
        className="category-progress-bar"
        value={
          (category.questions.filter(
            (question) => question?.answerStatus === 'ANSWERED'
          ).length /
            category.questions.length) *
          100
        }
        variant="determinate"
      />
      <p className="category-progress-percentage">
        {roundTwoDecimals(
          (category.questions.filter(
            (question) => question?.answerStatus === 'ANSWERED'
          ).length /
            category.questions.length) *
            100
        )}
        %
      </p>
      <h5 className="status">
        Status :{' '}
        {category.questions.filter(
          (question) => question?.answerStatus === 'ANSWERED'
        ).length === category.questions.length ? (
          <span className="status-title">Complete</span>
        ) : (
          <span className="primary-red">Incomplete</span>
        )}
      </h5>
    </>
  );
};

const Category = ({
  categories,
  responseId,
  setLoading,
  status,
  fetchAssessmentResponseById,
  assessmentId
}) => {
  const { userData } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleResponseSubmit = async () => {
    try {
      setLoading(true);

      let assessmentQuery = {
        responseId: responseId,
        status: 'SUBMITTED',
        updatedBy: {
          name: userData?.name,
          userType: userData?.type,
          uid: auth.currentUser.uid
        },
        companyId: userData?.cmp_id,
        assessmentId: assessmentId
      };

      let res = await Api.changeResponseStatus(assessmentQuery);

      if (res.status >= 400) throw new Error('Error in submitting response!');

      await fetchAssessmentResponseById();

      swal('Success', 'Response submitted for assessment', 'success');
      if (status === 'COMPLETED' || status === 'SUBMITTED') {
        navigate('/atgl/Contractor/Assessment');
      }
    } catch (error) {
      swal({
        title: 'Error',
        text: error.message,
        icon: 'error',
        button: 'OK'
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <div className="Category">
              <h2>Categories</h2>
              <hr />
            </div>
            {/* <div className="Button">
              <button type="button" className="btn btn-all">
                All
              </button>
              <button type="button" className="btn btn-new">
                New
              </button>
              <button type="button" className="btn btn-pending">
                Pending
              </button>
              <button type="button" className="btn btn-submitted">
                Submitted
              </button>
              <button type="button" className="btn btn-reassessment">
                Reassessment
              </button>
              <button type="button" className="btn btn-completed">
                Completed
              </button>
            </div> */}
            {/* <br /> */}
            <div className="category-container">
              <div className="row">
                {categories.map((category) => (
                  <div className="col-md-6 col-12 pr-4">
                    <div className="row">
                      <div className="col-md-10 col-12">
                        <CategoryCard
                          category={category}
                          responseId={responseId}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="d-flex w-100 justify-content-center py-2">
              <button
                className="btn btn-submit"
                onClick={handleResponseSubmit}
                disabled={
                  status === 'SUBMITTED' ||
                  status === 'COMPLETED' ||
                  categories.some((category) => {
                    return category.questions.some(
                      (question) => question.answerStatus === 'UNANSWERED'
                    );
                  })
                }
              >
                Submit
              </button>
            </div>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default Category;

import { Box, Card, CardContent, LinearProgress } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles } from '@material-ui/core/styles';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CancelIcon from '@material-ui/icons/Cancel';
import ListIcon from '@material-ui/icons/List';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import Modal from 'react-bootstrap/Modal';
import ChatIcon from '@material-ui/icons/Chat';
import 'bootstrap/dist/css/bootstrap.min.css';
import clsx from 'clsx';
import Form from 'react-bootstrap/Form';
import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router';
import Api from 'src/Api';
import swal from 'sweetalert';
import './category.css';
import './checklist.css';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import AssessmentChat from 'src/components/customer/AssessmentChat';
import { GenRandomString, roundTwoDecimals } from 'src/Constants';
import { storage } from 'src/firebase';
import { AnswerTypesValues } from 'src/components/common/AnwerTypes';

const uploadFilePromise = async (file, id, dir) => {
  let trimmedFileName = file.name.trim().replace(/\s+/g, '_');
  let filePath = `assessment/${id}/${dir}/${GenRandomString(
    5
  )}-${trimmedFileName}`;
  let fileRef = storage.ref(filePath);
  await fileRef.put(file);
  let fileUrl = await fileRef.getDownloadURL();
  return {
    fileName: trimmedFileName,
    filePath,
    fileLink: fileUrl,
    fileSize: file.size,
    fileType: file.type
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  }
}));

const QuestionRow = ({ question, index, formik, response }) => {
  let selector = `questions.${index}.`;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [show, setShow] = useState(false);
  const [sendFile, setSendFile] = useState(false);
  const { userData } = useSelector((state) => state.auth);
  const handleClose1 = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    console.log('QuestionRow', { userData });
  }, [userData]);

  return (
    <div className="row">
      {/*----------  status  ----------*/}
      <div className="col-1 table-title d-flex justify-content-center align-items-center border-end">
        <div className="my-3">
          {question?.assessStatus === 'REJECTED' && (
            <CancelIcon className="wrong" style={{ color: red[500] }} />
          )}
          {question?.assessStatus === 'ACCEPTED' && (
            <CheckCircleIcon
              className="correct"
              style={{ color: green[500] }}
            />
          )}
          {/* <div className="">
          <CheckCircleIcon className="correct" style={{ color: green[500] }} />
        </div> */}
        </div>
      </div>

      {/*----------  Question  ----------*/}

      <div className="col-4 d-flex justify-content-start align-items-center border-end">
        <div className="my-3">
          <h2>
            {index + 1}. {question.question.questText}
          </h2>
        </div>
      </div>

      {/*----------  Company Response  ----------*/}

      <div className="col-2 table-title d-flex justify-content-center align-items-center border-end">
        {/* {question.answer.yesNo && !question?.questType?.answerType ? (
          <div className="sub-title company-res my-3 text-center">
            <h5 className="text-success">Yes</h5>
          </div>
        ) : (
          <div className="sub-title my-3 company-res text-center">
            <h5 className="text-danger"> No </h5>
          </div>
        )} */}
        {question.answer.text && (
          <div className="sub-title my-3 company-res text-center">
            <h5 className="">, {question.answer.text}</h5>
          </div>
        )}
        <div className="sub-title company-res my-3 text-center">
          {question?.questType?.answerType && (
            <AnswerTypesValues
              id={question._id}
              type={question?.questType?.answerType}
              index={index}
              formik={formik}
            />
          )}
        </div>
      </div>

      {/*----------  Accept/Reject  ----------*/}

      <div className="col-2 table-title  d-flex justify-content-center align-items-center border-end">
        <div className="btn-group btn-group-toggle" data-toggle="buttons">
          <div style={{ marginRight: 15 }}>
            <label
              className={`btn btn-outline-success ${
                question?.assessStatus === 'ACCEPTED' && 'active'
              }`}
            >
              <input
                type="radio"
                hidden={true}
                onChange={() => {
                  formik.setFieldValue(selector + 'assessStatus', 'ACCEPTED');
                }}
                name="options"
                id="option1"
              />{' '}
              <div style={{ marginTop: 4 }}>Accept</div>
            </label>
          </div>

          <div>
            <label
              className={`btn btn-outline-danger ${
                question?.assessStatus === 'REJECTED' && 'active'
              }`}
            >
              <input
                type="radio"
                hidden={true}
                onChange={() => {
                  formik.setFieldValue(selector + 'assessStatus', 'REJECTED');
                }}
                name="options"
                id="option2"
              />{' '}
              <div style={{ marginTop: 4 }}>Reject</div>
            </label>
          </div>
        </div>
      </div>

      {/*----------  Remark  ----------*/}

      <div className="col-3 d-flex justify-content-center align-items-center">
        {' '}
        <div className="my-3 d-flex">
          {/*----------  Chat button  ----------*/}

          <Button variant="" className="chat-btn" onClick={handleShow}>
            <ChatIcon />
          </Button>

          {/*----------  File button  ----------*/}

          <Button variant="" className="attach-btn">
            <label for={`file-input-${index}`} className="file response-file">
              <AttachFileIcon />
            </label>
          </Button>

          {/*----------  File display button  ----------*/}
          <div>
            <Button
              style={{ marginLeft: '10px' }}
              variant=""
              className="attach-btn"
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              <label className="file">
                <ListIcon />
              </label>
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {formik?.values.questions[index].answer.attachment.length ==
                0 && (
                <MenuItem>
                  <p>No files available</p>
                </MenuItem>
              )}
              {formik?.values.questions[index].answer.attachment.map(
                (item, index) => {
                  return (
                    <MenuItem key={index} onClick={handleClose}>
                      <p className="file-name mx-2 my-2">
                        {item?.name || (
                          <a href={item?.fileLink} target="blank">
                            {item?.fileName}
                          </a>
                        )}
                      </p>
                    </MenuItem>
                  );
                }
              )}
            </Menu>
          </div>

          <input
            id={`file-input-${index}`}
            type="file"
            style={{ display: 'none' }}
            // name="selectedFile"
            // multiple
            required
            onChange={(e) => {
              console.log(typeof e.target.files[0]);
              formik.setFieldValue(
                selector +
                  `answer.attachment.${formik?.values.questions[index].answer.attachment.length}`,
                e.target.files[0]
              );
            }}
            accept="image/*, .doc, .csv,.xls, .xlsx, .docx, application/pdf, application/msword, application/vnd.ms-powerpoint,.ppt, .pptx"
          />
        </div>
      </div>
      {question && response ? (
        <AssessmentChat
          sendFile={sendFile}
          setSendFile={setSendFile}
          show={show}
          handleClose={handleClose1}
          question={question}
          response={response}
          uid={userData.tpiId}
          type={userData.type}
          name={userData.name}
        />
      ) : null}
    </div>
  );
};

const Checklist = ({ section, setLoading, responseId, response }) => {
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state.auth);
  const [assessmentId, setAssessmentId] = useState(responseId);

  const handleSectionSubmit = async (values) => {
    let areAllAccepted = values.questions.every(
      (question) => question.assessStatus === 'ACCEPTED'
    );
    console.log(
      '🚀 ~ file: Checklistcomponent.js ~ line 191 ~ handleSectionSubmit ~ areAllAccepted',
      areAllAccepted
    );
    let isScoreValid = !!!values.scoredMarks || +values.scoredMarks <= 0;
    console.log(
      '🚀 ~ file: Checklistcomponent.js ~ line 193 ~ handleSectionSubmit ~ isScoreValid',
      isScoreValid
    );
    if (areAllAccepted && isScoreValid) {
      return swal('', 'Please enter the score', 'warning');
    }
    try {
      setLoading(true);

      let sectionQuery = {
        assesRespId: responseId,
        responseData: values,
        sectionId: section._id
      };

      for (let i = 0; i < values.questions.length; i++) {
        let question = values.questions[i];

        for (let j = 0; j < question.answer.attachment.length; j++) {
          let file = question.answer.attachment[j];
          if (file?.arrayBuffer)
            values.questions[i].answer.attachment[j] = await uploadFilePromise(
              file,
              responseId,
              section._id
            );
        }
      }

      let res = await Api.updateSectionInResponse(sectionQuery);

      if (res.data.code >= 400) throw new Error('Error in saving response!');
      if (userData.type == 4) {
        swal('Saved', 'Your response is saved', 'success').then(() =>
          navigate(`/auditor/assessment/${responseId}/categories`)
        );
      } else {
        swal('Saved', 'Your response is saved', 'success').then(() =>
          navigate(`/sapphire/cap_assesmentview/${assessmentId}`)
        );
      }
    } catch (error) {
      swal({
        title: 'Error',
        text: error.message,
        icon: 'error',
        button: 'OK'
      });
    } finally {
      setLoading(false);
    }
  };

  const classes = useStyles();
  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: section,
    onSubmit: handleSectionSubmit,
    validationSchema: Yup.object().shape({
      scoredMarks: Yup.number()
        .max(section?.totalMarks || 0)
        .test('isPositive', 'Marks should be positive', (val) => val >= 0)
        .label('Marks')
    })
  });
  console.log('hai');
  return (
    <Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <div className="checklist-container">
              <div className="row">
                <div className="col-12 col-md-4">
                  <div className="d-flex w-100 h-100 justify-content-start align-items-center">
                    <div className="category-title">
                      <h4>{formik.values.category.name}</h4>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex w-100 h-100 justify-content-start align-items-center">
                    <LinearProgress
                      className="checklist-tpi-progress-bar"
                      value={
                        (formik.values.questions.filter(
                          (question) => question?.assessStatus !== 'NONE'
                        ).length /
                          formik.values.questions.length) *
                        100
                      }
                      variant="determinate"
                    />
                    <div className="checklist-tpi-progress-percentage">
                      {roundTwoDecimals(
                        (formik.values.questions.filter(
                          (question) => question?.assessStatus !== 'NONE'
                        ).length /
                          formik.values.questions.length) *
                          100
                      )}
                      %
                    </div>
                  </div>
                </div>

                {/*----------  Scored marks input  ----------*/}
                <div className="col-md-2">
                  <div className="d-flex w-100 justify-content-end align-items-center">
                    <div>Score : </div>
                    <div>
                      <FormControl
                        className={clsx(classes.margin, classes.textField)}
                        style={{
                          maxWidth: '45px',
                          marginLeft: '1rem'
                        }}
                        variant="outlined"
                      >
                        <OutlinedInput
                          disabled={formik.values.questions.some(
                            (question) => question.assessStatus !== 'ACCEPTED'
                          )}
                          // id="outlined-adornment-weight"
                          style={{
                            height: '1.8rem'
                            // paddingLeft:"18px"
                          }}
                          error={formik.errors.scoredMarks}
                          value={formik.values.scoredMarks}
                          onChange={formik.handleChange('scoredMarks')}
                          // endAdornment={
                          //   <InputAdornment position="end">{`/  ${formik.values.totalMarks}`}</InputAdornment>
                          // }
                          aria-describedby="outlined-weight-helper-text"
                          inputProps={{
                            'aria-label': 'weight'
                          }}
                          labelWidth={0}
                          // className="score-text"
                        />
                      </FormControl>
                    </div>
                    <div style={{ marginLeft: '8px' }}>
                      <span
                        style={{ fontSize: '14px' }}
                      >{` / ${formik.values.totalMarks}`}</span>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="Checklist-container2">
                {/*----------  Table head  ----------*/}
                <div className="row">
                  <div className="col-md-1 table-title border-end">
                    <div className="">Status</div>
                  </div>
                  <div className="col-md-4 table-title border-end">
                    <div className="">Topic</div>
                  </div>
                  <div className="col-md-2 table-title border-end">
                    <div className="">Company Response</div>
                  </div>
                  <div className="col-md-2 table-title border-end">
                    <div className="">Your Response</div>
                  </div>
                  <div className="col-md-3 table-title text-center">
                    <div className="">Remarks</div>
                  </div>
                </div>

                {formik.values.questions.map((question, index) => (
                  <QuestionRow
                    key={index.toString()}
                    section={section}
                    response={response}
                    question={question}
                    index={index}
                    formik={formik}
                  />
                ))}
              </div>
            </div>
            {/* </div> */}
          </CardContent>
        </Card>
        <div>
          <div className="d-flex w-100 justify-content-center py-4">
            <button
              type="button"
              className="btn"
              style={{
                backgroundColor: 'white',
                border: '1px solid #1e2b56'
              }}
              onClick={formik.handleSubmit}
            >
              <span style={{ fontWeight: '600', color: '#081D29' }}>
                {' '}
                Save{' '}
              </span>
            </button>
            {/* <button type="button" className="btn btn-submit">
              Submit
            </button> */}
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default Checklist;

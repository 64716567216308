import React, { useRef, useState } from 'react';
import { CSVReader } from 'react-papaparse';
import { Alert, Button, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import OverlaySpinner from 'src/components/common/OverlaySpinner';
import Api from 'src/Api';

const btnStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#1E2B56 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1E2B56 !important'
    }
  }
}));

const QuestionsImport = ({ onImportComplete }) => {
  const btnClasses = btnStyles();
  const [submit, setSubmit] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [isReset, setIsReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const csvRef = useRef();

  const {
    userData: { companyId }
  } = useSelector((state) => state.auth);

  const { questions: oldQuestions } = useSelector((state) => state.assessment);

  const handleOnDrop = (data) => {
    setQuestions(data);
    setSubmit(true);
  };

  const handleOnError = (err, file, inputElem, reason) => {
    swal('Error', err.message, 'error');
  };

  const handleOnRemoveFile = (data) => {
    setIsReset(false);
    setSubmit(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setIsReset(false);
      console.log(oldQuestions);
      const questionsData = questions
        .filter(
          (ques) =>
            ques.data.length !== 0 &&
            !!ques.data[0] &&
            !oldQuestions.some((que) => que.questText === ques.data[0])
        )
        .map((ques) => ({
          companyId,
          questText: ques.data[0]
        }));
      console.log(questionsData);
      await Api.createBulkQuestions({
        questionsData
      });
      await onImportComplete();
      setIsReset(true);
      setQuestions([]);
      swal('Success', 'Questions imported successfully', 'success');
    } catch (error) {
      swal('Error', error.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && <OverlaySpinner />}
      <div>
        <Alert
          severity="info"
          style={{
            marginBottom: '10px'
          }}
        >
          Refer to sample CSV file here -{' '}
          <a
            href={process.env.PUBLIC_URL + '/downloads/sample-questions.csv'}
            download="sample-questions.csv"
          >
            Download
          </a>
        </Alert>
        <CSVReader
          onDrop={handleOnDrop}
          onError={handleOnError}
          addRemoveButton
          onRemoveFile={handleOnRemoveFile}
          ref={csvRef}
          isReset={isReset}
        >
          <span
            className="d-flex w-100 justify-content-center align-items-center"
            style={{
              minHeight: '300px'
            }}
          >
            Drop CSV file here or click to upload.
          </span>
        </CSVReader>
      </div>
      <div className="d-flex w-100 justify-content-center align-items-center py-4">
        {submit && (
          <Button
            variant="contained"
            classes={btnClasses}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        )}
      </div>
    </div>
  );
};

export default QuestionsImport;

import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const initialState = {
  startDate: moment().startOf('month').format('YYYY-MM-DD'),
  endDate: moment().endOf('month').format('YYYY-MM-DD'),
  loading: false
};

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setStartDate, setEndDate, setLoading } = headerSlice.actions;

export default headerSlice.reducer;

import { Helmet } from 'react-helmet';
import React from 'react';
import {
  Box,
  Container,
  Card,
  Divider,
  CardHeader,
  Grid
} from '@material-ui/core';
import Documents from 'src/components/ATGL/view_assess/Documents';
import Assessments from 'src/components/ATGL/view_assess/assessments';
import CapDetails from 'src/components/ATGL/view_assess/CapDetails';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/dashboard/assessments.css';
import Button from 'react-bootstrap/Button';
import '../css/dashboard/dashboard.css';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const View_assessment = () => {
  const classes = useStyles();
  const [type, setType] = React.useState('');

  const handleChange = (event) => {
    setType(event.target.value);
  };
  return (
    <>
      <Helmet>
        <title>Assessment | Oren</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <div className="row">
            <div className="col-md-6">
              <h2>
                <ul class="breadcrumb">
                  <li>
                    <Link to="/sapphire/Assessment">Assessment</Link>
                  </li>
                  <li>
                    <Link to="/sapphire/View_assessment">
                      Assessment Detail
                    </Link>
                  </li>
                </ul>
              </h2>
            </div>
            <div className="col-md-6">
              <Link to="/sapphire/checklist">
                <Button
                  style={{ float: 'right' }}
                  variant=""
                  className="modal-submit-btn"
                >
                  Edit
                </Button>
              </Link>
            </div>
          </div>
          <div className="row" container spacing={2}>
            <div className="col-md-5">
              <Grid item lg={12} md={12} xl={12} xs={12}>
                <CapDetails />
              </Grid>
              <Grid className="grid2" item lg={12} md={12} xl={12} xs={12}>
                <Documents />
              </Grid>
            </div>
            <div className="col-md-7">
              <Card sx={{ height: '100%' }}>
                <br />
                <div className="row">
                  <div className="col-md-3 ">
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      className="select"
                    >
                      <Select
                        value={type}
                        onChange={handleChange}
                        displayEmpty
                        className={classes.selectEmpty}
                        inputProps={{ 'aria-label': 'Without label' }}
                        style={{ marginTop: 5, marginLeft: 10 }}
                      >
                        <MenuItem value="">
                          <em>Select Category</em>
                        </MenuItem>
                        <MenuItem value={'Corona'}>Corona</MenuItem>
                        <MenuItem value={'Delta'}>Delta</MenuItem>
                      </Select>
                      {/* <FormHelperText>Without label</FormHelperText> */}
                    </FormControl>
                  </div>
                  <div className="col-md-3 ">
                    <h5>Environmental</h5>
                  </div>
                </div>

                <Divider />

                <Grid
                  item
                  lg={12}
                  md={12}
                  xl={12}
                  xs={12}
                  className="text-center"
                >
                  <Assessments />
                </Grid>
              </Card>
            </div>
          </div>
        </Container>
      </Box>
    </>
  );
};

export default View_assessment;

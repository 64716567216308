import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  contractors : [],
  categories: [],
  responses: [],
  questions: [],
  assessments: [],
  loading: false,
  filter: 'ALL'
};

export const assessmentSlice = createSlice({
  name: 'assessment',
  initialState,
  reducers: {
    setResponses: (state, action) => {
      state.responses = [...action.payload];
    },
    setAssessments: (state, action) => {
      state.assessments = [...action.payload];
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setAllCategories(state, action) {
      state.categories = action.payload;
    },
    setContractors(state, action) {
      state.contractors = action.payload;
    },
    setAllQuestions(state, action) {
      state.questions = action.payload;
    },
    setFilterValue(state, action) {
      state.filter = action.payload;
    },
    resetAllData(state, action) {
      state = initialState
      return state
    }
  }
});

// Action creators are generated for each case reducer function
export const { setAllCategories,setFilterValue, setResponses, setLoading, setAssessments,setAllQuestions, resetAllData, setContractors } =
  assessmentSlice.actions;

export default assessmentSlice.reducer;

import { Helmet } from 'react-helmet';
import {
  Box,
  Button,
  Container,
  Card,
  Divider,
  CardHeader,
  Grid
} from '@material-ui/core';
import Documents from 'src/components/Learning/Documents';
// import customers from 'src/__mocks__/customers';
import Assessments from 'src/components/Learning/assessments';
import Sales from 'src/components/Learning/Sales';
// import Progress from 'src/components/Learning/TasksProgress';
// import FeedBack from 'src/components/dashboard//FeedBack';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Learning/learning.css';
import PublishIcon from '@material-ui/icons/Publish';
import { useState } from 'react';
import AddLearning from '../pages_2/AddLearning';
import { useSelector } from 'react-redux';

const Learning = () => {
  const [show, setShow] = useState(false);
  const { userData } = useSelector((state) => state.auth);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => setShow(true);
  return (
    <>
      <Helmet>
        <title>Learning | Oren</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <div className="row">
            <div className="col-md-6">
              <h2>
                <ul class="breadcrumb">
                  <li>
                    <a href="Learning">Learning</a>
                  </li>
                  {/* <li><a href="Observation">Observation</a></li> */}
                </ul>
              </h2>
            </div>
            <div className="col-md-6">
              {/* <FeedBack /> */}
              {/* Try and Error for modal */}
              <Button
                style={{ float: 'right' }}
                className="modal-submit-btn"
                className="view-btn"
                color="primary"
                startIcon={<PublishIcon />}
                size="small"
                variant="text"
                onClick={handleShow}
              >
                Upload Learning
              </Button>
            </div>
          </div>
          <div className="row" container spacing={3}>
            <div className="col-md-6">
              <Grid item lg={12} md={12} xl={12} xs={12}>
                <Sales />
              </Grid>
              <Grid
                id="grid2"
                className="grid2"
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <Documents />
              </Grid>
            </div>
            <div className="col-md-6" id="assesments-card">
              {/* <Card> */}
              <CardHeader title="Employee Learning" />
              <Divider />
              {/* </Card> */}
              {/* <Card id="assessment-table" className="text-center"> */}
              <Grid style={{ marginTop: "3%" }} item lg={12} md={12} xl={12} xs={12}>
                <Assessments />
              </Grid>
              {/* </Card> */}
            </div>
          </div>
        </Container>
      </Box>
      {userData ? (
        <AddLearning
          show={show}
          handleClose={handleClose}
          handleShow={handleShow}
          company_id={userData.cmp_id}
        />
      ) : null}
    </>
  );
};

export default Learning;

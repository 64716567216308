import { Box, Card, Container, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Cap_details from 'src/components/ATGL/dashboard/Cap_details';
import Cap_performance from 'src/components/ATGL/dashboard/cap_performance';
import Documents from 'src/components/ATGL/dashboard/Documents';
import ESGO from 'src/components/ATGL/dashboard/ESGO_Performance';

const Dashboard = () => {
  const [show2, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Helmet>
        <title>Home | Oren</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <div className="row">
            <div className="row">
              <div className="col-md-6">
                <h2>
                  <ul class="breadcrumb">
                    <li>
                      <a href="dashboard">Dashboard</a>
                    </li>
                    {/* <li><a href="Observation">Observation</a></li> */}
                  </ul>
                </h2>
              </div>
            </div>
          </div>
          <Grid container spacing={3}>
            <Grid item lg={6} md={12} xl={9} xs={12}>
              <Card>
                <ESGO />
              </Card>
            </Grid>
            <Grid item lg={6} md={6} xl={3} xs={12}>
              <Cap_performance sx={{ height: '100%' }} />
            </Grid>
            <Grid item lg={6} md={6} xl={3} xs={12}>
              <Documents sx={{ height: '100%' }} />
            </Grid>
            <Grid item lg={6} md={12} xl={12} xs={12}>
              <Cap_details sx={{ height: '100%' }} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;

import {
  Box,
  Card,
  CardContent,
  IconButton,
  TextField,
  Chip,
  Checkbox,
  Select,
  MenuItem,
  Menu,
  LinearProgress
} from '@material-ui/core';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import './checklist.css';
import ListIcon from '@material-ui/icons/List';
import CancelIcon from '@material-ui/icons/Cancel';
import { red, green } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ChatIcon from '@material-ui/icons/Chat';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CloseIcon from '@material-ui/icons/Close';
import { TextareaAutosize } from '@material-ui/core';
import Modal from 'react-bootstrap/Modal'; // for Modal
import Form from 'react-bootstrap/Form'; // for Modal
import { useFormik } from 'formik';
import swal from 'sweetalert';
import Api from 'src/Api';
import { Navigate, useNavigate } from 'react-router';
import {
  ASSESS_STATUS,
  GenRandomString,
  roundTwoDecimals
} from 'src/Constants';
import { storage } from 'src/firebase';
import { Input } from 'reactstrap';
import { useSelector } from 'react-redux';
import AssessmentChat from './AssessmentChat';
import * as Yup from 'yup';
import { AnswerTypesValues } from '../common/AnwerTypes';

const uploadFilePromise = async (file, id, dir) => {
  let trimmedFileName = file.name.trim().replace(/\s+/g, '_');
  let filePath = `assessment/${id}/${dir}/${GenRandomString(
    5
  )}-${trimmedFileName}`;
  let fileRef = storage.ref(filePath);
  await fileRef.put(file);
  let fileUrl = await fileRef.getDownloadURL();
  return {
    fileName: trimmedFileName,
    filePath,
    fileLink: fileUrl,
    fileSize: file.size,
    fileType: file.type
  };
};

const AnswerTypes = ({ id, type, index, options, formik, selector }) => {
  const onValueChange = (val) => {
    console.log({ selector, val });
    formik.setFieldValue(selector + 'answerStatus', val);
  };
  const value = formik.values.questions[index]?.answer?.value;
  switch (type) {
    case 'shortAnswer':
      return (
        <TextField
          value={value}
          onChange={(e) => {
            onValueChange('ANSWERED');
            formik.setFieldValue(selector + 'answer.value', e.target.value);
          }}
        />
      );
    case 'numeric':
      return (
        <TextField
          value={value}
          type="number"
          onChange={(e) => {
            onValueChange('ANSWERED');
            formik.setFieldValue(selector + 'answer.value', e.target.value);
          }}
        />
      );
    case 'longAnswer':
      return (
        <TextareaAutosize
          value={value}
          onChange={(e) => {
            onValueChange('ANSWERED');
            formik.setFieldValue(selector + 'answer.value', e.target.value);
          }}
        />
      );
    case 'checkbox':
      return (
        <Checkbox
          value={value}
          onChange={(e) => {
            onValueChange('ANSWERED');
            formik.setFieldValue(selector + 'answer.value', e.target.checked);
          }}
        />
      );
    case 'multiChoice':
      return (
        <Select
          value={value}
          onChange={(e) => {
            onValueChange('ANSWERED');
            formik.setFieldValue(selector + 'answer.value', e.target.value);
          }}
        >
          {options?.map((option) => {
            return (
              <MenuItem value={option} key={option}>
                {option}
              </MenuItem>
            );
          })}
        </Select>
      );
    default:
      return <> Choose Type </>;
  }
};

const QuestionRow = ({ question, index, formik, response }) => {
  let selector = `questions.${index}.`;
  let isDisabled = [ASSESS_STATUS.COMPLETED, ASSESS_STATUS.SUBMITTED].includes(
    response.status
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [show, setShow] = useState(false);
  const [sendFile, setSendFile] = useState(false);
  const { userData } = useSelector((state) => state.auth);
  const { type } = userData;
  const handleClose1 = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setAnchorEl(null);
  };

  console.log({ response });

  return (
    <div className="row">
      <div className="col-1 table-title border-end d-flex justify-content-center align-items-center">
        <div className="my-3">
          {question?.assessStatus === 'REJECTED' && (
            <CancelIcon className="wrong" style={{ color: red[500] }} />
          )}
          {question?.assessStatus === 'ACCEPTED' && (
            <CheckCircleIcon
              className="correct"
              style={{ color: green[500] }}
            />
          )}
        </div>
        {/* <div className="">
          <CheckCircleIcon className="correct" style={{ color: green[500] }} />
        </div> */}
      </div>

      <div className="col-5  border-end d-flex justify-content-start align-items-center">
        <div className="my-3">
          <h2>
            {index + 1}. {question.question.questText}
          </h2>
        </div>
      </div>

      <div className="col-4  table-title border-end">
        <div className="h-100  d-flex justify-content-center align-items-center">
          {question?.questType?.yesNo && (
            <div
              className="btn-group btn-group-toggle"
              style={{
                flexWrap: 'nowrap'
              }}
              data-toggle="buttons"
            >
              <div style={{ marginRight: 15 }}>
                <label
                  style={{ marginRight: '10px' }}
                  className={`btn btn-outline-success ${
                    question.answer.yesNo === true && 'active'
                  }`}
                >
                  <input
                    disabled={isDisabled}
                    type="radio"
                    hidden={true}
                    onChange={() => {
                      formik.setFieldValue(
                        selector + 'answerStatus',
                        'ANSWERED'
                      );
                      formik.setFieldValue(selector + 'answer.yesNo', true);
                    }}
                    name="options"
                    id="option1"
                  />{' '}
                  <div style={{ marginTop: 4 }}>Yes</div>
                </label>
                <label
                  className={`btn btn-outline-danger ${
                    question.answer.yesNo === false && 'active'
                  }`}
                >
                  <input
                    type="radio"
                    hidden={true}
                    disabled={isDisabled}
                    onChange={() => {
                      formik.setFieldValue(
                        selector + 'answerStatus',
                        'ANSWERED'
                      );
                      formik.setFieldValue(selector + 'answer.yesNo', false);
                    }}
                    name="options"
                    id="option2"
                  />{' '}
                  <div style={{ marginTop: 4 }}>No</div>
                </label>
              </div>
            </div>
          )}
          {question?.questType?.text && (
            <div className="pl-2" style={{ width: '70%' }}>
              <Input
                value={formik.values?.questions[index]?.answer?.text}
                invalid={
                  formik?.errors?.questions &&
                  formik?.errors?.questions.hasOwnProperty(index) &&
                  formik?.errors.questions[index]?.answer?.text
                }
                disabled={
                  formik.values?.questions[index]?.answerStatus ===
                    'UNANSWERED' || isDisabled
                }
                onChange={(e) => {
                  if (!!e.target.value) {
                    formik.setFieldValue(selector + 'answerStatus', 'ANSWERED');
                  } else {
                    formik.setFieldValue(
                      selector + 'answerStatus',
                      'UNANSWERED'
                    );
                  }
                  formik.setFieldValue(
                    selector + 'answer.text',
                    e.target.value
                  );
                }}
                placeholder="Your response..."
              />
            </div>
          )}

          {question?.questType?.answerType && (
            <div className="pl-2" style={{ width: '70%' }}>
              {type == 2 ? (
                <AnswerTypesValues
                  answer={question?.answer?.value}
                  type={question?.questType?.answerType}
                />
              ) : (
                <AnswerTypes
                  id={question._id}
                  type={question?.questType?.answerType}
                  index={index}
                  options={question?.questType?.options}
                  formik={formik}
                  selector={selector}
                />
              )}
            </div>
          )}
          {/* <div className="my-3">
          <button
            type="button"
            className="btn btn-outline-success"
            style={{
              marginRight: '1rem'
            }}
          >
            Yes
          </button>
          <button type="button" className="btn btn-outline-danger">
            No
          </button>
        </div> */}
        </div>
      </div>

      <div className="col-2 d-flex justify-content-center align-items-center">
        {' '}
        <div className="my-3 d-flex">
          <Button variant="" className="chat-btn" onClick={handleShow}>
            <ChatIcon />
          </Button>

          {/*----------  File button  ----------*/}

          <Button disabled={isDisabled} variant="" className="attach-btn">
            <label for={`file-input-${index}`} className="file response-file">
              <AttachFileIcon />
            </label>
          </Button>

          {/*----------  File display button  ----------*/}
          <div>
            <Button
              style={{ marginLeft: '10px' }}
              variant=""
              className="attach-btn"
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              <label className="file">
                <ListIcon />
              </label>
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {formik?.values.questions[index].answer.attachment.length ==
                0 && (
                <MenuItem>
                  <p>No files available</p>
                </MenuItem>
              )}
              {formik?.values.questions[index].answer.attachment.map(
                (item, index) => {
                  return (
                    <MenuItem key={index} onClick={handleClose}>
                      <p className="file-name mx-2 my-2">
                        {item?.name || (
                          <a href={item?.fileLink} target="blank">
                            {item?.fileName}
                          </a>
                        )}
                        <IconButton
                          onClick={() => {
                            formik.setFieldValue(
                              selector + `answer.attachment`,
                              formik?.values.questions[
                                index
                              ].answer.attachment.filter(
                                (_, idx) => idx != index
                              )
                            );
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </p>
                    </MenuItem>
                  );
                }
              )}
            </Menu>
          </div>

          <input
            id={`file-input-${index}`}
            type="file"
            style={{ display: 'none' }}
            // name="selectedFile"
            // multiple
            required
            onChange={(e) => {
              console.log(typeof e.target.files[0]);
              formik.setFieldValue(
                selector +
                  `answer.attachment.${formik?.values.questions[index].answer.attachment.length}`,
                e.target.files[0]
              );
            }}
            accept="image/*, .doc, .csv,.xls, .xlsx, .docx, application/pdf, application/msword, application/vnd.ms-powerpoint,.ppt, .pptx"
          />
        </div>
      </div>
      {question && response ? (
        <AssessmentChat
          sendFile={sendFile}
          setSendFile={setSendFile}
          show={show}
          handleClose={handleClose1}
          question={question}
          response={response}
          uid={userData.user}
          type={userData.type}
          name={userData.name}
        />
      ) : null}
    </div>
  );
};

const Checklist = ({ section, setLoading, responseId, response }) => {
  // console.log({section, response, userData})
  const navigate = useNavigate();

  const handleSectionSubmit = async (values) => {
    try {
      setLoading(true);

      let sectionQuery = {
        assesRespId: responseId,
        responseData: values,
        sectionId: section._id
      };

      for (let i = 0; i < values.questions.length; i++) {
        let question = values.questions[i];

        for (let j = 0; j < question.answer.attachment.length; j++) {
          let file = question.answer.attachment[j];
          if (file?.arrayBuffer)
            values.questions[i].answer.attachment[j] = await uploadFilePromise(
              file,
              responseId,
              section._id
            );
        }
      }

      let res = await Api.updateSectionInResponse(sectionQuery);

      if (res.data.code >= 400) throw new Error('Error in saving response!');
      swal('Saved', 'Your response is saved', 'success').then(() =>
        navigate(`/atgl/Contractor/assessment/${responseId}/categories`)
      );
    } catch (error) {
      swal({
        title: 'Error',
        text: error.message,
        icon: 'error',
        button: 'OK'
      });
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: section,
    validationSchema: Yup.object().shape({
      questions: Yup.array().of(
        Yup.object({
          questType: Yup.object().shape({
            text: Yup.boolean(),
            answerType: Yup.string(),
            options: Yup.array()
          }),
          answerStatus: Yup.string(),
          answer: Yup.object().shape({
            attachment: Yup.array(),
            value: Yup.string()
          })
        })
      )
    }),
    onSubmit: handleSectionSubmit
  });

  console.log({ formik });

  return (
    <Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <div className="checklist-container">
              <div className="row">
                <div className="col-md-6">
                  <div className="row">
                    <div className="category-title">
                      <h4>{section.category.name}</h4>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <LinearProgress
                    className="checklist-progress-bar"
                    value={roundTwoDecimals(
                      (formik.values.questions.filter(
                        (question) => question?.answerStatus === 'ANSWERED'
                      ).length /
                        formik.values.questions.length) *
                        100
                    )}
                    variant="determinate"
                  />
                  <p className="checklist-progress-percentage">
                    {roundTwoDecimals(
                      (formik.values.questions.filter(
                        (question) => question?.answerStatus === 'ANSWERED'
                      ).length /
                        formik.values.questions.length) *
                        100
                    )}
                    %
                  </p>
                </div>
                <hr />
                <div className="Checklist-container2">
                  <div className="row">
                    <div className="col-md-1 table-title border-end ">
                      <div className="">Status</div>
                    </div>
                    <div className="col-md-5 table-title border-end ">
                      <div className="">Topic</div>
                    </div>
                    <div className="col-md-3 table-title border-end ">
                      <div className="">Response</div>
                    </div>
                    <div className="col-md-3 table-title text-center">
                      <div className="">Confirmation</div>
                    </div>
                  </div>
                  {formik.values.questions.map((question, index) => (
                    <QuestionRow
                      key={index.toString()}
                      section={section}
                      response={response}
                      question={question}
                      index={index}
                      formik={formik}
                    />
                  ))}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
        <div>
          <div className="d-flex w-100 justify-content-center py-4">
            {![ASSESS_STATUS.COMPLETED, ASSESS_STATUS.SUBMITTED].includes(
              response.status
            ) && (
              <button
                type="button"
                className="btn"
                style={{
                  backgroundColor: 'white',
                  border: '1px solid #1e2b56'
                }}
                onClick={formik.handleSubmit}
              >
                Save
              </button>
            )}

            {/* <button type="button" className="btn btn-submit">
              Submit
            </button> */}
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default Checklist;

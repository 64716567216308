import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import Category from 'src/components/TPI/Assessment/category';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import Api from 'src/Api';
import swal from 'sweetalert';
import OverlaySpinner from 'src/components/common/OverlaySpinner';
import { Link } from 'react-router-dom';
const Categories = () => {
  const { responseId } = useParams();
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchAssessmentResponseById = async () => {
    try {
      setLoading(true);
      let query = {
        responseId: responseId,
        include: {
          assessment: true,
          sections_category: true,
          sections_questions_question: false
        }
      };
      let request = await Api.getResponseById(query);
      let response = request.data.data;

      setResponse(response);
    } catch (error) {
      console.log(error);
      return swal({
        title: 'Error',
        text: 'Error in fetching assessment',
        icon: 'error',
        button: 'OK'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAssessmentResponseById();
    return () => {};
  }, [responseId]);

  return (
    <>
      <Helmet>
        <title>Categories | Oren</title>
      </Helmet>
      {loading && <OverlaySpinner />}
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <h5>
            <ul class="breadcrumb">
              <li>
                <Link to="/auditor/Assessment">Assessment</Link>
              </li>
              <li>
                <Link to={`/auditor/assessment/${responseId}/categories`}>
                  Categories
                </Link>
              </li>
            </ul>
          </h5>
          {response?.sections && (
            <Category
              categories={response?.sections}
              responseId={responseId}
              setLoading={setLoading}
              status={response?.status}
              fetchAssessmentResponseById={fetchAssessmentResponseById}
              dueDate={
                response?.isDueDateExtended
                  ? response?.newDueDate
                  : response?.assessment?.dueDate
              }
              assessmentId={response?.assessment}
            />
          )}
          <Box sx={{ pt: 3 }}>
            {/* <CustomerListResults customers={customers} /> */}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Categories;

import moment from 'moment';
import { v4 as uuid } from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from 'react';

import {
  Box,
  Button,
  Card,
  CardHeader,
  // Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
  // TableSortLabel,
  // Tooltip
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getCapData, roundTwoDecimals } from 'src/Constants';

const orders = [
  {
    id: uuid(),
    ref: '1',
    Company: 'XYZ Company',
    Score: '40'
  },
  {
    id: uuid(),
    ref: '2',
    Company: 'ABC Company',
    Score: '40'
  },
  {
    id: uuid(),
    ref: '3',
    Company: 'PQR Company',
    Score: '40'
  },
  {
    id: uuid(),
    ref: '4',
    Company: 'PQR Company',
    Score: '40'
  },
  {
    id: uuid(),
    ref: '5',
    Company: 'PQR Company',
    Score: '40'
  },
  {
    id: uuid(),
    ref: '6',
    Company: 'PQR Company',
    Score: '40'
  }
];

const Caplist = ({ CapList }) => {
  const [viewAll, setViewAll] = useState(false);
  const [caps, setCaps] = useState(CapList);

  useEffect(() => {
    setCaps(CapList);
  }, [CapList]);
  return (
    <Card>
      <CardHeader title="Details" />
      <Divider />
      <PerfectScrollbar>
        <Box>
          <Table className="text-center" TableRow={5}>
            <TableHead>
              <TableRow id="table-row">
                <TableCell className="text-center">Sr.no</TableCell>
                <TableCell className="text-center">Company</TableCell>
                <TableCell className="text-center">Score</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(viewAll ? caps : caps.slice(0, 4)).map((item, index) => (
                <TableRow
                  hover
                  key={item._id.contractorId}
                  className={`light-${getCapData(
                    (item.scored / item.total) * 100
                  ).color.toLowerCase()}`}
                >
                  <TableCell className="text-center">{index + 1}</TableCell>
                  <TableCell className="text-center">
                    {item._id.contractorName}
                  </TableCell>
                  <TableCell className="text-center">
                    {roundTwoDecimals((item.scored / item.total) * 100)} %
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        {caps.length >= 5 ? (
          <Button
            className="view-btn"
            color="primary"
            endIcon={<ArrowRightIcon />}
            size="small"
            variant="text"
            onClick={() => setViewAll(!viewAll)}
          >
            {viewAll ? 'View Less' : 'View All'}
          </Button>
        ) : null}
      </Box>
    </Card>
  );
};

export default Caplist;

import React from 'react';
import { MDBDataTableV5 } from 'mdbreact';

export default function Basic({ rows }) {
  return (
    <MDBDataTableV5
      id="atgl_assessement_table"
      hover
      entriesOptions={[10, 20, 30]}
      entries={10}
      pagesAmount={4}
      searchBottom={false}
      data={{
        columns: [
          {
            label: 'Topic',
            field: 'Topic',
            sort: 'disabled',
            width: 150
          },
          // {
          //   label: 'Score',
          //   field: 'Score',
          //   width: 50
          // },
          {
            label: 'Response',
            field: 'Response',
            width: 100
          },
          {
            label: 'Remarks',
            field: 'Remarks',
            width: 50
          }
        ],
        rows: rows
      }}
    />
  );
}

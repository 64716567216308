import { Link, useNavigate } from 'react-router-dom';
import BlockIcon from '@material-ui/icons/Block';
import CreateIcon from '@material-ui/icons/Create';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { MDBDataTableV5 } from 'mdbreact';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import {
  deleteAuditor,
  disableAuditor,
  getAllUAuditors
} from '../../store/auditors/thunk';
import './auditors.css';
import API from '../../Api';
import { setCurrentAuditor } from 'src/store/auditors/actions';
import OverlaySpinner from '../common/OverlaySpinner';

const tableCol = [
  {
    label: 'S.no',
    field: 'sno',
    sort: 'asc',
    width: 150
  },
  // {
  //   label: 'Company Code',
  //   field: 'companycode',
  //   width: 10,
  //   attributes: {
  //     'aria-controls': 'DataTable',
  //     'aria-label': 'Company Code'
  //   }
  // },
  {
    label: 'Organization',
    field: 'organization',
    width: 10
  },
  {
    label: 'Mobile',
    field: 'mobile',
    width: 10
  },
  {
    label: 'E-mail',
    field: 'email',
    // sort: 'asc',
    width: 10
  },
  {
    label: 'Contact Person',
    field: 'contactperson',
    // sort: 'asc',
    width: 10
  },
  {
    label: 'Actions',
    field: 'action',
    sort: 'disabled',
    width: 10
  }
];

export default function Basic() {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.auth);
  const { allAuditors } = useSelector((state) => state.auditors);

  useEffect(() => {
    if (userData && userData?.companyId) {
      dispatch(getAllUAuditors(userData.companyId));
    }
  }, []);

  const deleteAuditorTable = (auditor) => {
    swal({
      title: `Delete`,
      text: `Are you sure,You want to delete ${auditor.name}?`,
      icon: 'warning',
      buttons: true,
      dangerMode: true
    }).then(async (willDelete) => {
      if (willDelete) {
        await dispatch(deleteAuditor(auditor));
      }
      await dispatch(getAllUAuditors(userData.companyId));
    });
  };

  const onDisable = (auditor) => {
    swal({
      title: 'Are you sure?',
      text: `You want to ${
        auditor.isActive == true ? 'disable' : 'enable'
      } this user`,
      icon: 'warning',
      buttons: true,
      dangerMode: true
    }).then(async (willDelete) => {
      if (willDelete) {
        let data = {
          tpiId: auditor.tpiId,
          disable: auditor.isActive == true ? true : false
        };
        await dispatch(disableAuditor(data));
        await dispatch(getAllUAuditors(userData.companyId));
      } else {
        dispatch(getAllUAuditors());
      }
    });
  };
  //auditors
  //const [datatable, setDatatable] = React.useState();

  return (
    <MDBDataTableV5
      className="my-3"
      searchTop
      searchBottom={false}
      sortRows={[]}
      entriesOptions={[5, 10, 20]}
      entries={5}
      pagesAmount={6}
      data={{
        columns: tableCol,
        rows: allAuditors
          ? allAuditors.map((auditor, index) => {
              return {
                sno: auditor.isActive ? (
                  <div>{index + 1}</div>
                ) : (
                  <span style={{ color: '#BDBDBD' }}>{index + 1}</span>
                ),
                // companycode: auditor.code,
                organization: auditor.isActive ? (
                  <span searchvalue={auditor.name}>{auditor.name}</span>
                ) : (
                  <span searchvalue={auditor.name} style={{ color: '#BDBDBD' }}>
                    {auditor.name}
                  </span>
                ),
                mobile: auditor.isActive ? (
                  <span searchvalue={auditor.phoneNo}>{auditor.phoneNo}</span>
                ) : (
                  <span
                    searchvalue={auditor.phoneNo}
                    style={{ color: '#BDBDBD' }}
                  >
                    {auditor.phoneNo}
                  </span>
                ),
                email: auditor.isActive ? (
                  <span searchvalue={auditor.email}>{auditor.email}</span>
                ) : (
                  <span
                    searchvalue={auditor.email}
                    style={{ color: '#BDBDBD' }}
                  >
                    {auditor.email}
                  </span>
                ),
                contactperson: auditor.isActive ? (
                  <span searchvalue={auditor.contactPersonName}>
                    {auditor.contactPersonName}
                  </span>
                ) : (
                  <span
                    searchvalue={auditor.contactPersonName}
                    style={{ color: '#BDBDBD' }}
                  >
                    {auditor.contactPersonName}
                  </span>
                ),
                action: (
                  <React.Fragment>
                    <button
                      disabled={!auditor.isActive}
                      onClick={async () => {
                        await dispatch(setCurrentAuditor(auditor));
                        Navigate('/sapphire/EditAuditor');
                      }}
                      type="button"
                      className="btn btn-edit btns-users"
                    >
                      <CreateIcon className="icons-users" />
                    </button>

                    <button
                      disabled={!auditor.isActive}
                      onClick={() => {
                        deleteAuditorTable(auditor);
                      }}
                      type="button"
                      className="btn btn-delete btns-users"
                    >
                      <DeleteIcon className="icons-users" />
                    </button>

                    <button
                      disabled={!auditor.isActive}
                      onClick={async () => {
                        await dispatch(setCurrentAuditor(auditor));
                        Navigate('/sapphire/ViewAuditor');
                      }}
                      type="button"
                      className="btn btn-visibility btns-users"
                    >
                      <VisibilityIcon className="icons-users" />
                    </button>

                    <button
                      onClick={() => {
                        onDisable(auditor);
                      }}
                      type="button"
                      className="btn btn-block btns-users"
                    >
                      {auditor.isActive ? (
                        <BlockIcon className="icons-users" />
                      ) : (
                        <CheckCircleIcon className="icons-users" />
                      )}
                    </button>
                  </React.Fragment>
                )
              };
            })
          : null
      }}
    />
  );
}

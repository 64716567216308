import { Helmet } from 'react-helmet';
import {
  Box,
  Card,
  Container,
  Button,
  Tabs,
  Tab,
  makeStyles,
  Alert,
  createStyles,
  IconButton
} from '@material-ui/core';
import CompaniesTable from 'src/components/users/CompaniesTable';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import OverlaySpinner from 'src/components/common/OverlaySpinner';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { Close } from '@material-ui/icons';
import XLSX from 'xlsx';
import { MDBDataTableV5 } from 'mdbreact';
import { bulkContractorsAdded } from '../store/auditors/thunk';
import TableChartIcon from '@material-ui/icons/TableChart';
import { Trash } from 'react-feather';
import swal from 'sweetalert';
import {  getAllContractorsList } from '../store/auditors/thunk'

const headers = [
  { label: 'Organization', key: 'name' },
  { label: 'Mobile', key: 'mobile' },
  { label: 'E-mail', key: 'emailid' },
  { label: 'Contact Person', key: 'contactPersonName' }
];

const tableCol = [
  {
    label: 'Company Name',
    field: 'name',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Location',
    field: 'location',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Email',
    field: 'emailid',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Contact Person',
    field: 'contactPersonName',
    sort: 'asc',
    width: 150
  }
];

const useStyles = makeStyles((theme) =>
  createStyles({
    scroller: {
      '& > .MuiTabs-scroller  > .MuiTabs-flexContainer': {
        // border: '1px solid black'
      }
    }
  })
);
const Companies = () => {
  const classes = useStyles();
  const Navigate = useNavigate();
  const { loading, allContrators } = useSelector((state) => state.auditors);
  const [bulkUserUploadModal, setBulkUserUploadModal] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const { userData } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleBulkUserUploadModal = () => {
    setBulkUserUploadModal((prev) => !prev);
    setJsonData([]);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const results = [];

    const reader = new FileReader();

    reader.readAsArrayBuffer(file);

    reader.onload = () => {
      const data = new Uint8Array(reader.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const csvData = XLSX.utils.sheet_to_csv(worksheet);
      const lines = csvData.split('\n');
      const headers = lines[0].split(',');
// checking wheather the headers are correct
      if (JSON.stringify(tableCol.map(t => t.field)) === JSON.stringify(headers)) {
        for (let i = 1; i < lines.length; i++) {
          const obj = {};
          const currentLine = lines[i].split(',');
  
          for (let j = 0; j < headers.length; j++) {
            obj[headers[j]] = currentLine[j];
          }
          results.push(obj);
        }
      } else {
        swal('Error', 'File is not in propper format. Please download sample file from above link and try again', 'error')
      }
      setJsonData(results);  
      
    };
  };

  const addBulkContractors = () => {
    const data = {
      contractorsData: jsonData,
      createdBy: userData._id,
      cmp_id: userData.companyId,
      cmp_name: userData.name,
      code: userData?.code
    };
    dispatch(bulkContractorsAdded(data));
    dispatch(getAllContractorsList(userData.companyId));
    handleBulkUserUploadModal();
  };

  return (
    <>
      {loading && <OverlaySpinner />}
      <Helmet>
        <title>Users | Oren</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <div className="row">
            <div className="d-flex flex-row justify-content-center alighn-items-center">
              <Tabs className={classes.scroller}>
                <Tab
                  style={{
                    minWidth: 0,
                    minHeight: 0,
                    padding: '.9rem 2.2rem',
                    borderTop: '1px solid #000000a6',
                    borderLeft: '1px solid #000000a6',
                    borderBottom: '1px solid #000000a6'
                  }}
                  className="tab-title"
                  onClick={() => Navigate('/sapphire/users')}
                  label="Auditors"
                />
                <Tab
                  style={{
                    minWidth: 0,
                    minHeight: 0,
                    padding: '.9rem 2rem',
                    borderTop: '1px solid #000000a6',
                    borderRight: '1px solid #000000a6',
                    borderBottom: '1px solid #000000a6'
                  }}
                  className="view-btn"
                  onClick={() => Navigate('/sapphire/companies')}
                  label="Company"
                />
              </Tabs>
            </div>
            <div className="col-md-6">
              <h2>
                <ul class="breadcrumb">
                  <li>
                    <Link to="/sapphire/companies">Companies</Link>
                  </li>
                </ul>
              </h2>
            </div>
            <div className="col-md-6">
              <div className="d-flex flex-row justify-content-end align-items-center">
                <div style={{ marginRight: '2%' }}>
                  <CSVLink
                    filename={`Companies_${moment().format('DD-MM-YYYY')}.csv`}
                    data={allContrators}
                    headers={headers}
                  >
                    <Button
                      style={{ float: 'right' }}
                      className="modal-submit-btn view-btn"
                      color="primary"
                      // startIcon={<PublishIcon />}
                      size="small"
                      variant="text"
                    >
                      Export
                    </Button>
                  </CSVLink>
                </div>
                <div style={{ marginRight: '2%' }}>
                  <Link to="/sapphire/add_company">
                    <Button
                      style={{ float: 'right' }}
                      className="modal-submit-btn view-btn"
                      color="primary"
                      // startIcon={<PublishIcon />}
                      size="small"
                      variant="text"
                    >
                      Add Company
                    </Button>
                  </Link>
                </div>
                <div>
                  <Button
                    style={{ float: 'right' }}
                    className="modal-submit-btn view-btn"
                    color="primary"
                    // startIcon={<PublishIcon />}
                    size="small"
                    variant="text"
                    onClick={handleBulkUserUploadModal}
                  >
                    Add Bulk Company
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <Card className="col-sm-12">
            <CompaniesTable />
          </Card>

          <Box sx={{ pt: 3 }}></Box>

          <Modal
            show={bulkUserUploadModal}
            onHide={handleBulkUserUploadModal}
            size="lg"
            backdrop="static"
            keyboard="false"
            centered
          >
            <Modal.Header>
              <Modal.Title>Bulk User Upload</Modal.Title>
              <Close
                fontSize="medium"
                onClick={handleBulkUserUploadModal}
                style={{ color: 'red', cursor: 'pointer' }}
              />
            </Modal.Header>
            <Modal.Body>
              <Box>
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <Alert variant="outlined" severity="info">
                    Download the sample file and fill the details and upload it. <a download href='/static/images/template/Sample_company_bulk_upload.csv'>
                    <Button startIcon={<TableChartIcon />} variant='contained' sx={{
                      textTransform: 'none',
                      ml:1
  }} color='primary' size='small'> Download </Button>
                    </a>
</Alert>
                </Box>
                <Box sx={{
                  marginY:2
                }}>
                  <input
                  
                  type="file"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={handleFileUpload}
                    
                  />
                  {
                    jsonData.length > 0 && (
                      <IconButton onClick={()=> setJsonData([])}> <Trash/> </IconButton>
                    )
                  }
                </Box>

                {jsonData.length > 0 && (
                  <Button
                    style={{ float: 'right' }}
                    className="modal-submit-btn view-btn"
                    color="primary"
                    // startIcon={<PublishIcon />}
                    size="small"
                    variant="text"
                    onClick={addBulkContractors}
                  >
                    Add users
                  </Button>
                )}

                <MDBDataTableV5
                  className="my-3"
                  // disableRetreatAfterSorting
                  searchTop
                  sortRows={[]}
                  searchBottom={false}
                  entriesOptions={[5, 10, 20]}
                  entries={5}
                  pagesAmount={6}
                  sortable={false}
                  data={{
                    columns: tableCol,
                    rows: jsonData
                    // ? jsonData.map((company, index) => {
                    //     return {
                    //       companyName: company.isActive ? (
                    //         <span searchvalue={company.name}>
                    //           {company.name}
                    //         </span>
                    //       ) : (
                    //         <span
                    //           searchvalue={company.name}
                    //           style={{ color: '#BDBDBD' }}
                    //         >
                    //           {company.name}
                    //         </span>
                    //       ),
                    //       location: company.isActive ? (
                    //         <span searchvalue={company.location}>
                    //           {company.location}
                    //         </span>
                    //       ) : (
                    //         <span
                    //           searchvalue={company.locationt}
                    //           iv
                    //           style={{ color: '#BDBDBD' }}
                    //         >
                    //           {company.location}
                    //         </span>
                    //       ),

                    //       email: company.isActive ? (
                    //         <span
                    //           searchvalue={company.emailid || company.emailId}
                    //         >
                    //           {company.emailid || company.emailId}
                    //         </span>
                    //       ) : (
                    //         <span
                    //           searchvalue={company.emailid || company.emailId}
                    //           style={{ color: '#BDBDBD' }}
                    //         >
                    //           {company.emailid || company.emailId}
                    //         </span>
                    //       ),
                    //       contactperson: company.isActive ? (
                    //         <span searchvalue={company.contactPersonName}>
                    //           {company.contactPersonName}
                    //         </span>
                    //       ) : (
                    //         <span
                    //           searchvalue={company.contactPersonName}
                    //           style={{ color: '#BDBDBD' }}
                    //         >
                    //           {company.contactPersonName}
                    //         </span>
                    //       )
                    //     };
                    //   })
                    // : null
                  }}
                />
              </Box>
            </Modal.Body>
          </Modal>
        </Container>
      </Box>
    </>
  );
};

export default Companies;

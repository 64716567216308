import { Helmet } from 'react-helmet';
import React, { useState } from 'react';
import {
  Box,
  Container,
  Card,
  Divider,
  CardHeader,
  Grid
} from '@material-ui/core';
import Documents from 'src/components/dashboard/Documents';
// import customers from 'src/__mocks__/customers';
import Assessments from 'src/components/dashboard//assessments';
import CapDetails from 'src/components/dashboard//CapDetails';
// import FeedBack from 'src/components/dashboard//FeedBack';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/dashboard/assessments.css';
import Modal from 'react-bootstrap/Modal'; // for Modal
import Form from 'react-bootstrap/Form'; // for Modal
import AttachFileIcon from '@material-ui/icons/AttachFile'; //for modal
import Button from 'react-bootstrap/Button'; //for modal
import '../css/dashboard/dashboard.css';
import MultipleFileUploads from 'src/components/common/MultipleFileUploads';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { feedbackAdded } from 'src/store/feedback/thunk';
import CloseIcon from '@material-ui/icons/Close';
import OverlaySpinner from 'src/components/common/OverlaySpinner';
import { setLoading } from 'src/store/auth/actions';

const Dashboard = () => {
  const [show2, setShow] = useState(false);
  const { userData } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.feedback);
  const dispatch = useDispatch();
  const [feedbackDocs, setFeedbackDocs] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // <>
  const onSubmit = (val) => {
    console.log('Val>>', val);
    let feedback = {
      userUid: userData.user,
      userType: userData.type,
      userInfo: {
        name: userData.name,
        email: userData.email
      },
      cmp_id: userData.cmp_id,
      cmp_name: userData.name,
      feedbackText: val.feedbackText,
      attachments: []
    };
    handleClose()
    dispatch(feedbackAdded(feedback, feedbackDocs));
  };
  return (
    <>
    {loading && <OverlaySpinner/>}
      <Helmet>
        <title>Home | Oren</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <div className="row">
            <div className="col-md-6">
              <h2>
                <ul class="breadcrumb">
                  <li>
                    <a href="dashboard">Dashboard</a>
                  </li>
                  {/* <li><a href="Observation">Observation</a></li> */}
                </ul>
              </h2>
            </div>
            <div className="col-md-6">
              {/* <FeedBack /> */}
              {/* Try and Error for modal */}
              <Button
                style={{ float: 'right' }}
                variant=""
                className="modal-submit-btn"
                onClick={handleShow}
              >
                Give Feedback
              </Button>
              <Modal
                show={show2}
                onHide={handleClose}
                backdrop="static"
                keyboard="false"
                centered
              >
                <Modal.Header>
                  <Modal.Title>Feedback</Modal.Title>
                  <Button
                    variant="secondary"
                    className="feedback-btn"
                    onClick={handleClose}
                  >
                    <CloseIcon />
                  </Button>
                </Modal.Header>
                <Modal.Body>
                  <Formik
                    initialValues={{
                      feedbackText: ''
                    }}
                    onSubmit={(val) => onSubmit(val)}
                    validationSchema={Yup.object({
                      // feedbackText: Yup.string().required(
                      //   'Feedback text is required'
                      // )
                    })}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      values,
                      errors,
                      touched,
                      setFieldTouched,
                      setFieldValue,
                      setFieldError
                    }) => (
                      <form onSubmit={handleSubmit}>
                        Share your suggestions or feedback below:
                        <div className="my-2">
                          <Form.Control
                            as="textarea"
                            placeholder="Leave a comment here"
                            style={{ height: '100px' }}
                            onChange={handleChange('feedbackText')}
                            value={values.feedbackText}
                            onFocus={() => setFieldTouched('feedbackText')}
                          />
                          <small className="text-danger form-text">
                            {touched.feedbackText && errors.feedbackText}
                          </small>
                          <br />
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div><MultipleFileUploads
                          handleFileChanges={(files) => setFeedbackDocs(files)}
                          acceptExt={'.jpeg, .jpg, .png, .pdf, .xls, .xlsx, .csv'}
                        /></div>
                          <div><Button
                          // variant="primary"
                          className="modal-submit-btn"
                          style={{ float: 'right' }}
                          disabled={values.feedbackText != "" ? false : true}
                          onClick={() => {
                            handleSubmit();
                          }}
                        >
                          Submit
                        </Button></div>
                        </div>
                        
                        
                      </form>
                    )}
                  </Formik>
                </Modal.Body>
              </Modal>
            </div>
          </div>
          <h2>
            {/* <ul class="breadcrumb">
          <li><a href="dashboard">Home</a></li>
          <li><a href="dashboard">Dashboard</a></li>
        </ul> */}
          </h2>
          <div
            className="row assessment-table-contractor"
            container
            spacing={3}
          >
            <div className="col-md-5">
              <Grid item lg={12} md={12} xl={12} xs={12}>
                <CapDetails />
              </Grid>
              <Grid className="grid2" item lg={12} md={12} xl={12} xs={12}>
                <Documents />
              </Grid>
            </div>
            <div className="col-md-7">
              <Card>
                <CardHeader title={<h2>Assessments</h2>} />
                <Divider />
              </Card>
              <Card id="assessment-table" className="text-center">
                <Grid item lg={12} md={12} xl={12} xs={12}>
                  <Assessments />
                </Grid>
              </Card>
            </div>
          </div>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;

import {
  Checkbox,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize
} from '@material-ui/core';
import _ from 'lodash';

export const AnswerTypes = ({
  id,
  type,
  index,
  options,
  formik,
  selector,
  disable = false
}) => {
  const onValueChange = (val) => {
    console.log({ selector, val });
    formik.setFieldValue(selector + 'answerStatus', val);
  };
  const value = formik.values.questions[index]?.answer?.value;
  switch (type) {
    case 'shortAnswer':
      return (
        <TextField
          value={value}
          onChange={(e) => {
            onValueChange('ANSWERED');
            formik.setFieldValue(selector + 'answer.value', e.target.value);
          }}
          disabled={disable}
        />
      );
    case 'numeric':
      return (
        <TextField
          value={value}
          type="number"
          onChange={(e) => {
            onValueChange('ANSWERED');
            formik.setFieldValue(selector + 'answer.value', e.target.value);
          }}
          disabled={disable}
        />
      );
    case 'longAnswer':
      return (
        <TextareaAutosize
          value={value}
          onChange={(e) => {
            onValueChange('ANSWERED');
            formik.setFieldValue(selector + 'answer.value', e.target.value);
          }}
          disabled={disable}
        />
      );
    case 'checkbox':
      return (
        <Checkbox
          value={value}
          onChange={(e) => {
            onValueChange('ANSWERED');
            formik.setFieldValue(selector + 'answer.value', e.target.checked);
          }}
          disabled={disable}
        />
      );
    case 'multiChoice':
      return (
        <Select
          value={value}
          onChange={(e) => {
            onValueChange('ANSWERED');
            formik.setFieldValue(selector + 'answer.value', e.target.value);
          }}
          disabled={disable}
        >
          {options?.map((option) => {
            return (
              <MenuItem value={option} key={option}>
                {option}
              </MenuItem>
            );
          })}
        </Select>
      );
    default:
      return <> Choose Type </>;
  }
};

export const AnswerTypesValues = ({ formik, index, type, id, answer = '' }) => {
  const value = answer
    ? answer
    : formik?.values?.questions[index]?.answer?.value;
  switch (type) {
    case 'shortAnswer':
    case 'numeric':
      return <div> {value}</div>;
    case 'longAnswer':
      return <div> {value}</div>;
    case 'checkbox':
      return <div> {value ? 'Yes' : 'No'}</div>;
    case 'multiChoice':
      return <div> {_.isArray(value) ? value.join(' ,') : value}</div>;
    default:
      return <> Choose Type </>;
  }
};

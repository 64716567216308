import {
  Box,
  Button,
  Card,
  CardHeader,
  // Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CloseIcon from '@material-ui/icons/Close';
import 'bootstrap/dist/css/bootstrap.min.css';
import firebase from 'firebase';
import moment from 'moment';
import { useEffect, useState } from 'react';
// import '../../css/observations/observation.css';
import Modal from 'react-bootstrap/Modal'; // for Modal
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import Progress from 'src/components/ATGL/dashboard/TasksProgress';
import FileDisplay from 'src/components/common/FileDisplay';
import OverlaySpinner from 'src/components/common/OverlaySpinner';
import SpinnerContainer from 'src/components/common/SpinnerContainer';
import 'src/css/ATGL/dashboard.css';
import swal from 'sweetalert';
import API from '../../../Api';
import AddNotice from './AddNotice';

const Documents = (props) => {
  const { userData } = useSelector((state) => state.auth);

  // View Document Modal
  const [show2, setShow] = useState(false);
  const [show, setShow2] = useState(false);
  const [notices, setNotices] = useState([]);
  const [selectedNotice, setSelectedNotice] = useState();
  const [employeeCount, setEmployeeCount] = useState(0);
  const [noticeTitle, setNoticeTitle] = useState();
  const [noticeDescription, setNoticeDescription] = useState();
  const [selectedFiles, setSelectedFiles] = useState({});
  const [formValid, setFormValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isAll, setIsAll] = useState(false);

  const [viewers, setViewers] = useState([]);
  const [viewersLoading, setViewersLoading] = useState(false);

  //Add Document Modal
  const handleClose = () => {
    setShow(false);
    setSelectedNotice();
  };
  const handleShow = (notice) => {
    setShow(true);
    setSelectedNotice(notice);
  };

  const handleClose2 = () => {
    resetData();
    setShow2(false);
  };
  const handleShow2 = () => setShow2(true);

  useEffect(() => {
    if (userData) {
      console.log({ userData });
      fetchAllNotices(userData.companyId);
    }
  }, [userData]);

  const fetchAllNotices = (companyId) => {
    setLoading(true);
    API.getLearningTopicDocs(companyId, 'Notices')
      .then((res) => {
        console.log('fetchAllNotices res', res.data.data);
        setNotices(
          res.data.data.docs.sort((a, b) =>
            moment(
              new firebase.firestore.Timestamp(
                b?.created_time?._seconds,
                b?.created_time?._nanoseconds
              ).toDate()
            ).diff(
              moment(
                new firebase.firestore.Timestamp(
                  a?.created_time?._seconds,
                  a?.created_time?._nanoseconds
                ).toDate()
              )
            )
          )
        );
        setEmployeeCount(res.data.data.usersCount);
        setLoading(false);
      })
      .catch((err) => {
        console.log('fetchAllNotices err', { err });
        swal({
          title: 'Error',
          text: err.message,
          icon: 'error',
          button: 'Ok'
        });
        setLoading(false);
      });
  };

  const fetchNoticeViewers = async () => {
    try {
      setViewersLoading(true);
      let users = await API.getEmployeeByIds(
        selectedNotice?.viewers.map((viewer) => viewer.uid)
      );
      setViewers(users.data.data);
    } catch (error) {
    } finally {
      setViewersLoading(false);
    }
  };

  useEffect(() => {
    if (Object.keys(selectedFiles).length) {
      let keys = Object.keys(selectedFiles);
      for (let file of keys) {
        if (
          !validateFileSize(selectedFiles[file]) ||
          !validateFileType(selectedFiles[file])
        ) {
          setSelectedFiles({});
        }
      }
    }
  }, [selectedFiles]);

  useEffect(() => {
    (selectedNotice?.viewers ?? []).length > 0 && fetchNoticeViewers();
    return () => {};
  }, [selectedNotice]);

  const resetData = () => {
    setNoticeTitle();
    setNoticeDescription();
    setSelectedFiles({});
    setFormValid(false);
  };

  const validateFileSize = (file) => {
    let fileSize = file.size / 1024 / 1024;
    if (fileSize > 10) {
      swal({
        title: file.name,
        text: 'Sorry, Max file size allowed is 10MB',
        icon: 'error',
        button: 'Ok'
      });
      return false;
    } else {
      return true;
    }
  };

  const validateFileType = (file) => {
    if (
      !file.type
        .toLowerCase()
        .match(
          /(jpg|jpeg|png|pdf|xlsx|xlsm|xlsb|xltx|xltm|xls|xlt|xls|xml|xlam|xla|xlw|xlr|csv)$/
        )
    ) {
      swal({
        title: file.name,
        text: 'Please select Image, PDF and Excel files only!',
        icon: 'error',
        button: 'Ok'
      });
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      {loading ? <OverlaySpinner /> : null}
      <Modal
        size="lg"
        show={show2}
        onHide={handleClose}
        backdrop="static"
        keyboard="false"
        centered
      >
        <Modal.Header>
          <Modal.Title>Notice</Modal.Title>
          <span
            onClick={handleClose}
            style={{
              cursor: 'pointer'
            }}
          >
            <CloseIcon color="error" />
          </span>
          {/* <Button variant="" style={{ color: 'red'  }}  onClick={handleClose}>X</Button> */}
        </Modal.Header>
        <Modal.Body>
          {selectedNotice ? (
            <>
              <div className="text-center">
                <h5>{selectedNotice?.title}</h5>
              </div>

              <div className="row">
                <div className="col-md-6">
                  {' '}
                  <p className="text-left">
                    Date:{' '}
                    {moment(
                      new firebase.firestore.Timestamp(
                        selectedNotice?.created_time?._seconds,
                        selectedNotice?.created_time?._nanoseconds
                      ).toDate()
                    ).format('DD/MM/YYYY')}
                  </p>
                </div>
                <div className="col-md-6">
                  <p style={{ float: 'right' }}>
                    Time:{' '}
                    {moment(
                      new firebase.firestore.Timestamp(
                        selectedNotice?.created_time?._seconds,
                        selectedNotice?.created_time?._nanoseconds
                      ).toDate()
                    ).format('hh:mm A')}
                  </p>
                </div>
              </div>
              <p>{selectedNotice?.desc}</p>
              <Divider />
              <br />
              <h5>Attachments:</h5>
              <div className="d-flex flex-wrap my-1">
                {selectedNotice?.item_url.includes('youtube.com') ? (
                  <FileDisplay
                    mode="youtube-embed"
                    file={selectedNotice?.item_url}
                  />
                ) : (
                  <FileDisplay
                    mode="view"
                    file={{
                      value: {
                        fileName: selectedNotice?.filename,
                        filePath: selectedNotice?.item_url
                      }
                    }}
                  />
                )}
              </div>
              {/* <a href={''} download="New COVID policy for May.pdf"><Button
            className="view-btn"
            color="primary"
            style={{ float: "left" }}
            size="small"
            variant="text"
          >Download</Button></a> */}
              {/* <p>(New COVID policy for May.pdf)</p> */}
              <br />
              <h5> Completed By: {(selectedNotice?.viewers ?? [])?.length}</h5>
              <Divider />
              {viewersLoading ? (
                <div className="my-4">
                  <SpinnerContainer />
                </div>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Time</TableCell>
                      <TableCell>Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedNotice?.viewers?.map((views) => (
                      <TableRow hover key={views._id}>
                        <TableCell>
                          {moment(views.viewed_at).format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell>
                          {moment(views.viewed_at).format('hh:mm A')}
                        </TableCell>
                        <TableCell>
                          {
                            viewers.find((viewer) => viewer.user === views.uid)
                              ?.fullName
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </>
          ) : null}
        </Modal.Body>
      </Modal>
      <AddNotice
        handleClose={handleClose2}
        show={show}
        onAddNotice={() => fetchAllNotices(userData?.companyId)}
      />

      <Card {...props}>
        <div className="row">
          <div className="col-md-9">
            <CardHeader title="Noticeboard" />
          </div>
          <div className="col-md-3 ">
            <CardHeader
              onClick={handleShow2}
              className="hover"
              title=" + Add Notice"
            />
          </div>
        </div>

        <Divider />
        <PerfectScrollbar>
          <Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Uploaded On</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Completed by</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notices.slice(0, isAll ? notices.length : 5).map((notice) => (
                  <TableRow hover>
                    <TableCell>
                      {moment(
                        new firebase.firestore.Timestamp(
                          notice?.created_time?._seconds,
                          notice?.created_time?._nanoseconds
                        ).toDate()
                      ).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell onClick={() => handleShow(notice)}>
                      <u> {notice.title}</u>
                    </TableCell>
                    <TableCell>
                      <Progress
                        total={employeeCount}
                        count={(notice.viewers ?? []).length}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
              }}
            >
              {notices.length > 5 && (
                <Button
                  className="view-btn"
                  color="primary"
                  endIcon={<ArrowRightIcon />}
                  size="small"
                  variant="text"
                  onClick={() => setIsAll((old) => !old)}
                >
                  {isAll ? 'View Less' : 'View all'}
                </Button>
              )}
            </Box>
          </Box>
        </PerfectScrollbar>
      </Card>
    </>
  );
};
export default Documents;

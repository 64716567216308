import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  InputLabel,
  FormControl,
  Link,
  OutlinedInput,
  TextField,
  Typography
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Api from 'src/Api';
import OverlaySpinner from 'src/components/common/OverlaySpinner';
import { auth } from 'src/firebase';
import { loginUser } from 'src/store/auth/thunk';
import swal from 'sweetalert';
import * as Yup from 'yup';

const logo = {
  avatarUrl: '/static/images/oren-logo.png'
};
const ResetPassword = () => {
  const [checked, setChecked] = React.useState(true);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showPassword)
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  const actionCode = getParameterByName('oobCode');
  let continueUrl = getParameterByName('continueUrl');
  if (continueUrl) {
    continueUrl = '/';
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (!actionCode && !continueUrl) {
      return navigate('/', { replace: true });
    }
    auth
      .verifyPasswordResetCode(actionCode)
      .then((email) => {
        console.log(email);
        setEmail(email);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        swal({
          title: 'Error',
          text: 'The link is malformed, expired, or has already been used',
          icon: 'error',
          button: 'Ok'
        }).then(() => navigate("/"));
      });
  }, []);

  const handlePasswordSubmit = async (val, helpers) => {
    let res;
    try {
      setLoading(true);
      let password = val.password;

      await auth.confirmPasswordReset(actionCode, password);

      res = await Api.changePasswordWithEmail({ email, password });

      if (res.data.code >= 400) {
        return swal('Error', 'Technical problem. Please try again later');
      }

      swal('Success', 'Your Password has been Changed Successfully', 'success').then(
        () => navigate('/')
      );
    } catch (error) {
      console.log('Reset password error', res);
      return swal(
        'Error',
        'Technical problem. Please try again later',
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <OverlaySpinner />}
      <Helmet>
        <title> Reset Password | ORENNOW</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              password: '',
              confirmPassword: ''
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .matches(
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
                  'Password length must be 6 and contain 1 Uppercase, 1 Lowercase, 1 Special Character, 1 Number.'
                )
                .required('New Password is required'),
              confirmPassword: Yup.string()
                .required('Confirm Password is required')
                .oneOf(
                  [Yup.ref('password'), null],
                  'Password should match with New Password!'
                )
            })}
            onSubmit={handlePasswordSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form>
                {/* <Box sx={{ mb: 3 }}>
                  
                  
                </Box> */}
                <Card id="login-card">
                  <Grid container spacing={3}>
                    <Grid className="text-center" item xs={12} md={12}>
                      <img src={logo.avatarUrl} alt="ORENNOW" id="logo2" />
                      <h2 className="forgot-password">Change your password</h2>
                    </Grid>
                  </Grid>
                  <FormControl sx={{ mt:3,mb:2, width: '46ch' }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                  <OutlinedInput
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="New Password"
                    margin="normal"
                    name="password"
                    onBlur={handleBlur('password')}
                    onChange={handleChange('password')}
                    type={showPassword ? 'text' : 'password'}
                    value={values.password}
                    variant="outlined"
                    endAdornment={
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                  }
                  />
                  <span style={{fontSize:"12px",marginLeft:"12px",marginTop:"5px",color:"red"}}>{touched.password && errors.password}</span>
                  </FormControl>

                  <FormControl sx={{ mt:2,mb:3, width: '46ch' }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                  <OutlinedInput
                    error={Boolean(
                      touched.confirmPassword && errors.confirmPassword
                    )}
                    fullWidth
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                    margin="normal"
                    name="confirmPassword"
                    onBlur={handleBlur('confirmPassword')}
                    onChange={handleChange('confirmPassword')}
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={values.confirmPassword}
                    variant="outlined"
                    endAdornment={
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                  }
                  />
                  <span style={{fontSize:"12px",marginLeft:"12px",marginTop:"5px",color:"red"}}>{
                      touched.confirmPassword && errors.confirmPassword
                    }</span>
                  </FormControl>
                  <div className="row">
                    <div className="col-md-6"></div>
                    <div className="col-md-6"></div>
                  </div>
                  <Box sx={{ py: 2 }}>
                    <Button
                      color="primary"
                      onClick={handleSubmit}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      className="login-button"
                    >
                      Change Password
                    </Button>
                  </Box>
                  <Grid container spacing={3}>
                    <Grid className="pull-left" item xs={12} md={6}></Grid>
                    <Grid className="float-right" item xs={12} md={6}>
                      <Typography
                        style={{ float: 'right' }}
                        color="textSecondary"
                        variant="body1"
                      >
                        {' '}
                        <Link component={RouterLink} to="/" variant="h6">
                          Sign In
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default ResetPassword;

import { Box, Card, CardContent } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import PublishIcon from '@material-ui/icons/Publish';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Formik } from 'formik';
import * as Yup from 'yup';
import './add_auditor.css';
import SingleFileUpload from '../common/SingleFileUpload';
import { auditorAdded, auditorEdit } from '../../store/auditors/thunk';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  tpiPhoneCheck,
  tpiCodeCheck,
  tpiEmailCheck,
  tpiNameCheck
} from '../common/DuplicationCheck';
import swal from 'sweetalert';
import OverlaySpinner from '../common/OverlaySpinner';
import { setLoading } from 'src/store/auth/actions';
import { uploadFilePromise } from 'src/Constants';
import ProfileUpload from '../common/ProfileUpload';

const EditAuditor = (props) => {
  const Navigate = useNavigate();

  const [image, setImage] = useState([]);

  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.auth);
  const { loading, currentAuditor } = useSelector((state) => state.auditors);
  const [selectedImage, setSelectedImage] = useState({
    isDeleted: false,
    file: null
  });
  const [imageChange, setImageChange] = useState(false);
  useEffect(() => {
    setSelectedImage({ isDeleted: false, file: currentAuditor.profileImage });
  }, []);

  const onSubmit = async (val) => {
    dispatch(setLoading(true));

    if (image) {
      let profileData = await uploadFilePromise(image, userData.companyId);
      val.profileImage = {
        fileName: profileData.fileName,
        fileLink: profileData.fileLink,
        filePath: profileData.filePath,
        fileSize: profileData.fileSize
      };
    }

    if (selectedImage.isDeleted) {
      val.profileImage = {
        fileName: '',
        fileLink: '',
        filePath: '',
        fileSize: ''
      };
    }

    val.tpiId = currentAuditor.tpiId;
    dispatch(auditorEdit(val, Navigate));
    dispatch(setLoading(false));
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '75%'
      }
    }
  }));
  const classes = useStyles();
  return (
    <>
      {loading && <OverlaySpinner />}
      <div style={{ marginTop: '2%', marginLeft: '1%' }} className="row">
        <div className="col-md-6">
          <h2>
            <ul class="breadcrumb">
              <li>
                <Link to="/sapphire/users">Users</Link>
              </li>
              <li>
                <Link to="">Edit Auditor</Link>
              </li>
            </ul>
          </h2>
        </div>
      </div>
      <Box
        {...props}
        style={{
          backgroundColor: 'white',
          marginLeft: '2%',
          marginRight: '2%'
        }}
      >
        <Box sx={{ mt: 1 }}>
          <CardContent>
            <div className="row">
              <Formik
                initialValues={
                  currentAuditor
                    ? {
                        code: currentAuditor.code,
                        name: currentAuditor.name,
                        phoneNo: currentAuditor.phoneNo,
                        email: currentAuditor.email,
                        contactPersonName: currentAuditor.contactPersonName
                      }
                    : null
                }
                onSubmit={(val) => onSubmit(val)}
                validationSchema={Yup.object({
                  code: Yup.string()
                    .required('Code is required')
                    .matches(/^[A-Za-z0-9_-]*$/, 'Code is invalid'),
                  name: Yup.string()
                    .required('Name is required')
                    .matches(/^[A-Za-z/' ]+$/, 'Name is invalid'),
                  email: Yup.string()
                    .required('Email is required')
                    .email('Please enter valid email'),
                  // phoneNo: Yup.string()
                  //   .required('Phone no is required')
                  //   .min(10, 'Phone no is invalid')
                  //   .max(10, 'Phone no is invalid'),
                  contactPersonName: Yup.string()
                    .required('Name is required')
                    .matches(/^[A-Za-z/' ]+$/, 'Name is invalid')
                })}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  touched,
                  setFieldTouched,
                  setFieldValue,
                  setFieldError,
                  dirty
                }) => (
                  <>
                    <form className={classes.root} onSubmit={handleSubmit}>
                      <div className="col-md-12">
                        <h2>Edit Auditor</h2>
                        <div className="image-btn my-4">
                          <ProfileUpload
                            handleFileChanges={(file) => {
                              setImage(file);
                            }}
                            defaultFile={
                              selectedImage.file?.fileName == ''
                                ? null
                                : selectedImage.file
                            }
                            handleUploadedFileDelete={() => {
                              setSelectedImage({
                                isDeleted: true,
                                file: null
                              });
                              setImageChange(true);
                            }}
                            acceptExt={'.jpg, .png'}
                          />
                          {/* <SingleFileUpload
                            handleFileChanges={(file) =>{
                              setImage(file)
                            }}
                            defaultFile={selectedImage.file?.fileName=="" ? null : selectedImage.file}
                            handleUploadedFileDelete={() => {
                              setSelectedImage({
                                isDeleted: true,
                                file: null
                              });
                              setImageChange(true);
                            }}
                            acceptExt={'.jpg, .png'}
                          /> */}
                        </div>
                        <div className="row">
                          <div className="col-md-5">
                            {/* <div>
                              <div>
                                <TextField
                                  disabled
                                  id="standard-basic"
                                  label="Company Code"
                                  variant="standard"
                                  className="my-2 form-control"
                                  onChange={handleChange('code')}
                                  value={values.code}
                                  onFocus={() => setFieldTouched('code')}
                                />
                              </div>
                              <div>
                                <small className="text-danger form-text">
                                  {touched.code && errors.code}
                                </small>
                                <br />
                              </div>
                            </div> */}
                            <div>
                              <div>
                                <TextField
                                  disabled
                                  id="standard-basic"
                                  label="Mobile Number"
                                  variant="standard"
                                  className="my-2 form-control"
                                  onChange={handleChange('phoneNo')}
                                  value={values.phoneNo}
                                  onFocus={() => setFieldTouched('phoneNo')}
                                />
                              </div>
                              <div>
                                <small className="text-danger form-text">
                                  {touched.phoneNo && errors.phoneNo}
                                </small>
                              </div>
                              <br />
                            </div>
                            <div>
                              <div>
                                <TextField
                                  id="standard-basic"
                                  label="Contact Person Name"
                                  variant="standard"
                                  className="my-2 form-control"
                                  onChange={handleChange('contactPersonName')}
                                  value={values.contactPersonName}
                                  onFocus={() =>
                                    setFieldTouched('contactPersonName')
                                  }
                                />
                              </div>
                              <div>
                                <small className="text-danger form-text">
                                  {touched.contactPersonName &&
                                    errors.contactPersonName}
                                </small>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-1"></div>
                          <div className="col-md-5">
                            <div>
                              <div>
                                <TextField
                                  id="standard-basic"
                                  label="Auditor Name"
                                  variant="standard"
                                  className="my-2 form-control"
                                  onChange={handleChange('name')}
                                  value={values.name}
                                  onFocus={() => setFieldTouched('name')}
                                />
                              </div>
                              <div>
                                <small className="text-danger form-text">
                                  {touched.name && errors.name}
                                </small>
                              </div>
                              <br />
                            </div>

                            <div>
                              <div>
                                <TextField
                                  disabled
                                  id="standard-basic"
                                  label="E-mail ID"
                                  variant="standard"
                                  className="my-2 form-control"
                                  onChange={handleChange('email')}
                                  value={values.email}
                                  onFocus={() => setFieldTouched('email')}
                                />
                              </div>
                              <div>
                                <small className="text-danger form-text">
                                  {touched.email && errors.email}
                                </small>
                              </div>
                              <br />
                            </div>
                          </div>
                        </div>
                        <div className="addbutton my-2">
                          <Button
                            className="modal-submit-btn"
                            className="view-btn"
                            color="primary"
                            size="small"
                            disabled={
                              dirty || image || imageChange ? false : true
                            }
                            onClick={handleSubmit}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </form>
                  </>
                )}
              </Formik>
            </div>
          </CardContent>
        </Box>
      </Box>
    </>
  );
};

export default EditAuditor;

import {
  Box,
  Card,
  CardContent,
  LinearProgress,
  TextField
} from '@material-ui/core';
import { WarningAmberRounded } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import ScheduleIcon from '@material-ui/icons/Schedule';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Api from 'src/Api';
import { ASSESS_STATUS, PERMISSIONS, roundTwoDecimals } from 'src/Constants';
import { auth } from 'src/firebase';
import swal from 'sweetalert';
import './category.css';
import { useNavigate } from 'react-router-dom';

const CategoryCard = ({ category, responseId, isActive = false }) => {
  return (
    <>
      {' '}
      <h4 className="d-flex align-items-center">
        <Link
          to={`/auditor/assessment/${responseId}/categories/${category._id}`}
          className={`link-title${isActive ? '' : '--disabled'}`}
        >
          {category?.category?.name}
        </Link>
        {category.questions.some(
          (ques) => ques?.assessStatus === 'REJECTED'
        ) && (
          <WarningAmberRounded
            style={{
              marginLeft: '.5rem'
            }}
            color="error"
          />
        )}
      </h4>
      <LinearProgress
        className="category-progress-bar"
        value={
          (category.questions.filter(
            (question) => question?.assessStatus !== 'NONE'
          ).length /
            category.questions.length) *
          100
        }
        variant="determinate"
      />
      <p className="category-progress-percentage">
        {roundTwoDecimals(
          (category.questions.filter(
            (question) => question?.assessStatus !== 'NONE'
          ).length /
            category.questions.length) *
            100
        )}
        %
      </p>
      <h5 className="status">
        Status :{' '}
        {category.questions.filter(
          (question) => question?.assessStatus !== 'NONE'
        ).length === category.questions.length ? (
          <span className="status-title">Complete</span>
        ) : (
          <span className="primary-red">Incomplete</span>
        )}
      </h5>
    </>
  );
};

const Category = ({
  categories,
  responseId,
  setLoading,
  status,
  fetchAssessmentResponseById,
  dueDate = '',
  assessmentId
}) => {
  const states = {
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED',
    NONE: 'NONE'
  };
  const { userData } = useSelector((state) => state.auth);
  const [reassModal, setReassModal] = useState(false);
  const [dueDateChange, setDueDateChange] = useState(states.NONE);
  const [newDueDate, setNewDueDate] = useState(dueDate);
  const navigate = useNavigate();

  const handleResponseSubmit = (status, newDueDate) => async () => {
    try {
      handleReassModalClose();
      setLoading(true);
      let assessmentQuery = {
        responseId: responseId,
        status: status,
        updatedBy: {
          name: userData?.name,
          userType: userData?.type,
          uid: auth.currentUser.uid
        },

        companyId: userData?.assoc_companies[0],
        assessmentId: assessmentId
      };

      if (newDueDate) {
        assessmentQuery.extendedDueDate = newDueDate;
      }
      let res = await Api.changeResponseStatus(assessmentQuery);
      if (res.status >= 400) throw new Error('Error in submitting assessment!');
      await fetchAssessmentResponseById();
      swal(
        'Success',
        `Changed assessment status to ${status.onlyFirstCapital()}`,
        'success'
      );
      if (status === 'COMPLETED') {
        navigate('/auditor/Assessment');
      }
    } catch (error) {
      swal({
        title: 'Error',
        text: error.message,
        icon: 'error',
        button: 'OK'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleReassModalClose = () => {
    setReassModal(false);
    setDueDateChange(states.NONE);
  };

  return (
    <Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <div className="Category">
              <h2>Categories</h2>
              <hr />
            </div>
            <div className="category-container">
              <div className="row">
                {categories.map((category) => (
                  <div className="col-md-6 col-12 pr-4">
                    <div className="row">
                      <div className="col-md-10 col-12">
                        <CategoryCard
                          category={category}
                          responseId={responseId}
                          isActive={
                            !PERMISSIONS.TPI.NOASSESS_STATES.includes(status)
                          }
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="d-flex w-100 justify-content-center py-2">
              <button
                className="btn btn-reassessment"
                onClick={() => setReassModal(true)}
                disabled={
                  status === 'REASSESSMENT' ||
                  // everything is assessed and
                  !(
                    categories.every((category) => {
                      return category.questions.every(
                        (question) => question.assessStatus !== 'NONE'
                      );
                    }) &&
                    // dont want some category in which some question assess status does not have
                    // NONE status and has status REJECTED
                    categories.some((category) => {
                      return category.questions.some(
                        (question) => question.assessStatus === 'REJECTED'
                      );
                    })
                  )
                }
              >
                Mark for Reassessment
              </button>
              <button
                className="btn btn-completed"
                onClick={handleResponseSubmit('COMPLETED')}
                disabled={
                  status === 'COMPLETED' ||
                  // there is some question which is not accepted
                  categories.some((category) => {
                    return category.questions.some(
                      (question) => question.assessStatus !== 'ACCEPTED'
                    );
                  })
                }
              >
                Mark as Completed
              </button>
            </div>
          </CardContent>
        </Card>
      </Box>
      <Modal
        size="md"
        show={reassModal}
        onHide={handleReassModalClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        {/* <Modal.Header></Modal.Header> */}
        <Modal.Body>
          <div className="d-flex w-100 justify-content-end">
            <CloseIcon onClick={handleReassModalClose} />
          </div>
          <div className="d-flex w-100 h-100 justify-content-center align-items-center flex-column">
            {dueDateChange === states.NONE && (
              <>
                <ScheduleIcon
                  fontSize={'large'}
                  style={{
                    color: '#F5CD6A',
                    height: '4rem',
                    width: '4rem'
                  }}
                />
                <div
                  className={'mt-3'}
                  style={{
                    fontSize: '1.2rem',
                    textAlign: 'center'
                  }}
                >
                  Do you want to change due date for this assessment?
                </div>
                <div className="d-flex my-4">
                  <Button
                    variant="danger"
                    className="mx-2"
                    size="lg"
                    onClick={() => setDueDateChange(states.ACCEPTED)}
                  >
                    Yes
                  </Button>
                  <Button
                    style={{ backgroundColor: '#E8E8E8' }}
                    variant="light"
                    className="mx-2"
                    size="lg"
                    onClick={handleResponseSubmit('REASSESSMENT')}
                  >
                    No
                  </Button>
                </div>
              </>
            )}

            {dueDateChange === states.ACCEPTED && (
              <>
                <ScheduleIcon
                  fontSize={'large'}
                  style={{
                    color: '#F5CD6A',
                    height: '4rem',
                    width: '4rem'
                  }}
                />
                <div
                  className="w-100 text-center d-flex justify-content-center align-items-center"
                  style={{
                    marginTop: '2rem'
                  }}
                >
                  <div>New Due Date</div>
                  <TextField
                    type="date"
                    onChange={(e) => setNewDueDate(e.target.value)}
                    inputProps={{
                      min: moment(dueDate).format('YYYY-MM-DD').toString()
                    }}
                    defaultValue={moment(dueDate)
                      .format('YYYY-MM-DD')
                      .toString()}
                    style={{
                      height: 'auto',
                      marginLeft: '1rem',
                      fontSize: '1.3rem'
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </div>
                <button
                  className="btn btn-reassessment my-3"
                  onClick={handleResponseSubmit('REASSESSMENT', newDueDate)}
                >
                  Mark For Reassessment
                </button>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </Box>
  );
};

export default Category;

import PerfectScrollbar from 'react-perfect-scrollbar';
import '../../css/Engagement/cap1.css';
import { Box, Card, CardHeader, Divider } from '@material-ui/core';
// import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import 'bootstrap/dist/css/bootstrap.min.css';

const cap = {
  avatarUrl: '/static/images/caps/Vector.png',
  type: 'Red Score',
  score: '40',
  color: 'Red',
  LastUpdated: '31/03/2021'
};

const CapDetails = ({ OrangeCap, viewDetails }) => (
  <div className="">
    <div className="">
      <div className="col-lg-12 col-sm-12">
        <div className="card hovercard card2 hovercard2">
          <div className="cardheader cardheader2">
            <div className="title1 title12">
              <a target="_blank">
                {OrangeCap.length} <br /> Orange Score
              </a>
            </div>
          </div>
          <div className="avatar avatar2">
            <img alt="" src={cap.avatarUrl} />
          </div>
          <div className="info info2">
            <div className="title title2" onClick={() => viewDetails()}>
              <a target="_blank">View</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default CapDetails;

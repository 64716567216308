
import Highcharts from "highcharts";
export const defaultOptionsDataSet1 = {
    chart: {
        type: 'column'
    },
    title: {
        text: ''
    },
    xAxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May','Jun','Jul']
    },
    yAxis: {
        min: 0,
        title: {
            text: ''
        },
        stackLabels: {
            enabled: true,
            style: {
                fontWeight: 'bold',
                color: ( // theme
                    Highcharts.defaultOptions.title.style &&
                    Highcharts.defaultOptions.title.style.color
                ) || 'gray'
            }
        }
    },
    legend: {
        align: 'right',
        x: 0,
        verticalAlign: 'top',
        y: 0,
        floating: false,
        backgroundColor:
            Highcharts.defaultOptions.legend.backgroundColor || 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false,
    },
    
    plotOptions: {
        column: {
            stacking: 'normal',
           
            dataLabels: {
                enabled: false
            }
        }
    },
    series: [  {
        name: 'Accidents',
        data: [300, 400, 400, 200, 500],
        color:Highcharts.defaultOptions.legend.backgroundColor || '#ff8a65',
        
    },
    {
        name: 'Unsafe Act',
        data: [200, 200, 300, 200, 100],
        color:Highcharts.defaultOptions.legend.backgroundColor || '#ce93d8',
        
    },
    {
        name: 'Near Misses',
        data: [200, 300, 400, 700, 200],
        color:Highcharts.defaultOptions.legend.backgroundColor || '#b3e5fc',
       
             
    }],
};

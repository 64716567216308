import { Box, Card, CardContent } from '@material-ui/core';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import PublishIcon from '@material-ui/icons/Publish';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Formik } from 'formik';
import * as Yup from 'yup';
import './add_auditor.css';
import SingleFileUpload from '../common/SingleFileUpload';
import { contractorrAdded } from '../../store/auditors/thunk';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  companyEmailCheck,
  companyPhoneCheck,
  contractCodeCheck,
  contractEmailCheck,
  contractNameCheck,
  tpiEmailCheck,
  tpiPhoneCheck
} from '../common/DuplicationCheck';
import swal from 'sweetalert';
import { setLoading } from 'src/store/auth/actions';
import OverlaySpinner from '../common/OverlaySpinner';
import { uploadFilePromise } from 'src/Constants';
import { Container } from 'reactstrap';
import ProfileUpload from '../common/ProfileUpload';

const AddCompany = (props) => {
  const Navigate = useNavigate();

  const [profileImage, setProfileImage] = useState(null);
  const { userData, loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    let val = { ...data };
    //console.log('Values>>', val);
    if (profileImage) {
      let profileData = await uploadFilePromise(
        profileImage,
        userData.companyId
      );
      val.profileImage = {
        fileName: profileData.fileName,
        fileLink: profileData.fileLink,
        filePath: profileData.filePath,
        fileSize: profileData.fileSize
      };
    }

    dispatch(setLoading(true));
      if (
      await Promise.all([
        contractEmailCheck(val.emailid),
        tpiEmailCheck(val.emailid),
        companyEmailCheck(val.emailid)
      ]).then((res) => res.some((resp) => resp === true))
    ) {
      swal({
        title: 'Error',
        text: 'Email already exists',
        icon: 'error',
        button: 'Ok'
      });
      dispatch(setLoading(false));
      return;
    } else if (await contractNameCheck(val.name)) {
      swal({
        title: 'Error',
        text: 'Name no alredy exists',
        icon: 'error',
        button: 'Ok'
      });
      dispatch(setLoading(false));
      return;
    }

    val.cmp_id = userData.companyId;
    val.cmp_name = userData.name;
    await dispatch(contractorrAdded(val, Navigate));
    dispatch(setLoading(false));
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '75%'
      }
    }
  }));
  const classes = useStyles();
  return (
    <>
      {loading && <OverlaySpinner />}
      <div style={{ marginTop: '2%', marginLeft: '1%' }} className="row">
        <div className="col-md-6">
          <h2>
            <ul class="breadcrumb">
              <li>
                <Link to="/sapphire/companies">Users</Link>
              </li>
              <li>
                <Link to="">Add Company</Link>
              </li>
            </ul>
          </h2>
        </div>
      </div>
      <Box style={{ marginLeft: '2%', marginRight: '2%' }} {...props}>
        <Box>
          <Card>
            <CardContent>
              <div className="row">
                <Formik
                  initialValues={{
                    code: userData?.code ?? '',
                    name: '',
                    // mobile: '',
                    emailid: '',
                    location: '',
                    contactPersonName: ''
                  }}
                  onSubmit={(val) => onSubmit(val)}
                  validationSchema={Yup.object({
                    // code: Yup.string()
                    //   .required('Code is required')
                    //   .matches(/^[A-Za-z0-9_-]*$/, 'Code is invalid'),
                    name: Yup.string()
                      .required('Name is required')
                      .matches(/^[A-Za-z/' ]+$/, 'Name is invalid'),
                    location: Yup.string().required('Location is required'),
                    emailid: Yup.string()
                      .required('Email is required')
                      .email('Please enter valid email'),
                    // mobile: Yup.string()
                    //   .required('Phone no is required')
                    //   .min(10, 'Phone no is invalid')
                    //   .max(10, 'Phone no is invalid'),
                    contactPersonName: Yup.string()
                      .required('Name is required')
                      .matches(/^[A-Za-z/' ]+$/, 'Name is invalid')
                  })}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    errors,
                    touched,
                    setFieldTouched,
                    setFieldValue,
                    setFieldError
                  }) => (
                    <>
                      <form className={classes.root} onSubmit={handleSubmit}>
                        <div className="col-md-12">
                          <h2>Add Company</h2>
                          <div className="image-btn my-4">
                            <ProfileUpload
                              handleFileChanges={(file) =>
                                setProfileImage(file)
                              }
                              acceptExt={'.jpg, .png'}
                            />
                            <p>(Format type : .jpg , .png)</p>
                          </div>
                          <div className="row">
                            <div className="col-md-5">
                              {/* <div>
                                <TextField
                                  id="standard-basic"
                                  label="Company Code"
                                  variant="standard"
                                  className="my-2"
                                  onChange={handleChange('code')}
                                  value={values.code}
                                  onFocus={() => setFieldTouched('code')}
                                />
                                <div>
                                  <small className="text-danger form-text">
                                    {touched.code && errors.code}
                                  </small>
                                </div>
                                <br />
                              </div> */}
                              <div>
                                <div>
                                  <TextField
                                    id="standard-basic"
                                    label="Location"
                                    variant="standard"
                                    className="my-2 form-control"
                                    onChange={handleChange('location')}
                                    value={values.location}
                                    onFocus={() => setFieldTouched('location')}
                                  />
                                </div>
                                <div>
                                  <small className="text-danger form-text">
                                    {touched.location && errors.location}
                                  </small>

                                  <br />
                                </div>
                              </div>
                              <div>
                                <div>
                                  <TextField
                                    id="standard-basic"
                                    label="E-mail ID"
                                    variant="standard"
                                    className="my-2 form-control"
                                    onChange={handleChange('emailid')}
                                    value={values.emailid}
                                    onFocus={() => setFieldTouched('emailid')}
                                  />
                                </div>
                                <div>
                                  <small className="text-danger form-text">
                                    {touched.emailid && errors.emailid}
                                  </small>
                                  <br />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-1"></div>
                            <div className="col-md-6">
                              <div>
                                <TextField
                                  id="standard-basic"
                                  label="Company Name"
                                  variant="standard"
                                  className="my-2 form-control"
                                  onChange={handleChange('name')}
                                  value={values.name}
                                  onFocus={() => setFieldTouched('name')}
                                />
                              </div>
                              <div>
                                <small className="text-danger form-text">
                                  {touched.name && errors.name}
                                </small>
                                <br />
                              </div>

                              {/* <div>
                                <TextField
                                  id="standard-basic"
                                  label="Phone Number"
                                  variant="standard"
                                  className="my-2 form-control"
                                  onChange={handleChange('mobile')}
                                  value={values.mobile}
                                  onFocus={() => setFieldTouched('mobile')}
                                />
                              </div>
                              <div>
                                <small className="text-danger form-text">
                                  {touched.mobile && errors.mobile}
                                </small>
                                <br />
                              </div> */}

                              <div>
                                <TextField
                                  id="standard-basic"
                                  label="Contact Person Name"
                                  variant="standard"
                                  className="my-2 form-control"
                                  onChange={handleChange('contactPersonName')}
                                  value={values.contactPersonName}
                                  onFocus={() =>
                                    setFieldTouched('contactPersonName')
                                  }
                                />
                              </div>
                              <div>
                                <small className="text-danger form-text">
                                  {touched.contactPersonName &&
                                    errors.contactPersonName}
                                </small>
                                <br />
                              </div>
                            </div>
                          </div>
                          <div className="addbutton my-2">
                            <Button
                              className="modal-submit-btn"
                              className="view-btn "
                              color="primary"
                              size="small"
                              onClick={handleSubmit}
                            >
                              Add
                            </Button>
                          </div>
                        </div>
                      </form>
                    </>
                  )}
                </Formik>
              </div>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default AddCompany;

import React, { useEffect, useRef, useState } from 'react';
import { Button, Card } from 'reactstrap';
import swal from 'sweetalert';
import FileDisplay from './FileDisplay';
import AttachFile from '@material-ui/icons/AttachFile';

const MultipleFileUploads = ({
  handleFileChanges,
  acceptExt = '*',
  defaultFiles,
  handleUploadedFilesDelete,
  disable
}) => {
  const fileInputRef = useRef();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    handleFileChanges(files);
    fileInputRef.current.value = '';
    return () => {};
  }, [files]);

  const handleFileSelectionChanges = (e) => {
    console.log(e.target.files);
    if (e.target.files) {
      let filteredFiles = [];
      let receivedFiles = e.target.files;

      Array.prototype.forEach.call(receivedFiles, (file) => {
        if (acceptExt !== '*') {
          let extensions = acceptExt
            .split(',')
            .map((ext) => ext.trim().substring(1));
          let fileNameSplit = file.name.split('.');

          if (!extensions.includes(fileNameSplit[fileNameSplit.length - 1])) {
            fileInputRef.current.value = '';

            return swal({
              title: 'Error',
              text: 'Files with unsupported type found',
              icon: 'error',
              button: 'OK'
            });
          }
        }

        filteredFiles.push(file);
      });

      if (receivedFiles.length === filteredFiles.length)
        setFiles((oldState) => [...oldState, ...filteredFiles]);
    }
  };

  const handleFileDelete = (index) => () => {
    let newState = [...files];
    newState.splice(index, 1);
    setFiles(newState);
  };

  return (
    <div>
      <Button
        className="view-btn"
        disable={disable}
        onClick={() => fileInputRef.current.click()}
      >
        <AttachFile className="response-file" />
      </Button>
      <input
        disabled={disable}
        type="file"
        multiple
        style={{ visibility: 'hidden' }}
        ref={fileInputRef}
        onChange={handleFileSelectionChanges}
        accept={acceptExt}
      />
      <div
        style={{
          margin: '.5rem 0 0 0',
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap'
        }}
      >
        {files.map((file, index) => (
          <FileDisplay
            key={index.toString()}
            file={file}
            handleDelete={handleFileDelete(index)}
          />
        ))}

        {defaultFiles &&
          defaultFiles.map((file, index) => (
            <FileDisplay
              key={index.toString()}
              file={file}
              handleDelete={handleUploadedFilesDelete(index)}
              mode="edit"
            />
          ))}
      </div>
    </div>
  );
};

export default MultipleFileUploads;

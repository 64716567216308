import { writeFile, utils } from 'xlsx';

export function writeDataToExcel(fileName, data) {
  console.log(data);

  const wb = utils.book_new();

  data.forEach((arr) => {
    const ws = utils.json_to_sheet(arr.data);

    utils.book_append_sheet(wb, ws, arr.sheetName, true);
  });

  writeFile(wb, `${fileName.split(/\s/).join('_')}.xlsx`);
}

import { Doughnut } from 'react-chartjs-2';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  // Select,
  useTheme
} from '@material-ui/core';
import 'src/css/dashboard/tpi_dashboard.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import API from '../../../Api'
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import CustomSpinner from 'src/components/common/CustomSpinner';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const TrafficByDevice = (props) => {
  const theme = useTheme();

  const { userData } = useSelector((state) => state.auth);
  const [allContractors, setAllContractors] = useState([]);
  const [filter, setFilter] = useState('ALL');
  const [graphData, setGraphData] = useState({});
  const [loading, setLoading] = useState(false);

  const data = {
    datasets: [
      {
        data: [10, 10, 10, 2],
        backgroundColor: ['#9bdd9b', '#f4cc69', '#f99564', '#5d5d5d'],
        borderWidth: 5,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels: ['Environment', 'Social', 'Governance', 'Others']
  };

  const options = {
    animation: false,
    cutoutPercentage: 70,
    layout: {
      padding: 0
    },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: true,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };
  const fetchGraphData = async (id) => {
    try {
      let res;
      if (!!graphData?.[id]) return;
      setLoading(true);
      if (id === 'ALL') {
        res = (
          await API.getESGOForAllContractors({
            companyId: userData.assoc_companies[0]
          })
        )?.data;
      } else {
        res = (
          await API.getESGOForContractor({
            contractorId: id
          })
        )?.data;
      }
      setGraphData({
        ...graphData,
        [id]: [
          res.data.environment.scored,
          res.data.social.scored,
          res.data.governance.scored,
          res.data.others.scored
        ]
      });
    } catch (error) {
      swal('Error', 'Failed to fetch ESGO data', 'error');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    userData?.assoc_companies[0] && fetchGraphData(filter);
    return () => {};
  }, [filter]);

  useEffect(() => {
    userData?.assoc_companies[0] && init();
    return () => {};
  }, [userData]);


  const init = async () => {
    let contractors = await API.getAllContractorsByID({
      companyId: userData?.assoc_companies
    });
    setAllContractors(contractors.data.data);
    setFilter(contractors.data.data.length>0 ? contractors.data.data[0].user : null)
  };
  

  const classes = useStyles();
  const [Contractor, setContractor] = React.useState('');



  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <Card {...props}>
      <CardContent>
        <div className="row">
          <div className="col-md-6">
            <h2>Overall Assessments</h2>
          </div>
          <div className="col-md-6">
          <div className="pull-right">
              <FormControl
                variant="outlined"
                className={classes.formControl}
                className="select2"
              >
                <Select
                  value={filter}
                  onChange={handleChange}
                  displayEmpty
                  className={(classes.selectEmpty, classes.formControl)}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {/* <MenuItem value="ALL">
                    <p>Companies</p>
                  </MenuItem> */}
                  {allContractors.map((contra) => (
                    <MenuItem value={contra.user}>
                      {contra.name}
                    </MenuItem>
                  ))}
                </Select>
                {/* <FormHelperText>Without label</FormHelperText> */}
              </FormControl>
            </div>
          </div>
        </div>
        <Divider />

        <Box
          sx={{
            height: 200,
            position: 'relative',
            top: 30
          }}
        >
         {loading && (
            <div className="d-flex w-100 h-100 justify-content-center align-items-center">
              <CustomSpinner />
            </div>
          )}

          {graphData[filter] && graphData[filter].every((val) => val === 0) && (
            <div className="d-flex w-100 h-100 justify-content-center align-items-center">
              No data available
            </div>
          )}

          {graphData[filter] && graphData[filter].length > 0 && (
            <Doughnut
              redraw={!!filter}
              data={{
                datasets: [
                  {
                    data: graphData[filter] ?? [],
                    backgroundColor: [
                      '#9BDD9B',
                      '#F4CC69',
                      '#F99564',
                      '#5D5D5D'
                    ],
                    borderWidth: 5,
                    borderColor: colors.common.white,
                    hoverBorderColor: colors.common.white
                  }
                ],
                labels: ['Environment', 'Social', 'Governance', 'Others']
              }}
              options={options}
            />
          )}
        </Box>
        <Box
          sx={{
            justifyContent: 'center',
            pt: 10,
            pl: 20
          }}
        >
          <div className="row ">
            <div className="col-md-6 dots">
              <li className="dot-green">
                <h5 className="dashboard">Environment</h5>
              </li>
            </div>
            <div className="col-md-6 dots">
              <li className="dot-orange">
                <h5 className="dashboard">Social</h5>
              </li>
            </div>
            <div className="col-md-6 dots">
              <li className="dot-red">
                <h5 className="dashboard">Governance</h5>
              </li>
            </div>
            <div className="col-md-6 dots">
              <li className="dot-grey">
                <h5 className="dashboard">Others</h5>
              </li>
            </div>
          </div>
        </Box>
      </CardContent>
    </Card>
  );
};

export default TrafficByDevice;

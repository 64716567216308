import { Box, Card, CardContent } from '@material-ui/core';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import PublishIcon from '@material-ui/icons/Publish';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import './add_auditor.css';
import ProfileUpload from '../common/ProfileUpload';
import { auditorAdded } from '../../store/auditors/thunk';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  tpiPhoneCheck,
  tpiCodeCheck,
  tpiEmailCheck,
  tpiNameCheck,
  contractPhoneCheck,
  companyPhoneCheck,
  contractEmailCheck,
  companyEmailCheck
} from '../common/DuplicationCheck';
import swal from 'sweetalert';
import OverlaySpinner from '../common/OverlaySpinner';
import { setLoading } from 'src/store/auth/actions';
import { GenRandomString, uploadFilePromise } from 'src/Constants';
import { storage } from 'src/firebase';

const Auditor = (props) => {
  const Navigate = useNavigate();

  const [profileImage, setProfileImage] = useState(null);
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.auditors);

  const onSubmit = async (data) => {
    console.log('submited');
    let val = { ...data };
    dispatch(setLoading(true));
    try {
      if (profileImage) {
        let profileData = await uploadFilePromise(
          profileImage,
          userData.companyId
        );
        val.profileImage = {
          fileName: profileData.fileName,
          fileLink: profileData.fileLink,
          filePath: profileData.filePath,
          fileSize: profileData.fileSize
        };
      }

      // val.phoneNo = '+91' + val.phoneNo;
      val.assoc_companies = [userData.companyId];

      if (
        await Promise.all([
          contractEmailCheck(val.email),
          tpiEmailCheck(val.email),
          companyEmailCheck(val.email)
        ]).then((res) => res.some((resp) => resp === true))
      ) {
        swal({
          title: 'Error',
          text: 'Email already exists',
          icon: 'error',
          button: 'Ok'
        });
        dispatch(setLoading(false));
        return;
      } else if (await tpiNameCheck(val.name)) {
        swal({
          title: 'Error',
          text: 'Name already exists',
          icon: 'error',
          button: 'Ok'
        });
        dispatch(setLoading(false));
        return;
      }
    } catch (error) {
      console.log('submit error ', error);
    }

    // val.cmp_id = userData.companyId;
    //val.cmp_name = userData.name;

    dispatch(auditorAdded(val, Navigate));
    dispatch(setLoading(false));
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '75%'
      }
    }
  }));

  const classes = useStyles();
  return (
    <>
      {loading && <OverlaySpinner />}
      <div style={{ marginTop: '2%', marginLeft: '1%' }} className="row">
        <div className="col-md-6">
          <h2>
            <ul class="breadcrumb">
              <li>
                <Link to="/sapphire/users">Users</Link>
              </li>
              <li>
                <Link to="">Add Auditor</Link>
              </li>
            </ul>
          </h2>
        </div>
      </div>
      <Box style={{ marginLeft: '2%', marginRight: '2%' }} {...props}>
        <Box>
          <CardContent>
            <div className="row">
              <Formik
                initialValues={{
                  code: userData?.code ?? '',
                  name: '',
                  email: '',
                  contactPersonName: ''
                }}
                onSubmit={onSubmit}
                validationSchema={Yup.object({
                  // code: Yup.string()
                  //   .required('Code is required')
                  //   .matches(/^[A-Za-z0-9_-]*$/, 'Code is invalid'),
                  name: Yup.string()
                    .required('Name is required')
                    .matches(/^[A-Za-z/' ]+$/, 'Name is invalid'),
                  email: Yup.string()
                    .required('Email is required')
                    .email('Please enter valid email'),
                  // phoneNo: Yup.string()
                  //   .required('Phone no is required')
                  //   .min(10, 'Phone no is invalid')
                  //   .max(10, 'Phone no is invalid'),
                  contactPersonName: Yup.string()
                    .required('Name is required')
                    .matches(/^[A-Za-z/' ]+$/, 'Name is invalid')
                })}
              >
                {({
                  handleChange,
                  values,
                  errors,
                  touched,
                  setFieldTouched,
                  setFieldValue,
                  setFieldError
                }) => (
                  <Form className={classes.root}>
                    <div className="col-md-12">
                      <h2>Profile image</h2>
                      <div className="image-btn my-4">
                        <div className="">
                          <div>
                            <ProfileUpload
                              handleFileChanges={(file) =>
                                setProfileImage(file)
                              }
                              acceptExt={'.jpg, .png'}
                            />
                          </div>
                          <div>
                            <p>(Format type : .jpg , .png)</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* <div className="col-md-5">
                            <div>
                              <div>
                                <TextField
                                  id="standard-basic"
                                  label="Mobile Number"
                                  variant="standard"
                                  className="my-2 form-control"
                                  onChange={handleChange('phoneNo')}
                                  value={values.phoneNo}
                                  onFocus={() => setFieldTouched('phoneNo')}
                                />
                              </div>
                              <div>
                                <small className="text-danger form-text">
                                  {touched.phoneNo && errors.phoneNo}
                                </small>
                              </div>
                              <br />
                            </div>
                            <div>
                              <div>
                                <TextField
                                  id="standard-basic"
                                  label="Contact Person Name"
                                  variant="standard"
                                  className="my-2 form-control"
                                  onChange={handleChange('contactPersonName')}
                                  value={values.contactPersonName}
                                  onFocus={() =>
                                    setFieldTouched('contactPersonName')
                                  }
                                />
                              </div>
                              <div>
                                <small className="text-danger form-text">
                                  {touched.contactPersonName &&
                                    errors.contactPersonName}
                                </small>
                              </div>
                            </div>
                          </div> */}
                        <div className="col-md-1"></div>
                        <div className="col-md-6">
                          <div>
                            <div>
                              <TextField
                                id="standard-basic"
                                label="Auditor Name"
                                variant="standard"
                                className="my-2 form-control"
                                onChange={handleChange('name')}
                                value={values.name}
                                onFocus={() => setFieldTouched('name')}
                              />
                            </div>
                            <div>
                              <small className="text-danger form-text">
                                {touched.name && errors.name}
                              </small>
                            </div>
                            <br />
                          </div>

                          <div>
                            <div>
                              <TextField
                                id="standard-basic"
                                label="E-mail ID"
                                variant="standard"
                                className="my-2 form-control"
                                onChange={handleChange('email')}
                                value={values.email}
                                onFocus={() => setFieldTouched('email')}
                              />
                            </div>
                            <div>
                              <small className="text-danger form-text">
                                {touched.email && errors.email}
                              </small>
                            </div>
                            <br />
                          </div>
                        </div>
                      </div>
                      <div className="addbutton my-2">
                        <Button
                          className="modal-submit-btn"
                          color="primary"
                          size="small"
                          type="submit"
                          onClick={() => onSubmit(values)}
                        >
                          Add
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </CardContent>
        </Box>
      </Box>
    </>
  );
};

export default Auditor;

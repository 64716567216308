import { MDBDataTableV5 } from 'mdbreact';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import API from '../../Api';
import '../../css/dashboard/assessments.css';
import OverlaySpinner from '../common/OverlaySpinner';

export default function Basic() {
  const { userData } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [leaderBoard, setLeaderBoard] = useState([]);
  useEffect(() => {
    if (userData) {
      fetchLeaderBoard();
    }
  }, [userData]);

  const fetchLeaderBoard = async () => {
    setLoading(true);
    const companyId = userData.cmp_id ?? userData.companyId ?? userData.assoc_companies[0];
    let response = await API.fetchLeaderBoard(companyId);
    console.log('fetchleaderboard', response.data.result);
    setLeaderBoard(response.data.result);

    setLoading(false);
  };

  return loading ? (
    <OverlaySpinner />
  ) : (
    <MDBDataTableV5
     searchBottom={false}
     searchTop={false} 
      hover
      entriesOptions={[10, 20, 30]}
      entries={10}
      pagesAmount={4}
      data={{
        columns: [
          {
            label: 'Sr. No',
            field: 'ref',
            sort: 'enable',
            width: 150
          },
          {
            label: 'Company',
            field: 'Company',
            width: 270
          },
          {
            label: 'Score',
            field: 'Score',
            width: 270
          }
        ],
        rows: leaderBoard
          ? leaderBoard.map((item, index) => {
              return {
                ref: index + 1,
                Company: item?.name,
                Score: item?.points
              };
            })
          : null
      }}
    />
  );
}

import PerfectScrollbar from 'react-perfect-scrollbar';

import { Box, Card, CardHeader, Divider } from '@material-ui/core';
// import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/Engagement/cap1.css';

const cap = {
  avatarUrl: '/static/images/caps/Vector.png',
  type: 'Red Score',
  score: '40',
  color: 'Red',
  LastUpdated: '31/03/2021'
};

const CapDetails = ({ RedCap, viewDetails }) => (
  <div className="">
    <div className="">
      <div className="col-lg-12 col-sm-12">
        <div className="card hovercard">
          <div className="cardheader">
            <div className="title1">
              <a target="_blank">
                {RedCap.length} <br /> Red Score
              </a>
            </div>
          </div>
          <div className="avatar">
            <img alt="" src={cap.avatarUrl} />
          </div>
          <div className="info">
            <div className="title" onClick={() => viewDetails()}>
              <a target="_blank">View</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default CapDetails;

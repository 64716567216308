import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './reducers'


// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
// const reduxMiddlewares =  [thunk]
export const store = configureStore({
    reducer: {
        ...rootReducer
    },
  })

// const store = createStore(
//     rootReducer,
//     composeEnhancers(applyMiddleware(...reduxMiddlewares))
// )

export default store  
import {
    SET_ALLDATA, SET_ALL_AUDITORS,
    SET_ALL_CONTRACTORS, SET_CURRENT_AUDITOR,
    SET_CURRENT_COMPANY, SET_LOADING
} from './types';

const initialState = {
  loading: false,
  allAuditors: [],
  allContrators: [],
  currentAuditor: null,
  currentCompany: null
};

const auditor = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_LOADING:
      state = {
        ...state,
        loading: payload
      };
      return state;
    case SET_ALL_AUDITORS:
      state = {
        ...state,
        allAuditors: payload
      };
      return state;
    case SET_ALL_CONTRACTORS:
      state = {
        ...state,
        allContrators: payload
      };
      return state;
    case SET_CURRENT_AUDITOR:
      state = {
        ...state,
        currentAuditor: payload
      };
      return state;
    case SET_CURRENT_COMPANY:
      state = {
        ...state,
        currentCompany: payload
      };
      return state;
    case SET_ALLDATA:
      state = {
        ...state,
        loading: false,
        allAuditors: [],
        allContrators: [],
        currentAuditor: null,
        currentCompany: null
      };
      return state;
    default:
      return state;
  }
};

export default auditor;

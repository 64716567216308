import { Box, Card, Container } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Edit_assess from 'src/components/ATGL/assessment/edit_assessment';

const Edit_Assessment = () => {
  return (
    <>
      <Helmet>
        <title>Assessment | Oren</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <h2>
            <ul class="breadcrumb">
              <li>
                <Link to="/sapphire/Assessment">Assessment</Link>
              </li>
              <li>
                <Link to="#">Edit Assessment</Link>
              </li>
            </ul>
          </h2>
          <Card className="col-sm-12">
            <Edit_assess />
          </Card>

          <Box sx={{ pt: 3 }}></Box>
        </Container>
      </Box>
    </>
  );
};

export default Edit_Assessment;

import {
  Box,
  Button,
  Card,
  CardContent,
  colors,
  Divider,
  Typography,
  // Select,
  useTheme
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
// import 'src/css/dashboard/tpi_dashboard.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useFormik } from 'formik';
import moment from 'moment';
import { React, useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import Api from 'src/Api';
import CustomSpinner from 'src/components/common/CustomSpinner';
import { getCapData } from 'src/Constants';
import swal from 'sweetalert';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  typography: {
    padding: theme.spacing(2)
  }
}));

const displayDate = (date, month, year) => {
  let time = moment();
  if (year) time.set('year', year);
  if (month) time.set('month', month);
  if (date) {
    time.set('date', date);
    return time.format('DD MMM YYYY');
  }
  return time.format('MMM YYYY');
};

const CapCompareGraph = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const graphFormik = useFormik({
    initialValues: {
      location: '',
      graphMonth: moment().month()
    }
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const {
    userData: { companyId }
  } = useSelector((state) => state.auth);
  const views = {
    GRAPH: 'GRAPH',
    COMPARE: 'COMPARE'
  };
  const [currView, setCurrView] = useState(views.GRAPH);
  const [cmpGraph1, setCmpGraph1] = useState([0, 0, 0]);
  const [cmpGraph2, setCmpGraph2] = useState([0, 0, 0]);

  const theme = useTheme();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFetchCompare = (date, month, year, setData) => {
    let query = {
      from: '',
      to: '',
      companyId
    };
    if (date) {
      query.from = moment()
        .set({ date, month, year })
        .startOf('day')
        .toISOString();
      query.to = moment().set({ date, month, year }).endOf('day').toISOString();
    } else {
      query.from = moment().set({ month, year }).startOf('month').toISOString();
      query.to = moment().set({ month, year }).endOf('month').toISOString();
    }
    fetchCapDetails(
      query.from,
      query.to,
      graphFormik.values.location,
      (data) => {
        let red = 0;
        let green = 0;
        let orange = 0;
        data.forEach((score) => {
          let cap = getCapData((score.scored / score.total) * 100);
          if (cap.color === 'Red') red++;
          else if (cap.color === 'Green') green++;
          else orange++;
        });
        setData([green, orange, red]);
      }
    );
  };

  const setCompareView = (values) => {
    setCurrView(views.COMPARE);
    setAnchorEl(null);
    setLoading2(true);
    setLoading3(true);
    handleFetchCompare(
      values.compare1.date,
      values.compare1.month,
      values.compare1.year,
      (data) => {
        setCmpGraph1(data);
        setLoading2(false);
      }
    );
    handleFetchCompare(
      values.compare2.date,
      values.compare2.month,
      values.compare2.year,
      (data) => {
        setCmpGraph2(data);
        setLoading3(false);
      }
    );
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const options = {
    animation: false,
    cutoutPercentage: 70,
    layout: {
      padding: 0
    },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: true,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const classes = useStyles();
  const [locations, setLocations] = useState([]);
  const [singleGraph, setSingleGraph] = useState([0, 0, 0]);

  const compareFormik = useFormik({
    initialValues: {
      compare1: {
        date: '',
        month: '',
        year: ''
      },
      compare2: {
        date: '',
        month: '',
        year: ''
      }
    },
    validationSchema: Yup.object().shape({
      compare1: Yup.object().shape({
        month: Yup.number().required(),
        year: Yup.number().required()
      }),
      compare2: Yup.object().shape({
        month: Yup.number().required(),
        year: Yup.number().required()
      })
    }),
    onSubmit: setCompareView
  });

  const fetchCapDetails = async (from, to, location, callback) => {
    try {
      let query = {
        from,
        to,
        companyId
      };
      if (!!location) {
        query.location = location;
      }
      let res = (await Api.getCapPerformanceData(query)).data.data;
      return callback(res);
    } catch (error) {
      swal('Error', error.message, 'error');
    }
  };

  useEffect(() => {
    if (currView === views.GRAPH) {
      setLoading1(true);
      let from = moment()
        .set('month', graphFormik.values.graphMonth)
        .startOf('month');
      let to = moment()
        .set('month', graphFormik.values.graphMonth)
        .endOf('month');
      let location = graphFormik.values.location;
      fetchCapDetails(from, to, location, (data) => {
        let red = 0;
        let green = 0;
        let orange = 0;
        data.forEach((score) => {
          let cap = getCapData((score.scored / score.total) * 100);
          if (cap.color === 'Red') red++;
          else if (cap.color === 'Green') green++;
          else orange++;
        });
        setSingleGraph([green, orange, red]);
        setLoading1(false);
      });
    }
    if (currView === views.COMPARE) {
      compareFormik.isValid && setCompareView(compareFormik.values);
    }
    return () => {};
  }, [graphFormik.values.graphMonth, graphFormik.values.location, currView]);

  const fetchLocations = async () => {
    let contractors = await Api.getAllContractorsByID({
      companyId: companyId
    });
    setLocations([
      ...new Set(contractors.data.data.map((contra) => contra.location))
    ]);
  };

  useEffect(() => {
    companyId && fetchLocations();
    return () => {};
  }, [companyId]);

  return (
    <Card {...props}>
      <CardContent style={{ height: '100%' }}>
        <div className="row">
          <div className="col-md-4">
            <h2>Performance</h2>
          </div>
          <div className="col-md-8">
            <Button
              aria-describedby={id}
              className="view-btn  my-2  pull-right"
              color="primary"
              startIcon={<CompareArrowsIcon />}
              size="small"
              variant="text"
              onClick={handleClick}
            >
              Compare
            </Button>
            <div>
              {/* <Button aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
                 Open Popover
                     </Button> */}
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom'
                  // horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top'
                  //horizontal: 'left',
                }}
                className="compare-box"
              >
                <Typography
                  style={{ padding: '0px' }}
                  className={classes.typography}
                  id="comparison-heading"
                >
                  Comparison
                </Typography>
                <div className="container">
                  <div className="row" id="popover-box">
                    <div className="col-md-4">
                      {' '}
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        className="select2 pull-right"
                      >
                        <Select
                          value={compareFormik.values.compare1.date}
                          onChange={compareFormik.handleChange('compare1.date')}
                          displayEmpty
                          className="form-control"
                          className={(classes.selectEmpty, classes.formControl)}
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem value="">
                            <p>Date</p>
                          </MenuItem>
                          {compareFormik.values.compare1.month &&
                            compareFormik.values.compare1.year &&
                            Array.apply(
                              null,
                              Array(
                                moment()
                                  .set({
                                    month: compareFormik.values.compare1.month,
                                    year: compareFormik.values.compare1.year
                                  })
                                  .daysInMonth()
                              )
                            ).map((_, index) => (
                              <MenuItem value={(index + 1).toString()}>
                                {index + 1}
                              </MenuItem>
                            ))}
                        </Select>
                        {/* <FormHelperText>Without label</FormHelperText> */}
                      </FormControl>
                    </div>
                    <div className="col-md-4">
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        className="select2 pull-right"
                      >
                        <Select
                          value={compareFormik.values.compare1.month}
                          error={
                            compareFormik.touched.compare1?.month &&
                            compareFormik.errors.compare1?.month
                          }
                          onBlur={compareFormik.handleBlur('compare1.month')}
                          onChange={(e) => {
                            compareFormik.setFieldValue(
                              'compare1.month',
                              e.target.value
                            );
                            compareFormik.setFieldValue('compare1.date', '');
                          }}
                          displayEmpty
                          className={(classes.selectEmpty, classes.formControl)}
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem value="">
                            <p>Month</p>
                          </MenuItem>
                          {Array.apply(null, Array(12)).map((_, index) => {
                            let monthName = moment()
                              .set('month', index)
                              .format('MMMM');
                            return (
                              <MenuItem value={index}>{monthName}</MenuItem>
                            );
                          })}
                        </Select>
                        {/* <FormHelperText>Without label</FormHelperText> */}
                      </FormControl>
                    </div>
                    <div className="col-md-4">
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        className="select2 pull-right"
                      >
                        <Select
                          value={compareFormik.values.compare1.year}
                          error={
                            compareFormik.touched.compare1?.year &&
                            compareFormik.errors.compare1?.year
                          }
                          onBlur={compareFormik.handleBlur('compare1.year')}
                          onChange={(e) => {
                            compareFormik.setFieldValue(
                              'compare1.year',
                              e.target.value
                            );
                            compareFormik.setFieldValue('compare1.date', '');
                          }}
                          displayEmpty
                          className={(classes.selectEmpty, classes.formControl)}
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem value="">
                            <p>Year</p>
                          </MenuItem>
                          <MenuItem value={'2018'}>2018</MenuItem>
                          <MenuItem value={'2019'}>2019</MenuItem>
                          <MenuItem value={'2020'}>2020</MenuItem>
                          <MenuItem value={'2021'}>2021</MenuItem>
                        </Select>
                        {/* <FormHelperText>Without label</FormHelperText> */}
                      </FormControl>
                    </div>
                    <div className="col-md-12 text-center">
                      <Typography className="to">
                        To
                        <br />
                        <br />
                      </Typography>
                    </div>
                  </div>
                  <div className="row" id="popover-box">
                    <div className="col-md-4">
                      {' '}
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        className="select2 pull-right"
                      >
                        <Select
                          value={compareFormik.values.compare2.date}
                          onChange={compareFormik.handleChange('compare2.date')}
                          displayEmpty
                          className={(classes.selectEmpty, classes.formControl)}
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem value="">
                            <p>Date</p>
                          </MenuItem>
                          {compareFormik.values.compare2.month &&
                            compareFormik.values.compare2.year &&
                            Array.apply(
                              null,
                              Array(
                                moment()
                                  .set({
                                    month: compareFormik.values.compare2.month,
                                    year: compareFormik.values.compare2.year
                                  })
                                  .daysInMonth()
                              )
                            ).map((_, index) => (
                              <MenuItem value={(index + 1).toString()}>
                                {index + 1}
                              </MenuItem>
                            ))}
                        </Select>
                        {/* <FormHelperText>Without label</FormHelperText> */}
                      </FormControl>
                    </div>
                    <div className="col-md-4">
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        className="select2 pull-right"
                      >
                        <Select
                          value={compareFormik.values.compare2.month}
                          error={
                            compareFormik.touched.compare2?.month &&
                            compareFormik.errors.compare2?.month
                          }
                          onBlur={compareFormik.handleBlur('compare2.month')}
                          onChange={(e) => {
                            compareFormik.setFieldValue(
                              'compare2.month',
                              e.target.value
                            );
                            compareFormik.setFieldValue('compare2.date', '');
                          }}
                          displayEmpty
                          className={(classes.selectEmpty, classes.formControl)}
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem value="">
                            <p>Month</p>
                          </MenuItem>
                          {Array.apply(null, Array(12)).map((_, index) => {
                            let monthName = moment()
                              .set('month', index)
                              .format('MMMM');
                            return (
                              <MenuItem value={index}>{monthName}</MenuItem>
                            );
                          })}
                        </Select>
                        {/* <FormHelperText>Without label</FormHelperText> */}
                      </FormControl>
                    </div>
                    <div className="col-md-4">
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        className="select2 pull-right"
                      >
                        <Select
                          value={compareFormik.values.compare2.year}
                          error={
                            compareFormik.touched.compare2?.year &&
                            compareFormik.errors.compare2?.year
                          }
                          onBlur={compareFormik.handleBlur('compare2.year')}
                          onChange={(e) => {
                            compareFormik.setFieldValue(
                              'compare2.year',
                              e.target.value
                            );
                            compareFormik.setFieldValue('compare2.date', '');
                          }}
                          displayEmpty
                          className={(classes.selectEmpty, classes.formControl)}
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem value="">
                            <p>Year</p>
                          </MenuItem>
                          <MenuItem value={'2018'}>2018</MenuItem>
                          <MenuItem value={'2019'}>2019</MenuItem>
                          <MenuItem value={'2020'}>2020</MenuItem>
                          <MenuItem value={'2021'}>2021</MenuItem>
                        </Select>
                        {/* <FormHelperText>Without label</FormHelperText> */}
                      </FormControl>
                    </div>
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-4 text-center">
                    <Button
                      className="view-btn form-control  my-4  text-center"
                      color="primary"
                      onClick={compareFormik.handleSubmit}
                      size="small"
                      variant="text"
                    >
                      Compare
                    </Button>
                  </div>
                </div>
              </Popover>
            </div>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              className="select2  pull-right"
            >
              <Select
                value={graphFormik.values.location}
                onChange={graphFormik.handleChange('location')}
                displayEmpty
                className={(classes.selectEmpty, classes.formControl)}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="">
                  <p>Location</p>
                </MenuItem>
                {locations.map((location) => (
                  <MenuItem value={location}>{location}</MenuItem>
                ))}
              </Select>
              {/* <FormHelperText>Without label</FormHelperText> */}
            </FormControl>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              className="select2 pull-right"
            >
              {currView === views.GRAPH && (
                <Select
                  value={graphFormik.values.graphMonth}
                  onChange={graphFormik.handleChange('graphMonth')}
                  displayEmpty
                  className={(classes.selectEmpty, classes.formControl)}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="">
                    <p>Month</p>
                  </MenuItem>
                  {Array.apply(null, Array(12)).map((_, index) => {
                    let monthName = moment().set('month', index).format('MMMM');
                    return <MenuItem value={index}>{monthName}</MenuItem>;
                  })}
                </Select>
              )}
              {/* <FormHelperText>Without label</FormHelperText> */}
            </FormControl>
          </div>
          <Divider />
        </div>
        {/* <div className="pull-right"> */}
        {/* </div> */}
        {currView === views.COMPARE ? (
          <>
            {' '}
            <div className="container">
              <div className="d-flex w-100 justify-content-end align-items-center py-3">
                <Button
                  className="view-btn"
                  color="primary"
                  startIcon={<CloseIcon />}
                  size="small"
                  variant="text"
                  onClick={() => setCurrView(views.GRAPH)}
                >
                  Close
                </Button>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Box
                    sx={{
                      height: 200,
                      position: 'relative',
                      top: 20
                    }}
                  >
                    <h5 className="text-center year-pie">
                      {'< ' +
                        displayDate(
                          compareFormik.values.compare1.date,
                          compareFormik.values.compare1.month,
                          compareFormik.values.compare1.year
                        ) +
                        ' >'}
                    </h5>
                    {loading2 ? (
                      <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                        <CustomSpinner />
                      </div>
                    ) : cmpGraph1.every((ele) => ele === 0) ? (
                      <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                        <div>No data available</div>
                      </div>
                    ) : (
                      <Doughnut
                        redraw={cmpGraph1}
                        data={{
                          datasets: [
                            {
                              data: cmpGraph1,
                              backgroundColor: [
                                '#0BA360',
                                '#FDA232',
                                '#F96E6E'
                              ],
                              borderWidth: 5,
                              borderColor: colors.common.white,
                              hoverBorderColor: colors.common.white
                            }
                          ],
                          labels: ['Green Score', 'Orange Score', 'Red Score']
                        }}
                        options={options}
                      />
                    )}
                  </Box>
                </div>
                <div className="col-md-6">
                  <Box
                    sx={{
                      height: 200,
                      position: 'relative',
                      top: 20
                    }}
                  >
                    <h5 className="text-center year-pie">
                      {' '}
                      {'< ' +
                        displayDate(
                          compareFormik.values.compare2.date,
                          compareFormik.values.compare2.month,
                          compareFormik.values.compare2.year
                        ) +
                        ' >'}
                    </h5>
                    {loading3 ? (
                      <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                        <CustomSpinner />
                      </div>
                    ) : cmpGraph2.every((ele) => ele === 0) ? (
                      <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                        <div>No data available</div>
                      </div>
                    ) : (
                      <Doughnut
                        redraw={cmpGraph2}
                        data={{
                          datasets: [
                            {
                              data: cmpGraph2,
                              backgroundColor: [
                                '#0BA360',
                                '#FDA232',
                                '#F96E6E'
                              ],
                              borderWidth: 5,
                              borderColor: colors.common.white,
                              hoverBorderColor: colors.common.white
                            }
                          ],
                          labels: ['Green Score', 'Orange Score', 'Red Score']
                        }}
                        options={options}
                      />
                    )}
                  </Box>
                </div>
              </div>
            </div>
            <Box
              sx={{
                justifyContent: 'center',
                pt: 5,
                pl: 20
              }}
            >
              <div className="row ">
                <div className="col-md-4 dots">
                  <li className="dot-green">Green Score</li>
                </div>
                <div className="col-md-4 dots">
                  <li className="dot-orange">Orange Score</li>
                </div>
                <div className="col-md-4 dots">
                  <li className="dot-red">Red Score</li>
                </div>
                {/* <div className="col-md-6 dots"><li className="dot-grey">Others</li></div> */}
              </div>
            </Box>
          </>
        ) : loading1 ? (
          <div className="d-flex w-100 h-100 justify-content-center align-items-center">
            <CustomSpinner />
          </div>
        ) : (
          <>
            <Box
              sx={{
                height: 200,
                position: 'relative',
                top: 25
              }}
            >
              <h5 className="dropddown-selection">
                Month :{' '}
                {moment()
                  .set('month', graphFormik.values.graphMonth)
                  .format('MMMM')}
              </h5>
              {singleGraph.every((ele) => ele === 0) ? (
                <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                  <div>No data available</div>
                </div>
              ) : (
                <Doughnut
                  redraw={singleGraph}
                  data={{
                    datasets: [
                      {
                        data: singleGraph,
                        backgroundColor: ['#0BA360', '#FDA232', '#F96E6E'],
                        borderWidth: 5,
                        borderColor: colors.common.white,
                        hoverBorderColor: colors.common.white
                      }
                    ],
                    labels: ['Green Score', 'Orange Score', 'Red Score']
                  }}
                  options={options}
                />
              )}
            </Box>

            <Box
              sx={{
                justifyContent: 'center',
                pt: 5,
                pl: 20
              }}
            >
              <div className="row ">
                <div className="col-md-4 dots">
                  <li className="dot-green">Green Score</li>
                </div>
                <div className="col-md-4 dots">
                  <li className="dot-orange">Orange Score</li>
                </div>
                <div className="col-md-4 dots">
                  <li className="dot-red">Red Score</li>
                </div>
                {/* <div className="col-md-6 dots"><li className="dot-grey">Others</li></div> */}
              </div>
            </Box>
          </>
        )}{' '}
      </CardContent>
    </Card>
  );
};

export default CapCompareGraph;

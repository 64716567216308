import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
// import CustomerListResults from 'src/components/customer/CustomerListResults';
// import CustomerListToolbar from 'src/components/customer/CustomerListToolbar';
// import customers from 'src/__mocks__/customers';
import Checklistcomponent from 'src/components/TPI/Assessment/Checklistcomponent';
import { useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import Api from 'src/Api';
import swal from 'sweetalert';
import OverlaySpinner from 'src/components/common/OverlaySpinner';
import { Link } from 'react-router-dom';
import { PERMISSIONS } from 'src/Constants';
import { useSelector } from 'react-redux';

const Checklist = () => {
  const navigation = useNavigate();
  const { userData } = useSelector((state) => state.auth);
  const { responseId, categoryId } = useParams();
  const [response, setResponse] = useState(null);
  const [section, setSection] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchAssessmentResponseById = async () => {
    try {
      setLoading(true);
      let query = {
        responseId: responseId,
        include: {
          assessment: false,
          sections_category: true,
          sections_questions_question: true
        }
      };
      let request = await Api.getResponseById(query);
      let response = request.data.data;
      if (PERMISSIONS.TPI.NOASSESS_STATES.includes(response?.status)) {
        return navigation('/auditor/assessment', { replace: true });
      }
      setResponse(response);
      setSection(
        response.sections.find((section) => section._id === categoryId)
      );
    } catch (error) {
      console.log(error);
      return swal({
        title: 'Error',
        text: 'Error in fetching assessment',
        icon: 'error',
        button: 'OK'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log(categoryId, responseId);
    if (responseId && categoryId) fetchAssessmentResponseById();
    return () => {};
  }, [responseId, categoryId]);
  return (
    <>
      {loading && <OverlaySpinner />}
      <Helmet>
        <title>Checklist | Oren</title>
      </Helmet>
      {section && (
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3
          }}
        >
          <Container maxWidth={false}>
            <h2>
              {userData.type == 4 ? (
                <ul class="breadcrumb">
                  {/* <li><a href="dashboard">Home</a></li> */}
                  <li>
                    <Link to="/auditor/Assessment">Assessment</Link>
                  </li>
                  <li>
                    <Link to={`/auditor/assessment/${responseId}/categories`}>
                      Categories
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/auditor/assessment/${responseId}/categories/${categoryId}`}
                    >
                      Checklist
                    </Link>
                  </li>
                </ul>
              ) : (
                <ul class="breadcrumb">
                  {/* <li><a href="dashboard">Home</a></li> */}
                  <li>
                    <Link to="/sapphire/Assessment">Assessment</Link>
                  </li>
                  <li>
                    <Link to={`/sapphire/cap_assesmentview/${responseId}`}>
                      Categories
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/sapphire/cap_assesmentview/${responseId}/Categories/${categoryId}`}
                    >
                      Checklist
                    </Link>
                  </li>
                </ul>
              )}
            </h2>
            <Checklistcomponent
              section={section}
              setLoading={setLoading}
              responseId={responseId}
              response={response}
            />
            <Box sx={{ pt: 3 }}>
              {/* <CustomerListResults customers={customers} /> */}
            </Box>
          </Container>
        </Box>
      )}
    </>
  );
};

export default Checklist;

import {
  Box,
  Card,
  CardContent,
  LinearProgress,
  MenuItem,
  Paper,
  Select
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Close, Edit, History } from '@material-ui/icons';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import OverlaySpinner from 'src/components/common/OverlaySpinner';
import { roundTwoDecimals } from 'src/Constants';
import { setupAssessmentsForCompany } from 'src/store/assessment/thunk';
import { setFilterValue } from 'src/store/assessment/slice';
import { useNavigate } from 'react-router-dom';

const AssessmentCard = ({ assessment, status }) => {
  let progress = assessment.sections.reduce(
    (acc, section) => {
      if (
        section.questions.every((quest) => quest.answerStatus === 'ANSWERED')
      ) {
        acc.completed++;
      }
      return acc;
    },
    { completed: 0, total: assessment.sections.length }
  );
  let progPerc = !!progress.total
    ? roundTwoDecimals((progress.completed / progress.total) * 100)
    : 0;
  return (
    <div class="col-lg-3 col-12 text-center">
      <div class="box-column">
        <div class={'box-top ' + assessment.status.toLowerCase()}>
          <h5 className="assess-title">{assessment.assessment.name}</h5>
          <br />
          <h5 className="assess-title">
            Score :{' '}
            {assessment.sections.reduce(
              (acc, curr) => acc + curr.scoredMarks,
              0
            )}{' '}
            /{' '}
            {assessment.sections.reduce(
              (acc, curr) => acc + curr.totalMarks,
              0
            )}
          </h5>
        </div>
        <div class="box-bottom">
          <div class="box-title facebook-title">
            {assessment.contractorName}
          </div>
          <div class="box-text">
            Last Updated:{' '}
            {moment(assessment.lastAccessTime).format('DD/MM/YYYY')}
            <div className="row">
              <div className="col-md-10">
                <LinearProgress
                  className="category-progress-bar catbar "
                  value={progPerc}
                  variant="determinate"
                />
              </div>
              <div className="col-md-2">
                <p className=" catper ">{progPerc}%</p>
              </div>
            </div>
            <Link to={'/sapphire/Cap_AssesmentView/' + assessment._id}>
              <Button
                className="view-btn"
                color="primary"
                endIcon={<ArrowRightIcon />}
                size="small"
                variant="text"
              >
                View
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const timeFilters = {
  NTO: 'NTO',
  OTN: 'OTN'
};

const renderCardsWithFilter = (responses, filter, contractor, timeFilter) => {
  let filtered = responses;
  if (contractor !== 'ALL') {
    filtered = filtered.filter((resp) => resp.contractorId === contractor);
  }
  if (filter !== 'ALL') {
    filtered = filtered.filter((response) => response.status === filter);
  }

  if (timeFilter === timeFilters.NTO) {
    let toFilter = [...filtered];
    filtered = toFilter.sort((a, b) =>
      moment(b.lastAccessTime).diff(moment(a.lastAccessTime))
    );
  }

  if (timeFilter === timeFilters.OTN) {
    let toFilter = [...filtered];
    filtered = toFilter.sort((b, a) =>
      moment(b.lastAccessTime).diff(moment(a.lastAccessTime))
    );
  }

  return filtered.map((response) => (
    <AssessmentCard status={response.status} assessment={response} />
  ));
};

const Category = (props) => {
  const { assessments, responses, filter, contractors, loading } = useSelector(
    (state) => state.assessment
  );
  const { userData } = useSelector((state) => state.auth);
  // const { filter } = useSelector((state) => state.assessment);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //const [filter, setFilter] = useState('ALL');
  const [contractor, setContractor] = useState('ALL');
  const [timeFilter, setTimeFilter] = useState(timeFilters.NTO);

  const [assessModal, setAssessModal] = useState(false);

  const handleAssessModal = () => {
    setAssessModal((old) => !old);
  };

  const setFilter = (data) => {
    dispatch(setFilterValue(data));
  };

  useEffect(() => {
    if (userData && !!userData.companyId)
      dispatch(setupAssessmentsForCompany(userData.companyId));
    return () => {};
  }, [userData]);

  return (
    <Box {...props}>
      {loading && <OverlaySpinner />}
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            {/* <div className="Category">
                            <h2>Assessment</h2>
                            <hr />
                        </div> */}
            <div className="Button mt-3 d-flex w-100 justify-content-between align-items-center">
              <div>
                {[
                  'All',
                  // 'New',
                  'Pending',
                  'Submitted',
                  'Reassessment',
                  'Completed'
                ].map((status) => (
                  <button
                    type="button"
                    className={`btn ${'btn-' + status.toLowerCase()}`}
                    style={
                      filter == status.toUpperCase()
                        ? {
                            boxShadow: '0 0 0 0.25rem rgb(13 110 253 / 25%)'
                          }
                        : {}
                    }
                    onClick={() => setFilter(status.toUpperCase())}
                  >
                    {status}
                  </button>
                ))}
              </div>
              <div style={{ marginBottom: '15px' }}>
                <Select
                  startAdornment={<History />}
                  value={timeFilter}
                  onChange={(e) => setTimeFilter(e.target.value)}
                  defaultValue={''}
                  variant="outlined"
                  style={{
                    marginRight: '1rem',
                    transform: 'translateY(.6rem)'
                  }}
                >
                  <MenuItem value={timeFilters.NTO}>Newest To Oldest</MenuItem>
                  <MenuItem value={timeFilters.OTN}>Oldest To Newest</MenuItem>
                </Select>
                <Select
                  value={contractor}
                  onChange={(e) => setContractor(e.target.value)}
                  defaultValue={'ALL'}
                  variant="outlined"
                  style={{
                    marginRight: '1rem'
                  }}
                >
                  <MenuItem value="ALL">All Companies</MenuItem>
                  {contractors.length !== 0
                    ? contractors.map((contra, index) => {
                        return (
                          <MenuItem key={index} value={contra.user}>
                            {contra.name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Select>
                <Button
                  style={{
                    backgroundColor: '#223059'
                  }}
                  onClick={handleAssessModal}
                >
                  Assessments
                </Button>
              </div>
            </div>
            {/* <br /> */}
            <div className="category-container">
              <div class="box">
                <div class="pl-24 pr-24">
                  <div class="row">
                    {renderCardsWithFilter(
                      responses,
                      filter,
                      contractor,
                      timeFilter
                    )}
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </Box>
      <Modal
        show={assessModal}
        onHide={handleAssessModal}
        size="lg"
        backdrop="static"
        keyboard="false"
        centered
      >
        <Modal.Header>
          <Modal.Title>Assessments</Modal.Title>
          <Close
            fontSize="medium"
            onClick={handleAssessModal}
            style={{ color: 'red', cursor: 'pointer' }}
          />
        </Modal.Header>
        <Modal.Body>
          <Box>
            {assessments.map((assessment) =>
              !responses.some(
                (res) => res.assessment._id === assessment._id
              ) ? (
                <Paper variant="outlined" className={'mb-2'}>
                  <div
                    className={
                      'd-flex w-100 px-3 py-2 justify-content-between align-items-center  '
                    }
                  >
                    <div>{assessment.name}</div>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={() =>
                        navigate('/sapphire/editAssessment/' + assessment?._id)
                      }
                    >
                      <Edit />
                    </IconButton>
                    {/* <Button variant="outlined">Edit</Button> */}
                  </div>
                </Paper>
              ) : null
            )}
          </Box>
        </Modal.Body>
      </Modal>
    </Box>
  );
};

export default Category;

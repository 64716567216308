import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  TextField,
  Typography
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, Navigate, useNavigate } from 'react-router-dom';
import OverlaySpinner from 'src/components/common/OverlaySpinner';
import { loginUser } from 'src/store/auth/thunk';
import * as Yup from 'yup';
import * as Constants from '../Constants';

const logo = {
  avatarUrl: '/static/images/oren-logo.png'
};
const Login = () => {
  const [checked, setChecked] = React.useState(true);
  const [showPassword, setShowPassword] = React.useState(false);
  const { userData, isLoggedIn } = useSelector((state) => state.auth);
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    let status = localStorage.getItem('rememberMe');
    if (+status === 1) {
      setRememberMe(true);
    } else {
      setRememberMe(false);
    }
    return () => {};
  }, []);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);

  const handleSubmit = async (val) => {
    console.log('LOGIN>>>', val);
    await dispatch(loginUser(val.email, val.password));
    // navigate('/Contractor/dashboard', { replace: true });
    if (rememberMe) {
      localStorage.setItem('email', val.email);
      localStorage.setItem('pass', val.password);
    } else {
      localStorage.setItem('email', '');
      localStorage.setItem('pass', '');
    }
    console.log('LOGIN');
  };

  if (!loading && isLoggedIn && userData?.type) {
    console.log(
      'redirecting',
      Constants.getUrl(userData?.type),
      userData?.type,
      isLoggedIn
    );
    return <Navigate to={Constants.getUrl(userData?.type)} replace={true} />;
  }

  return (
    <>
      {loading && <OverlaySpinner />}
      <Helmet>
        <title>Login | ORENNOW</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            enableReinitialize={true}
            initialValues={{
              email: rememberMe ? localStorage.getItem('email') : '',
              password: rememberMe ? localStorage.getItem('pass') : ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                {/* <Box sx={{ mb: 3 }}>
                  
                  
                </Box> */}
                <Card id="login-card">
                  <Grid container spacing={3}>
                    <Grid className="text-center" item xs={12} md={12}>
                      <img src={logo.avatarUrl} alt="ORENNOW" id="logo2" />
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      pb: 1,
                      pt: 3
                    }}
                  >
                    <Typography
                      align="center"
                      color="textSecondary"
                      variant="body1"
                    >
                      Login with email address
                    </Typography>
                  </Box>
                  <FormControl sx={{ mt: 3, width: '46ch' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Email address
                    </InputLabel>
                    <OutlinedInput
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                    />
                    <span
                      style={{
                        fontSize: '12px',
                        marginLeft: '12px',
                        marginTop: '5px',
                        color: 'red'
                      }}
                    >
                      {touched.email && errors.email}
                    </span>
                  </FormControl>
                  <FormControl sx={{ mt: 3, width: '46ch' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      style={{ width: '100%' }}
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="Password"
                      type={showPassword ? 'text' : 'password'}
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      variant="outlined"
                      endAdornment={
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      }
                    />
                    <span
                      style={{
                        fontSize: '12px',
                        marginLeft: '12px',
                        marginTop: '5px',
                        color: 'red'
                      }}
                    >
                      {touched.password && errors.password}
                    </span>
                  </FormControl>
                  {/* <div className="pass-input">
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="Password"
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                    />
                    <div>
                      <i
                        onClick={() => setPassVisible(false)}
                        className={
                          !passVisible
                            ? 'hide-eye-icon eye-icon'
                            : 'fa fa-eye eye-icon'
                        }
                        aria-hidden="true"
                      ></i>
                      <i
                        onClick={() => setPassVisible(true)}
                        className={
                          passVisible
                            ? 'hide-eye-icon eye-icon'
                            : 'fa fa-eye-slash eye-icon'
                        }
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div> */}
                  <Box sx={{ py: 2 }}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      className="login-button"
                    >
                      Sign in
                    </Button>
                  </Box>
                  <Grid container spacing={3}>
                    <Grid className="pull-left" item xs={12} md={6}>
                      <Typography color="textSecondary" variant="body1">
                        {' '}
                        <Checkbox
                          color="primary"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                          checked={rememberMe}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setRememberMe(true);
                              localStorage.setItem('rememberMe', 1);
                            } else {
                              setRememberMe(false);
                              localStorage.setItem('rememberMe', 0);
                              localStorage.setItem('email', '');
                              localStorage.setItem('pass', '');
                            }
                          }}
                        />{' '}
                        Remember me
                      </Typography>
                    </Grid>
                    <Grid className="float-right" item xs={12} md={6}>
                      <Typography
                        style={{ float: 'right' }}
                        color="textSecondary"
                        variant="body1"
                        className="my-2"
                      >
                        {' '}
                        <Link
                          component={RouterLink}
                          to="/Forgot_Password"
                          variant="h6"
                        >
                          Forgot Password?
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Login;

import {
  Box,
  Card,
  CardContent,
  LinearProgress,
  MenuItem,
  Select
} from '@material-ui/core';
import { History } from '@material-ui/icons';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import OverlaySpinner from 'src/components/common/OverlaySpinner';
import { roundTwoDecimals } from 'src/Constants';
import { setFilterValue } from 'src/store/assessment/slice';
import {
  createAssessmentResponse,
  setupAssessmentsForContractor
} from 'src/store/assessment/thunk';

const ResponseCard = ({ response, status }) => {
  let progress = response.sections.reduce(
    (acc, section) => {
      if (
        section.questions.every((quest) => quest.answerStatus === 'ANSWERED')
      ) {
        acc.completed++;
      }
      return acc;
    },
    { completed: 0, total: response.sections.length }
  );
  let progPerc = !!progress.total
    ? roundTwoDecimals((progress.completed / progress.total) * 100)
    : 0;
  return (
    <div class="col-lg-3 col-12 text-center">
      <div class="box-column">
        <div class={'box-top ' + response.status.toLowerCase()}>
          <h5 className="assess-title">{response.assessment.name}</h5>
          <br />
          <h5 className="assess-title">
            Score:{' '}
            {response.sections.reduce((acc, curr) => acc + curr.scoredMarks, 0)}{' '}
            /{' '}
            {response.sections.reduce((acc, curr) => acc + curr.totalMarks, 0)}
          </h5>
        </div>
        <div class="box-bottom">
          <div class="box-title facebook-title">{response.contractorName}</div>
          <div class="box-text">
            Last Updated: {moment(response.lastAccessTime).format('DD/MM/YYYY')}
            <div className="row">
              <div className="col-md-10">
                <LinearProgress
                  className="category-progress-bar catbar "
                  value={progPerc}
                  variant="determinate"
                />
              </div>
              <div className="col-md-2">
                <p className=" catper ">{progPerc}%</p>
              </div>
            </div>
            <Link
              to={'/atgl/Contractor/assessment/' + response._id + '/categories'}
            >
              <Button
                className="view-btn"
                color="primary"
                endIcon={<ArrowRightIcon />}
                size="small"
                variant="text"
              >
                View
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const AssessmentCard = ({ assessment, onClick, companyName }) => {
  return (
    <div class="col-lg-3 col-12 text-center">
      <div class="box-column">
        <div class={'box-top ' + assessment.status.toLowerCase()}>
          <h5 className="assess-title">{assessment.name}</h5>
          <br />
          {/* <h5 className="assess-title">
            Score:{' '}
            {assessment.sections.reduce((acc, curr) => acc + curr.scoredMarks, 0)}
          </h5> */}
          <br/>
        </div>
        <div class="box-bottom">
          <div class="box-title facebook-title">{companyName}</div>
          <div class="box-text">
            Created At: {moment(assessment.createdAt).format('DD/MM/YYYY')}
            <div className="row">
            <div className="row">
              <div className="col-md-10">
                <LinearProgress
                  className="category-progress-bar catbar "
                  value={0}
                  variant="determinate"
                />
              </div>
              <div className="col-md-2">
                <p className=" catper ">{0}%</p>
              </div>
            </div>
            </div>
          </div>
          <Button
            onClick={onClick}
            className="view-btn"
            color="primary"
            endIcon={<ArrowRightIcon />}
            size="small"
            variant="text"
          >
            Start Assessment
          </Button>
        </div>
      </div>
    </div>
  );
};

const timeFilters = {
  NTO: 'NTO',
  OTN: 'OTN'
};

const renderResponseCardsWithFilter = (responses, filter, timeFilter) => {
  let filtered = responses;
  if (filter !== 'ALL') {
    filtered = filtered.filter((response) => response.status === filter);
  }

  if (timeFilter === timeFilters.NTO) {
    let toFilter = [...filtered];
    filtered = toFilter.sort((a, b) =>
      moment(b.lastAccessTime).diff(moment(a.lastAccessTime))
    );
  }

  if (timeFilter === timeFilters.OTN) {
    let toFilter = [...filtered];
    filtered = toFilter.sort((b, a) =>
      moment(b.lastAccessTime).diff(moment(a.lastAccessTime))
    );
  }

  return filtered.map((response) => (
    <ResponseCard status={response.status} response={response} />
  ));
};

const renderAssessmentCardsWithFilter = (
  assessments,
  filter,
  onStartAssessment,
  companyName,
  timeFilter
) => {
  let filtered = assessments;

  if (filter !== 'ALL' && filter !== 'NEW') {
    filtered = [];
  }

  if (timeFilter === timeFilters.NTO) {
    let toFilter = [...filtered];
    filtered = toFilter.sort((a, b) =>
      moment(b.createdAt).diff(moment(a.createdAt))
    );
  }

  if (timeFilter === timeFilters.OTN) {
    let toFilter = [...filtered];
    filtered = toFilter.sort((b, a) =>
      moment(b.createdAt).diff(moment(a.createdAt))
    );
  }

  return filtered.map((assessment) => (
    <AssessmentCard
      assessment={assessment}
      companyName={companyName}
      onClick={() => onStartAssessment(assessment._id)}
    />
  ));
};

const Category = (props) => {
  const { responses, assessments,filter, loading } = useSelector(
    (state) => state.assessment
  );
  const { userData } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [filter, setFilter] = useState('ALL');
  const setFilter = (data) => {
    dispatch(setFilterValue(data));
  };
  const [timeFilter, setTimeFilter] = useState(timeFilters.NTO);

  useEffect(() => {
    if (userData && !!userData.user)
      dispatch(setupAssessmentsForContractor(userData.user));
    return () => {};
  }, [userData]);

  const onStartAssessment = (assessmentId) => {
    const contractor = {
      contractorId: userData?.user,
      contractorName: userData?.name,
      companyId: userData?.cmp_id,
      contractorLocation: userData?.location
    };
    dispatch(createAssessmentResponse(contractor, assessmentId, navigate));
  };

  return (
    <Box {...props}>
      {loading && <OverlaySpinner />}
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <div className="d-flex w-100 justify-content-between align-items-center">
              <div className="Button">
                {[
                  'All',
                  'New',
                  'Pending',
                  'Submitted',
                  'Reassessment',
                  'Completed'
                ].map((status) => (
                  <button
                    type="button"
                    className={'btn btn-' + status.toLowerCase()}
                    style={
                      filter === status.toUpperCase()
                        ? {
                            boxShadow: '0 0 0 0.25rem rgb(13 110 253 / 25%)'
                          }
                        : {}
                    }
                    onClick={() => setFilter(status.toUpperCase())}
                  >
                    {status}
                  </button>
                ))}
              </div>
              <Select
                startAdornment={<History />}
                value={timeFilter}
                onChange={(e) => setTimeFilter(e.target.value)}
                defaultValue={''}
                variant="outlined"
                style={{
                  marginRight: '1rem',
                  transform: 'translateY(.6rem)'
                }}
              >
                <MenuItem value={timeFilters.NTO}>Newest To Oldest</MenuItem>
                <MenuItem value={timeFilters.OTN}>Oldest To Newest</MenuItem>
              </Select>
            </div>
            {/* <br /> */}
            <div className="category-container">
              <div class="box">
                <div class="pl-24 pr-24">
                  <div class="row">
                    {renderAssessmentCardsWithFilter(
                      assessments,
                      filter,
                      onStartAssessment,
                      userData?.name,
                      timeFilter
                    )}
                    {renderResponseCardsWithFilter(
                      responses,
                      filter,
                      timeFilter
                    )}
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default Category;

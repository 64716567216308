import { experimentalStyled } from '@material-ui/core';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { getUrl, USER_TYPES } from 'src/Constants';
import OverlaySpinner from './common/OverlaySpinner';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar3';

const DashboardLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
}));

const DashboardLayoutWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 256
  }
}));

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const DashboardLayout = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { userData, loading, isLoggedIn } = useSelector((state) => state.auth);
  console.log(localStorage.getItem('isSignedIn'), 3);
  return (
    <DashboardLayoutRoot>
      <DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />

      <DashboardSidebar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            {isLoggedIn && userData?.type !== USER_TYPES.TPI && (
              <Navigate to={getUrl(userData?.type)} replace={true} />
            )}
            {/* {loading && <OverlaySpinner />} */}
            {localStorage.getItem('isSignedIn') === "true" ? (
              loading || !isLoggedIn ? (
                <OverlaySpinner />
              ) : (
                <Outlet />
              )
            ) : (
              <Navigate to={'/'} replace={true} />
            )}
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;

import React,{Component} from 'react'
import "./style.css"
import CustomSpinner from './CustomSpinner'


const OverlaySpinner =()=>{
        return (
          <div className="splashscreen">
            <CustomSpinner/>
          </div>
        );
}

export default OverlaySpinner
import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import OverlaySpinner from 'src/components/common/OverlaySpinner';
import AssessmentGrid from 'src/components/TPI/Assessment/assessment';
import { setupAssessmentsForTpi } from 'src/store/assessment/thunk';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const Categories = () => {
  const classes = useStyles();
  const [contractorFilter, setContractorFilter] = React.useState('ALL');
  const { responses, contractors, loading } = useSelector(
    (state) => state.assessment
  );
  const { userData } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userData && !!userData.assoc_companies)
      dispatch(setupAssessmentsForTpi(userData.assoc_companies));
    return () => {};
  }, [userData]);

  const handleChange = (event) => {
    setContractorFilter(event.target.value);
  };
  return (
    <>
      <Helmet>
        <title>Assessment | Oren</title>
      </Helmet>
      {loading && <OverlaySpinner />}
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <div className="row">
            <div className="col-md-6">
              <h2>
                <ul class="breadcrumb">
                  {/* <li><a href="dashboard">Home</a></li> */}
                  <li>
                    <a href="Assessment">Assessment</a>
                  </li>
                  {/* <li><a href="Categories">Categories</a></li> */}
                  {/* <li><a href="dashboard">Dashboard</a></li> */}
                </ul>
              </h2>
            </div>
            <div className="col-md-6">
              {/* <a href="categories" > */}
              {/* <Button
                className="view-btn assessment-button pull-right"
                color="primary"
                size="small"
                variant="text"
                ml={5}
              >
                Add New Assessment
              </Button> */}

              {/* </a> */}
              {/* <FormControl
                variant="outlined"
                className={classes.formControl}
                className="select2 pull-right"
              >
                <Select
                  value={contractorFilter}
                  onChange={handleChange}
                  displayEmpty
                  className={(classes.selectEmpty, classes.formControl)}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="ALL">
                    <p>All</p>
                  </MenuItem>

                  {[...new Set(responses.map((res) => res.user))]
                    .map((conId) => {
                      let res = responses.find(
                        (res) => res.user === conId
                      );
                      return {
                        id: res.user,
                        name: res.contractorName
                      };
                    })
                    .map((cont) => (
                      <MenuItem value={cont.id}>{cont.name}</MenuItem>
                    ))}
                </Select>
                {/* <FormHelperText>Without label</FormHelperText> */}
              {/* </FormControl> */}
            </div>
          </div>

          <AssessmentGrid assessments={responses} contractors={contractors} />
          <Box sx={{ pt: 3 }}>
            {/* <CustomerListResults customers={customers} /> */}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Categories;

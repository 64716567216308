import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Api from 'src/Api';
import OverlaySpinner from 'src/components/common/OverlaySpinner';
import { loginUser } from 'src/store/auth/thunk';
import swal from 'sweetalert';
import * as Yup from 'yup';

const logo = {
  avatarUrl: '/static/images/oren-logo.png'
};
const Login = () => {
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleEmailSubmit = async (val, helpers) => {
    let res;
    try {
      setLoading(true);
      let email = val.email;

      res = await Api.forgotPassword({ email });

      if (res.data.code === 400) {
        let authErrCode = res.data.authErrCode;

        switch (authErrCode) {
          case 'auth/internal-error':
            return swal('Error', 'Email not found', 'error');

          default:
            return swal('Error', 'Technical problem. Please try again later');
        }
      }

      swal(
        'Success',
        'Please check your email for password reset link',
        'success'
      ).then(() => navigate('/'));
    } catch (error) {
      console.log('Forgot password error', res);
      return swal('Error', 'Technical problem. Please try again later').then(
        () => navigate('/', { replace: true })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <OverlaySpinner />}
      <Helmet>
        <title> Forgot Password| ORENNOW</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required')
            })}
            onSubmit={handleEmailSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form>
                {/* <Box sx={{ mb: 3 }}>
                  
                  
                </Box> */}
                <Card id="login-card">
                  <Grid container spacing={3}>
                    <Grid className="text-center" item xs={12} md={12}>
                      <img src={logo.avatarUrl} alt="ORENNOW" id="logo2" />
                      <h2 className="forgot-password">
                        Forgot your password ?
                      </h2>
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      pb: 1,
                      pt: 3
                    }}
                  >
                    <Typography
                      align="center"
                      color="textSecondary"
                      variant="body1"
                    >
                      Enter your email address to receive Reset Password email
                    </Typography>
                  </Box>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email Address"
                    margin="normal"
                    name="email"
                    onBlur={handleBlur('email')}
                    onChange={handleChange('email')}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />
                  <div className="row">
                    <div className="col-md-6"></div>
                    <div className="col-md-6"></div>
                  </div>
                  <Box sx={{ py: 2 }}>
                    <Button
                      color="primary"
                      onClick={handleSubmit}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      className="login-button"
                    >
                      Send Email
                    </Button>
                  </Box>
                  <Grid container spacing={3}>
                    <Grid className="pull-left" item xs={12} md={6}></Grid>
                    <Grid className="float-right" item xs={12} md={6}>
                      <Typography
                        style={{ float: 'right' }}
                        color="textSecondary"
                        variant="body1"
                      >
                        {' '}
                        <Link component={RouterLink} to="/" variant="h6">
                          Sign In
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Login;

import {LOGIN_USER,LOGOUT_USER, SET_LOADING} from './types'


export const authInitialState = {
    loading : false,
    userData : null,
    isLoggedIn: false,
}

const auth = (state=authInitialState, action) =>{
    const {type,payload} = action;

    switch(type){ 
        case LOGIN_USER:
            state = {
                ...state,
                loading : false,
                userData : payload,
                isLoggedIn: true
            }
            return state
        case LOGOUT_USER:
            state = { ...state, isLoggedIn : false, userData : null }
            return state
        case SET_LOADING:
            state = { ...state,loading: payload }  
            return state   
        default:
            return state;
    }
}

export default auth
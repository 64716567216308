import React from 'react';
import { Spinner } from 'reactstrap';

const SpinnerContainer = () => {
  return (
    <div className="d-flex w-100 h-100 justify-content-center align-items-center">
      <Spinner style={{ width: '2rem', height: '2rem', color: '#2F3D64' }} />
    </div>
  );
};

export default SpinnerContainer;

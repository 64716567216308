import moment from 'moment';
import { v4 as uuid } from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import '../../css/observations/observation.css';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import 'bootstrap/dist/css/bootstrap.min.css';

const orders = [
  {
    id: uuid(),
    ref: '1',
    date: 1554670800000,
    Time: '12:00pm',
    description: 'Lorem ipsum dodar linuet...',
    Type: 'Accident',
    amount: 16.76,
    color: 'Accident',
    customer: {
      name: 'Adam Denisov'
    },
    createdAt: 1554670800000,
    status: 'Verified'
  },
  {
    id: uuid(),
    ref: '2',
    date: 1554670800000,
    Time: '12:00pm',
    description: 'Lorem ipsum dodar linuet...',
    Type: 'Unsafe-Act',
    amount: 16.76,
    color: 'Unsafe-Act',
    customer: {
      name: 'Adam Denisov'
    },
    createdAt: 1554670800000,
    status: ' Not Verified'
  },
  {
    id: uuid(),
    ref: '3',
    date: 1554670800000,
    Time: '12:00pm',
    description: 'Lorem ipsum dodar linuet...',
    Type: 'Near Miss',
    amount: 16.76,
    color: 'Near-Miss',
    customer: {
      name: 'Adam Denisov'
    },
    createdAt: 1554670800000,
    status: 'Verified'
  },
  {
    id: uuid(),
    ref: '1',
    date: 1554670800000,
    Time: '12:00pm',
    description: 'Lorem ipsum dodar linuet...',
    Type: 'Accident',
    amount: 16.76,
    color: 'Accident',
    customer: {
      name: 'Adam Denisov'
    },
    createdAt: 1554670800000,
    status: 'Verified'
  },
  {
    id: uuid(),
    ref: '2',
    date: 1554670800000,
    Time: '12:00pm',
    description: 'Lorem ipsum dodar linuet...',
    Type: 'Unsafe-Act',
    amount: 16.76,
    color: 'Unsafe-Act',
    customer: {
      name: 'Adam Denisov'
    },
    createdAt: 1554670800000,
    status: ' Not Verified'
  },
  {
    id: uuid(),
    ref: '3',
    date: 1554670800000,
    Time: '12:00pm',
    description: 'Lorem ipsum dodar linuet...',
    Type: 'Near Miss',
    amount: 16.76,
    color: 'Near-Miss',
    customer: {
      name: 'Adam Denisov'
    },
    createdAt: 1554670800000,
    status: 'Verified'
  },
  {
    id: uuid(),
    ref: '1',
    date: 1554670800000,
    Time: '12:00pm',
    description: 'Lorem ipsum dodar linuet...',
    Type: 'Accident',
    amount: 16.76,
    color: 'Accident',
    customer: {
      name: 'Adam Denisov'
    },
    createdAt: 1554670800000,
    status: 'Verified'
  },
  {
    id: uuid(),
    ref: '2',
    date: 1554670800000,
    Time: '12:00pm',
    description: 'Lorem ipsum dodar linuet...',
    Type: 'Unsafe-Act',
    amount: 16.76,
    color: 'Unsafe-Act',
    customer: {
      name: 'Adam Denisov'
    },
    createdAt: 1554670800000,
    status: ' Not Verified'
  },
  {
    id: uuid(),
    ref: '3',
    date: 1554670800000,
    Time: '12:00pm',
    description: 'Lorem ipsum dodar linuet...',
    Type: 'Near Miss',
    amount: 16.76,
    color: 'Near-Miss',
    customer: {
      name: 'Adam Denisov'
    },
    createdAt: 1554670800000,
    status: 'Verified'
  },
  {
    id: uuid(),
    ref: '1',
    date: 1554670800000,
    Time: '12:00pm',
    description: 'Lorem ipsum dodar linuet...',
    Type: 'Accident',
    amount: 16.76,
    color: 'Accident',
    customer: {
      name: 'Adam Denisov'
    },
    createdAt: 1554670800000,
    status: 'Verified'
  },
  {
    id: uuid(),
    ref: '2',
    date: 1554670800000,
    Time: '12:00pm',
    description: 'Lorem ipsum dodar linuet...',
    Type: 'Unsafe-Act',
    amount: 16.76,
    color: 'Unsafe-Act',
    customer: {
      name: 'Adam Denisov'
    },
    createdAt: 1554670800000,
    status: ' Not Verified'
  },
  {
    id: uuid(),
    ref: '3',
    date: 1554670800000,
    Time: '12:00pm',
    description: 'Lorem ipsum dodar linuet...',
    Type: 'Near Miss',
    amount: 16.76,
    color: 'Near-Miss',
    customer: {
      name: 'Adam Denisov'
    },
    createdAt: 1554670800000,
    status: 'Verified'
  }
];

const LatestOrders = (props) => (
  <Card {...props}>
    <CardHeader title="Observation Details" />
    <Divider />
    <PerfectScrollbar>
      <Box sx={{ minWidth: 800 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="text-center">
               Sr. No
              </TableCell>
              <TableCell className="text-center">
                Date
              </TableCell>
              <TableCell sortDirection="desc">
                <Tooltip
                  enterDelay={300}
                  title="Sort"
                >
                  <TableSortLabel>
                    Time
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell className="text-center">
                Reported By
              </TableCell>
              <TableCell className="text-center">
                Description
              </TableCell>
              <TableCell className="text-center">
                Attachment
              </TableCell>
              <TableCell className="text-center">
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => (
              <TableRow
                hover
                key={order.id}
              >
              <TableCell className={order.color}>
              <div className="text-center">
            {order.ref}
            </div>
            </TableCell>
            <TableCell className={order.color}>
               <div className="text-center">
            {moment(order.date).format('DD/MM/YYYY')}
            </div>
            </TableCell>
            <TableCell className={order.color}>
            <div className="text-center">
              {order.Time}
              </div>
            </TableCell>
            <TableCell className={order.color}>
            <div className="text-center">
              {order.customer.name}
              </div>
            </TableCell>
            <TableCell className={order.color} >
            <div className="text-center">
              {order.description}
              </div>
            </TableCell>
            <TableCell className={order.color}>
            <div className="text-center">
            {order.Type}
            </div>
            </TableCell>
                <TableCell className={order.color}>
                  <FilterNoneIcon />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </PerfectScrollbar>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        p: 2
      }}
    >
      <Button
        className="view-btn"
        color="primary"
        endIcon={<ArrowRightIcon />}
        size="small"
        variant="text"
      >
        View all
      </Button>
    </Box>
  </Card>
);

export default LatestOrders;

import { Bar } from 'react-chartjs-2';
import { useState, useEffect } from 'react';
import {
  Box,
  // Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  colors
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import API from '../../Api';
import moment from 'moment';
import OverlaySpinner from '../common/OverlaySpinner';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
// import ArrowRightIcon from '@material-ui/icons/ArrowRight';
// import '../../css/Learning/learning.css';

const Sales = (props) => {
  const theme = useTheme();
  const { userData } = useSelector((state) => state.auth);
  const [bar, setBar] = useState({});
  const [loading, setLoading] = useState(false);

  const data = {
    datasets: [
      {
        backgroundColor: '#1e2b56',
        data: [18, 5, 19, 27, 29, 19, 20]
        // label: 'Accidents'
      }
    ],
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun']
  };

  useEffect(() => {
    if (userData) {
      fetchLearningGraph();
    }
  }, [userData]);

  const fetchLearningGraph = async () => {
    setLoading(true);
    let year = moment().format('YYYY');
    let response = await API.fetchLearningGraph(userData.cmp_id, year);
    // let response = await API.fetchLearningGraph("mXD7brUWklrFWCXwCkcn", year);

    let labels = [...response.data.data].map((obj) =>
      obj[userData.user] ? obj.month : ''
    );

    let data = [...response.data.data].map((obj) =>
      obj[userData.user] ? (obj[userData.user] / 3600).toFixed(2) : 0
    );

    console.log('fetchLearningGraph', { response, data, labels });

    setBar({
      datasets: [
        {
          backgroundColor: '#1e2b56',
          data,
          label: 'Learning in hours'
        }
      ],
      labels
    });
    setLoading(false);
  };

  const options = {
    animation: false,
    cornerRadius: 0,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          barThickness: 40,
          maxBarThickness: 50,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  return loading ? (
    <OverlaySpinner />
  ) : (
    <>
      <Card {...props}>
        <CardHeader title="Monthly Learning" />
        <Divider />
        <CardContent>
          <Box
            sx={{
              height: 200,
              position: 'relative'
            }}
          >
            <Bar data={bar} options={options} />
          </Box>
        </CardContent>
        <Divider />
      </Card>
    </>
  );
};

export default Sales;
